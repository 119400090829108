import { useAxios } from './useAxios';

import { ProductCategory } from '../types/ProductCategory';

import linksForSWR from '../utils/linksForSWR';

interface Response {
  products?: ProductCategory[];
  error: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  mutateProducts: (
    data?: ProductCategory[],
    shouldRevalidate?: boolean,
  ) => Promise<ProductCategory[]>;
}

export default function useProducts(): Response {
  const {
    data: products,
    error,
    mutate: mutateProducts,
  } = useAxios(linksForSWR.products());

  return { products, error, mutateProducts };
}
