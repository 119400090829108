/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid, { GridSize } from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles/createTheme';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Layout from '../../../components/Layout';

import { useAxios } from '../../../hooks/useAxios';

import { UserProfile } from '../../../types/UserProfile';

import linksForSWR from '../../../utils/linksForSWR';
import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '75vh',
  },

  headerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: COLORS.gray2,
    lineHeight: '4rem',
  },

  buttonChangeYourPlan: {
    width: '9.5rem',
    height: '3rem',
    backgroundColor: COLORS.gray2,
    color: COLORS.background,
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    padding: 0,
    borderRadius: '0.5rem',

    '&:hover': {
      backgroundColor: COLORS.black,
      color: COLORS.background,
      transition: '0.3s',
    },
  },

  divider: {
    marginTop: '0.75rem',
    width: '50%',
  },

  subtitle: {
    color: COLORS.gray2,
    fontSize: '1.125rem',
    fontWeight: 600,
    margin: '1.5rem 0',
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',

    '& label': {
      fontSize: '0.75rem',
      color: COLORS.gray2,
    },

    '& input': {
      height: '2.75rem',
      border: `1px solid ${COLORS.lightGray3}`,
      borderRadius: '0.5rem',
      padding: '0 1rem',
      outline: 'none',
      backgroundColor: COLORS.white,
    },
  },

  photoField: {
    marginTop: '0.75rem',

    '& p': {
      fontSize: '0.75rem',
      color: COLORS.gray2,
    },

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      margin: '0.625rem 0 2.25rem 0',
      columnGap: '1.75rem',
    },
  },

  avatar: {
    width: '5.5rem',
    height: '5.5rem',
  },

  buttonChangePhoto: {
    backgroundColor: COLORS.gray2,
    color: COLORS.background,
    fontWeight: 'bold',
    fontSize: '0.75rem',
    textTransform: 'none',
    padding: 0,
    height: '2.25rem',
    width: '6.5rem',
    borderRadius: '0.5rem',

    '&:hover': {
      backgroundColor: COLORS.black,
      color: COLORS.background,
      transition: '0.3s',
    },
  },

  buttonRemovePhoto: {
    height: '2.25rem',
    width: '6.5rem',
    textTransform: 'none',
    color: COLORS.gray2,
    borderRadius: '0.5rem',

    '&:hover': {
      color: COLORS.black,
      transition: '0.3s',
      background: 'none',
    },
  },

  infoIcon: {
    color: COLORS.lightGray3,
    fontSize: '2.125rem',
    marginTop: '1.5rem',
  },
});

type FieldsPersonalInfo = 'name' | 'email' | 'cpf' | 'phone' | 'birth';
type FieldsAddressInfo =
  | 'zipCode'
  | 'address'
  | 'number'
  | 'neighborhood'
  | 'city'
  | 'state';

export default function Profile() {
  const classes = useStyles();

  const matchesMediaQueryLG = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('lg'),
  );

  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    email: '',
    cpf: '',
    phone: '',
    birth: '',
  });

  const [addressInfo, setAddressInfo] = useState({
    zipCode: '',
    address: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
  });

  const { data, error } = useAxios(linksForSWR.client.profile()) as {
    data: UserProfile;
    error: any;
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!data) return;

    setPersonalInfo({
      name: data.name,
      cpf: data.cpf,
      phone: data.phoneNumber,
      email: data.email,
      birth: moment
        .utc(data.birthDate, 'YYYY-MM-DDTHH:mm:ssZ')
        .locale(t('client.profile.locale'))
        .format('L'),
    });

    if (data.address) {
      setAddressInfo({
        zipCode: data.address.zipNumber,
        address: data.address.street,
        number: data.address.number,
        city: data.address.city,
        neighborhood: data.address.neighborhood,
        state: data.address.state,
      });
    }
  }, [data, i18n.language]);

  return (
    <>
      <Layout type="user" routeSelected="profile">
        {!data ? (
          <div className={classes.errorContainer}>
            {error ? t('client.profile.error') : <CircularProgress />}
          </div>
        ) : (
          <div>
            <h1 className={classes.title}>{t('client.profile.title')}</h1>

            <Divider className={classes.divider} />

            <div>
              <h2 className={classes.subtitle}>
                {t('client.profile.personalData.title')}
              </h2>

              <Grid container direction="row" alignItems="center" spacing={3}>
                {[
                  { title: 'name', width: 6 },
                  { title: 'birth', width: 3 },
                  { title: 'cpf', width: 3 },
                  { title: 'email', width: 6 },
                  { title: 'phone', width: 3 },
                ].map(field => (
                  <Grid
                    key={field.title}
                    item
                    xs={field.width as GridSize}
                    className={classes.field}
                  >
                    <label htmlFor={field.title}>
                      {t(`client.profile.personalData.fields.${field.title}`)}
                    </label>

                    <input
                      id={field.title}
                      value={
                        personalInfo[field.title as FieldsPersonalInfo] || ''
                      }
                      disabled
                    />
                  </Grid>
                ))}
              </Grid>
            </div>

            <div className={classes.photoField}>
              <p>{t('client.profile.photo.title')}</p>

              <div>
                <Avatar className={classes.avatar} />

                <Button className={classes.buttonChangePhoto}>
                  {t('client.profile.photo.buttonChange')}
                </Button>

                <Button className={classes.buttonRemovePhoto}>
                  {t('client.profile.photo.buttonRemove')}
                </Button>
              </div>
            </div>

            <div>
              <h2 className={classes.subtitle}>
                {t('client.profile.address.title')}
              </h2>

              <Grid container direction="row" alignItems="center" spacing={3}>
                {[
                  { title: 'zipCode', width: 3 },
                  { title: 'address', width: matchesMediaQueryLG ? 8 : 7 },
                  { title: 'number', width: matchesMediaQueryLG ? 1 : 2 },
                  { title: 'neighborhood', width: 3 },
                  { title: 'city', width: 3 },
                  { title: 'state', width: 3 },
                ].map(field => (
                  <Grid
                    key={field.title}
                    item
                    xs={field.width as GridSize}
                    className={classes.field}
                  >
                    <label htmlFor={field.title}>
                      {t(`client.profile.address.fields.${field.title}`)}
                    </label>

                    <input
                      id={field.title}
                      value={
                        addressInfo[field.title as FieldsAddressInfo] || ''
                      }
                      disabled
                    />
                  </Grid>
                ))}
              </Grid>

              <Tooltip title="">
                <InfoOutlinedIcon className={classes.infoIcon} />
              </Tooltip>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
