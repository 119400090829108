/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import WebViewer from '@pdftron/pdfjs-express';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';

import Layout from '../../../components/Layout';
import ModalSignDocument from '../../../components/Modals/ModalSignDocument';
import ModalDelete from '../../../components/Modals/ModalDelete';

import { useAxios } from '../../../hooks/useAxios';
import useAuthContext from '../../../hooks/useAuthContext';

import { Language } from '../../../types/Language';
import { Document } from '../../../types/Document';

import api from '../../../services/api';

import { i18nToWebview } from '../../../utils/i18nToSomething';
import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    rowGap: '2rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  webviewContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: '79.875vh',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '70%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '82%',
    },
  },

  loading: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  webview: {
    flex: 1,
    margin: '0.5rem',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  historic: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '-2.125rem',

    [theme.breakpoints.up('md')]: {
      width: '30%',
      marginLeft: '2.25rem',
    },

    [theme.breakpoints.up('lg')]: {
      width: '18%',
    },
  },

  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: COLORS.gray2,
    lineHeight: '2rem',
  },

  activitiesList: {
    maxHeight: '67vh',
    padding: 0,
    overflow: 'auto',
    marginTop: '2.25rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.5rem',
  },

  listElement: {
    borderBottom: `1px solid ${COLORS.lightGray1}`,
    marginRight: '1.25rem',

    '& h1': {
      color: COLORS.gray2,
      fontWeight: '500',
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },

    '& p': {
      fontSize: '0.875rem',
      color: COLORS.mediumGray3,
      margin: '0.625rem 0 1.5rem 0',
    },
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '67vh',
  },
}));

export default function DocumentView() {
  const classes = useStyles();
  const { id }: { id: string } = useParams();
  const viewer = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [instanceAux, setInstanceAux] = useState<any>(null);
  const [isFinishedFile, setIsFinishedFile] = useState(false);
  const [file, setFile] = useState(null);
  const [hasPrintOnHeaders, setHasPrintOnHeaders] = useState(false);
  const [hasDownloadOnHeaders, setHasDownloadOnHeaders] = useState(false);

  const [isLoadingFile, setIsLoadingFile] = useState(true);

  const { t, i18n } = useTranslation();

  const { user } = useAuthContext();

  const { data: document, error }: { data: Document; error: any } = useAxios(
    linksForSWR.client.documents.view(id),
  );

  const annotationsLoadedCallback = () => {
    const { annotationManager } = instanceAux.Core;

    const annotations = annotationManager.getAnnotationsList();
    annotationManager.deleteAnnotations(annotations);
  };

  const documentLoadedCallback = () => {
    const { annotationManager } = instanceAux.Core;

    instanceAux.UI.setFitMode(instanceAux.UI.FitMode.FitWidth);

    if (!isFinishedFile) {
      annotationManager.importAnnotations(document.xfdf);
      annotationManager.enableReadOnlyMode();
      return;
    }

    annotationManager.disableReadOnlyMode();

    instanceAux.Core.documentViewer.addEventListener(
      'annotationsLoaded',
      annotationsLoadedCallback,
    );
  };

  const pageCompleteCallback = () => {
    setIsLoadingFile(false);
  };

  async function loadFile() {
    try {
      const [lastStatus] = document.status.slice(-1);

      const isMergePdf = lastStatus.is_active && lastStatus.name === 'signed';
      const response = await api.get(
        `/document/file/${id}?pdfMerge=${isMergePdf}`,
        {
          responseType: 'arraybuffer',
        },
      );

      setFile(response.data);
      setIsFinishedFile(isMergePdf);

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      instanceAux.UI.loadDocument(url, {
        filename: document.fileName,
        extension: 'pdf',
      });

      instanceAux.Core.documentViewer.addEventListener(
        'pageComplete',
        pageCompleteCallback,
      );

      instanceAux.Core.documentViewer.addEventListener(
        'documentLoaded',
        documentLoadedCallback,
      );
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        licenseKey: process.env.REACT_APP_PDF_EXPRESS_JS_KEY_VIEWER,
      },
      viewer.current,
    )
      .then((instance: any) => {
        setInstanceAux(instance);

        const { Feature } = instance.UI;

        instance.UI.disableElements(['searchButton', 'menuButton']);
        instance.UI.disableFeatures([
          Feature.NotesPanel,
          Feature.Ribbons,
          Feature.Annotations,
        ]);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (instanceAux) {
      instanceAux.UI.setLanguage(i18nToWebview(i18n.language as Language));
    }
  }, [instanceAux, i18n.language]);

  useEffect(() => {
    if (document && instanceAux) {
      loadFile();
    }

    return () => {
      if (instanceAux) {
        instanceAux.Core.documentViewer.removeEventListener(
          'pageComplete',
          pageCompleteCallback,
        );

        instanceAux.Core.documentViewer.removeEventListener(
          'documentLoaded',
          documentLoadedCallback,
        );
        instanceAux.Core.documentViewer.removeEventListener(
          'annotationsLoaded',
          annotationsLoadedCallback,
        );
      }
    };
  }, [document, instanceAux]);

  useEffect(() => {
    if (!instanceAux || !document || !file) {
      return;
    }

    if (!hasPrintOnHeaders) {
      const print = {
        type: 'actionButton',
        img: 'icon-header-print-fill',
        title: 'Print',
        onClick: () => {
          const pageCount = instanceAux.Core.documentViewer.getPageCount();

          instanceAux.UI.printInBackground({
            pagesToPrint: Array.from(
              { length: pageCount },
              (_, key) => key + 1,
            ),
            includeAnnotations: true,
            includeComments: false,
          });
        },
        dataElement: 'alertButton',
      };

      instanceAux.UI.setHeaderItems((header: any) => {
        header.push(print);
        setHasPrintOnHeaders(true);
      });
    }

    if (isFinishedFile && !hasDownloadOnHeaders) {
      const download = {
        type: 'actionButton',
        img: 'icon-header-download',
        title: 'Download',
        onClick: () => {
          const fileBlob = new Blob([file], { type: 'application/pdf' });
          FileSaver.saveAs(fileBlob, document.fileName);
        },
        dataElement: 'alertButton',
      };

      instanceAux.UI.setHeaderItems((header: any) => {
        header.push(download);
        setHasDownloadOnHeaders(true);
      });
    }
  }, [instanceAux, file, isFinishedFile]);

  return (
    <Layout type="user" routeSelected="documents">
      <div className={classes.container}>
        <div className={classes.webviewContainer}>
          {isLoadingFile && (
            <div className={classes.loading}>
              <CircularProgress style={{ color: COLORS.mediumGray5 }} />
            </div>
          )}

          <div className={classes.webview} ref={viewer} />
        </div>

        <div className={classes.historic}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: '1.875rem', paddingRight: '1.25rem' }}
          >
            <ModalSignDocument
              hash={document?.hash}
              documentId={document?._id}
              faceMatch={document?.facematch}
              disabled={isFinishedFile}
              isLoading={isLoadingFile}
              isOwner={document?.user?.email === user?.email}
            />

            {document?.user && document?.user?.email === user?.email && (
              <ModalDelete
                what="document"
                type="none"
                component={{ id: document?._id }}
              />
            )}
          </Grid>

          <h1 className={classes.title}>{t('client.documents.view.title')}</h1>

          {document ? (
            <List className={classes.activitiesList}>
              <div className={classes.listElement}>
                <h1>
                  {moment(document?.createdAt, 'YYYY-MM-DDTHH:mm:ssZ')
                    .locale(t('client.documents.locale'))
                    .format('L, LTS')}
                </h1>

                <p>
                  {t('client.documents.view.logs.created')}{' '}
                  {document?.user?.name.split(' ')[0]}.
                </p>
              </div>

              {document?.userSignatures
                .sort(
                  (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime(),
                )
                .map(signature => {
                  return (
                    <div key={signature._id} className={classes.listElement}>
                      <h1>
                        {moment(signature.createdAt, 'YYYY-MM-DDTHH:mm:ssZ')
                          .locale(t('client.documents.locale'))
                          .format('L, LTS')}
                      </h1>

                      <p>
                        {signature.user
                          ? signature.user.name.split(' ')[0]
                          : signature.email
                          ? signature.email.split('@')[0]
                          : t('client.documents.deletedUser')}{' '}
                        {t('client.documents.view.logs.added')}
                      </p>
                    </div>
                  );
                })}

              {document?.userSignatures
                .sort(
                  (a, b) =>
                    new Date(a.updatedAt).getTime() -
                    new Date(b.updatedAt).getTime(),
                )
                .map(signature => {
                  const [lastItem] = signature.status.slice(-1);
                  if (lastItem.name !== 'signed' || !lastItem.is_active)
                    return null;

                  return (
                    <div key={signature._id} className={classes.listElement}>
                      <h1>
                        {moment(signature.updatedAt, 'YYYY-MM-DDTHH:mm:ssZ')
                          .locale(t('client.documents.locale'))
                          .format('L, LTS')}
                      </h1>

                      <p>
                        {signature.user
                          ? signature.user.name.split(' ')[0]
                          : signature.email
                          ? signature.email.split('@')[0]
                          : t('client.documents.deletedUser')}{' '}
                        {t('client.documents.view.logs.signed')}
                      </p>
                    </div>
                  );
                })}
            </List>
          ) : (
            <div className={classes.errorContainer}>
              {error ? t('client.documents.error') : <CircularProgress />}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
