/* eslint-disable jsx-a11y/media-has-caption */
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../utils/colors';

const useStyles = (props: { heightContent: string }) =>
  makeStyles({
    file: {
      height: props.heightContent,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',

      '& img, video': {
        width: '100%',
        height: '100%',
      },
    },
  })();

interface VaultViewerProps {
  file: File | Blob | null;
  heightContent: string;
}

export default function VaultViewer({ file, heightContent }: VaultViewerProps) {
  const classes = useStyles({ heightContent });

  if (!file) {
    return (
      <div className={classes.file}>
        <CircularProgress style={{ color: COLORS.mediumGray5 }} />
      </div>
    );
  }

  const [type] = file.type.split('/');
  const fileAsString = URL.createObjectURL(file);

  if (type === 'video' || file.type === 'application/ogg') {
    return (
      <div className={classes.file}>
        <video controls>
          <source src={fileAsString} type={file.type} />
        </video>
      </div>
    );
  }

  if (type === 'image') {
    return (
      <div className={classes.file}>
        <img src={fileAsString} alt="" />
      </div>
    );
  }

  if (type === 'audio') {
    return (
      <div className={classes.file}>
        <audio controls>
          <source src={fileAsString} type={file.type} />
        </audio>
      </div>
    );
  }

  return (
    <iframe src={fileAsString} title="_" className={classes.file}>
      <p>Não foi possível visualizar o arquivo</p>
    </iframe>
  );
}
