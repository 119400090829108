import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import clsx from 'clsx';

import { useAxios } from '../../../hooks/useAxios';

import { UserProfile } from '../../../types/UserProfile';
import { Plan } from '../../../types/Plan';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';
import { cepMask } from '../../../utils/masks';
import getAddressByCep from '../../../utils/getAddressByCep';

const useStyles = makeStyles({
  containerTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1.75rem 1.75rem 0 0',

    '& svg': {
      fontSize: '1.625rem',
      color: COLORS.gray2,
      cursor: 'pointer',
    },
  },

  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },

  addressContainer: {
    width: '40rem',
    margin: '2rem 3rem 4rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem',

    '& .item': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: COLORS.background,
      borderRadius: '.625rem',
      padding: '1rem .5rem',
      columnGap: '.5rem',
    },
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',

    '& label': {
      fontSize: '0.75rem',
      color: COLORS.gray2,

      '&.error': {
        color: COLORS.redWarning,
      },
    },

    '& input': {
      height: '2.75rem',
      border: `1px solid ${COLORS.lightGray3}`,
      borderRadius: '0.5rem',
      padding: '0 1rem',
      outline: 'none',
      backgroundColor: COLORS.white,

      '&:focus': {
        borderColor: COLORS.gray1,
      },

      '&.error': {
        borderColor: COLORS.redWarning,
      },
    },
  },

  errorMessage: {
    color: COLORS.redWarning,
    marginTop: '.5rem',
  },

  buttonBuyInAddress: {
    padding: 0,
    height: '3rem',
    width: '29%',
    borderRadius: '0.5rem',
    textTransform: 'none',
    color: COLORS.background,
    background: COLORS.greenButton,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginTop: '1rem',

    '&:hover': {
      background: COLORS.greenHover,
      color: COLORS.background,
    },
  },
});

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubscribePlan: (plan: Plan) => Promise<void>;
  chosenPlan: Plan;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalEditAddressInPricing({
  isOpen,
  setIsOpen,
  handleSubscribePlan,
  chosenPlan,
  isSubmitting,
  setIsSubmitting,
}: Props) {
  const classes = useStyles();

  const [hasError, setHasError] = useState(false);

  const [zipCode, setZipCode] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const { data: userProfile, mutate: mutateUserProfile } = useAxios(
    linksForSWR.client.profile(),
  ) as {
    data: UserProfile;
    mutate: (
      data?: UserProfile,
      shouldRevalidate?: boolean | undefined,
    ) => void;
  };

  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setZipCode('');
      setStreet('');
      setNeighborhood('');
      setNumber('');
      setCity('');
      setState('');
      setHasError(false);
    }, 150);
  };

  const fillAddressByCep = async (cep: string) => {
    const response = await getAddressByCep(cep);

    if (!response) {
      return;
    }

    setStreet(response.street);
    setNeighborhood(response.neighborhood);
    setCity(response.city);
    setState(response.state);
  };

  const handleEditAddress = async () => {
    setHasError(false);
    if (!zipCode || !street || !number || !neighborhood || !city || !state) {
      setHasError(true);

      return;
    }

    setIsSubmitting(true);

    try {
      await api.put('/profile', {
        email: userProfile.email,
        phoneNumber: userProfile.phoneNumber,
        address: {
          zipNumber: zipCode.replace(/\D/g, ''),
          street,
          number,
          neighborhood,
          city,
          state,
        },
      });

      mutateUserProfile(
        {
          ...userProfile,
          address: {
            zipNumber: zipCode,
            street,
            number,
            neighborhood,
            city,
            state,
          },
        },
        false,
      );

      await handleSubscribePlan(chosenPlan);
      handleClose();
    } catch (error) {
      console.log(error);
    }

    setIsSubmitting(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      <DialogTitle className={classes.containerTitle} disableTypography>
        <CloseIcon onClick={handleClose} />
      </DialogTitle>

      <DialogContent style={{ padding: 0 }}>
        <div style={{ margin: '0 2.25rem 3rem 2.25rem' }}>
          <h1 className={classes.title}>
            {t('components.modalEditAddressInPricing.title')}
          </h1>

          <div className={classes.addressContainer}>
            <Grid container spacing={3}>
              <Grid item className={classes.field} xs={12}>
                <label
                  htmlFor="zipCode"
                  className={clsx({ error: hasError && !zipCode })}
                >
                  {t('components.modalEditAddressInPricing.zipCode')}
                </label>

                <MaskedInput
                  mask={cepMask}
                  guide={false}
                  showMask
                  id="zipCode"
                  value={zipCode}
                  onChange={e => {
                    setZipCode(e.target.value);
                    fillAddressByCep(e.target.value);
                  }}
                  className={clsx({ error: hasError && !zipCode })}
                />
              </Grid>

              <Grid item className={classes.field} xs={12}>
                <label
                  htmlFor="street"
                  className={clsx({ error: hasError && !street })}
                >
                  {t('components.modalEditAddressInPricing.street')}
                </label>

                <input
                  id="street"
                  value={street}
                  onChange={e => {
                    setStreet(e.target.value);
                  }}
                  className={clsx({ error: hasError && !street })}
                />
              </Grid>

              <Grid item className={classes.field} xs={12}>
                <label
                  htmlFor="number"
                  className={clsx({ error: hasError && !number })}
                >
                  {t('components.modalEditAddressInPricing.number')}
                </label>

                <input
                  id="number"
                  value={number}
                  onChange={e => {
                    setNumber(e.target.value);
                  }}
                  className={clsx({ error: hasError && !number })}
                />
              </Grid>

              <Grid item className={classes.field} xs={12}>
                <label
                  htmlFor="neighborhood"
                  className={clsx({ error: hasError && !neighborhood })}
                >
                  {t('components.modalEditAddressInPricing.neighborhood')}
                </label>

                <input
                  id="neighborhood"
                  value={neighborhood}
                  onChange={e => {
                    setNeighborhood(e.target.value);
                  }}
                  className={clsx({ error: hasError && !neighborhood })}
                />
              </Grid>

              <Grid item className={classes.field} xs={12}>
                <label
                  htmlFor="city"
                  className={clsx({ error: hasError && !city })}
                >
                  {t('components.modalEditAddressInPricing.city')}
                </label>

                <input
                  id="city"
                  value={city}
                  onChange={e => {
                    setCity(e.target.value);
                  }}
                  className={clsx({ error: hasError && !city })}
                />
              </Grid>

              <Grid item className={classes.field} xs={12}>
                <label
                  htmlFor="state"
                  className={clsx({ error: hasError && !state })}
                >
                  {t('components.modalEditAddressInPricing.state')}
                </label>

                <input
                  id="state"
                  value={state}
                  onChange={e => {
                    setState(e.target.value);
                  }}
                  className={clsx({ error: hasError && !state })}
                />
              </Grid>
            </Grid>

            {hasError && (
              <span className={classes.errorMessage}>
                {t('components.modalEditAddressInPricing.error')}
              </span>
            )}

            <Button
              onClick={handleEditAddress}
              className={classes.buttonBuyInAddress}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                t('components.modalEditAddressInPricing.buttonConfirmation')
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
