/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { mutate } from 'swr';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useAlert from '../../../hooks/useAlertContext';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';

const useStyles = makeStyles({
  buttonDeleteDocument: {
    border: `0.125rem solid ${COLORS.lightRed}`,
    padding: 0,
    width: '2.25rem',
    minWidth: '2.25rem',
    height: '2.25rem',
    borderRadius: '0.5rem',

    '& svg': {
      fontSize: '1.75rem',
      color: COLORS.lightRed,
    },

    '&:hover': {
      transition: '0.3s',
      backgroundColor: COLORS.lightRed,
      '& svg': {
        transition: '0.3s',
        color: COLORS.background,
      },
    },
  },

  buttonDeleteVault: {
    width: '11.125rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.lightRed,
    textTransform: 'none',
    border: `1px ${COLORS.lightRed} solid`,
    margin: '2.5rem 0 2.25rem 0',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.lightRed,
      color: COLORS.white,
      transition: 'all 0.3s',

      '& svg': {
        fill: COLORS.white,
        transition: 'all 0.3s',
      },
    },
  },

  deleteVaultIcon: {
    fontSize: '1.25rem',
    fill: COLORS.lightRed,
  },

  deleteVaultIconInIndex: {
    color: COLORS.lightRed,
    fontSize: '1.5rem',
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.redWarning,
      transition: 'all 0.3s',
    },
  },

  deleteClientIconInIndex: {
    color: COLORS.lightRed,
    fontSize: '1.25rem',
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.redWarning,
      transition: 'all 0.3s',
    },
  },

  buttonDeleteClientInEdit: {
    padding: 0,
    textTransform: 'none',
    height: '3rem',
    width: '9.25rem',
    border: `1px solid ${COLORS.lightRed}`,
    borderRadius: '0.5rem',
    color: COLORS.lightRed,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',

    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },

    '&:hover': {
      backgroundColor: COLORS.lightRed,
      color: COLORS.white,
      transition: '0.3s',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiSvgIcon-root': {
      fontSize: '2.25rem',
      color: COLORS.mediumGray4,
      cursor: 'pointer',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 3rem',
  },

  textContent: {
    backgroundColor: COLORS.white,
    fontSize: '1.125rem',
    color: COLORS.black,
    maxWidth: '29.25rem',
  },

  deleteIconContainer: {
    width: '3.875rem',
    height: '3.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.redWarning,
    borderRadius: '50%',
    margin: '1.75rem 0',
  },

  buttonNo: {
    backgroundColor: COLORS.black,
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.white,
    textTransform: 'none',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.pureBlack,
      transition: '0.3s',
    },
  },

  buttonYes: {
    backgroundColor: 'none',
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.redWarning,
    textTransform: 'none',
    border: `1px ${COLORS.redWarning} solid`,

    margin: '0.75rem 0 2.25rem 0',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.redWarning,
      color: COLORS.white,
      transition: '0.3s',
    },
  },
});

type DeleteType = 'index' | 'view' | 'editClient' | 'editRegister' | 'none';

interface Props {
  what: 'document' | 'vault' | 'client';
  type: DeleteType;
  component: {
    id: string;
    name?: string;
  };
}

export default function ModalDelete({ what, type, component }: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const history = useHistory();
  const { showAlert } = useAlert();

  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const typeHandleDelete = {
    async document() {
      await api.delete(`/document/delete/${component.id}`);

      mutate(linksForSWR.client.documents.index());
      mutate(linksForSWR.client.home.documents());
      mutate(linksForSWR.client.home.documents(2));
      mutate(linksForSWR.client.home.dashboard());

      history.push('/documents');

      handleClose();

      showAlert('documentDeletedSuccessfully', 'success', null);
    },

    async vault() {
      await api.delete(`/vault/document/delete/${component.id}`);

      mutate(linksForSWR.client.vault.index());
      mutate(linksForSWR.client.home.dashboard());

      history.push('/vault');

      handleClose();
      showAlert('documentDeletedSuccessfully', 'success', null);
    },

    async client() {
      await api.delete(`/operator/user/${component.id}`);

      mutate(linksForSWR.operator.clients.index());
      mutate(linksForSWR.operator.registers.index());

      handleClose();

      const typeUrlBrowser = {
        editClient: '/clients',
        editRegister: '/registers',
      };

      const urlBrowser =
        typeUrlBrowser[
          type as Extract<DeleteType, 'editClient' | 'editRegister'>
        ];
      if (urlBrowser) {
        history.push(urlBrowser);
      }

      showAlert('clientDeletedSuccessfully', 'success', null);
    },
  };

  const handleDelete = async () => {
    try {
      const currentHandleDelete = typeHandleDelete[what];

      if (currentHandleDelete) {
        currentHandleDelete();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {what === 'document' ? (
        <Tooltip title={t('components.modalDelete.document.button')}>
          <Button onClick={handleOpen} className={classes.buttonDeleteDocument}>
            <DeleteOutlineIcon />
          </Button>
        </Tooltip>
      ) : what === 'vault' ? (
        type === 'index' ? (
          <DeleteOutlineIcon
            className={classes.deleteVaultIconInIndex}
            onClick={handleOpen}
          />
        ) : (
          <Button
            startIcon={
              <DeleteOutlineIcon className={classes.deleteVaultIcon} />
            }
            className={classes.buttonDeleteVault}
            onClick={handleOpen}
          >
            {t('components.modalDelete.document.button')}
          </Button>
        )
      ) : what === 'client' ? (
        type === 'index' ? (
          <DeleteOutlineIcon
            className={classes.deleteClientIconInIndex}
            onClick={handleOpen}
          />
        ) : (
          <Button
            startIcon={<DeleteOutlineIcon />}
            className={classes.buttonDeleteClientInEdit}
            onClick={handleOpen}
          >
            {t('components.modalDelete.user.button')}
          </Button>
        )
      ) : null}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.title} disableTypography>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent className={classes.content}>
          <p className={classes.textContent}>
            {t('components.modalDelete.content.title')}{' '}
            <span style={{ color: COLORS.redWarning, fontWeight: 'bold' }}>
              {t('components.modalDelete.content.delete')}
            </span>{' '}
            {t('components.modalDelete.content.the')}{' '}
            {what === 'document' || what === 'vault'
              ? t('components.modalDelete.document.title')
              : what === 'client'
              ? t('components.modalDelete.user.title')
              : ''}
            {what === 'client' && (
              <span style={{ fontWeight: 'bold' }}>{component.name}</span>
            )}
            ?
            {what === 'vault' ? (
              <>
                {' '}
                {t('components.modalDelete.document.vault.part1')}{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {t('components.modalDelete.document.vault.part2')}
                </span>
              </>
            ) : null}
          </p>

          <div className={classes.deleteIconContainer}>
            <DeleteOutlinedIcon
              style={{ fontSize: '2.375rem', color: COLORS.white }}
            />
          </div>

          <Button className={classes.buttonNo} onClick={handleClose}>
            {t('components.modalDelete.no')}
          </Button>

          <Button className={classes.buttonYes} onClick={handleDelete}>
            {t('components.modalDelete.yes')}{' '}
            {what === 'document' || what === 'vault'
              ? t('components.modalDelete.document.title')
              : what === 'client'
              ? t('components.modalDelete.user.title')
              : ''}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
