/* eslint-disable import/prefer-default-export */
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import i18n from 'i18next';

import { i18nToMUI } from '../utils/i18nToSomething';
import COLORS from '../utils/colors';

import { Language } from '../types/Language';

import MyFonts from '../fonts';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: COLORS.black,
      },

      secondary: {
        main: COLORS.white,
      },
    },

    typography: {
      fontFamily: ['Noto Sans', ' sans-serif'].join(','),
    },

    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': MyFonts,
        },
      },
    },

    spacing: 4,
  },

  i18nToMUI(i18n.language as Language),
);

interface Props {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: Props) {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
}
