/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ChangeLanguage from '../../components/ChangeLanguage';

import api from '../../services/api';

import COLORS from '../../utils/colors';

import littleDigitalImg from '../../images/little-digital.svg';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5%',

    '& img': {
      height: '10%',
      marginBottom: '1rem',
    },

    '& .title': {
      color: COLORS.black,
      fontSize: '150%',
      fontWeight: 'bold',
      textAlign: 'center',
    },

    '& .uploadText': {
      color: COLORS.black,
      fontSize: '80%',
      marginTop: '2rem',
      marginBottom: '0.5rem',
      textAlign: 'center',
    },

    '& .keepingText': {
      color: COLORS.black,
      fontSize: '80%',
      textAlign: 'center',
    },
  },

  selectDocumentContainer: {
    height: '16rem',
    width: '75%',
    borderRadius: '0.5rem',
    borderStyle: 'dashed',
    borderColor: COLORS.mediumGray4,
    background: COLORS.lightGray2,
    margin: '2rem 0 4.25rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      borderColor: COLORS.mediumGray5,
      transition: '0.3s',
      cursor: 'pointer',
    },

    '&.hasFile': {
      margin: '2rem 0',
    },
  },

  textDragAndDrop: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '100%',
  },

  fileNameContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '80%',
    columnGap: '0.5rem',

    '& p': {
      fontWeight: 'bold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },

  textSupportedFiles: {
    color: COLORS.mediumGray5,
  },

  buttonsContainer: {
    width: '75%',
    color: COLORS.black,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    transition: '0.3s',
    marginBottom: '5%',
  },

  buttonValidate: {
    background: COLORS.gray2,
    color: COLORS.white,
    textTransform: 'none',
    padding: 0,
    height: '2.25rem',
    width: '5rem',
    borderRadius: '0.5rem',

    '&:hover': {
      background: COLORS.black,
      color: COLORS.white,
      transition: '0.3s',
    },
  },

  buttonCancel: {
    background: 'none',
    color: COLORS.lightRed,
    padding: 0,

    '& .MuiSvgIcon-root': {
      fontSize: '1.625rem',
    },

    '&:hover': {
      background: 'none',
      color: COLORS.redWarning,
      transition: '.3s',
    },
  },

  copyright: {
    textAlign: 'center',
  },
});

const useStylesBootstrap = makeStyles({
  arrow: {
    color: COLORS.black,
  },

  tooltip: {
    backgroundColor: COLORS.black,
  },
});

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function Validator() {
  const classes = useStyles();
  const history = useHistory();
  const [file, setFile] = useState<File | null>(null);

  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf',
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        fileRejections[0].errors.forEach(err => {
          console.log(err.message);
        });
        return;
      }

      setFile(acceptedFiles[0]);
    },
  });

  const handleResult = async () => {
    if (!file) return;

    try {
      const data = new FormData();
      data.append('file', file);

      const response = await api.post('/document/validator', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      history.push({
        pathname: '/validator/result',
        state: {
          fileName: file.name,
          message: response.data.message,
          status: response.data.success,
          date: response.data.data,
        },
      });
    } catch (error: any) {
      if (!error.response) return;

      history.push({
        pathname: '/validator/result',
        state: {
          fileName: file.name,
          message: error.response.data.message,
          status: error.response.data.success,
          date: error.response.data.data,
        },
      });
    }
  };

  return (
    <div className={classes.container}>
      <img src={littleDigitalImg} alt="logo" />

      <Typography className="title">{t('validator.index.validate')}</Typography>

      <Typography className="uploadText">
        {t('validator.index.upload')}
      </Typography>

      <Typography className="keepingText">
        {t('validator.index.keeping')}
      </Typography>

      <div
        {...getRootProps()}
        className={clsx(classes.selectDocumentContainer, {
          hasFile: Boolean(file),
        })}
      >
        {!file ? (
          <Typography className={classes.textDragAndDrop}>
            {t('validator.index.dragAndDrop')}
          </Typography>
        ) : (
          <div className={classes.fileNameContainer}>
            <PictureAsPdfIcon />

            <p>{file.name}</p>
          </div>
        )}

        {!file && (
          <Typography className={classes.textSupportedFiles}>
            {t('validator.index.supportedFiles')}: PDF
          </Typography>
        )}

        <input id="input" hidden {...getInputProps()} />
      </div>

      {file && (
        <div className={classes.buttonsContainer}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button className={classes.buttonValidate} onClick={handleResult}>
              {t('validator.index.buttonValidate')}
            </Button>

            <BootstrapTooltip
              placement="bottom"
              title={t('validator.index.buttonTrash')}
            >
              <Button
                className={classes.buttonCancel}
                onClick={() => {
                  setFile(null);
                }}
              >
                <DeleteOutlineIcon />
              </Button>
            </BootstrapTooltip>
          </Grid>
        </div>
      )}

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.copyright}
      >
        <Typography>
          © {new Date().getFullYear()} DattaID. {t('validator.copyright')}
        </Typography>

        <ChangeLanguage />
      </Grid>
    </div>
  );
}
