/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DebounceInput } from 'react-debounce-input';

import Layout from '../../../components/Layout';
import CustomPagination from '../../../components/CustomPagination';

import useCostCenters from '../../../hooks/useCostCenters';
import useAuthContext from '../../../hooks/useAuthContext';
import { useAxios } from '../../../hooks/useAxios';

import { Document } from '../../../types/Document';
import { CostCenter } from '../../../types/CostCenter';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';
import getTwoFirstLettersByName from '../../../utils/getTwoFirstLettersByName';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-end',

    '& .title': {
      fontSize: '3rem',
      fontWeight: 700,
      color: COLORS.pureBlack,
    },

    '& .subtitle': {
      lineHeight: '2.5rem',
      color: COLORS.gray5,
      marginLeft: '1rem',
      fontSize: '1.125rem',
      fontWeight: 500,
    },
  },

  search: {
    width: '100%',
    marginTop: '1rem',
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    paddingLeft: '1rem',
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${COLORS.lightGray3}`,
    outline: `${COLORS.lightGray1}`,

    '&:hover': {
      transition: '0.1s',
      outline: `0.25rem solid ${COLORS.lightGray1}`,
    },

    [theme.breakpoints.up('md')]: {
      width: '33.33%',
      marginTop: 0,
    },

    '& input': {
      fontSize: '1.125rem',
      marginBottom: '0.125rem',
      lineHeight: '1.5rem',
      color: COLORS.gray3,
    },

    '& svg': {
      fill: COLORS.mediumGray1,
      fontSize: '1.5rem',
    },

    '& .Mui-focused': {
      '& svg': {
        fill: COLORS.gray2,
      },
    },
  },

  closeIcon: {
    fontSize: '1.625rem',
    marginRight: '0.625rem',
    color: COLORS.mediumGray5,
    cursor: 'pointer',
  },

  tablePaper: {
    width: '100%',
    marginTop: '1.5rem',
    borderRadius: '0.5rem',
  },

  tableHead: {
    color: COLORS.gray2,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    width: '20%',
    fontWeight: 500,
  },

  tableCell: {
    display: 'flex',
    alignItems: 'center',
    height: '4.625rem',
    padding: '0 !important',

    '&:hover': {
      backgroundColor: COLORS.lightOrange,
    },

    '& a': {
      display: 'block',
      width: '100%',
      height: '100%',
      padding: '1rem',
      textDecoration: 'none',
      color: COLORS.black,
    },
  },

  fileNameContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& .fileName': {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '18.75rem',
    },

    '& .date': {
      fontSize: '0.75rem',
      color: COLORS.black,
    },
  },

  buttonView: {
    height: '2.25rem',
    width: '4.375',
    textTransform: 'none',
    borderRadius: '0.5rem',

    backgroundColor: COLORS.lightGray2,
    color: COLORS.mediumGray5,

    fontSize: '0.875rem',
    fontWeight: 600,

    '& svg': {
      fill: COLORS.mediumGray5,
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      color: COLORS.gray2,
      backgroundColor: COLORS.lightGray4,
      transition: '0.3s',

      '& svg': {
        fill: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  avatar: {
    color: COLORS.gray2,
    backgroundColor: COLORS.lightGreen,
    fontSize: '0.75rem',
  },

  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '71vh',
    rowGap: '1.5rem',
    fontSize: '2.25rem',
    color: COLORS.gray2,
    fontWeight: 500,
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64.5vh',
  },
}));

interface FolderData {
  data: {
    costcenter: CostCenter;
    documents: Document[];
  };
  xTotalCount: number;
}

export default function FoldersView() {
  const classes = useStyles();
  const { id }: { id: string } = useParams();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const { t } = useTranslation();
  const { costCenters } = useCostCenters();
  const { user } = useAuthContext();

  const rowsPerPage = 6;
  const isPersonalAccount = id === 'personal';

  const { data: folders, error }: { data: FolderData; error: any } = useAxios(
    linksForSWR.client.folders(
      isPersonalAccount ? null : id,
      page,
      rowsPerPage,
      search,
    ),
  );

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleSearch = (value: string) => {
    setPage(1);
    setSearch(value);
  };

  return (
    <Layout type="user" routeSelected="folders">
      <Grid
        container
        item
        alignItems="flex-end"
        justifyContent="space-between"
        style={{ marginBottom: '2.25rem' }}
      >
        <div className={classes.titleContainer}>
          <Typography variant="h1" className="title">
            {t('client.folders.title')}
          </Typography>

          <span className="subtitle">
            {id === 'personal'
              ? t('client.folders.view.personalSubtitle')
              : costCenters?.data.find(costCenter => costCenter._id === id)
                  ?.name}
          </span>
        </div>

        <DebounceInput
          minLength={0}
          debounceTimeout={300}
          element={TextField}
          size="medium"
          placeholder={t('client.folders.view.search')}
          fullWidth
          variant="standard"
          value={search}
          onChange={e => {
            handleSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: search ? (
              <InputAdornment
                position="end"
                onClick={() => {
                  setSearch('');
                }}
              >
                <CloseIcon className={classes.closeIcon} />
              </InputAdornment>
            ) : null,
            disableUnderline: true,
          }}
          className={classes.search}
        />
      </Grid>

      {folders ? (
        folders.data.documents.length ? (
          <Paper className={classes.tablePaper} elevation={0}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {(
                      t('client.folders.view.columns', {
                        returnObjects: true,
                      }) as string[]
                    ).map(element => (
                      <TableCell key={element} className={classes.tableHead}>
                        {element}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {folders.data.documents.map(document => (
                    <TableRow key={document._id}>
                      <TableCell align="left" className={classes.tableCell}>
                        <Link to={`/documents/${document._id}`}>
                          <div className={classes.fileNameContainer}>
                            <Typography className="fileName">
                              {document.fileName}
                            </Typography>

                            <Typography className="date">
                              {moment(document.createdAt)
                                .locale(t('client.folders.locale'))
                                .format('LL')}
                            </Typography>
                          </div>
                        </Link>
                      </TableCell>

                      <TableCell align="left" style={{ height: '4.625rem' }}>
                        <Typography style={{ fontSize: '0.875rem' }}>
                          {document.user
                            ? document.user.email === user?.email
                              ? t('client.folders.view.labelOwner')
                              : document.user?.name
                            : t('client.folders.deletedUser')}
                        </Typography>
                      </TableCell>

                      <TableCell align="left" style={{ height: '4.625rem' }}>
                        <AvatarGroup max={3}>
                          {document.userSignatures.map(userSignature => {
                            const [lastStatus] = userSignature.status.slice(-1);
                            const alreadySigned =
                              lastStatus.is_active &&
                              lastStatus.name === 'signed';

                            return (
                              <Tooltip
                                key={userSignature._id}
                                title={
                                  userSignature.user
                                    ? userSignature.user.name
                                    : userSignature.email
                                    ? userSignature.email
                                    : t('client.folders.deletedUser')
                                }
                              >
                                <Avatar
                                  className={classes.avatar}
                                  style={{
                                    backgroundColor: alreadySigned
                                      ? COLORS.lightGreen
                                      : COLORS.lightGray4,
                                  }}
                                >
                                  {getTwoFirstLettersByName(
                                    userSignature.user
                                      ? userSignature.user.name
                                      : userSignature.email
                                      ? userSignature.email
                                      : '-',
                                  )}
                                </Avatar>
                              </Tooltip>
                            );
                          })}
                        </AvatarGroup>
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{ height: '4.625rem', paddingRight: '2.25rem' }}
                      >
                        <Button
                          className={classes.buttonView}
                          endIcon={<VisibilityIcon />}
                          component={Link}
                          to={`/documents/${document._id}`}
                        >
                          {t('client.folders.view.buttonView')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <CustomPagination
              page={page}
              handleChangePage={handleChangePage}
              total={folders?.xTotalCount}
              rowsPerPage={rowsPerPage}
              contentToLeft={null}
            />
          </Paper>
        ) : (
          <div className={classes.emptyContainer}>
            {t('client.folders.empty')}
          </div>
        )
      ) : (
        <div className={classes.errorContainer}>
          {error ? t('client.folders.error') : <CircularProgress />}
        </div>
      )}
    </Layout>
  );
}
