import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { useTranslation } from 'react-i18next';

import COLORS from '../../../utils/colors';

import Upload from './upload';
import WhoWillSign from './whoWillSign';

const useStyles = makeStyles({
  buttonCreateDocument: {
    height: '3rem',
    width: '13.25rem',
    textTransform: 'none',
    fontSize: '1.125rem',
    fontWeight: 600,
    color: COLORS.white,
    backgroundColor: COLORS.greenButton,
    borderRadius: '0.5rem',
    border: COLORS.greenButton,

    '& svg': {
      fontSize: '1.25rem',
    },

    '&:hover': {
      backgroundColor: COLORS.greenHover,
    },
  },

  textCreateDocument: {
    fontSize: '1.125rem',
    color: COLORS.blue,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
      color: COLORS.blueHover,
    },
  },
});

interface Props {
  type: 'button' | 'text';
}

export default function ModalUploadToSign({ type }: Props) {
  const [openUpload, setOpenUpload] = useState(false);
  const [openWhoWillSign, setOpenWhoWillSign] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { t } = useTranslation();
  const classes = useStyles();

  const handleOpen = () => {
    setOpenUpload(true);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();

    setOpenUpload(false);
    setOpenWhoWillSign(false);
    setTimeout(() => {
      setFile(null);
    }, 200);
  };

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setOpenUpload(false);
    setTimeout(() => {
      setOpenWhoWillSign(true);
    }, 300);
  };

  return (
    <div>
      {type === 'button' ? (
        <Button
          startIcon={<ControlPointIcon />}
          onClick={handleOpen}
          className={classes.buttonCreateDocument}
        >
          {t('components.modalUploadToSign.titleButton')}
        </Button>
      ) : (
        <Typography className={classes.textCreateDocument} onClick={handleOpen}>
          {t('components.modalUploadToSign.titleText')}
        </Typography>
      )}

      <Upload
        file={file}
        setFile={setFile}
        open={openUpload}
        handleClose={handleClose}
        handleNext={handleNext}
      />

      <WhoWillSign
        open={openWhoWillSign}
        handleClose={handleClose}
        file={file}
      />
    </div>
  );
}
