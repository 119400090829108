import { useAxios } from './useAxios';

import { CostCenter } from '../types/CostCenter';

import linksForSWR from '../utils/linksForSWR';

interface Response {
  costCenters: CostCenter[];
  error: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  mutateCostCenters: (
    data?: CostCenter[],
    shouldRevalidate?: boolean,
  ) => Promise<CostCenter[]>;
}

export default function useCostCentersOperator(userId: string): Response {
  const {
    data: costCenters,
    error,
    mutate: mutateCostCenters,
  } = useAxios(linksForSWR.operator.costCenters(userId));

  return { costCenters, error, mutateCostCenters };
}
