/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import clsx from 'clsx';

import ModalApproveDocument from '../../../components/Modals/ModalApproveDocument';
import ModalDelete from '../../../components/Modals/ModalDelete';
import Layout from '../../../components/Layout';

import { useAxios } from '../../../hooks/useAxios';
import useAlert from '../../../hooks/useAlertContext';

import { Client } from '../../../types/Client';
import { UserDocument } from '../../../types/UserDocument';
import { Field, AddressInfoFields } from '../../../types/Field';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import { cepMask, cpfMask, numberMask, phoneMask } from '../../../utils/masks';
import linksForSWR from '../../../utils/linksForSWR';

const useStyles = makeStyles({
  title: {
    paddingBottom: '0.75rem',
    borderBottom: `1px solid ${COLORS.lightGray3}`,
    fontWeight: 'bold',
    fontSize: '3rem',
    lineHeight: '4rem',
    color: COLORS.pureBlack,
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '75vh',
  },

  reviewContainer: {
    margin: '2.25rem 0 .75rem 0',

    '& h1': {
      color: COLORS.gray2,
      fontSize: '1.125rem',
      fontWeight: 600,
    },
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',

    '& label': {
      fontSize: '0.75rem',
      color: COLORS.gray2,
    },

    '& input, & div div input': {
      height: '2.75rem',
      border: `1px solid ${COLORS.lightGray3}`,
      borderRadius: '0.5rem',
      padding: '0 1rem',
      outline: 'none',
      backgroundColor: COLORS.white,
      fontSize: '0.875rem',
    },

    '& .Mui-disabled': {
      color: '#757575',
      backgroundColor: COLORS.white,
    },
  },

  buttonEdit: {
    textTransform: 'none',
    padding: 0,
    height: '2.25rem',
    width: '5.375rem',
    background: '#FFF',
    border: `1px solid ${COLORS.lightGray4}`,
    borderRadius: '0.5rem',
    backgroundColor: COLORS.lightGray2,
    color: COLORS.mediumGray5,
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.5rem',

    '& svg': {
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      backgroundColor: COLORS.lightGray3,
      border: `1px solid ${COLORS.mediumGray4}`,
      transition: '0.3s',
    },
  },

  buttonSave: {
    textTransform: 'none',
    padding: 0,
    height: '2.25rem',
    width: '5.375rem',
    background: '#FFF',
    borderRadius: '0.5rem',
    backgroundColor: COLORS.gray2,
    color: COLORS.background,
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.5rem',

    '& svg': {
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      backgroundColor: COLORS.black,
      color: COLORS.background,
      transition: '0.3s',
    },
  },

  document: {
    '& p': {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1rem',
      color: COLORS.gray2,
      marginBottom: '1rem',
    },
  },

  personalInfoContainer: {
    marginTop: '3.125rem',
    paddingBottom: '2rem',
    borderBottom: `1px solid ${COLORS.lightGray3}`,
  },

  infoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.75rem',

    '& h2': {
      color: COLORS.gray2,
      fontSize: '1.125rem',
      fontWeight: 600,
    },
  },

  bottomContainer: {
    display: 'flex',
    justifyContent: 'end',
    margin: '2.25rem 0 1.625rem 0',
  },
});

interface ClientData {
  data: Client;
  error: any;
  mutate: (data?: Client, revalidate?: boolean) => void;
}

export default function EditPendingRegister() {
  const classes = useStyles();
  const { id }: { id: string } = useParams();
  const { showAlert, hideAlert } = useAlert();

  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    email: '',
    cpf: '',
    phone: '',
    birth: '',
  });

  const [addressInfo, setAddressInfo] = useState({
    zipCode: '',
    address: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
  });

  const [documents, setDocuments] = useState<{
    selfie: UserDocument | undefined;
    frontSide: UserDocument | undefined;
    backSide: UserDocument | undefined;
  }>({
    selfie: undefined,
    frontSide: undefined,
    backSide: undefined,
  });

  const [isEditingPersonalInfo, setIsEditingPersonalInfo] = useState(false);
  const [isEditingAddressInfo, setIsEditingAddressInfo] = useState(false);

  const { t } = useTranslation();

  const { data, error, mutate }: ClientData = useAxios(
    linksForSWR.operator.registers.edit(id),
  );

  const handleUpdateObj = {
    personal: {
      state: isEditingPersonalInfo,
      setState: setIsEditingPersonalInfo,
    },

    address: {
      state: isEditingAddressInfo,
      setState: setIsEditingAddressInfo,
    },
  };

  useEffect(() => {
    if (!data) return;

    setPersonalInfo({
      name: data.name,
      email: data.email,
      cpf: data.cpf,
      phone: data.phoneNumber,
      birth: moment
        .utc(data.birthDate, 'YYYY-MM-DDTHH:mm:ssZ')
        .format('YYYY-MM-DD'),
    });

    if (data.address) {
      setAddressInfo({
        zipCode: data.address.zipNumber,
        address: data.address.street,
        number: data.address.number,
        neighborhood: data.address.neighborhood,
        city: data.address.city,
        state: data.address.state,
      });
    }

    setDocuments({
      selfie: data.documents.find(document => document.type === 'selfie'),
      frontSide: data.documents.find(
        document => document.type === 'front-side',
      ),
      backSide: data.documents.find(document => document.type === 'back-side'),
    });
  }, [data]);

  const handleStatePersonal = (event: any) => {
    if (event.target) {
      setPersonalInfo({
        ...personalInfo,
        [event.target.name]: event.target.value,
      });
      return;
    }

    setPersonalInfo({
      ...personalInfo,
      birth: event._d,
    });
  };

  const handleUpdatePerson = async (type: 'personal' | 'address') => {
    const { state, setState } = handleUpdateObj[type];

    if (!state) {
      setState(true);
      return;
    }

    const person = {
      name: personalInfo.name,
      cpf: personalInfo.cpf,
      birthDate: personalInfo.birth,
      email: personalInfo.email,
      phoneNumber: personalInfo.phone,
      address: {
        zipNumber: addressInfo.zipCode,
        city: addressInfo.city,
        state: addressInfo.state,
        street: addressInfo.address,
        number: addressInfo.number,
        neighborhood: addressInfo.neighborhood,
      },
    };

    try {
      mutate({ ...person, documents: data.documents }, false);
      setState(false);

      showAlert('profileUpdated', 'success', null);
      hideAlert(1000);

      await api.put(`/operator/user/${id}`, person);
      mutate();
    } catch (err) {
      console.log(err);
      setState(true);
    }
  };

  const personalInfoFields = [
    {
      id: 'name',
      title: t('operator.registers.edit.personalInformation.name'),
      width: 6,
      component: (
        <input
          name="name"
          value={personalInfo.name || ''}
          disabled={!isEditingPersonalInfo}
          onChange={handleStatePersonal}
        />
      ),
    },
    {
      id: 'birth',
      title: t('operator.registers.edit.personalInformation.birth'),
      width: 3,
      component: (
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale={t('operator.registers.locale')}
        >
          <DatePicker
            format={moment(personalInfo.birth || '')
              .locale(t('operator.registers.locale'))
              .format('L')}
            variant="inline"
            value={personalInfo.birth || ''}
            onChange={handleStatePersonal}
            InputProps={{
              disableUnderline: true,
            }}
            disabled={!isEditingPersonalInfo}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      id: 'cpf',
      title: t('operator.registers.edit.personalInformation.cpf'),
      width: 3,
      component: (
        <MaskedInput
          mask={cpfMask}
          guide={false}
          showMask
          name="cpf"
          value={personalInfo.cpf}
          onChange={handleStatePersonal}
          disabled={!isEditingPersonalInfo}
        />
      ),
    },
    {
      id: 'email',
      title: t('operator.registers.edit.personalInformation.email'),
      width: 6,
      component: (
        <input
          name="email"
          value={personalInfo.email || ''}
          disabled={!isEditingPersonalInfo}
          onChange={handleStatePersonal}
          type="email"
        />
      ),
    },
    {
      id: 'phone',
      title: t('operator.registers.edit.personalInformation.phone'),
      width: 3,
      component: (
        <MaskedInput
          mask={phoneMask}
          guide={false}
          showMask
          name="phone"
          value={personalInfo.phone}
          onChange={handleStatePersonal}
          disabled={!isEditingPersonalInfo}
        />
      ),
    },
  ] as Field[];

  const addressInfoFields = [
    {
      id: 'zipCode',
      title: t('operator.registers.edit.addressInformation.zipCode'),
      width: 3,
      component: (
        <MaskedInput
          mask={cepMask}
          guide={false}
          showMask
          name="zipCode"
          value={addressInfo.zipCode || ''}
          onChange={e => {
            setAddressInfo({
              ...addressInfo,
              zipCode: e.target.value,
            });
          }}
          disabled={!isEditingAddressInfo}
        />
      ),
    },
    {
      id: 'address',
      title: t('operator.registers.edit.addressInformation.address'),
      width: 8,
    },
    {
      id: 'number',
      title: t('operator.registers.edit.addressInformation.number'),
      width: 1,
      component: (
        <MaskedInput
          mask={numberMask}
          guide={false}
          showMask
          name="number"
          value={addressInfo.number || ''}
          onChange={e => {
            setAddressInfo({
              ...addressInfo,
              number: e.target.value,
            });
          }}
          disabled={!isEditingAddressInfo}
        />
      ),
    },
    {
      id: 'neighborhood',
      title: t('operator.registers.edit.addressInformation.neighborhood'),
      width: 3,
    },
    {
      id: 'city',
      title: t('operator.registers.edit.addressInformation.city'),
      width: 3,
    },
    {
      id: 'state',
      title: t('operator.registers.edit.addressInformation.state'),
      width: 3,
    },
  ] as Field[];

  return (
    <Layout type="operator" routeSelected="registers">
      {!data ? (
        <div className={classes.errorContainer}>
          {error ? t('operator.registers.error') : <CircularProgress />}
        </div>
      ) : (
        <>
          <h1 className={classes.title}>
            {t('operator.registers.edit.title')}
          </h1>

          <div className={classes.reviewContainer}>
            <h1>{t('operator.registers.edit.documentsReview.title')}</h1>

            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={10}
              style={{ marginTop: '1rem' }}
            >
              <Grid item xs={3} className={classes.document}>
                <p>{t('operator.registers.edit.documentsReview.selfie')}</p>

                <ModalApproveDocument
                  title={t('operator.registers.edit.documentsReview.selfie')}
                  clientId={id}
                  documents={[documents.selfie, documents.frontSide]}
                  info={undefined}
                />
              </Grid>

              <Grid item xs={3} className={classes.document}>
                <p>
                  {t('operator.registers.edit.documentsReview.documentFront')}
                </p>

                <ModalApproveDocument
                  title={t(
                    'operator.registers.edit.documentsReview.documentFront',
                  )}
                  clientId={id}
                  documents={[documents.frontSide]}
                  info={personalInfo}
                />
              </Grid>

              <Grid item xs={3} className={classes.document}>
                <p>
                  {t('operator.registers.edit.documentsReview.documentBack')}
                </p>

                <ModalApproveDocument
                  title={t(
                    'operator.registers.edit.documentsReview.documentBack',
                  )}
                  clientId={id}
                  documents={[documents.backSide]}
                  info={personalInfo}
                />
              </Grid>
            </Grid>
          </div>

          <div className={classes.personalInfoContainer}>
            <div className={classes.infoHeader}>
              <h2>{t('operator.registers.edit.personalInformation.title')}</h2>

              <Button
                onClick={() => {
                  handleUpdatePerson('personal');
                }}
                className={clsx({
                  [classes.buttonSave]: isEditingPersonalInfo,
                  [classes.buttonEdit]: !isEditingPersonalInfo,
                })}
                endIcon={<EditOutlinedIcon />}
              >
                {isEditingPersonalInfo
                  ? t('operator.registers.edit.buttonSave')
                  : t('operator.registers.edit.buttonEdit')}
              </Button>
            </div>

            <Grid container direction="row" alignItems="center" spacing={3}>
              {personalInfoFields.map(field => (
                <Grid
                  key={field.id}
                  item
                  xs={field.width}
                  className={classes.field}
                >
                  <label htmlFor={field.id}>{field.title}</label>
                  {field.component}
                </Grid>
              ))}
            </Grid>
          </div>

          <div style={{ marginTop: '1.5rem' }}>
            <div className={classes.infoHeader}>
              <h2>{t('operator.registers.edit.addressInformation.title')}</h2>

              <Button
                onClick={() => {
                  handleUpdatePerson('address');
                }}
                className={clsx({
                  [classes.buttonSave]: isEditingAddressInfo,
                  [classes.buttonEdit]: !isEditingAddressInfo,
                })}
                endIcon={<EditOutlinedIcon />}
              >
                {isEditingAddressInfo
                  ? t('operator.registers.edit.buttonSave')
                  : t('operator.registers.edit.buttonEdit')}
              </Button>
            </div>

            <Grid container direction="row" alignItems="center" spacing={3}>
              {addressInfoFields.map(field => (
                <Grid
                  key={field.id}
                  item
                  xs={field.width}
                  className={classes.field}
                >
                  <label htmlFor={field.id}>{field.title}</label>

                  {field.component ?? (
                    <input
                      name={field.id}
                      value={addressInfo[field.id as AddressInfoFields] || ''}
                      disabled={!isEditingAddressInfo}
                      onChange={e => {
                        setAddressInfo({
                          ...addressInfo,
                          [field.id]: e.target.value,
                        });
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </div>

          <div className={classes.bottomContainer}>
            <ModalDelete
              what="client"
              type="editRegister"
              component={{ id, name: personalInfo.name }}
            />
          </div>
        </>
      )}
    </Layout>
  );
}
