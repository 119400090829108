/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Grid, { GridSize } from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { mutate } from 'swr';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import clsx from 'clsx';

import useAlert from '../../../hooks/useAlertContext';

import { UserDocument } from '../../../types/UserDocument';
import { PersonalInfoFields, AddressInfoFields } from '../../../types/Field';
import { UserDocumentStatusName } from '../../../types/StatusName';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';

const useStyles = makeStyles({
  document: {
    width: '15.5rem',
    height: '18.75rem',
    cursor: 'pointer',
    borderRadius: 0,
  },

  withoutDocumentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  mainImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center bottom',
  },

  statusDocument: {
    height: '2.25rem',
    width: '15.5rem',
    display: 'flex',
    alignItems: 'center',
    color: COLORS.white,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    padding: '0 .75rem',
    cursor: 'pointer',

    '& div': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },

  userDocumentImg: {
    objectFit: 'contain',
    maxHeight: '80vh',
    width: '100%',
  },

  form: {
    width: '33.25rem',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 3rem',
    backgroundColor: COLORS.gray5,
  },

  buttonClose: {
    position: 'fixed',
    top: '2.25rem',
    right: '2.25rem',
    color: COLORS.white,
    fontSize: '2rem',

    '&:hover': {
      cursor: 'pointer',
      color: COLORS.pureBlack,
      transition: '0.3s',
    },
  },

  title: {
    fontWeight: 600,
    fontSize: '1.5rem',
    color: COLORS.white,
    marginBottom: '2rem',
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',

    '& span': {
      color: COLORS.white,
      fontSize: '0.75rem',
      fontWeight: '600',
    },

    '& input': {
      height: '2.75rem',
      border: `1px solid ${COLORS.lightGray3}`,
      borderRadius: '0.5rem',
      padding: '0 1rem',
      outline: 'none',
      backgroundColor: COLORS.white,
    },
  },

  approveOrDeclineContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '3rem 0 2rem 0',
  },

  buttonApprove: {
    marginRight: '1rem',
    borderRadius: '0.5rem',
    width: '50%',
    height: '3.25rem',
    textTransform: 'none',
    padding: 0,
    backgroundColor: COLORS.white,
    color: COLORS.greenButton,

    '&:hover': {
      transition: '0.3s',
      backgroundColor: COLORS.greenButton,
      color: COLORS.white,
    },
  },

  approveActive: {
    background: COLORS.greenButton,
    color: COLORS.white,

    '&:hover': {
      backgroundColor: COLORS.greenButton,
      color: COLORS.white,
    },
  },

  buttonDecline: {
    borderRadius: '0.5rem',
    width: '50%',
    height: '3.25rem',
    textTransform: 'none',
    padding: 0,
    backgroundColor: COLORS.white,
    color: COLORS.redWarning,

    '&:hover': {
      transition: '0.3s',
      backgroundColor: COLORS.redWarning,
      color: COLORS.white,
    },
  },

  declineActive: {
    background: COLORS.redWarning,
    color: COLORS.white,

    '&:hover': {
      backgroundColor: COLORS.redWarning,
      color: COLORS.white,
    },
  },

  buttonDone: {
    width: '12rem',
    height: '3rem',
    backgroundColor: COLORS.white,
    padding: 0,
    borderRadius: '0.5rem',
    color: COLORS.gray4,
    fontWeight: 'bold',
    textTransform: 'none',

    '&.Mui-disabled': {
      backgroundColor: COLORS.mediumGray5,
      color: COLORS.white,
    },
  },

  fieldObservation: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',
    margin: '1rem 0 3rem 0',
    maxHeight: '7.5rem',
    opacity: 1,
    transition: 'all 0.5s ease-in-out, opacity 1s ease-in-out',

    '& span': {
      color: COLORS.white,
      fontSize: '0.75rem',
      fontWeight: '600',
    },

    '& textarea': {
      resize: 'none',
      border: `1px solid ${COLORS.lightGray3}`,
      borderRadius: '0.5rem',
      padding: '1rem',
      outline: 'none',
      backgroundColor: COLORS.white,
      color: COLORS.gray3,
    },
  },

  fieldObservationHide: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',
    marginBottom: '1rem',
    opacity: 0,
    maxHeight: 0,
    transition: 'all 0.5s ease-in-out, opacity 0.25s ease-in-out',
    pointerEvents: 'none',

    '& span': {
      color: COLORS.white,
      fontSize: '0.75rem',
      fontWeight: '600',
    },

    '& textarea': {
      resize: 'none',
      border: `1px solid ${COLORS.lightGray3}`,
      borderRadius: '0.5rem',
      padding: '1rem',
      outline: 'none',
      backgroundColor: COLORS.white,
    },
  },
});

interface Client {
  documents: UserDocument[];
}

type Field = PersonalInfoFields | AddressInfoFields;

interface PersonalInfo {
  name?: string;
  email?: string;
  cpf?: string;
  phone?: string;
  birth?: string;
}
interface AddressInfo {
  zipCode?: string;
  address?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
}

interface Props {
  title: string;
  clientId: string | number;
  documents: (UserDocument | undefined)[];
  info: (PersonalInfo & AddressInfo) | undefined;
}

export default function ModalApproveDocument({
  title,
  clientId,
  documents,
  info,
}: Props) {
  const [open, setOpen] = useState(false);
  const [approved, setApproved] = useState('');
  const [observation, setObservation] = useState('');
  const [statusDocument, setStatusDocument] =
    useState<UserDocumentStatusName | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const fieldsInfo = {
    name: t('components.modalApproveDocument.fields.name'),
    cpf: t('components.modalApproveDocument.fields.cpf'),
    birth: t('components.modalApproveDocument.fields.birth'),
    email: t('components.modalApproveDocument.fields.email'),
    phone: t('components.modalApproveDocument.fields.phone'),

    zipCode: t('components.modalApproveDocument.fields.zipCode'),
    address: t('components.modalApproveDocument.fields.address'),
    number: t('components.modalApproveDocument.fields.number'),
    neighborhood: t('components.modalApproveDocument.fields.neighborhood'),
    city: t('components.modalApproveDocument.fields.city'),
    state: t('components.modalApproveDocument.fields.state'),
  };

  const statusDocumentsInfo = {
    approved: {
      title: t('components.modalApproveDocument.statusDocument.approved'),
      color: COLORS.greenButton,
    },

    declined: {
      title: t('components.modalApproveDocument.statusDocument.declined'),
      color: COLORS.redWarning,
    },

    default: {
      title: t('components.modalApproveDocument.statusDocument.review'),
      color: COLORS.orangeAdvice,
    },

    waitingDocuments: { title: '', color: '' },
    waitingValidation: { title: '', color: '' },
  };

  useEffect(() => {
    if (documents[0]) {
      const [lastStatus] = documents[0].status.slice(-1);
      setStatusDocument(lastStatus.is_active ? lastStatus.name : null);
    }
  }, [documents[0]]);

  const handleClose = () => {
    setApproved('');
    setObservation('');
    setOpen(false);
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      if (!approved) {
        throw new Error('approveDocumentWithoutChoose');
      }

      if (approved === 'false' && !observation) {
        throw new Error('approveDocumentWithoutObservation');
      }

      const status = approved === 'true' ? 'approved' : 'declined';

      await api.post('/operator/user/personal-document', {
        personDocument_id: documents[0]?._id,
        client_id: clientId,
        observation,
        status,
      });

      mutate(
        linksForSWR.operator.registers.edit(clientId),
        (client: Client) => {
          if (!client) return null;

          const updatedDocuments = client.documents.map(
            (document: UserDocument) => {
              if (document._id !== documents[0]?._id) {
                return document;
              }

              const updatedStatus = [...document.status];
              updatedStatus[updatedStatus.length - 1] = {
                ...updatedStatus[updatedStatus.length - 1],
                is_active: false,
              };

              updatedStatus.push({
                is_active: true,
                name: status,
              });

              return {
                ...document,
                status: updatedStatus,
              };
            },
          );

          return {
            ...client,
            documents: updatedDocuments,
          };
        },
      );

      mutate(linksForSWR.operator.registers.edit(clientId));
      mutate(linksForSWR.operator.registers.index());
      mutate(linksForSWR.operator.clients.index());

      handleClose();

      showAlert(`${status}Document`, 'success', null);
    } catch (error: any) {
      if (
        error.message === 'approveDocumentWithoutChoose' ||
        error.message === 'approveDocumentWithoutObservation'
      ) {
        showAlert(error.message, 'error', null);
      }
      console.log(error);
    }

    setIsProcessing(false);
  };

  return (
    <>
      <div>
        <Paper
          onClick={() => {
            setOpen(true);
          }}
          className={classes.document}
          square
        >
          {documents[0] &&
            (documents[0].status.slice(-1)[0].name === 'waitingDocuments' ||
            !documents[0].status.slice(-1)[0].is_active ? (
              <div className={classes.withoutDocumentContainer}>
                <span>
                  {t('components.modalApproveDocument.withoutDocument')}
                </span>
              </div>
            ) : (
              <img
                src={documents[0].path}
                alt={title}
                className={classes.mainImg}
              />
            ))}
        </Paper>

        {statusDocument && (
          <Paper
            square
            style={{
              backgroundColor:
                statusDocumentsInfo[statusDocument]?.color ||
                statusDocumentsInfo.default.color,
            }}
            className={classes.statusDocument}
            onClick={() => {
              setOpen(true);
            }}
          >
            {statusDocumentsInfo[statusDocument] ? (
              <span>{statusDocumentsInfo[statusDocument].title}</span>
            ) : (
              <div>
                <span>{statusDocumentsInfo.default.title}</span>

                <ArrowForwardIcon />
              </div>
            )}
          </Paper>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        PaperProps={{
          style: { backgroundColor: 'transparent', boxShadow: 'none' },
        }}
      >
        <Grid container item justifyContent="center" alignItems="center">
          <Grid
            container
            item
            xs={9}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            style={{ backgroundColor: 'rgba(0, 0, 0, .77)', height: '100%' }}
            spacing={0}
          >
            {documents.map(document => (
              <img
                key={document?.path}
                src={document?.path}
                alt={document?.type}
                className={classes.userDocumentImg}
                style={{ maxWidth: `${90 / documents.length}%` }}
              />
            ))}
          </Grid>

          <Grid
            container
            item
            direction="row"
            alignItems="center"
            xs={3}
            className={classes.form}
          >
            <CloseIcon onClick={handleClose} className={classes.buttonClose} />

            <h1 className={classes.title}>{title}</h1>

            <Grid container direction="row" alignItems="center" spacing={3}>
              {info &&
                Object.keys(info).map(element => {
                  const key = element as Field;
                  let xs = 12;
                  let value = info[key];

                  if (key === 'address') {
                    xs = 9;
                  }

                  if (key === 'number') {
                    xs = 3;
                  }

                  if (key === 'birth') {
                    value = moment(info[key])
                      .locale(t('components.modalApproveDocument.locale'))
                      .format('L');
                  }

                  return (
                    <Grid
                      key={key}
                      item
                      xs={xs as GridSize}
                      className={classes.field}
                    >
                      <span>{fieldsInfo[key]}</span>

                      <input disabled value={value} />
                    </Grid>
                  );
                })}
            </Grid>

            <div className={classes.approveOrDeclineContainer}>
              <Button
                className={`${classes.buttonApprove} ${
                  approved === 'true' ? classes.approveActive : ''
                }`}
                startIcon={<CheckIcon />}
                onClick={() => {
                  setApproved('true');
                }}
              >
                {t('components.modalApproveDocument.buttons.approve')}
              </Button>

              <Button
                className={clsx(classes.buttonDecline, {
                  [classes.declineActive]: approved === 'false',
                })}
                startIcon={<CloseIcon />}
                onClick={() => {
                  setApproved('false');
                }}
              >
                {t('components.modalApproveDocument.buttons.decline')}
              </Button>
            </div>

            <div
              className={clsx({
                [classes.fieldObservation]: approved === 'false',
                [classes.fieldObservationHide]: approved !== 'false',
              })}
            >
              <span>{t('components.modalApproveDocument.observation')}</span>

              <textarea
                value={observation}
                onChange={e => {
                  setObservation(e.target.value);
                }}
                rows={2}
              />
            </div>

            <Button
              fullWidth
              variant="contained"
              className={classes.buttonDone}
              onClick={handleSubmit}
              disabled={isProcessing}
            >
              {isProcessing ? (
                <CircularProgress size={24} style={{ color: COLORS.white }} />
              ) : (
                t('components.modalApproveDocument.buttons.done')
              )}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
