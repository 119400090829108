/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DeleteSweepOutlinedIcon from '@material-ui/icons/DeleteSweepOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from '@material-ui/icons/Search';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useTranslation } from 'react-i18next';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import clsx from 'clsx';

import Layout from '../../../components/Layout';
import ModalOpenDocumentVault from '../../../components/Modals/ModalOpenDocumentVault';
import ModalDelete from '../../../components/Modals/ModalDelete';
import CustomPagination from '../../../components/CustomPagination';
import ModalFileTypesAvailableInVault from '../../../components/Modals/ModalFileTypesAvailableInVault';

import { useAxios } from '../../../hooks/useAxios';
import useAuthContext from '../../../hooks/useAuthContext';
import useAlert from '../../../hooks/useAlertContext';

import { Vault as VaultInterface } from '../../../types/Vault';

import COLORS from '../../../utils/colors';
import getTwoFirstLettersByName from '../../../utils/getTwoFirstLettersByName';
import linksForSWR from '../../../utils/linksForSWR';

interface StylesProps {
  isActive: boolean;
}

const useStyles = makeStyles(theme => ({
  titleContainer: {
    columnGap: '1.625rem',
  },

  title: {
    fontWeight: 'bold',
    fontSize: '3rem',
    lineHeight: '4rem',
  },

  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.25rem',
    textAlign: 'justify',
  },

  description: {
    color: COLORS.gray2,
    fontSize: '0.875rem',
    maxWidth: '16.5rem',
  },

  learnMore: {
    color: COLORS.mediumGray3,
    fontSize: '0.75rem',
  },

  learnMoreLink: {
    color: COLORS.blue,
    textDecoration: 'none',

    '&:hover': {
      color: COLORS.blueHover,
    },
  },

  search: {
    height: '3rem',
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    paddingLeft: '1rem',
    display: 'flex',
    justifyContent: 'center',
    outline: `${COLORS.lightGray1}`,
    width: '80%',
    marginBottom: '1rem',

    '&:hover': {
      transition: '0.1s',
      outline: `0.25rem solid ${COLORS.lightGray1}`,
    },

    [theme.breakpoints.up('md')]: {
      width: '33.33%',
      marginBottom: 0,
    },

    '& input': {
      fontSize: '1.125rem',
      marginBottom: '0.125rem',
      lineHeight: '1.5rem',
      color: COLORS.gray3,
    },

    '& svg': {
      fill: COLORS.mediumGray1,
      fontSize: '1.5rem',
    },

    '& .Mui-focused': {
      '& svg': {
        fill: COLORS.gray2,
      },
    },
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '.5rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      rowGap: '0',
      columnGap: '2rem',
    },
  },

  buttonNewDocuments: {
    color: COLORS.white,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    padding: '0.75rem',
    fontSize: '1rem',
    width: '12rem',
    height: '3rem',
    borderRadius: '0.5rem',
    backgroundColor: (props: StylesProps) =>
      props.isActive ? COLORS.orangeAdvice : COLORS.lightGray4,

    '&:hover': {
      backgroundColor: (props: StylesProps) =>
        props.isActive ? COLORS.orangeHover : COLORS.lightGray4,
    },

    '&.Mui-disabled': {
      color: COLORS.white,
      backgroundColor: COLORS.lightGray4,
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.125rem',
      width: '13.5rem',
    },
  },

  createDocumentInput: {
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    padding: '0.75rem',
    fontSize: '1rem',
    width: '12rem',
    height: '3rem',
    borderRadius: '0.5rem',

    '&:hover': {
      backgroundColor: COLORS.blueHover,
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.125rem',
      width: '13.5rem',
    },
  },

  tablePaper: {
    width: '100%',
    marginTop: '1.5rem',
    borderRadius: '0.5rem',
  },

  tableHeadCell: {
    fontSize: '1.125rem',
    width: '20%',

    '&:first-of-type': {
      width: '40%',
    },

    '& .MuiSvgIcon-root': {
      fontSize: '1.75rem',
      margin: '0 3rem 0 1rem',
      opacity: 0,
    },
  },

  avatar: {
    color: COLORS.gray2,
    fontSize: '0.75rem',
    backgroundColor: COLORS.lightGray4,

    '&.isOpen': {
      backgroundColor: COLORS.lightGreen,
    },
  },

  deleteIcon: {
    color: COLORS.lightGray4,
    fontSize: '1.5rem',
    cursor: 'pointer',
  },

  buttonReturnToAllVaults: {
    fontSize: '0.75rem',
    textTransform: 'none',
    fontWeight: 500,
    height: '1rem',
    width: '10.875rem',
    color: COLORS.mediumGray1,

    '& svg': {
      width: '0.875rem',
      height: '0.875rem',
    },

    '&:hover': {
      color: COLORS.gray5,
      background: 'none',
      transition: '0.3s',
    },
  },
}));

interface VaultContent {
  vaults: VaultInterface[];
  numberVaultsToBeOpenedByUser: number;
}

interface VaultData {
  data: VaultContent;
  xTotalCount: number;
}

export default function Vault() {
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');
  const [onlyUnopenedFiles, setOnlyUnopenedFiles] = useState(false);

  const [file, setFile] = useState<File | null>(null);
  const history = useHistory();

  const { showAlert } = useAlert();

  const rowsPerPage = 6;

  const { data: vaultContent, error }: { data: VaultData; error: any } =
    useAxios(
      linksForSWR.client.vault.index(
        page,
        rowsPerPage,
        search,
        onlyUnopenedFiles,
      ),
    );

  const classes = useStyles({
    isActive: Boolean(vaultContent?.data.numberVaultsToBeOpenedByUser),
  });

  const { user } = useAuthContext();

  const { t } = useTranslation();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeInputFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const supportedFiles = [
      'text/plain', // txt
      'text/html',
      'application/json',
      'audio/aac',
      'audio/x-wav', // .wav
      'audio/x-vorbis+ogg',
      'audio/mpeg', // .mp3
      'audio/mp4', // .m4a
      'image/bmp',
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml',
      'video/quicktime', // .mov
      'application/ogg', // .ogx, .ogg
      'video/ogg',
      'video/webm',
      'video/mp4',
      'application/pdf',
    ];
    const currentFile = event.target.files ? event.target.files[0] : null;

    if (!currentFile) return;

    if (!supportedFiles.includes(currentFile.type)) {
      showAlert('uploadUnsupportedFilesInVault', 'error', null);
      return;
    }

    setFile(currentFile);

    history.push('vault/create', { file: currentFile });
  };

  const handleSearch = (value: string) => {
    setPage(1);
    setSearch(value);
  };

  const returnToAllVaults = () => {
    setSearch('');
    setPage(1);
    setOnlyUnopenedFiles(false);
  };

  return (
    <>
      <Layout type="user" routeSelected="vault">
        <Grid container justifyContent="space-between">
          <Grid
            container
            item
            alignItems="center"
            className={classes.titleContainer}
          >
            <>
              <Typography variant="h1" className={classes.title}>
                {t('client.vault.index.title')}
              </Typography>

              <div className={classes.descriptionContainer}>
                <Typography className={classes.description}>
                  {t('client.vault.index.description')}
                </Typography>

                <Typography className={classes.learnMore}>
                  <Link to="/vault" className={classes.learnMoreLink}>
                    {t('client.vault.index.learnMore.link')}
                  </Link>{' '}
                  {t('client.vault.index.learnMore.content')}
                </Typography>
              </div>
            </>

            <ModalFileTypesAvailableInVault />
          </Grid>

          <Grid
            container
            item
            justifyContent="space-between"
            style={{ marginTop: '2.875rem' }}
          >
            <DebounceInput
              minLength={0}
              debounceTimeout={300}
              element={TextField}
              size="medium"
              placeholder={t('client.vault.index.search')}
              fullWidth
              variant="standard"
              value={search}
              onChange={e => {
                handleSearch(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: COLORS.mediumGray1 }} />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              className={classes.search}
            />

            <div className={classes.buttonsContainer}>
              <Button
                startIcon={
                  vaultContent ? (
                    `${Number(vaultContent.data.numberVaultsToBeOpenedByUser)}`
                  ) : (
                    <CircularProgress
                      style={{ color: COLORS.white }}
                      size={24}
                    />
                  )
                }
                className={classes.buttonNewDocuments}
                onClick={() => {
                  setOnlyUnopenedFiles(true);
                }}
                disabled={!vaultContent?.data.numberVaultsToBeOpenedByUser}
              >
                {t('client.vault.index.buttonNewDocuments')}
              </Button>

              <Button
                startIcon={<ControlPointIcon />}
                className={classes.createDocumentInput}
                component="label"
              >
                {t('client.vault.index.buttonCreateDocument')}

                <input
                  type="file"
                  hidden
                  value={file?.name || ''}
                  onChange={handleChangeInputFile}
                  accept="*"
                />
              </Button>
            </div>
          </Grid>
        </Grid>

        <Paper className={classes.tablePaper} elevation={0}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {(
                    t('client.vault.index.columns', {
                      returnObjects: true,
                    }) as string[]
                  ).map((element, index) => (
                    <TableCell key={element} className={classes.tableHeadCell}>
                      {!index && <DescriptionIcon />}
                      {element}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {vaultContent ? (
                  vaultContent.data.vaults.map(vault => {
                    let stillWillOpen = true;
                    let myPasswordDocument = '';
                    let observation = '';

                    vault.sharedUsers.forEach(sharedUser => {
                      if (sharedUser.user?.email === user?.email) {
                        stillWillOpen = !sharedUser.isOpened;
                        myPasswordDocument = sharedUser.password;
                      }
                    });

                    if (user?.email !== vault.user?.email) {
                      const sharedUserFound = vault.sharedUsers.find(
                        sharedUser => sharedUser.user?.email === user?.email,
                      );
                      if (sharedUserFound) {
                        observation = sharedUserFound.observation;
                      }
                    }

                    return (
                      <TableRow key={vault._id}>
                        <ModalOpenDocumentVault
                          type="documentName"
                          documentData={{
                            id: vault._id,
                            title: vault.fileName,
                            password: myPasswordDocument,
                            createdAt: moment(vault.createdAt)
                              .locale(t('client.vault.locale'))
                              .format('LL'),
                            stillWillOpen,
                            observation,
                          }}
                        />

                        <TableCell align="left">
                          <Typography style={{ fontSize: '0.875rem' }}>
                            {vault.user
                              ? vault.user.email === user?.email
                                ? t('client.vault.index.label')
                                : vault.user?.name
                              : t('client.vault.deletedUser')}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <AvatarGroup max={3}>
                            {vault.sharedUsers.map(sharedUser => (
                              <Tooltip
                                key={sharedUser._id}
                                title={
                                  sharedUser.user
                                    ? sharedUser.user.name
                                    : t('client.vault.deletedUser')
                                }
                              >
                                <Avatar
                                  className={clsx(classes.avatar, {
                                    isOpen: sharedUser.isOpened,
                                  })}
                                >
                                  {getTwoFirstLettersByName(
                                    sharedUser.user
                                      ? sharedUser.user.name
                                      : '-',
                                  )}
                                </Avatar>
                              </Tooltip>
                            ))}
                          </AvatarGroup>
                        </TableCell>

                        {/* <TableCell align="left">
                          <Typography style={{ fontSize: '0.875rem' }}>
                            {vault.costCenter
                              ? vault.costCenter.name
                              : t('client.documents.index.personalAccount')}
                          </Typography>
                        </TableCell> */}

                        {stillWillOpen ? (
                          <ModalOpenDocumentVault
                            type="openDocument"
                            documentData={{
                              id: vault._id,
                              title: vault.fileName,
                              password: myPasswordDocument,
                              createdAt: moment(vault.createdAt)
                                .locale(t('client.vault.locale'))
                                .format('LL'),
                              stillWillOpen,
                              observation,
                            }}
                          />
                        ) : (
                          <TableCell align="center">
                            {vault.user?.email === user?.email ? (
                              <ModalDelete
                                what="vault"
                                type="index"
                                component={{ id: vault._id }}
                              />
                            ) : (
                              <DeleteSweepOutlinedIcon
                                className={classes.deleteIcon}
                              />
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell />
                    <TableCell align="center">
                      {error ? t('client.vault.error') : <CircularProgress />}
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {vaultContent && (
            <CustomPagination
              page={page}
              handleChangePage={handleChangePage}
              total={vaultContent.xTotalCount}
              rowsPerPage={rowsPerPage}
              contentToLeft={
                onlyUnopenedFiles ? (
                  <Button
                    startIcon={<ArrowBackIcon />}
                    className={classes.buttonReturnToAllVaults}
                    onClick={returnToAllVaults}
                  >
                    {t('client.vault.index.buttonReturn')}
                  </Button>
                ) : null
              }
            />
          )}
        </Paper>
      </Layout>
    </>
  );
}
