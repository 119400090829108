import CryptoJS from 'crypto-js';

// Credits: https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
const _fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const result = reader.result as string;
      const gistBase64 = result.split(',')[1];

      resolve(gistBase64);
    };

    reader.onerror = error => reject(error);
  });

function _base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64);
  const { length } = binaryString;
  const bytes = new Uint8Array(length);

  for (let i = 0; i < length; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer as unknown as number[];
}

export default async function encryptFile(file: File, secret: string) {
  const fileBase64 = await _fileToBase64(file);
  const fileBuffer = _base64ToArrayBuffer(fileBase64);

  const wordArray = CryptoJS.lib.WordArray.create(fileBuffer);
  const encrypted = CryptoJS.AES.encrypt(wordArray, secret).toString();

  return encrypted;
}
