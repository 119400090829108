/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import FaceIcon from '@material-ui/icons/Face';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RemoveIcon from '@material-ui/icons/Remove';
import Select from '@material-ui/core/Select';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';

import CostCenterSelectOperator from '../../CostCenterSelectOperator';

import useAlert from '../../../hooks/useAlertContext';
import useCostCentersOperator from '../../../hooks/useCostCentersOperator';
import { useAxios } from '../../../hooks/useAxios';

import { PlansResponse } from '../../../types/Plan';
import { ProductCategory } from '../../../types/ProductCategory';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';
import useProducts from '../../../hooks/useProducts';
import { numberMask } from '../../../utils/masks';

const useStyles = makeStyles(theme => ({
  buttonMain: {
    padding: 0,
    textTransform: 'none',
    height: '3rem',
    width: '12.875rem',
    backgroundColor: COLORS.gray2,
    borderRadius: '0.5rem',
    color: COLORS.white,
    fontSize: '1.125rem',
    fontWeight: 'bold',

    '& svg': {
      color: COLORS.orangeAdvice,
      width: '1.5rem',
      height: '1.5rem',
    },

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',

      '& svg': {
        color: COLORS.orangeHover,
        transition: '0.3s',
      },
    },
  },

  containerTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1.75rem 1.75rem 0 0',

    '& svg': {
      fontSize: '1.625rem',
      color: COLORS.gray2,
      cursor: 'pointer',
    },
  },

  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },

  mainContent: {
    width: '40rem',
    margin: '2rem 3rem 1.5rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2rem',
  },

  selectLabel: {
    fontWeight: 'bold',
    color: COLORS.gray2,
  },

  planSelect: {
    '& .MuiInputBase-input': {
      borderRadius: '.5rem',
      position: 'relative',
      border: `1px solid ${COLORS.gray2}`,
      color: COLORS.gray2,
      backgroundColor: COLORS.white,
      fontSize: 16,
      padding: '0.625rem 1.625rem 0.625rem 0.75rem',
      marginTop: '.4rem',
      transition: theme.transitions.create(['border-color', 'box-shadow']),

      '&.Mui-disabled': {
        backgroundColor: COLORS.background,
        color: COLORS.mediumGray3,
        borderColor: COLORS.mediumGray3,
      },
    },

    '&.MuiInput-formControl': {
      marginTop: 0,
    },

    '& .MuiSelect-select': {
      display: 'flex',

      '& span': {
        display: 'none',
      },
    },

    '&:focus': {
      borderRadius: '.5rem',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },

  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: '1rem',

    '& span': {
      width: '100%',
      color: COLORS.mediumGray1,
    },

    '&:hover': {
      backgroundColor: COLORS.lightBlue,
      transition: '0.3s',

      '& span': {
        color: COLORS.gray1,
      },
    },
  },

  products: {
    width: '40rem',
    margin: '2rem 3rem 1.5rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2rem',

    '& .item': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: COLORS.background,
      borderRadius: '.625rem',
      padding: '1rem .5rem',
      columnGap: '.5rem',
    },
  },

  productDescription: {
    width: '100%',

    '& .title': {
      fontSize: '1.125rem',
      fontWeight: 700,
    },

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .value': {
        color: COLORS.gray1,
        fontSize: '1rem',
      },
    },
  },

  buttonGroupOperateQuantityProducts: {
    borderRadius: '.75rem',
    border: `1px solid ${COLORS.gray1}`,
    padding: '0 0.25rem',
    width: '7rem',
    height: '2rem',
    display: 'flex',

    '& .MuiButtonBase-root': {
      color: COLORS.orangeAdvice,

      '&.Mui-disabled': {
        color: COLORS.mediumGray4,
      },
    },

    '& input': {
      width: '2.75rem',
      textAlign: 'center',
      height: '80%',
      border: 'none',
      borderRadius: '.75rem',
      backgroundColor: COLORS.white,
    },
  },

  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem',
    fontSize: '1.25rem',

    '& div': {
      width: '70%',
      display: 'flex',
      justifyContent: 'space-between',

      '& :nth-child(2)': {
        color: COLORS.orangeAdvice,
        fontWeight: 700,
      },
    },
  },

  inputObservation: {
    display: 'flex',
    justifyContent: 'center',
    margin: '.5rem 0',

    '& .MuiInputBase-root': {
      width: '100%',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.lightGray2,
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.mediumGray1,
        transition: '0.3s',
      },

      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.mediumGray1,
        borderWidth: '1px',
      },
    },

    '& label': {
      color: COLORS.mediumGray1,
    },

    '& textarea': {
      color: COLORS.gray2,
    },
  },

  buttonConfirmation: {
    padding: 0,
    height: '3rem',
    width: '29%',
    borderRadius: '0.5rem',
    textTransform: 'none',
    color: COLORS.background,
    background: COLORS.greenButton,
    fontWeight: 'bold',
    fontSize: '0.875rem',

    '&:hover': {
      background: COLORS.greenHover,
      color: COLORS.background,
    },
  },
}));

interface Props {
  userId: string;
  name: string;
}

type ProductToBuy = ProductCategory & {
  quantity: number;
};

export default function ModalSubscriptionToTheClient({ userId, name }: Props) {
  const [open, setOpen] = useState(false);
  const [observation, setObservation] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chosenCostCenter, setChosenCostCenter] = useState(-1);
  const [chosenPlan, setChosenPlan] = useState(0);
  const [productsToBuy, setProductsToBuy] = useState([] as ProductToBuy[]);

  const productIcon = {
    sendingDocuments: (
      <InsertDriveFileOutlinedIcon style={{ fontSize: '3rem' }} />
    ),
    facialBiometrics: <FaceIcon style={{ fontSize: '3rem' }} />,
    vault: <LockOutlinedIcon style={{ fontSize: '3rem' }} />,
  };

  const classes = useStyles();
  const { costCenters } = useCostCentersOperator(userId);
  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();
  const { data: plans } = useAxios(linksForSWR.plans()) as {
    data: PlansResponse;
  };
  const { products } = useProducts();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setObservation('');
    setChosenCostCenter(-1);
    setTimeout(() => {
      setChosenPlan(0);
    }, 150);
  };

  const handleButtonQuantityCredits = (value: number, index: number) => {
    const localProductsToBuy = [...productsToBuy];
    localProductsToBuy[index].quantity += value;

    setProductsToBuy(localProductsToBuy);
  };

  const handleInputQuantityCredits = (value: string, index: number) => {
    const localProductsToBuy = [...productsToBuy];
    localProductsToBuy[index].quantity = Number(value);

    setProductsToBuy(localProductsToBuy);
  };

  const handleBuyNormalPlan = async () => {
    if (!observation) {
      showAlert('informObservationInCredits', 'error', null);
      return;
    }

    setIsSubmitting(true);

    try {
      await api.post('/operator/user/plans', {
        user_id: userId,
        cost_center_id:
          chosenCostCenter !== -1 ? costCenters[chosenCostCenter]._id : null,
        plan_id: plans.data[chosenPlan]._id,
        plan_name: plans.data[chosenPlan].name,
        language: i18n.language,
        observation,
      });

      handleClose();
    } catch (error) {
      showAlert('buyCreditsError', 'error', null);
    }

    setIsSubmitting(false);
  };

  const handleBuyCustomPlan = async () => {
    if (!observation) {
      showAlert('informObservationInCredits', 'error', null);
      return;
    }

    setIsSubmitting(true);

    try {
      await api.post('/plan', {
        user_id: userId,
        costcenter_id:
          chosenCostCenter !== -1 ? costCenters[chosenCostCenter]._id : null,
        typePayment: 'subscriptions',
        linkPayment: true,
        products: productsToBuy
          .filter(product => product.quantity > 0)
          .map(product => ({
            product_id: product._id,
            name: product.name,
            quantity: product.quantity,
          })),
        language: i18n.language,
        observation,
      });

      handleClose();
    } catch (error) {
      showAlert('buyCreditsError', 'error', null);
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (products) {
      setProductsToBuy(
        products
          .sort((a, b) => a.totalValue - b.totalValue)
          .map(product => ({ ...product, quantity: 0 })),
      );
    }
  }, [products]);

  return (
    <>
      <Button
        className={classes.buttonMain}
        startIcon={<ReceiptIcon />}
        onClick={handleOpen}
      >
        {t('components.modalSubscriptionToTheClient.buttonTitle')}
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle className={classes.containerTitle} disableTypography>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent style={{ padding: 0 }}>
          <div style={{ margin: '0 2.25rem 3rem 2.25rem' }}>
            <h1 className={classes.title}>
              {t('components.modalSubscriptionToTheClient.title')}
            </h1>

            <div className={classes.mainContent}>
              <CostCenterSelectOperator
                chosenCostCenter={chosenCostCenter}
                setChosenCostCenter={setChosenCostCenter}
                userId={userId}
                name={name}
              />

              <FormControl disabled={plans && !plans.data.length} fullWidth>
                <label
                  id="select-label"
                  htmlFor="costCenter-select"
                  className={classes.selectLabel}
                >
                  {t('components.modalSubscriptionToTheClient.labelSelectPlan')}
                </label>

                <Select
                  className={classes.planSelect}
                  id="costCenter-select"
                  labelId="select-label"
                  value={chosenPlan}
                  onChange={e => {
                    setChosenPlan(Number(e.target.value));
                  }}
                  disableUnderline
                >
                  {plans &&
                    plans.data
                      .sort((a, b) => a.totalValue - b.totalValue)
                      .map((plan, index) => (
                        <MenuItem
                          key={plan._id}
                          value={index}
                          className={classes.menuItem}
                        >
                          <p>{plan.name}</p>
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </div>

            {plans?.data[chosenPlan].name === 'CustomPlan' && (
              <div className={classes.products}>
                {productsToBuy.map((product, index) => (
                  <div className="item" key={product._id}>
                    {productIcon[product.name]}

                    <div className={classes.productDescription}>
                      <p className="title">
                        {t(
                          `components.modalCreditsOperator.products.${product.name}`,
                        )}
                      </p>

                      <div>
                        <span className="value">
                          R$ {product.totalValue} /{' '}
                          {product.name === 'vault' ? 'GB' : 'un.'}
                        </span>

                        <div
                          className={classes.buttonGroupOperateQuantityProducts}
                        >
                          <IconButton
                            size="small"
                            disabled={!product.quantity}
                            onClick={() => {
                              const valueToRemoveOne = -1;
                              handleButtonQuantityCredits(
                                valueToRemoveOne,
                                index,
                              );
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>

                          <MaskedInput
                            mask={numberMask}
                            guide={false}
                            showMask
                            name="number"
                            value={product.quantity}
                            onChange={e => {
                              handleInputQuantityCredits(e.target.value, index);
                            }}
                          />

                          <IconButton
                            size="small"
                            onClick={() => {
                              const valueToAddOne = 1;
                              handleButtonQuantityCredits(valueToAddOne, index);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className={classes.footerContainer}>
              <TextField
                id="observation"
                label={t('components.modalSubscriptionToTheClient.observation')}
                variant="outlined"
                className={classes.inputObservation}
                multiline
                minRows={3}
                value={observation}
                onChange={e => {
                  setObservation(e.target.value);
                }}
                fullWidth
              />

              <Button
                onClick={
                  plans?.data[chosenPlan].name === 'CustomPlan'
                    ? handleBuyCustomPlan
                    : handleBuyNormalPlan
                }
                className={classes.buttonConfirmation}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  t('components.modalSubscriptionToTheClient.buttonAdd')
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
