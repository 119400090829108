import React, { useEffect } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import ChangeLanguage from '../../components/ChangeLanguage';

import useAuthContext from '../../hooks/useAuthContext';
import useSocketContext from '../../hooks/useSocketContext';
import useAlert from '../../hooks/useAlertContext';

import COLORS from '../../utils/colors';

import logoWhiteImg from '../../images/white-logo.svg';
import littleDigitalImg from '../../images/little-digital.svg';
import backgroundImage from '../../images/dattaid-background.svg';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    height: '100vh',
    backgroundColor: COLORS.gray2,
    backgroundImage: `url(${backgroundImage})`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  paper: {
    width: '80%',
    height: '70%',
    padding: '2% 6%',
    borderRadius: '3.125rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '5%',

    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },

    '& img': {
      height: '10%',
    },

    '& h1': {
      textAlign: 'center',
      fontSize: '100%',
      fontWeight: 'bold',
      color: COLORS.black,

      [theme.breakpoints.up('md')]: {
        minWidth: 'max-content',
        fontSize: '1.125rem',
      },
    },
  },

  qrCode: {
    borderRadius: '.5rem',
    width: '100%',
    height: '50%',
  },

  loadingContainer: {
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  step: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '7%',

    '& span': {
      height: '1.5rem',
      width: '1.5rem',
      minWidth: 'max-content',
      borderRadius: '50%',
      backgroundColor: COLORS.gray4,
      color: COLORS.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '600',
      fontSize: '100%',

      [theme.breakpoints.up(1536)]: {
        height: '2rem',
        width: '2rem',
        fontSize: '1.125rem',
      },
    },

    '& h2': {
      fontSize: '100%',
      maxWidth: '83%',
      fontWeight: 'bold',
      color: COLORS.gray2,

      [theme.breakpoints.up('md')]: {
        fontSize: '1.125rem',
      },
    },
  },

  copyright: {
    marginTop: '10%',
    textAlign: 'center',
    color: COLORS.lightGray0,
    fontSize: '100%',

    [theme.breakpoints.up('sm')]: {
      marginTop: '5%',
      fontSize: '1.125rem',
    },
  },

  bottomLogo: {
    width: 'auto',
    height: '4rem',
    position: 'absolute',
    right: '2%',
    bottom: '0',

    [theme.breakpoints.up('sm')]: {
      right: '2.5rem',
      bottom: '2.5rem',
      height: '5.5rem',
    },
  },
}));

export interface LoginData {
  message: string;
  signature: string;
}

export default function Login() {
  const classes = useStyles();

  const { t } = useTranslation();

  const { login } = useAuthContext();
  const { socket, socketId } = useSocketContext();
  const { showAlert } = useAlert();

  const chatLogin = ({ message, signature }: LoginData) => {
    try {
      login(message, signature);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    socket.on('chat login', chatLogin);

    return () => {
      socket.off('chat login', chatLogin);
    };
  }, [socket]);

  useEffect(() => {
    const session = JSON.parse(
      localStorage.getItem('@dattaid:session') || 'null',
    );

    if (typeof session === 'boolean' && !session) {
      showAlert('sessionExpired', 'error', null);
      localStorage.removeItem('@dattaid:session');
    }
  }, []);

  return (
    <div className={classes.container}>
      <Paper elevation={0} className={classes.paper}>
        <img src={littleDigitalImg} alt="logo" />

        {socketId ? (
          <QRCodeSVG
            value={`{"socket":"${socketId}","method":"AUTH","needs":["name","email","token","roles","dattaid"],"who":"DattaID"}`}
            className={classes.qrCode}
          />
        ) : (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}

        <h1>{t('login.title')}</h1>

        <div className={classes.step}>
          <span>1</span>

          <h2>{t('login.step1')}</h2>
        </div>

        <div className={classes.step}>
          <span>2</span>

          <h2>{t('login.step2')}</h2>
        </div>
      </Paper>

      <p className={classes.copyright}>
        © {new Date().getFullYear()} DattaID. {t('login.copyright')}
      </p>

      <ChangeLanguage />

      <img className={classes.bottomLogo} src={logoWhiteImg} alt="logo" />
    </div>
  );
}
