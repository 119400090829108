/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CloseIcon from '@material-ui/icons/Close';
// import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ModalUploadToSign from '../../../components/Modals/ModalUploadToSign';
import Layout from '../../../components/Layout';
import CustomPagination from '../../../components/CustomPagination';
import CustomProgress from '../../../components/CustomProgress';

import { useAxios } from '../../../hooks/useAxios';
import useAuthContext from '../../../hooks/useAuthContext';

import { Document } from '../../../types/Document';

import COLORS from '../../../utils/colors';
import getTwoFirstLettersByName from '../../../utils/getTwoFirstLettersByName';
import linksForSWR from '../../../utils/linksForSWR';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: '3rem',

    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
      lineHeight: '4rem',
    },
  },

  subtitle: {
    lineHeight: '2.5rem',
    color: COLORS.gray5,
    marginLeft: '1rem',
    fontSize: '1.125rem',
    fontWeight: 500,
  },

  search: {
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    paddingLeft: '1rem',
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${COLORS.lightGray3}`,
    outline: `${COLORS.lightGray1}`,
    width: '80%',
    marginBottom: '.5rem',

    [theme.breakpoints.up('md')]: {
      width: '33.33%',
      marginBottom: 0,
    },

    '&:hover': {
      transition: '0.1s',
      outline: `0.25rem solid ${COLORS.lightGray1}`,
    },

    '& input': {
      fontSize: '1.125rem',
      marginBottom: '0.125rem',
      lineHeight: '1.5rem',
      color: COLORS.gray3,
    },

    '& svg': {
      fill: COLORS.mediumGray1,
      fontSize: '1.5rem',
    },

    '& .Mui-focused': {
      '& svg': {
        fill: COLORS.gray2,
      },
    },
  },

  closeIcon: {
    fontSize: '1.625rem',
    marginRight: '0.625rem',
    color: COLORS.mediumGray5,
    cursor: 'pointer',
  },

  buttonScanDocument: {
    width: '13.25rem',
    height: '3rem',
    textTransform: 'none',
    color: COLORS.greenButton,
    backgroundColor: 'none',
    border: `1px solid ${COLORS.greenButton}`,
    borderRadius: '0.5rem',
    fontSize: '1.125rem',
    fontWeight: 'bold',

    '& svg': {
      fontSize: '1.25rem',
    },

    '&:hover': {
      backgroundColor: COLORS.white,
    },
  },

  tablePaper: {
    width: '100%',
    marginTop: '1.5rem',
    borderRadius: '0.5rem',
  },

  tableHead: {
    color: COLORS.gray2,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    width: '20%',
    fontWeight: 500,
  },

  tableCellDocument: {
    display: 'flex',
    alignItems: 'center',
    height: '4.625rem',
    padding: '0 !important',

    '&:hover': {
      backgroundColor: COLORS.lightOrange,
    },

    '& a': {
      display: 'block',
      width: '100%',
      height: '100%',
      padding: '1rem',
      textDecoration: 'none',
      color: COLORS.black,
    },
  },

  fileNameContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& .fileName': {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '18.75rem',
    },

    '& .date': {
      fontSize: '0.75rem',
      color: COLORS.black,
    },
  },

  avatar: {
    color: COLORS.gray2,
    fontSize: '0.75rem',
  },

  buttonSignNowDocument: {
    height: '2.25rem',
    width: '6.75rem',
    textTransform: 'none',
    borderRadius: '0.5rem',
    padding: 0,
    backgroundColor: COLORS.orangeAdvice,
    color: COLORS.white,
    fontSize: '0.875rem',
    fontWeight: 'bold',

    '& svg': {
      fill: COLORS.white,
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      backgroundColor: COLORS.orangeHover,
      transition: '0.3s',
    },
  },

  buttonViewDocument: {
    height: '2.25rem',
    width: '4.375',
    textTransform: 'none',
    borderRadius: '0.5rem',
    backgroundColor: COLORS.lightGray2,
    color: COLORS.mediumGray5,
    fontSize: '0.875rem',
    fontWeight: 600,

    '& svg': {
      fill: COLORS.mediumGray5,
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      color: COLORS.gray2,
      backgroundColor: COLORS.lightGray4,
      transition: '0.3s',

      '& svg': {
        fill: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  buttonReturnToAllDocuments: {
    fontSize: '0.75rem',
    textTransform: 'none',
    fontWeight: 500,
    height: '1rem',
    width: '10.875rem',
    color: COLORS.mediumGray1,

    '& svg': {
      width: '0.875rem',
      height: '0.875rem',
    },

    '&:hover': {
      color: COLORS.gray5,
      background: 'none',
      transition: '0.3s',
    },
  },

  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '64.5vh',
    rowGap: '1.5rem',

    '& .text': {
      fontSize: '2.25rem',
      color: COLORS.gray2,
      fontWeight: '500',
    },
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64.5vh',
  },
}));

interface LocationState {
  state: {
    search: string;
    status: string;
  };
}

interface DocumentData {
  data: Document[];
  xTotalCount: number;
}

export default function Documents() {
  const classes = useStyles();
  const [page, setPage] = useState(1);

  const location: LocationState = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState(location.state?.search ?? '');

  const { user } = useAuthContext();

  const rowsPerPage = 6;

  const { data: documents, error }: { data: DocumentData; error: any } =
    useAxios(
      linksForSWR.client.documents.index(
        page,
        rowsPerPage,
        search,
        location.state?.status ?? '',
      ),
    );

  const { t } = useTranslation();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleSearch = (value: string) => {
    setPage(1);
    setSearch(value);

    if (!value && location.state && location.state.search) {
      history.replace({
        state: {
          ...location.state,
          search: '',
        },
      });
    }
  };

  const returnToAllDocuments = () => {
    setSearch('');
    setPage(1);
    history.replace({ state: {} });
  };

  return (
    <Layout type="user" routeSelected="documents">
      <Grid container justifyContent="space-between">
        <Grid container item alignItems="flex-end">
          <Typography variant="h1" className={classes.title}>
            {t('client.documents.index.title')}
          </Typography>

          {location.state &&
            location.state.status === 'waitingForMySignature' && (
              <span className={classes.subtitle}>
                {t('client.documents.index.labelWaitingForMySignature')}
              </span>
            )}

          {location.state && location.state.status === 'waitingForAnother' && (
            <span className={classes.subtitle}>
              {t('client.documents.index.labelWaitingForAnother')}
            </span>
          )}

          {location.state && location.state.status === 'signed' && (
            <span className={classes.subtitle}>
              {t('client.documents.index.labelTotalSigned')}
            </span>
          )}
        </Grid>

        <Grid
          container
          item
          justifyContent="space-between"
          style={{ marginTop: '2.875rem' }}
        >
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            element={TextField}
            size="medium"
            placeholder={t('client.documents.index.search')}
            fullWidth
            variant="standard"
            value={search}
            onChange={e => {
              handleSearch(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: search ? (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  <CloseIcon className={classes.closeIcon} />
                </InputAdornment>
              ) : null,
              disableUnderline: true,
            }}
            className={classes.search}
            autoFocus={!!location.state?.search}
          />

          <div style={{ display: 'flex', columnGap: '0.5rem' }}>
            {/* <Button
                startIcon={<PrintIcon />}
                className={classes.buttonScanDocument}
              >
                Scan Document
              </Button> */}

            <ModalUploadToSign type="button" />
          </div>
        </Grid>
      </Grid>

      {documents ? (
        documents.data.length ? (
          <Paper className={classes.tablePaper} elevation={0}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {(
                      t('client.documents.index.columns', {
                        returnObjects: true,
                      }) as string[]
                    ).map(element => (
                      <TableCell key={element} className={classes.tableHead}>
                        {element}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {documents.data.map(document => (
                    <TableRow key={document._id}>
                      <TableCell
                        align="left"
                        className={classes.tableCellDocument}
                      >
                        <Link to={`/documents/${document._id}`}>
                          <div className={classes.fileNameContainer}>
                            <Typography className="fileName">
                              {document.fileName}
                            </Typography>

                            <Typography className="date">
                              {moment(document.createdAt)
                                .locale(t('client.documents.locale'))
                                .format('LL')}
                            </Typography>
                          </div>
                        </Link>
                      </TableCell>

                      <TableCell align="left" style={{ height: '4.625rem' }}>
                        <Typography style={{ fontSize: '0.875rem' }}>
                          {document.user
                            ? document.user.email === user?.email
                              ? t('client.documents.index.label')
                              : document.user?.name
                            : t('client.documents.deletedUser')}
                        </Typography>
                      </TableCell>

                      <TableCell align="left" style={{ height: '4.625rem' }}>
                        <AvatarGroup max={3}>
                          {document.userSignatures.map(userSignature => {
                            const [lastStatus] = userSignature.status.slice(-1);
                            const alreadySigned =
                              lastStatus.is_active &&
                              lastStatus.name === 'signed';

                            return (
                              <Tooltip
                                key={userSignature._id}
                                title={
                                  userSignature.user
                                    ? userSignature.user.name
                                    : userSignature.email
                                    ? userSignature.email
                                    : t('client.documents.deletedUser')
                                }
                              >
                                <Avatar
                                  className={classes.avatar}
                                  style={{
                                    backgroundColor: alreadySigned
                                      ? COLORS.lightGreen
                                      : COLORS.lightGray4,
                                  }}
                                >
                                  {getTwoFirstLettersByName(
                                    userSignature.user
                                      ? userSignature.user.name
                                      : userSignature.email
                                      ? userSignature.email
                                      : '-',
                                  )}
                                </Avatar>
                              </Tooltip>
                            );
                          })}
                        </AvatarGroup>
                      </TableCell>

                      {/* <TableCell align="left" style={{ height: '4.625rem' }}>
                        <Typography style={{ fontSize: '0.875rem' }}>
                          {document.costcenter
                            ? document.costcenter.name
                            : t('client.vault.index.personalAccount')}
                        </Typography>
                      </TableCell> */}

                      <TableCell align="left" style={{ height: '4.625rem' }}>
                        <CustomProgress
                          totalProgress={
                            document.signedProgress.totalSignerOnDocument
                          }
                          total={document.userSignatures.length}
                        />
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{ height: '4.625rem', paddingRight: '2.25rem' }}
                      >
                        {document.userSignatures.find(userSignature => {
                          if (!userSignature.user) return false;

                          if (userSignature.user.email !== user?.email)
                            return false;

                          const [lastStatus] = userSignature.status.slice(-1);

                          return (
                            lastStatus.name === 'signed' && lastStatus.is_active
                          );
                        }) ||
                        document.status.find(
                          status =>
                            status.name === 'signed' && status.is_active,
                        ) ? (
                          <Button
                            className={classes.buttonViewDocument}
                            endIcon={<VisibilityIcon />}
                            component={Link}
                            to={`/documents/${document._id}`}
                          >
                            {t('client.documents.index.buttonView')}
                          </Button>
                        ) : (
                          <Button
                            className={classes.buttonSignNowDocument}
                            endIcon={<BorderColorIcon />}
                            component={Link}
                            to={`/documents/${document._id}`}
                          >
                            {t('client.documents.index.buttonSign')}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <CustomPagination
              page={page}
              handleChangePage={handleChangePage}
              total={documents.xTotalCount}
              rowsPerPage={rowsPerPage}
              contentToLeft={
                location.state?.status || location.state?.search || search ? (
                  <Button
                    startIcon={<ArrowBackIcon />}
                    className={classes.buttonReturnToAllDocuments}
                    onClick={returnToAllDocuments}
                  >
                    {t('client.documents.index.buttonReturn')}
                  </Button>
                ) : null
              }
            />
          </Paper>
        ) : (
          <div className={classes.emptyContainer}>
            <Typography className="text">
              {t('client.documents.index.empty')}
            </Typography>

            <ModalUploadToSign type="text" />
          </div>
        )
      ) : (
        <div className={classes.errorContainer}>
          {error ? t('client.documents.error') : <CircularProgress />}
        </div>
      )}
    </Layout>
  );
}
