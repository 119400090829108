import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

import CostCenterSelect from '../../CostCenterSelect';

import useCostCenters from '../../../hooks/useCostCenters';
import useAlert from '../../../hooks/useAlertContext';

import api from '../../../services/api';

interface Props {
  isOpen: boolean;
  documentId: string;
}

const ModalChooseCostCenter: React.FC<Props> = ({ isOpen, documentId }) => {
  const [open, setOpen] = useState(false);
  const [chosenCostCenter, setChosenCostCenter] = useState(-1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const { costCenters } = useCostCenters();
  const { showAlert } = useAlert();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await api.post('/costcenter/document/save', {
        document_id: documentId,
        costcenter_id:
          chosenCostCenter === -1
            ? null
            : costCenters.data[chosenCostCenter]._id,
      });

      handleClose();
    } catch (error) {
      console.log(error);
      showAlert('choosingCostCenterError', 'error', null);
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={open}>
      <DialogTitle>{t('components.modalChooseCostCenter.title')}</DialogTitle>

      <DialogContent>
        <CostCenterSelect
          chosenCostCenter={chosenCostCenter}
          setChosenCostCenter={setChosenCostCenter}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit} disabled={isSubmitting}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalChooseCostCenter;
