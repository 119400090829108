import useSWR from 'swr';

import api from '../services/api';

// eslint-disable-next-line import/prefer-default-export
export function useAxios(url: string, headers = {}) {
  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url, headers);
      const xTotalCount = response.headers['x-total-count'];

      if (xTotalCount) {
        return {
          data: response.data,
          xTotalCount,
        };
      }
      return response.data;
    },
    {},
  );

  return { data, error, mutate };
}
