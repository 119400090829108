/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
// import { useParams } from 'react-router-dom';
import WebViewer from '@pdftron/pdfjs-express';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import moment from 'moment';
// import FileSaver from 'file-saver';

import { Document } from '../../types/Document';

// import api from '../../services/api';

import logoImg from '../../images/logo.png';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  appBar: {
    background: '#FFF',
    color: '#222',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    minHeight: '4rem',
    padding: '0 2rem',

    '& div': {
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto 0 35%',

      '& .MuiSvgIcon-root': {
        color: '#ff5252',
        fontSize: '2.25rem',
        marginRight: '0.5rem',
      },
    },
  },

  content: {
    display: 'flex',
    marginTop: '2rem',
    boxSizing: 'border-box',
    padding: '0 2rem',
  },

  webview: {
    flex: 1,
    margin: '0.5rem',
    height: '88.31vh',
    width: '75%',
  },

  historic: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '1.5rem',
  },

  card: {
    marginTop: '0.75rem',
    width: '100%',
  },

  cardTitle: {
    paddingBottom: 0,

    '& span': {
      fontSize: '1rem',
      fontWeight: '600',
    },
  },
});

export default function DocumentReview() {
  const classes = useStyles();
  // const { id } = useParams();
  const [document] = useState<Document | null>();
  const viewer = useRef(null);

  useEffect(() => {
    async function loadDocument() {
      try {
        // const response = await api.get(`/document/${id}`);
        // setDocument(response.data);

        // const [lastStatus] = response.data.status.slice(-1);

        // let query = "";
        // if (lastStatus.is_active && lastStatus.name === 'signed') {
        //   query = "?pdfMerge=true";
        // }

        // const file = await api.get(`/document/file/${id}${query}`, {
        //   responseType: 'arraybuffer',
        // });

        // const blob = new Blob([file.data], { type: 'application/pdf' });
        // const url = window.URL.createObjectURL(blob);

        WebViewer(
          {
            path: '/webviewer/lib',
            licenseKey: process.env.REACT_APP_PDF_EXPRESS_JS_KEY_VIEWER,
          },
          viewer.current,
        )
          .then((instance: any) => {
            const { documentViewer, annotationManager } = instance.Core;
            const { Feature } = instance.UI;

            instance.UI.disableElements(['searchButton', 'menuButton']);
            instance.UI.disableFeatures([
              Feature.NotesPanel,
              Feature.Ribbons,
              Feature.Annotations,
            ]);

            const download = {
              type: 'actionButton',
              img: 'icon-header-download',
              title: 'Download',
              onClick: () => {
                // FileSaver.saveAs(mergedBlob, 'download.pdf');
              },
              dataElement: 'alertButton',
            };

            // if (lastStatus.is_active && lastStatus.name === 'signed') {
            instance.UI.setHeaderItems((header: any) => {
              header.push(download);
            });
            // }

            documentViewer.addEventListener('documentLoaded', () => {
              // annotationManager.importAnnotations(response.data.xfdf);
              instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
            });

            annotationManager.setReadOnly(true);
          })
          .catch((err: any) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }

    loadDocument();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.appBar}>
        <img src={logoImg} alt="logo" height="32" />

        <div>
          <PictureAsPdfIcon />

          <Typography variant="h1" style={{ fontSize: '1.15rem' }}>
            sample.pdf
          </Typography>
        </div>
      </div>

      <div className={classes.content}>
        <div className={classes.webview} ref={viewer} />

        <div className={classes.historic}>
          <h1 style={{ fontSize: '1.5rem' }}>Logs</h1>

          <Card className={classes.card}>
            <CardHeader
              title={moment(document?.createdAt, 'YYYY-MM-DDTHH:mm:ssZ').format(
                'MM/DD/YYYY, h:mm:ss a',
              )}
              className={classes.cardTitle}
            />

            <CardContent>
              <Typography>
                Document created by {document?.user.name}.
              </Typography>
            </CardContent>
          </Card>

          {document?.userSignatures
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime(),
            )
            .map(signature => {
              return (
                <Card key={signature._id} className={classes.card}>
                  <CardHeader
                    title={moment(
                      signature.createdAt,
                      'YYYY-MM-DDTHH:mm:ssZ',
                    ).format('MM/DD/YYYY, h:mm:ss a')}
                    className={classes.cardTitle}
                  />

                  <CardContent>
                    <Typography>
                      {signature.user ? signature.user.name : signature.email}{' '}
                      was added as a signee.
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}

          {document?.userSignatures
            .sort(
              (a, b) =>
                new Date(a.updatedAt).getTime() -
                new Date(b.updatedAt).getTime(),
            )
            .map(signature => {
              const [lastStatus] = signature.status.slice(-1);

              if (lastStatus.name !== 'signed' || lastStatus.is_active)
                return null;

              return (
                <Card key={signature._id} className={classes.card}>
                  <CardHeader
                    title={moment(
                      signature.updatedAt,
                      'YYYY-MM-DDTHH:mm:ssZ',
                    ).format('MM/DD/YYYY, h:mm:ss a')}
                    className={classes.cardTitle}
                  />

                  <CardContent>
                    <Typography>
                      {signature.user ? signature.user.name : signature.email}{' '}
                      signed the document.
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
        </div>
      </div>
    </div>
  );
}
