export default {
  client: {
    home: {
      documents: (limit = 3) => `/document?limit=${limit}`,
      logs: (limit = 3) => `/logs?limit=${limit}`,
      dashboard: () => '/dashboard',
    },

    documents: {
      index: (page = 1, limit = 6, filename = '', status = '') =>
        `/document?page=${page}&limit=${limit}&filename=${filename}&status=${status}`,
      view: (id: string | number) => `/document/${id}`,
    },

    vault: {
      index: (page = 1, limit = 6, filter = '', onlyUnopenedFiles = false) =>
        `/vault?page=${page}&limit=${limit}&filter=${filter}&onlyUnopenedFiles=${onlyUnopenedFiles}`,

      view: (id: string | number) => `/vault/${id}`,
    },

    transactions: (page = 1, limit = 7) =>
      `/transactions/logs?page=${page}&limit=${limit}`,

    history: (page = 1, limit = 8, filter = '', date = '') =>
      `/logs?page=${page}&limit=${limit}&${filter}&${date}`,

    profile: () => '/profile',
    credits: (costCenterId: string) =>
      `plans/credits?costcenter_id=${costCenterId}`,
    costCenters: (page: number | null = 1, limit: number | null = 7) =>
      `/costcenter?page=${page}&limit=${limit}`,
    subscriptions: (costCenterId = '') =>
      `/user/subscriptions/?costcenter_id=${costCenterId}`,
    folders: (
      costCenterId: string | number | null,
      page = 1,
      limit = 6,
      search = '',
    ) =>
      `/folders?costcenter_id=${costCenterId}&page=${page}&limit=${limit}&search=${search}`,
  },

  operator: {
    registers: {
      index: (page = 1, limit = 8, search = '') =>
        `/operator/user?page=${page}&limit=${limit}&filter=${search}`,

      edit: (id: string | number) => `/operator/user/${id}`,
    },

    clients: {
      index: (page = 1, limit = 8, status = 'active', search = '') =>
        `/operator/user?page=${page}&limit=${limit}&status=${status}&filter=${search}`,

      edit: (id: string | number) => `/operator/user/${id}`,
    },
    costCenters: (id: string) => `/operator/costCenters/${id}`,
  },

  products: () => '/products',
  plans: () => '/plans',
};
