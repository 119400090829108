/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Link, Redirect, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import COLORS from '../../utils/colors';

import ChangeLanguage from '../../components/ChangeLanguage';

import littleDigitalImg from '../../images/little-digital.svg';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    padding: '5% 10%',
    rowGap: '8%',

    '& img': {
      width: '3rem',
      textAlign: 'center',
    },
  },

  resultsContainer: {
    width: '100%',
  },

  resultField: {
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',

    '& h2': {
      fontSize: '1rem',
    },

    '& p': {
      fontSize: '.875rem',
    },

    '& div': {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        fontSize: '1.25rem',
        marginRight: '.25rem',
      },

      '&.success': {
        color: COLORS.greenHover,
      },

      '&.error': {
        color: COLORS.redWarning,
      },
    },
  },

  linkValidateOtherDocument: {
    background: COLORS.gray5,
    color: COLORS.white,
    padding: 0,
    textTransform: 'none',
    borderRadius: '0.5rem',
    height: '2.25rem',
    width: '12rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',

    '&:hover': {
      background: COLORS.black,
      color: 'white',
    },
  },

  copyright: {
    textAlign: 'center',
  },
});

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },

  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

interface Location {
  state: {
    fileName: string;
    status: boolean;
    message: string;
    date: string;
  };
}

export default function ViewValidator() {
  const classes = useStyles();
  const location = useLocation() as Location;

  const { t } = useTranslation();

  if (!location.state) return <Redirect to="/validator" />;

  return (
    <div className={classes.container}>
      <img src={littleDigitalImg} alt="logo" />

      <h1>{t('validator.view.title')}</h1>

      <section className={classes.resultsContainer}>
        <div className={classes.resultField}>
          <h2>{t('validator.view.filename')}:</h2>

          <p>{location.state.fileName}</p>
        </div>

        <div className={classes.resultField}>
          <h2>Status:</h2>

          {location.state.status ? (
            <div className="success">
              <CheckCircleIcon />

              <p>{t('validator.view.messages.success')}</p>

              <BootstrapTooltip
                placement="right"
                title={`${t('validator.view.signatureValidated')} ${moment()
                  .locale(t('validator.locale'))
                  .format('L, LTS')}`}
              >
                <InfoOutlinedIcon />
              </BootstrapTooltip>
            </div>
          ) : (
            <div className="error">
              <WarningIcon />

              <p>{t(`validator.view.messages.${location.state.message}`)}</p>
            </div>
          )}
        </div>

        {location.state.status && (
          <>
            <div className={classes.resultField}>
              <h2>{t('validator.view.signedBy')}:</h2>

              <p>DattaID</p>
            </div>

            <div className={classes.resultField}>
              <h2>{t('validator.view.date')}:</h2>

              <p>
                {moment(location.state.date)
                  .locale(t('validator.locale'))
                  .format('L, LTS')}
              </p>
            </div>
          </>
        )}
      </section>

      <Link className={classes.linkValidateOtherDocument} to="/validator">
        {t('validator.view.buttonValidateOtherDocument')}
      </Link>

      <p>support@dattaid.com</p>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.copyright}
      >
        <Typography>
          © {new Date().getFullYear()} DattaID. {t('validator.copyright')}
        </Typography>

        <ChangeLanguage />
      </Grid>
    </div>
  );
}
