/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Layout from '../../../components/Layout';
import CustomPagination from '../../../components/CustomPagination';
import ModalCreateCostCenter from '../../../components/Modals/ModalCreateCostCenter';
import ModalEditCostCenter from '../../../components/Modals/ModalEditCostCenter';
import ModalInviteCostCenterParticipant from '../../../components/Modals/ModalInviteCostCenterParticipant';
import ModalCostCenterParticipantInfo from '../../../components/Modals/ModalCostCenterParticipantInfo';
import ModalDeleteCostCenter from '../../../components/Modals/ModalDeleteCostCenter';

import useCostCenters from '../../../hooks/useCostCenters';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    marginBottom: '2rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& h1': {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: COLORS.pureBlack,

      [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
      },
    },
  },

  tablePaper: {
    width: '100%',
    borderRadius: '0.5rem',
  },

  tableContainer: {
    padding: '0.5rem 1.75rem 0 1.75rem',
  },

  tableHead: {
    border: 'none',
    padding: 0,
    height: '2.25rem',
    color: COLORS.gray2,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    width: '20%',
    fontWeight: 'bold',
  },

  tableCell: {
    padding: 0,
    height: '4.625rem',
    color: COLORS.black,
    fontSize: '0.7rem',
    width: '25%',
    maxWidth: '5rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    '&.button': {
      minWidth: 'max-content',
    },

    '&.lastItem': {
      paddingRight: '1rem',

      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
      },
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '0.875rem',
    },
  },

  paginationContainer: {
    padding: '2rem 0.75rem 0',
    height: '6.5rem',
    alignSelf: 'center',
  },

  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '71vh',
    rowGap: '1.5rem',

    '& .text': {
      fontSize: '2.25rem',
      color: COLORS.gray2,
      fontWeight: 500,
    },
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64.5vh',
  },
}));

export default function CostCenters() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const rowsPerPage = 7;

  const { t } = useTranslation();

  const { costCenters, error } = useCostCenters();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  return (
    <Layout type="user" routeSelected="">
      <div className={classes.headerContainer}>
        <Typography variant="h1">{t('client.costCenters.title')}</Typography>

        <ModalCreateCostCenter isOpen={false} />
      </div>

      {costCenters ? (
        costCenters.data.length ? (
          <Paper className={classes.tablePaper} elevation={0}>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    {(
                      t('client.costCenters.columns', {
                        returnObjects: true,
                      }) as string[]
                    ).map(element => (
                      <TableCell key={element} className={classes.tableHead}>
                        {element}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {costCenters.data.map(center => (
                    <TableRow key={center._id}>
                      <TableCell align="left" className={classes.tableCell}>
                        <span>{center.name}</span>
                      </TableCell>

                      <TableCell align="left" className={classes.tableCell}>
                        {moment(center.createdAt, 'YYYY-MM-DDTHH:mm:ssZ')
                          .locale(t('client.costCenters.locale'))
                          .format('LLLL')}
                      </TableCell>

                      <TableCell align="left" className={classes.tableCell}>
                        <AvatarGroup max={10}>
                          {center.groupUsers.map(user => (
                            <ModalCostCenterParticipantInfo
                              key={user._id}
                              groupUser={user}
                              center={center}
                              isOpen={false}
                            />
                          ))}
                        </AvatarGroup>
                      </TableCell>

                      <TableCell
                        align="right"
                        className={`${classes.tableCell} button`}
                      >
                        <ModalInviteCostCenterParticipant
                          center={center}
                          isOpen={false}
                        />
                      </TableCell>

                      <TableCell
                        align="right"
                        className={`${classes.tableCell} button`}
                      >
                        <ModalDeleteCostCenter center={center} />
                      </TableCell>

                      <TableCell
                        align="right"
                        className={`${classes.tableCell} button lastItem`}
                      >
                        <ModalEditCostCenter center={center} isOpen={false} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div className={classes.paginationContainer}>
              <CustomPagination
                page={page}
                handleChangePage={handleChangePage}
                total={costCenters.xTotalCount}
                rowsPerPage={rowsPerPage}
                contentToLeft={null}
              />
            </div>
          </Paper>
        ) : (
          <div className={classes.emptyContainer}>
            <Typography className="text">
              {t('client.costCenters.empty')}
            </Typography>
          </div>
        )
      ) : (
        <div className={classes.errorContainer}>
          {error ? t('client.costCenters.error') : <CircularProgress />}
        </div>
      )}
    </Layout>
  );
}
