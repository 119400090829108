/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, Prompt } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import Layout from '../../../components/Layout';
import PersonVaultTile from '../../../components/PersonVaultTile';
import ModalExitCreateVaultPage from '../../../components/Modals/ModalExitCreateVaultPage';
import CostCenterSelect from '../../../components/CostCenterSelect';

import useAuthContext from '../../../hooks/useAuthContext';
import useAlert from '../../../hooks/useAlertContext';
import useCostCenters from '../../../hooks/useCostCenters';
import { useAxios } from '../../../hooks/useAxios';

import { Credit } from '../../../types/Credit';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import blockchainBundle from '../../../utils/blockchainBundle';
import generatePassword from '../../../utils/generatePassword';
import encryptFile from '../../../utils/encryptFile';
import linksForSWR from '../../../utils/linksForSWR';
import VaultViewer from '../../../components/VaultViewer';

const heightContent = '71vh';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  content: {
    paddingTop: '1.5rem',

    [theme.breakpoints.up('md')]: {
      padding: '1.5rem 1rem 0 1rem',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '1.5rem 4.875rem 0 6.5rem',
    },
  },

  mainContent: {
    [theme.breakpoints.up('md')]: {
      height: heightContent,
    },
  },

  costCenterSelectContainer: {
    width: '95%',
    marginBottom: '1.25rem',

    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },

  loading: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    height: '100%',
    width: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  fileViewerContainer: {
    position: 'relative',
    marginTop: '2rem',

    [theme.breakpoints.up('md')]: {
      paddingLeft: '4rem',
      marginTop: 0,
    },
  },

  webViewer: {
    height: heightContent,
  },

  title: {
    marginBottom: '.75rem',
    fontWeight: 600,
    fontSize: '2.25rem',
    color: COLORS.pureBlack,
    maxWidth: '22.625rem',
    lineHeight: '3rem',
  },

  subtitle: {
    color: COLORS.mediumGray3,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    maxWidth: '20rem',
    marginBottom: '1.5rem',
    textAlign: 'justify',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '26.875rem',
    },
  },

  learnMoreLink: {
    color: COLORS.blue,
    textDecoration: 'none',

    '&:hover': {
      color: COLORS.blueHover,
    },
  },

  addEmail: {
    width: '20rem',
    height: '3.25rem',
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    paddingLeft: '1rem',
    display: 'flex',
    justifyContent: 'center',
    border: `1px ${COLORS.lightGray3} solid`,

    [theme.breakpoints.up('lg')]: {
      width: '28rem',
    },
  },

  circularProgressInTextField: {
    marginRight: '0.75rem',
    width: '1.875rem',
    height: '1.875rem',
    color: COLORS.greenButton,
  },

  addEmailIcon: {
    color: COLORS.greenButton,
    fontSize: '1.875rem',
    marginRight: '0.75rem',
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.greenHover,
      transition: '0.3s',
    },
  },

  list: {
    maxHeight: '48.5%',
    overflow: 'auto',
    marginTop: '2.25rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  },

  buttonSend: {
    backgroundColor: COLORS.gray4,
    color: COLORS.white,
    fontWeight: 'bold',
    fontSize: '1.25rem',
    textTransform: 'none',
    marginTop: '3rem',
    padding: 0,
    borderRadius: '0.5rem',
    width: '10.625rem',
    height: '3.125rem',

    '&:hover': {
      transition: '0.3s',
      backgroundColor: COLORS.black,
    },
  },
}));

const chooseIconButtonSend = {
  enter: (
    <LockOutlinedIcon style={{ fontSize: '1.25rem', color: COLORS.white }} />
  ),
  leave: <LockOpenIcon style={{ fontSize: '1.25rem', color: COLORS.white }} />,
};

const passwordFile = generatePassword();

interface LocationState {
  file: File;
}

interface Participant {
  user: string;
  password: string;
  email: string;
  observation: string;
}

export default function CreateVault() {
  const classes = useStyles();
  const [isAdding, setIsAdding] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isShowingExitPrompt, setIsShowingExitPrompt] = useState(true);

  const privateKeyWeb = JSON.parse(
    localStorage.getItem('@dattaid:privateKeyWeb') || 'null',
  );

  const [person, setPerson] = useState({
    user: '',
    password: '',
    email: '',
    observation: '',
  });

  const [sharedUsers, setSharedUsers] = useState<Participant[]>([]);
  const [iconButtonSend, setIconButtonSend] = useState<'enter' | 'leave'>(
    'leave',
  );
  const [chosenCostCenter, setChosenCostCenter] = useState(-1);

  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const { user } = useAuthContext();
  const { costCenters } = useCostCenters();
  const { showAlert } = useAlert();

  const { data: currentCredits }: { data: Credit[] } = useAxios(
    linksForSWR.client.credits(
      chosenCostCenter !== -1 ? costCenters.data[chosenCostCenter]._id : '',
    ),
  );

  if (!location.state) {
    history.goBack();
  }

  const { file } = location.state as LocationState;

  // Credits: https://javascript.plainenglish.io/how-to-alert-a-user-before-leaving-a-page-in-react-a2858104ca94
  useEffect(() => {
    const alertRefresh = (event: BeforeUnloadEvent) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };

    window.addEventListener('beforeunload', alertRefresh);

    return () => {
      window.removeEventListener('beforeunload', alertRefresh);
    };
  }, []);

  const handleClearField = () => {
    setPerson({
      user: '',
      password: '',
      email: '',
      observation: '',
    });
  };

  const handleErrorAddSharedUser = (error: any) => {
    if (
      error.message === 'invalidEmail' ||
      error.message === 'vaultErrorOwnerAdded' ||
      error.message === 'signeeDuplicated'
    ) {
      showAlert(error.message, 'error', null);
      return;
    }

    const { data } = error.response;

    if (data && data.message === 'User not found') {
      showAlert('dattaidNotFound', 'error', null);
    }
  };

  const addSharedUser = async () => {
    if (!person.email) {
      return;
    }

    setIsAdding(true);
    try {
      // Credits: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
      const regexEmail =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

      if (!regexEmail.test(person.email)) {
        throw new Error('invalidEmail');
      }

      if (person.email === user?.email) {
        throw new Error('vaultErrorOwnerAdded');
      }
      if (
        sharedUsers.findIndex(element => element.email === person.email) !== -1
      ) {
        throw new Error('signeeDuplicated');
      }

      const response = await api.get(`/vault/user/${person.email}`);
      const { _id, publicKey } = response.data;

      const password = await blockchainBundle.encrypt(
        passwordFile,
        privateKeyWeb,
        publicKey,
      );

      person.user = _id;
      person.password = password;

      setSharedUsers([...sharedUsers, person]);
      handleClearField();
    } catch (error) {
      handleErrorAddSharedUser(error);
    }
    setIsAdding(false);
  };

  const handleChangeField = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setPerson({ ...person, [name]: value });
  };

  const handleChangeObservation = (newValue: string, email: string) => {
    const index = sharedUsers.findIndex(element => element.email === email);
    sharedUsers[index].observation = newValue;
    setSharedUsers([...sharedUsers]);
  };

  const handleRemove = (email: string) => {
    const newSharedUsers = sharedUsers.filter(
      element => element.email !== email,
    );
    setSharedUsers(newSharedUsers);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.code === 'Enter') {
      addSharedUser();
    }
  };

  const handleSendVault = async () => {
    if (!currentCredits) return;

    const vaultCredits = currentCredits.find(credit => credit.name === 'vault');

    if (!vaultCredits || vaultCredits.quantity < 1) {
      showAlert('noVaultCreditsError', 'error', null);
      return;
    }

    setIsSending(true);
    setIsShowingExitPrompt(false);

    try {
      const fileEncrypted = await encryptFile(file, passwordFile);
      const sharedUsersFormatted = sharedUsers.map(sharedUser => ({
        user: sharedUser.user,
        password: sharedUser.password,
        observation: sharedUser.observation,
      }));

      const response = await api.get(`/vault/user/${user?.email}`);
      const { _id, publicKey } = response.data;

      const password = await blockchainBundle.encrypt(
        passwordFile,
        privateKeyWeb,
        publicKey,
      );

      const owner = {
        user: _id,
        password,
        observation: '',
      };

      await api.post('/vault', {
        file: fileEncrypted,
        fileName: `${file.name.slice(0, file.name.lastIndexOf('.'))}.dattaid`,
        fileSize: file.size,
        sharedUsers: [owner, ...sharedUsersFormatted],
        costcenter_id:
          chosenCostCenter !== -1
            ? costCenters.data[chosenCostCenter]._id
            : null,
      });

      mutate(linksForSWR.client.vault.index());
      mutate(linksForSWR.client.home.dashboard());

      history.push('/vault');

      showAlert('vaultSentSuccessfully', 'success', null);
    } catch (error) {
      showAlert('vaultSentError', 'error', null);
      console.log(error);
    }

    setIsSending(false);
    setIsShowingExitPrompt(true);
  };

  return (
    <Layout type="user" routeSelected="vault">
      <div className={classes.container}>
        <Grid container className={classes.content}>
          <Grid
            container
            item
            direction="column"
            xs={12}
            md={6}
            onKeyDown={handleKeyDown}
            className={classes.mainContent}
          >
            <div className={classes.costCenterSelectContainer}>
              <CostCenterSelect
                chosenCostCenter={chosenCostCenter}
                setChosenCostCenter={setChosenCostCenter}
              />
            </div>

            <Typography variant="h1" className={classes.title}>
              {t('client.vault.create.title')}
            </Typography>

            <Typography variant="h2" className={classes.subtitle}>
              {t('client.vault.create.subtitle.part1')}{' '}
              <Link to="/vault" className={classes.learnMoreLink}>
                {t('client.vault.create.subtitle.link')}
              </Link>{' '}
              {t('client.vault.create.subtitle.part2')}
            </Typography>

            <TextField
              name="email"
              size="medium"
              placeholder={t('client.vault.create.inputAdd')}
              fullWidth
              variant="standard"
              value={person.email}
              onChange={handleChangeField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {isAdding ? (
                      <CircularProgress
                        className={classes.circularProgressInTextField}
                      />
                    ) : (
                      <AddCircleOutlineOutlinedIcon
                        className={classes.addEmailIcon}
                        onClick={addSharedUser}
                      />
                    )}
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              className={classes.addEmail}
            />

            <List className={classes.list}>
              {sharedUsers.map(sharedUser => (
                <PersonVaultTile
                  key={sharedUser.user}
                  person={sharedUser}
                  handleChangeObservation={handleChangeObservation}
                  handleRemove={handleRemove}
                  placeholder={t('client.vault.create.placeholderPerson')}
                />
              ))}
            </List>

            <div style={{ textAlign: 'center' }}>
              <Button
                startIcon={
                  !isSending ? chooseIconButtonSend[iconButtonSend] : null
                }
                className={classes.buttonSend}
                onMouseEnter={() => {
                  setIconButtonSend('enter');
                }}
                onMouseLeave={() => {
                  setIconButtonSend('leave');
                }}
                onClick={handleSendVault}
                disabled={isSending}
              >
                {isSending ? (
                  <CircularProgress size={32} style={{ color: COLORS.white }} />
                ) : (
                  t('client.vault.create.buttonSend')
                )}
              </Button>
            </div>
          </Grid>

          <Grid item xs={12} md={6} className={classes.fileViewerContainer}>
            <VaultViewer file={file} heightContent={heightContent} />
          </Grid>
        </Grid>
      </div>

      <ModalExitCreateVaultPage
        setIsShowingExitPrompt={setIsShowingExitPrompt}
      />

      <Prompt
        when={isShowingExitPrompt}
        message={`${t('components.modalExitCreateVaultPage.content.part1')} ${t(
          'components.modalExitCreateVaultPage.content.erase',
        )} ${t('components.modalExitCreateVaultPage.content.part2')}`}
      />
    </Layout>
  );
}
