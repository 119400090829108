import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { cache } from 'swr';

import { Role } from '../types/Role';

import blockchainBundle from '../utils/blockchainBundle';

interface User {
  name: string;
  email: string;
  dattaid: string;
  signature: string;
  role: Role;
}

interface ContextProps {
  logged: boolean;
  user: User | null;
  updateUser: (newUser: User) => void;
  login: (message: string, signature: string) => Promise<void> | void;
  logout: () => void;
}

interface Props {
  children: React.ReactNode;
}

export interface LoginData {
  message: string;
  signature: string;
}

const AuthContext = createContext<ContextProps>({} as ContextProps);

const AuthProvider = ({ children }: Props) => {
  const [logged, setLogged] = useState(
    !!JSON.parse(localStorage.getItem('@dattaid:logged') as string),
  );
  const [user, setUser] = useState<User | null>(
    JSON.parse(localStorage.getItem('@dattaid:user') as string),
  );

  const history = useHistory();

  const logout = () => {
    localStorage.clear();
    cache.clear();
    setLogged(false);
    setUser(null);
    history.push('/');
  };

  const login = (message: string, signature: string) => {
    const { name, email, dattaid, roles, token } = JSON.parse(message);

    const role = roles[0].name as Role;
    const userAux = { name, email, dattaid, signature, role };

    setLogged(!!dattaid);
    setUser(userAux);

    const { privateKey, publicKey: publicKeyWeb } =
      blockchainBundle.generateDattaId();

    localStorage.setItem('@dattaid:logged', JSON.stringify(!!dattaid));
    localStorage.setItem('@dattaid:user', JSON.stringify(userAux));
    localStorage.setItem('@dattaid:publicKeyWeb', JSON.stringify(publicKeyWeb));
    localStorage.setItem('@dattaid:privateKeyWeb', JSON.stringify(privateKey));
    localStorage.setItem('@dattaid:session', JSON.stringify(true));
    localStorage.setItem('@dattaid:token', JSON.stringify(token));

    const typeUrlBrowser = {
      user: '/home',
      moderator: '/registers',
      admin: '/registers',
    };

    const urlBrowser = typeUrlBrowser[role];
    if (urlBrowser) {
      history.push(urlBrowser);
    }
  };

  const updateUser = (newUser: User) => {
    setUser(newUser);
    localStorage.setItem('@dattaid:user', JSON.stringify(newUser));
  };

  return (
    <AuthContext.Provider
      value={{
        logged,
        user,
        updateUser,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
