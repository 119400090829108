/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Layout from '../../../components/Layout';
import CustomPagination from '../../../components/CustomPagination';

import { useAxios } from '../../../hooks/useAxios';

import { Log } from '../../../types/Log';

import linksForSWR from '../../../utils/linksForSWR';
import COLORS from '../../../utils/colors';

import littleDigitalImg from '../../../images/little-digital.svg';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    fontSize: '3rem',
    lineHeight: '4rem',
  },

  filtersContainer: {
    display: 'flex',
    rowGap: '1.25rem',
    flexDirection: 'column',
    marginBottom: '2rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      columnGap: '1.25rem',
      rowGap: 0,
      marginBottom: '0',
    },
  },

  selectDatesContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.75rem',
    alignItems: 'center',
    color: COLORS.mediumGray1,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      columnGap: '.75rem',
      rowGap: 0,
    },

    '& svg': {
      color: COLORS.mediumGray1,
    },
  },

  buttonsFilterContainer: {
    display: 'flex',
    columnGap: '0.75rem',
    alignItems: 'center',
    color: COLORS.mediumGray1,
  },

  datePicker: {
    width: '9.75rem',
    height: '3rem',
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    display: 'flex',
    justifyContent: 'center',

    '& .MuiInputBase-input': {
      fontSize: '0.875rem',
    },

    [theme.breakpoints.up('lg')]: {
      width: '13.25rem',
      paddingLeft: '1rem',
      paddingRight: '0.625rem',

      '& .MuiInputBase-input': {
        fontSize: 'inherit',
      },
    },
  },

  closeIcon: {
    fontSize: '1.625rem',
    color: COLORS.mediumGray5,
    cursor: 'pointer',
  },

  buttonFilter: {
    backgroundColor: COLORS.white,
    height: '3rem',
    width: '8rem',
    textTransform: 'none',
    fontSize: '.875rem',
    padding: 0,

    [theme.breakpoints.up('lg')]: {
      width: '13rem',
      fontSize: '1.125rem',
    },
  },

  infoIcon: {
    color: COLORS.lightGray3,
    fontSize: '2.125rem',
  },

  tablePaper: {
    border: 'none',
    padding: '0 0.875rem',
  },

  tableContainer: {
    '& .MuiTableCell-root': {
      padding: '0.75rem 0',
    },
  },

  tableHead: {
    '& .MuiTableCell-root': {
      border: 'none',
    },

    '& span': {
      color: COLORS.gray2,
      fontSize: '0.75rem',
      fontWeight: '500',
    },
  },

  tableRow: {
    '& .MuiTableCell-root': {
      height: '100%',
      color: COLORS.mediumGray5,
      fontSize: '.75rem',

      maxWidth: '1.5rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      [theme.breakpoints.up('md')]: {
        fontSize: '0.875rem',
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: '1.125rem',
      },

      '& img': {
        width: '1.25rem',
        height: '1.75rem',
      },
    },
  },
}));

type FilterType = 'sign' | 'auth';

interface LogData {
  data: Log[];
  xTotalCount: number;
}

export default function History() {
  const classes = useStyles();
  const [page, setPage] = useState(1);

  const [startTime, setStartTime] = useState<MaterialUiPickersDate>(null);
  const [endTime, setEndTime] = useState<MaterialUiPickersDate>(null);
  const [fullDate, setFullDate] = useState('');

  const [filterActive, setFilterActive] = useState({
    auth: false,
    sign: false,
  });
  const [filter, setFilter] = useState('');

  const rowsPerPage = 8;

  const { t } = useTranslation();

  const { data: logs, error }: { data: LogData; error: any } = useAxios(
    linksForSWR.client.history(page, rowsPerPage, filter, fullDate),
  );

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeFilter = (value: FilterType) => {
    const newFilterActive = {
      ...filterActive,
      [value]: !filterActive[value],
    };

    setFilterActive(newFilterActive);

    // Credits: https://stackoverflow.com/questions/32831487/javascript-object-to-querystring-using-reduce
    const filterSerialized = (Object.keys(newFilterActive) as FilterType[])
      .filter(element => newFilterActive[element])
      .map(element => `filter=${element}`)
      .join('&');

    setFilter(filterSerialized);
    setPage(1);
  };

  const handleFullDate = (
    startDate: MaterialUiPickersDate,
    endDate: MaterialUiPickersDate,
  ) => {
    if (!startDate || !endDate) {
      setFullDate('');
      return;
    }

    setFullDate(`startTime=${startDate}&endTime=${endDate}`);
    setPage(1);
  };

  useEffect(() => {
    handleFullDate(startTime, endTime);
  }, [startTime, endTime]);

  return (
    <Layout type="user" routeSelected="history">
      <Grid container justifyContent="space-between">
        <Typography variant="h1" className={classes.title}>
          {t('client.history.title')}
        </Typography>

        <Grid
          container
          justifyContent="space-between"
          style={{ margin: '1.5rem 0 1.5rem 0', width: '100%' }}
        >
          <div className={classes.filtersContainer}>
            <div className={classes.selectDatesContainer}>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={t('client.history.locale')}
              >
                <DatePicker
                  placeholder={t('client.history.date.title')}
                  format={moment(startTime)
                    .locale(t('client.history.locale'))
                    .format('L')}
                  variant="inline"
                  value={startTime}
                  onChange={setStartTime}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    ),
                    endAdornment: startTime ? (
                      <InputAdornment
                        position="end"
                        onClick={e => {
                          e.stopPropagation();
                          setStartTime(null);
                        }}
                      >
                        <CloseIcon className={classes.closeIcon} />
                      </InputAdornment>
                    ) : null,
                    disableUnderline: true,
                  }}
                  className={classes.datePicker}
                />

                <span>{t('client.history.date.label')}</span>

                <DatePicker
                  placeholder={t('client.history.date.title')}
                  format={moment(endTime)
                    .locale(t('client.history.locale'))
                    .format('L')}
                  variant="inline"
                  value={endTime}
                  onChange={setEndTime}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    ),
                    endAdornment: endTime ? (
                      <InputAdornment
                        position="end"
                        onClick={e => {
                          e.stopPropagation();
                          setEndTime(null);
                        }}
                      >
                        <CloseIcon className={classes.closeIcon} />
                      </InputAdornment>
                    ) : null,
                    disableUnderline: true,
                  }}
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className={classes.buttonsFilterContainer}>
              <Button
                variant="outlined"
                className={classes.buttonFilter}
                style={{
                  color: filterActive.auth ? COLORS.blue : COLORS.mediumGray1,
                  border: `1px solid ${
                    filterActive.auth ? COLORS.blue : COLORS.lightGray3
                  }`,
                }}
                onClick={() => {
                  handleChangeFilter('auth');
                }}
              >
                {t('client.history.buttonActions.authentication')}
              </Button>

              <Button
                variant="outlined"
                className={classes.buttonFilter}
                style={{
                  color: filterActive.sign ? COLORS.blue : COLORS.mediumGray1,
                  border: `1px solid ${
                    filterActive.sign ? COLORS.blue : COLORS.lightGray3
                  }`,
                }}
                onClick={() => {
                  handleChangeFilter('sign');
                }}
              >
                {t('client.history.buttonActions.signed')}
              </Button>
            </div>
          </div>

          <Tooltip title="">
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Tooltip>
        </Grid>
      </Grid>

      <Paper className={classes.tablePaper} elevation={0}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {(
                  t('client.history.columns', {
                    returnObjects: true,
                  }) as string[]
                ).map((element, index) => (
                  <TableCell
                    align={
                      element === 'Platform' || element === 'Plataforma'
                        ? 'center'
                        : 'left'
                    }
                    key={element}
                    style={{ width: index ? '15%' : '40%' }}
                  >
                    <span>{element}</span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {logs ? (
                logs.data.map(log => (
                  <TableRow key={log._id} className={classes.tableRow}>
                    <TableCell>
                      {moment(log.createdAt, 'YYYY-MM-DDTHH:mm:ssZ')
                        .locale(t('client.history.locale'))
                        .format('LLLL')}
                    </TableCell>

                    <TableCell align="left">
                      {t(`client.history.actions.${log.protocol.method}`)}
                    </TableCell>

                    <TableCell align="left">{log.server_ip}</TableCell>

                    <TableCell align="left">
                      {`${log.protocol.device.manufacturer} ${log.protocol.device.model}`}
                    </TableCell>

                    <TableCell align="center">
                      {log.protocol.who === 'DattaID' ? (
                        <img src={littleDigitalImg} alt="DattaID" />
                      ) : (
                        log.protocol.who
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell />
                  <TableCell align="center">
                    {error ? t('client.history.error') : <CircularProgress />}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <CustomPagination
          page={page}
          handleChangePage={handleChangePage}
          total={logs?.xTotalCount}
          rowsPerPage={rowsPerPage}
          contentToLeft={null}
        />
      </Paper>
    </Layout>
  );
}
