/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { mutate } from 'swr';
import clsx from 'clsx';

import Layout from '../../../components/Layout';
import ModalDelete from '../../../components/Modals/ModalDelete';

import { useAxios } from '../../../hooks/useAxios';
import useAuthContext from '../../../hooks/useAuthContext';

import { Vault } from '../../../types/Vault';

import decryptFile from '../../../utils/decryptFile';
import COLORS from '../../../utils/colors';
import getTwoFirstLettersByName from '../../../utils/getTwoFirstLettersByName';
import linksForSWR from '../../../utils/linksForSWR';
import VaultViewer from '../../../components/VaultViewer';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: COLORS.black,
    margin: '-2.5rem -8.5rem -1.5rem -5rem',
    height: '88.425vh',

    [theme.breakpoints.up('lg')]: {
      marginRight: '-3.125rem',
      marginLeft: '-2.5rem',
    },
  },

  safeSpaceContainer: {
    padding: '3rem 8rem 0 5.25rem',
    height: '89%',
  },

  loading: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    height: '100%',
    width: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    marginBottom: '.75rem',
    fontWeight: 600,
    fontSize: '4rem',
    color: COLORS.white,
    maxWidth: '28.125rem',
    lineHeight: '5.5rem',
  },

  subtitle: {
    color: COLORS.mediumGray2,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    maxWidth: '26.875rem',
    marginBottom: '1.5rem',
  },

  headerContentContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '2.25rem',
  },

  documentTitle: {
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: '1.625rem',
    color: COLORS.white,
  },

  documentDate: {
    fontSize: '.75rem',
    lineHeight: '1rem',
    color: COLORS.white,
  },

  avatar: {
    color: COLORS.gray2,
    fontSize: '0.75rem',
    backgroundColor: COLORS.lightGray1,

    '&.isOpen': {
      backgroundColor: COLORS.lightGreen,
    },
  },

  buttonBack: {
    color: COLORS.white,
    fontWeight: 700,
    fontSize: '1.125rem',
    textTransform: 'none',
    marginLeft: '3.375rem',
    marginTop: '1.5rem',
    width: '7.625rem',
    height: '3rem',
    borderRadius: '0.5rem',

    '&:hover': {
      backgroundColor: COLORS.background,
      color: COLORS.black,

      '& svg': {
        fill: COLORS.black,
        transition: 'all 0.3s',
      },
      transition: 'all 0.3s',
    },
  },

  observation: {
    color: COLORS.white,
    fontSize: '1rem',
    lineHeight: '1.375rem',
    margin: '3rem 0 2.25rem 0',
    maxWidth: '20.25rem',
  },

  errorContainer: {
    '& span': {
      color: COLORS.redWarning,
      fontSize: '1rem',
    },

    '& svg': {
      color: COLORS.white,
    },
  },

  webViewerContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      paddingLeft: '4rem',
    },
  },

  webViewer: {
    height: '100%',
    maxHeight: '100%',
    width: '100%',
  },

  arrowBackIcon: {
    color: COLORS.white,
    fontSize: '1.5rem',
  },
}));

const CustomToolTip = withStyles({
  tooltip: {
    backgroundColor: COLORS.mediumGray5,
    color: COLORS.lightGray2,
    borderRadius: '0.5rem',
    fontWeight: 'bold',
    fontSize: '.875rem',
  },
})(Tooltip);

interface Location {
  state: {
    passwordFile: string;
  };
}

export default function ViewVault() {
  const [file, setFile] = useState<Blob | null>(null);

  const classes = useStyles();

  const { t } = useTranslation();

  const { user } = useAuthContext();

  const history = useHistory();
  const location = useLocation() as Location;
  const { id }: { id: string } = useParams();

  const { data: vault, error }: { data: Vault; error: any } = useAxios(
    linksForSWR.client.vault.view(id),
  );

  useEffect(() => {
    async function loadFile() {
      const fileDecrypted = decryptFile(
        vault.file,
        location.state.passwordFile,
      );

      mutate(linksForSWR.client.vault.index());
      mutate(linksForSWR.client.vault.index(1, 6, '', true));

      setFile(fileDecrypted);
    }

    if (vault && location.state) {
      loadFile();
    }
  }, [vault]);

  return (
    <Layout type="user" routeSelected="vault">
      <div className={classes.container}>
        <Grid container className={classes.safeSpaceContainer}>
          <Grid container item direction="column" xs={12} md={6}>
            <Typography variant="h1" className={classes.title}>
              {t('client.vault.view.title')}
            </Typography>

            <Typography variant="h2" className={classes.subtitle}>
              {t('client.vault.view.subtitle')}
            </Typography>

            {vault ? (
              <>
                <div className={classes.headerContentContainer}>
                  <div>
                    <Typography className={classes.documentTitle}>
                      {vault.fileName}
                    </Typography>

                    <Typography className={classes.documentDate}>
                      {moment(vault.createdAt)
                        .locale(t('client.vault.locale'))
                        .format('LL')}
                    </Typography>
                  </div>

                  <AvatarGroup max={3}>
                    {vault.sharedUsers.map(sharedUser => (
                      <CustomToolTip
                        key={sharedUser._id}
                        title={
                          sharedUser.user
                            ? sharedUser.user.name
                            : t('client.vault.deletedUser')
                        }
                      >
                        <Avatar
                          className={clsx(classes.avatar, {
                            isOpen: sharedUser.isOpened,
                          })}
                        >
                          {getTwoFirstLettersByName(
                            sharedUser.user ? sharedUser.user.name : '-',
                          )}
                        </Avatar>
                      </CustomToolTip>
                    ))}
                  </AvatarGroup>
                </div>

                {vault.user.email === user?.email ? (
                  <ModalDelete what="vault" type="view" component={{ id }} />
                ) : (
                  vault.sharedUsers.map(sharedUser => {
                    if (
                      sharedUser.user.email !== user?.email ||
                      !sharedUser.observation
                    )
                      return null;

                    return (
                      <Typography
                        key={sharedUser._id}
                        className={classes.observation}
                      >
                        {t('client.vault.view.note')}: {sharedUser.observation}
                      </Typography>
                    );
                  })
                )}
              </>
            ) : (
              <div className={classes.errorContainer}>
                {error ? (
                  <span>{t('client.vault.error')}</span>
                ) : (
                  <CircularProgress />
                )}
              </div>
            )}
          </Grid>

          <Grid item xs={12} md={6} className={classes.webViewerContainer}>
            <VaultViewer file={file} heightContent="100%" />
          </Grid>
        </Grid>

        <Button
          startIcon={<ArrowBackIcon className={classes.arrowBackIcon} />}
          className={classes.buttonBack}
          onClick={() => {
            history.push('/vault');
          }}
        >
          {t('client.vault.view.buttonBack')}
        </Button>
      </div>
    </Layout>
  );
}
