/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GroupIcon from '@material-ui/icons/Group';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Layout from '../../../components/Layout';
import ModalUploadToSign from '../../../components/Modals/ModalUploadToSign';

import { useAxios } from '../../../hooks/useAxios';
import useAuthContext from '../../../hooks/useAuthContext';

import { Document } from '../../../types/Document';
import { Log } from '../../../types/Log';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';

import littleDigitalImg from '../../../images/little-digital.svg';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    fontSize: '3rem',
    lineHeight: '4rem',
  },

  headerContainer: {
    marginTop: '2.875rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    rowGap: '1rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  search: {
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    paddingLeft: '1rem',
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${COLORS.lightGray3}`,
    outline: `${COLORS.lightGray1}`,
    width: '100%',

    '&:hover': {
      transition: '0.1s',
      outline: `0.25rem solid ${COLORS.lightGray1}`,
    },

    [theme.breakpoints.up('md')]: {
      width: '33.33%',
    },

    '& input': {
      fontSize: '1.125rem',
      marginBottom: '0.125rem',
      lineHeight: '1.5rem',
      color: COLORS.gray3,
    },

    '& svg': {
      fill: COLORS.mediumGray1,
      fontSize: '1.5rem',
    },

    '& .Mui-focused': {
      '& svg': {
        fill: COLORS.gray2,
      },
    },
  },

  closeIcon: {
    fontSize: '1.625rem',
    marginRight: '0.625rem',
    color: COLORS.mediumGray5,
    cursor: 'pointer',
  },

  documentsContainer: {
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2.25rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      columnGap: '2.25rem',
      rowGap: 0,
    },
  },

  tablePaperDocuments: {
    width: '100%',
    height: '15rem',
    borderRadius: '0.5rem',
    padding: '0.875rem',

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },

  tableContainer: {
    height: '95%',
  },

  tableRow: {
    '& .MuiTableCell-root': {
      padding: '0.75rem',
    },
  },

  tableCellDocument: {
    display: 'flex',
    alignItems: 'center',
    height: '4rem',
    padding: '0 !important',

    '&:hover': {
      backgroundColor: COLORS.lightOrange,
    },

    '& a': {
      display: 'block',
      width: '100%',
      height: '100%',
      padding: '.75rem',
      textDecoration: 'none',
      color: COLORS.black,
    },
  },

  fileNameContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& .text': {
      fontSize: '0.875rem',
      fontWeight: 700,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '18.75rem',
    },

    '& .date': {
      fontSize: '0.75rem',
      color: COLORS.gray2,
    },
  },

  buttonTableContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  ownerLabel: {
    fontSize: '0.875rem',
    color: COLORS.gray2,
    fontWeight: 600,
    marginRight: '1.5rem',
  },

  textTableCellDocument: {
    fontSize: '0.875rem',
    fontWeight: 700,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '18.75rem',
  },

  buttonSignNowDocument: {
    height: '2.25rem',
    width: '6.75rem',
    textTransform: 'none',
    borderRadius: '0.5rem',
    backgroundColor: COLORS.orangeAdvice,
    color: COLORS.white,
    fontSize: '0.875rem',
    fontWeight: 700,

    '& svg': {
      fill: COLORS.white,
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      backgroundColor: COLORS.orangeHover,
      transition: '0.3s',
    },
  },

  buttonViewDocument: {
    height: '2.25rem',
    width: '4.375',
    textTransform: 'none',
    borderRadius: '0.5rem',
    backgroundColor: COLORS.lightGray2,
    color: COLORS.mediumGray5,
    fontSize: '0.875rem',
    fontWeight: 600,

    '& svg': {
      fill: COLORS.mediumGray5,
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      color: COLORS.gray2,
      backgroundColor: COLORS.lightGray4,
      transition: '0.3s',

      '& svg': {
        fill: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  buttonViewMore: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'center',
    color: COLORS.mediumGray5,

    '&:hover': {
      backgroundColor: 'transparent',
      color: COLORS.black,
      transition: '0.3s',
    },
  },

  boxesContainer: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr',
    gap: '0.75rem',

    [theme.breakpoints.up('md')]: {
      width: '50%',
      gridTemplateRows: 'repeat(2, 1fr)',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },

  boxContainer: {
    width: '100%',
    height: '7.125rem',
    cursor: 'pointer',
    padding: '0.5rem 1.75rem 0.75rem 1.75rem',
    color: COLORS.background,
    backgroundColor: COLORS.gray2,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textDecoration: 'none',

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',
    },

    '&.disabled': {
      cursor: 'default',
      backgroundColor: COLORS.mediumGray3,
    },

    '& h2': {
      maxWidth: '11.25rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      fontSize: '1.125rem',

      [theme.breakpoints.up('md')]: {
        fontSize: '.875rem',
        lineHeight: '1.125rem',
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },
    },

    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      height: '2.125rem',
      alignItems: 'center',

      '& .MuiSvgIcon-root': {
        fontSize: '1.875rem',
        backgroundColor: COLORS.mediumGray5,
        padding: '0.25rem',
        borderRadius: '0.5rem',

        [theme.breakpoints.up('lg')]: {
          fontSize: '2.125rem',
        },
      },

      '& span': {
        fontSize: '1.25rem',
        lineHeight: '2.5rem',
        fontWeight: 500,

        [theme.breakpoints.up('lg')]: {
          fontSize: '1.875rem',
        },
      },
    },
  },

  titleLogs: {
    margin: '1.75rem 0 1.25rem 0',
    fontSize: '1.875rem',
    fontWeight: 700,
    color: COLORS.gray2,
  },

  tableLogsPaper: {
    background: 'none',
    border: 'none',
    width: '100%',
  },

  tableLogsContainer: {
    '& .MuiTableCell-root': {
      border: 'none',
      padding: '0.75rem',
    },
  },

  tableLogsHead: {
    color: COLORS.mediumGray1,
    fontSize: '1.125rem',
    fontWeight: 700,
  },

  tableLogsRow: {
    '& span': {
      color: COLORS.mediumGray5,
      fontSize: '1.125rem',
    },
  },

  buttonMoreLogs: {
    padding: 0,
    backgroundColor: COLORS.lightGray1,
    color: COLORS.mediumGray5,
    height: '1.5rem',
    width: '4.5rem',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '0.75rem',
    borderRadius: '0.5rem',
    marginTop: '0.65rem',

    '&:hover': {
      color: COLORS.gray2,
      backgroundColor: COLORS.lightGray1,
    },
  },

  whoInfoContainer: {
    fontSize: '0.875rem !important',
    color: `${COLORS.greenButton} !important`,
  },

  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '1.5rem',

    '& .text': {
      fontSize: '2.25rem',
      color: COLORS.gray2,
      fontWeight: '500',
    },
  },

  errorDocumentsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15rem',
    width: '50%',
  },

  errorLogsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

interface DocumentData {
  data: Document[];
  xTotalCount: number;
}

interface LogData {
  data: Log[];
  xTotalCount: number;
}

export default function Home() {
  const classes = useStyles();

  const [search, setSearch] = useState('');
  const history = useHistory();
  const { user } = useAuthContext();

  const {
    data: documents,
    error: errorDocuments,
  }: { data: DocumentData; error: any } = useAxios(
    linksForSWR.client.home.documents(3),
  );

  const { data: logs, error: errorLogs }: { data: LogData; error: any } =
    useAxios(linksForSWR.client.home.logs());

  const { data: dashboard, error: errorDashboard } = useAxios(
    linksForSWR.client.home.dashboard(),
  );

  const { t } = useTranslation();

  const handleChangeSearch = (value: string) => {
    setSearch(value);
    history.push('/documents', {
      search: value,
    });
  };

  return (
    <Layout type="user" routeSelected="home">
      <Grid container justifyContent="space-between">
        <Typography variant="h1" className={classes.title}>
          {t('client.home.title')}
        </Typography>

        <Grid
          container
          justifyContent="space-between"
          className={classes.headerContainer}
        >
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            element={TextField}
            size="medium"
            placeholder={t('client.home.search')}
            fullWidth
            variant="standard"
            value={search}
            onChange={e => {
              handleChangeSearch(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: search ? (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  <CloseIcon className={classes.closeIcon} />
                </InputAdornment>
              ) : null,
              disableUnderline: true,
            }}
            className={classes.search}
          />

          <ModalUploadToSign type="button" />
        </Grid>
      </Grid>

      <div className={classes.documentsContainer}>
        {documents ? (
          documents.data.length ? (
            <Paper className={classes.tablePaperDocuments} elevation={0}>
              <TableContainer className={classes.tableContainer}>
                <Table>
                  <TableBody>
                    {documents.data.map(document => (
                      <TableRow key={document._id} className={classes.tableRow}>
                        <TableCell
                          align="left"
                          className={classes.tableCellDocument}
                        >
                          <Link to={`/documents/${document._id}`}>
                            <div className={classes.fileNameContainer}>
                              <Typography className="text">
                                {document.fileName}
                              </Typography>

                              <Typography className="date">
                                {moment(document.createdAt)
                                  .locale(t('client.home.locale'))
                                  .format('LL')}
                              </Typography>
                            </div>
                          </Link>
                        </TableCell>

                        <TableCell style={{ height: '100%' }}>
                          <div className={classes.buttonTableContainer}>
                            {document.user?.email === user?.email && (
                              <span className={classes.ownerLabel}>
                                {t('client.home.documents.label')}
                              </span>
                            )}

                            {document.userSignatures.find(userSignature => {
                              if (!userSignature.user) return false;
                              if (userSignature.user.email !== user?.email)
                                return false;

                              const [lastStatus] =
                                userSignature.status.slice(-1);

                              return (
                                lastStatus.name === 'signed' &&
                                lastStatus.is_active
                              );
                            }) ||
                            document.status.find(
                              status =>
                                status.name === 'signed' && status.is_active,
                            ) ? (
                              <Button
                                className={classes.buttonViewDocument}
                                endIcon={<VisibilityOutlinedIcon />}
                                component={Link}
                                to={`/documents/${document._id}`}
                              >
                                {t('client.home.documents.buttonView')}
                              </Button>
                            ) : (
                              <Button
                                className={classes.buttonSignNowDocument}
                                endIcon={<BorderColorIcon />}
                                component={Link}
                                to={`/documents/${document._id}`}
                              >
                                {t('client.home.documents.buttonSign')}
                              </Button>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Link to="/documents" className={classes.buttonViewMore}>
                  {t('client.home.documents.buttonViewMore')}
                </Link>
              </div>
            </Paper>
          ) : (
            <Paper
              className={classes.tablePaperDocuments}
              elevation={0}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div className={classes.emptyContainer}>
                <Typography className="text">
                  {t('client.documents.index.empty')}
                </Typography>

                <ModalUploadToSign type="text" />
              </div>
            </Paper>
          )
        ) : (
          <div className={classes.errorDocumentsContainer}>
            {errorDocuments ? t('client.home.error') : <CircularProgress />}
          </div>
        )}
        <div className={classes.boxesContainer}>
          <Link
            className={clsx(classes.boxContainer, {
              disabled: !dashboard?.waiting,
            })}
            to={
              dashboard?.waiting
                ? {
                    pathname: '/documents',
                    state: {
                      status: 'waitingForMySignature',
                    },
                  }
                : '/home'
            }
          >
            <h2>{t('client.home.boxes.mySignature')}</h2>

            <div>
              <ScheduleIcon />

              {dashboard ? (
                <span>{dashboard.waiting}</span>
              ) : errorDashboard ? (
                t('client.home.error')
              ) : (
                <CircularProgress style={{ color: COLORS.white }} size={32} />
              )}
            </div>
          </Link>

          <Link
            className={clsx(classes.boxContainer, {
              disabled: !dashboard?.waitingForAnother,
            })}
            to={
              dashboard?.waiting
                ? {
                    pathname: '/documents',
                    state: {
                      status: 'waitingForAnother',
                    },
                  }
                : '/home'
            }
          >
            <h2>{t('client.home.boxes.others')}</h2>

            <div>
              <GroupIcon />

              {dashboard ? (
                <span>{dashboard.waitingForAnother}</span>
              ) : errorDashboard ? (
                t('client.home.error')
              ) : (
                <CircularProgress style={{ color: COLORS.white }} size={32} />
              )}
            </div>
          </Link>

          <Link
            className={clsx(classes.boxContainer, {
              disabled: !dashboard?.signed,
            })}
            to={
              dashboard?.signed
                ? {
                    pathname: '/documents',
                    state: { status: 'signed' },
                  }
                : '/home'
            }
          >
            <h2>{t('client.home.boxes.total')}</h2>

            <div>
              <DescriptionOutlinedIcon />

              {dashboard ? (
                <span>{dashboard.signed}</span>
              ) : errorDashboard ? (
                t('client.home.error')
              ) : (
                <CircularProgress style={{ color: COLORS.white }} size={32} />
              )}
            </div>
          </Link>

          <Link
            className={clsx(classes.boxContainer, {
              disabled: !dashboard?.vaults,
            })}
            to={dashboard?.vaults ? '/vault' : '/home'}
          >
            <h2>{t('client.home.boxes.vault')}</h2>

            <div>
              <LockOutlinedIcon />

              {dashboard ? (
                <span>{dashboard.vaults}</span>
              ) : errorDashboard ? (
                t('client.home.error')
              ) : (
                <CircularProgress style={{ color: COLORS.white }} size={32} />
              )}
            </div>
          </Link>
        </div>
      </div>

      <div>
        <h2 className={classes.titleLogs}>{t('client.home.logs.title')}</h2>

        {logs ? (
          <Paper className={classes.tableLogsPaper} elevation={0}>
            <TableContainer className={classes.tableLogsContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    {(
                      t('client.history.columns', {
                        returnObjects: true,
                      }) as string[]
                    ).map(element => (
                      <TableCell
                        key={element}
                        className={classes.tableLogsHead}
                        align={
                          element === 'Platform' || element === 'Plataforma'
                            ? 'center'
                            : 'left'
                        }
                      >
                        {element}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {logs.data.map(log => (
                    <TableRow key={log._id} className={classes.tableLogsRow}>
                      <TableCell align="left" style={{ height: '100%' }}>
                        <span>
                          {moment(log.createdAt, 'YYYY-MM-DDTHH:mm:ssZ')
                            .locale(t('client.home.locale'))
                            .format('LLLL')}
                        </span>
                      </TableCell>

                      <TableCell align="left" style={{ height: '100%' }}>
                        <span>
                          {t(`client.history.actions.${log.protocol.method}`)}
                        </span>
                      </TableCell>

                      <TableCell align="left" style={{ height: '100%' }}>
                        <span>{log.server_ip}</span>
                      </TableCell>

                      <TableCell align="left" style={{ height: '100%' }}>
                        <span>{`${log.protocol.device.manufacturer} ${log.protocol.device.model}`}</span>
                      </TableCell>

                      <TableCell align="center" style={{ height: '100%' }}>
                        <span className={classes.whoInfoContainer}>
                          {log.protocol.who === 'DattaID' ? (
                            <img
                              src={littleDigitalImg}
                              alt="DattaID"
                              width="19"
                              height="28"
                            />
                          ) : (
                            log.protocol.who
                          )}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                component={Link}
                to="/history"
                className={classes.buttonMoreLogs}
              >
                {t('client.home.logs.buttonMore')}
              </Button>
            </div>
          </Paper>
        ) : (
          <div className={classes.errorLogsContainer}>
            {errorLogs ? t('client.home.error') : <CircularProgress />}
          </div>
        )}
      </div>
    </Layout>
  );
}
