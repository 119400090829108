import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { useTranslation } from 'react-i18next';

import { Signee } from '../../types/Signee';

import COLORS from '../../utils/colors';

const useStyles = makeStyles({
  avatar: {
    width: '2.75rem',
    height: '2.75rem',
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    fontSize: '1rem',
  },

  textEmail: {
    color: COLORS.blue,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },

  buttonSignature: {
    textTransform: 'none',
    padding: 0,
    fontSize: '0.875rem',
    fontWeight: 500,
    color: COLORS.mediumGray5,

    '& svg': {
      width: '0.875rem',
      height: '0.875rem',
    },

    '&:hover': {
      background: 'none',
      color: COLORS.gray1,
      transition: '0.3s',
    },
  },

  progressOnButton: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    marginTop: -12,
    color: 'white',
  },

  buttonInitials: {
    textTransform: 'none',
    padding: 0,
    fontSize: '0.875rem',
    fontWeight: 500,
    color: COLORS.mediumGray5,
    marginTop: '0.125rem',

    '& svg': {
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      background: 'none',
      color: COLORS.gray1,
      transition: '0.3s',
    },
  },

  locationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.75rem',
  },

  buttonInitialAllPages: {
    textTransform: 'none',
    padding: 0,
    fontSize: '0.875rem',
    fontWeight: 500,
    color: COLORS.mediumGray5,
    marginTop: '0.125rem',
    display: 'flex',
    textAlign: 'left',

    '& svg': {
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      background: 'none',
      color: COLORS.gray1,
      transition: '0.3s',
    },
  },

  buttonDeletePerson: {
    color: COLORS.lightRed,
    fontSize: '1.5rem',
    padding: 0,

    '&:hover': {
      color: COLORS.redWarning,
      cursor: 'pointer',
      transition: '.3s',
    },
  },

  buttonLocationSignature: {
    padding: 0,
    textTransform: 'none',
    color: COLORS.blue,

    '&:hover': {
      background: 'none',
      color: COLORS.blueHover,
      transition: '0.3s',
    },
  },

  buttonDeleteLocationSignature: {
    padding: 0,
    textTransform: 'none',
    color: COLORS.lightRed,

    '&:hover': {
      background: 'none',
      color: COLORS.redWarning,
      transition: '0.3s',
    },
  },

  selectOrder: {
    width: '2.75rem',
    height: '2.75rem',
    padding: 0,
  },

  buttonLocationInitials: {
    padding: 0,
    textTransform: 'none',
    color: COLORS.blue,

    '&:hover': {
      background: 'none',
      color: COLORS.blueHover,
      transition: '0.3s',
    },
  },

  buttonDeleteLocationInitials: {
    padding: 0,
    textTransform: 'none',
    color: COLORS.lightRed,

    '&:hover': {
      background: 'none',
      color: COLORS.redWarning,
      transition: '0.3s',
    },
  },

  divider: {
    margin: '1rem 0',
    backgroundColor: COLORS.lightGray2,
  },
});

interface Props {
  signee: Signee;
  handleSign: (email: string) => Promise<void>;
  handleInitials: (email: string) => Promise<void>;
  handleRemovePerson: (email: string) => void;
  handleRemoveSignature: (email: string, id: string) => void;
  handleRemoveInitials: (email: string, id: string) => void;
  addInitialOnAllPages: (currentSignee: Signee) => void;
  canAddInitialOnAllPages: boolean;
  loadingSignature: boolean;
  loadingInitials: boolean;
  isSortingSignees: boolean;
  maxNumberToChooseOrder: number;
  handleChangeOrder: (email: string, value: number) => void;
}

export default function PersonSigneeTile({
  signee,
  handleSign,
  handleInitials,
  handleRemovePerson,
  handleRemoveSignature,
  handleRemoveInitials,
  addInitialOnAllPages,
  canAddInitialOnAllPages,
  loadingSignature,
  loadingInitials,
  isSortingSignees,
  maxNumberToChooseOrder,
  handleChangeOrder,
}: Props) {
  const classes = useStyles();

  const { t } = useTranslation();

  const valuesForOrdering = Array.from(
    { length: maxNumberToChooseOrder },
    (v, k) => k + 1,
  );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', columnGap: '0.75rem' }}>
          {isSortingSignees ? (
            <Select
              value={signee.order}
              onChange={event => {
                handleChangeOrder(signee.email, event.target.value as number);
              }}
              className={classes.selectOrder}
            >
              {valuesForOrdering.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Avatar className={classes.avatar}>
              {signee.email.toUpperCase()[0]}
            </Avatar>
          )}

          <div>
            <span className={classes.textEmail}>{signee.email}</span>

            <div style={{ position: 'relative' }}>
              <Button
                className={classes.buttonSignature}
                onClick={() => {
                  handleSign(signee.email);
                }}
                disabled={loadingSignature}
                startIcon={<BorderColorOutlinedIcon />}
              >
                {t('components.personSigneeTile.buttonAddSignature')}
              </Button>

              {loadingSignature && (
                <CircularProgress
                  size={24}
                  className={classes.progressOnButton}
                />
              )}
            </div>

            <Button
              className={classes.buttonInitials}
              onClick={() => {
                handleInitials(signee.email);
              }}
              disabled={loadingInitials}
              startIcon={<VerticalAlignBottomIcon />}
            >
              {t('components.personSigneeTile.buttonAddInitials')}
            </Button>

            {canAddInitialOnAllPages && (
              <Button
                className={classes.buttonInitialAllPages}
                onClick={() => {
                  addInitialOnAllPages(signee);
                }}
                disabled={loadingInitials}
                startIcon={<DoneAllIcon />}
              >
                {t('components.personSigneeTile.buttonInitialAllPages')}
              </Button>
            )}
          </div>
        </div>

        <DeleteOutlinedIcon
          onClick={() => {
            handleRemovePerson(signee.email);
          }}
          className={classes.buttonDeletePerson}
        />
      </div>

      <div style={{ marginTop: '0.75rem' }}>
        {signee.signatureLocations.map((signatureLocation, index) => (
          <div key={signatureLocation.id} className={classes.locationContainer}>
            <Button className={classes.buttonLocationSignature}>
              {t('components.personSigneeTile.signature')} {index + 1},{' '}
              {t('components.personSigneeTile.page')} {signatureLocation.page}
            </Button>

            <Button
              className={classes.buttonDeleteLocationSignature}
              onClick={() => {
                handleRemoveSignature(signee.email, signatureLocation.id);
              }}
            >
              {t('components.personSigneeTile.buttonRemove')}
            </Button>
          </div>
        ))}

        {signee.initialsLocations.map((initialsLocation, index) => (
          <div key={initialsLocation.id} className={classes.locationContainer}>
            <Button className={classes.buttonLocationInitials}>
              {t('components.personSigneeTile.initials')} {index + 1},{' '}
              {t('components.personSigneeTile.page')} {initialsLocation.page}
            </Button>

            <Button
              className={classes.buttonDeleteLocationInitials}
              onClick={() => {
                handleRemoveInitials(signee.email, initialsLocation.id);
              }}
            >
              {t('components.personSigneeTile.buttonRemove')}
            </Button>
          </div>
        ))}
      </div>

      <Divider className={classes.divider} />
    </>
  );
}
