export default {
  white: '#FFFFFF',
  background: '#F5F8FD',

  lightGray0: '#F3F3F3',
  lightGray1: '#E7E7E7',
  lightGray2: '#EFEFEF',
  lightGray3: '#DEDEDE',
  lightGray4: '#D4D4D4',
  mediumGray1: '#BABABA',
  mediumGray2: '#B8B8B8',
  mediumGray3: '#A7A7A7',
  mediumGray4: '#A7A3A3',
  mediumGray5: '#858585',
  gray1: '#535353',
  gray2: '#484848',
  gray3: '#3A3A3A',
  gray4: '#373737',
  gray5: '#333333',

  black: '#222222',
  pureBlack: '#000000',

  lightGreen: '#C7F8BF',
  greenButton: '#73BB68',
  greenHover: '#48983C',

  lightOrange: '#FFF8EE',
  orangeAdvice: '#FFBE57',
  orangeHover: '#FCB13B',

  lightRed: '#FF8A8A',
  redWarning: '#FF4646',

  blue: '#19B1D2',
  blueHover: '#00A5C9',
  lightBlue: '#d9ebef',
  lightBlue2: '#b1e1ea',
  lightBlueHover: '#9dd3dd',
};
