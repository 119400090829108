import { ptBR, enUS } from '@material-ui/core/locale';

import { Language } from '../types/Language';

export function i18nToWebview(language: Language) {
  if (language === 'pt-BR') return 'pt_br';

  return 'en';
}

export function i18nToMUI(language: Language) {
  if (language === 'pt-BR') return ptBR;

  return enUS;
}

export function i18nToFlag(language: Language) {
  if (language === 'pt-BR') return 'BR';

  return 'US';
}
