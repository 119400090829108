import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import COLORS from '../../utils/colors';

import littleDigitalImg from '../../images/little-digital.svg';

const useStyles = makeStyles({
  container: {
    width: '100vw',
    padding: '8rem 20%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '3.5rem',
    backgroundColor: COLORS.white,

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '3rem',
      paddingBottom: '.75rem',
      borderBottom: `.25rem solid ${COLORS.redWarning}`,
    },

    '& img': {
      height: '4.25rem',
      width: '3rem',
    },

    '& h1': {
      fontSize: '1.5rem',
    },

    '& section': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '1rem',

      '& div span': {
        display: 'block',
      },
    },

    '& h2': {
      fontSize: '1rem',
    },

    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '.375rem',
      marginLeft: '1rem',
    },

    '& p, & li': {
      textAlign: 'justify',
      lineHeight: '1.375rem',
    },

    '& .dattaid': {
      fontWeight: 600,
      color: COLORS.gray2,
      borderBottom: `0.125rem solid ${COLORS.redWarning}`,
    },
  },
});

export default function Privacy() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <img src={littleDigitalImg} alt="logo" />

        <h1>Política de Privacidade</h1>
      </div>

      <span>Última atualização: 14/07/2022</span>

      <section>
        <h2>1. Responsável pelo Processamento</h2>

        <Grid
          container
          justifyContent="space-between"
          direction="row"
          spacing={4}
        >
          <Grid item>
            <span>Empresa:</span> VS DATTA IMAGEM
          </Grid>

          <Grid item>
            <span>CNPJ:</span> 02.054.956/0001-00
          </Grid>

          <Grid item>
            <span>Telefone:</span> +55 83 3225-5858
          </Grid>

          <Grid item>
            <span>E-mail:</span> suporte@vsdi.com.br
          </Grid>

          <Grid item>
            <span>DPO:</span> daniel@vsdi.com.br
          </Grid>
        </Grid>
      </section>

      <section>
        <h2>2. Finalidades do Processamento</h2>

        <p>
          <span className="dattaid">DattaID</span> trata os dados pessoais para
          as seguintes finalidades:
        </p>

        <ul>
          <li>
            Consultas: utilizamos os dados pessoais para responder às questões
            de pessoas que nos contactam através de formulários, e-mail,
            telefone ou pessoalmente.
          </li>

          <li>
            Gestão de clientes: utilizamos os dados pessoais para gerir os
            serviços contratados pelos clientes.
          </li>

          <li>
            Fornecedores: utilizamos os dados pessoais para processar os dados
            de fornecedores de quem obtivemos serviços ou bens.
          </li>

          <li>
            Ações comerciais: utilizamos os dados pessoais para enviar notícias
            sobre nossa empresa, artigos, notícias de interesse do clientes,
            convites para eventos e comunicações em geral.
          </li>
        </ul>
      </section>

      <section>
        <h2>3. Regime jurídico do tratamento de dados</h2>

        <p>
          O tratamento de dados que o <span className="dattaid">DattaID</span>{' '}
          realiza baseia-se em diferentes regimes jurídicos, dependendo da
          natureza de cada processo:
        </p>

        <ul>
          <li>
            Baseado no cumprimento e execução de uma relação pré-contratual ou
            contratual com clientes ou fornecedores no contexto de contratação
            de serviço.
          </li>

          <li>
            Com base no cumprimento das obrigações legais e fiscais aplicáveis
            ao <span className="dattaid">DattaID</span>.
          </li>

          <li>
            Com base no consentimento das pessoas que se cadastram no{' '}
            <span className="dattaid">DattaID</span>, que pode ser revogado a
            qualquer momento mediante pedido escrito dirigido à
            gestao@vsdi.com.br.
          </li>

          <li>
            Com base no legítimo interesse dos clientes, representantes de
            empresas ou para fins comerciais e de marketing.
          </li>
        </ul>

        <p>
          Nos formulários de coleta de dados, todos os campos indicados são
          obrigatórios, portanto, caso o usuário não forneça os dados
          correspondentes, o <span className="dattaid">DattaID</span> poderá
          negar o serviço correspondente.
        </p>
      </section>

      <section>
        <h2>4. Destinatários</h2>

        <p>
          Os dados não serão transferidos para terceiros nem serão feitas
          transferências para terceiros países, exceto:
        </p>

        <ul>
          <li>
            Entidades Públicas, Agência Fiscal, Juízes e Tribunais e, em geral,
            autoridades competentes, quando o{' '}
            <span className="dattaid">DattaID</span> tiver a obrigação legal de
            fornecê-los.
          </li>

          <li>
            Para determinadas tarefas, serviços de empresas terceirizadas que
            devem acessar os dados pessoais sob nossa responsabilidade. Trata-se
            de uma ordem de processo em que os dados acima mencionados são
            processados em nome e por conta do{' '}
            <span className="dattaid">DattaID</span> como resultado de sua
            prestação de serviços, contratando apenas serviços de empresas que
            garantam o cumprimento das normas de proteção de dados, como por
            exemplo o Face match. O <span className="dattaid">DattaID</span>{' '}
            segue critérios rigorosos para a seleção de prestadores de serviços
            para cumprir suas obrigações de proteção de dados e se compromete a
            assinar o contrato de processamento de dados correspondente com
            prestadores de serviços através do qual imporá, entre outras, as
            seguintes obrigações: aplicar técnicas e medidas organizacionais; e
            excluir ou devolver os dados à{' '}
            <span className="dattaid">DattaID</span> assim que a prestação do
            serviço for concluída.
          </li>

          <li>
            Usamos serviços da empresa: RD Station (provedor de e-mail
            marketing). Endereço: Rodovia Virgílio Várzea, S/N, Floripa Office
            anexo ao Floripa Shopping. Saco Grande, Florianópolis/Santa
            Catarina, CEP: 88032-000. E-mail: dpo@rdstation.com
          </li>
        </ul>
      </section>

      <section>
        <h2>5. Direitos</h2>
        <p>
          As pessoas singulares de quem tratamos dados na{' '}
          <span className="dattaid">DattaID</span> reconheceram os seguintes
          direitos:
        </p>

        <ul>
          <li>Direito de acesso;</li>

          <li> Direito de retificação;</li>

          <li>Direito de exclusão;</li>

          <li>Direito de confirmação sobre a existência de tratamento;</li>

          <li> Direito de solicitar o bloqueio ou eliminação;</li>

          <li>Direito à informação das entidades públicas ou privadas;</li>

          <li>Direito de restringir o processamento;</li>

          <li>Direito à revisão de decisão automatizada;</li>

          <li>Direito de oposição a um tratamento;</li>

          <li>
            Direito à explicação da lógica por trás da coleta dos seus dados;
          </li>

          <li>
            Direito à informação sobre a possibilidade de não fornecer
            consentimento e sobre as consequências da negativa;
          </li>

          <li>Direito de retirar o seu consentimento.</li>
        </ul>

        <p>
          Os direitos acima podem ser exercidos enviando uma solicitação por
          escrito à <span className="dattaid">DattaID</span> para o endereço de
          correspondência indicado no cabeçalho ou enviando um e-mail para
          daniel@vsdi.com.br.
        </p>

        <p>
          Caso não se obtenha uma resposta satisfatória no exercício dos
          direitos é possível apresentar uma reclamação junto da Agência
          Nacional de Proteção de Dados, através dos formulários ou outros
          canais.
        </p>

        <p>
          O <span className="dattaid">DattaID</span> responderá ao interessado
          sobre o exercício do direito solicitado no prazo de um mês após o
          recebimento do pedido. Este prazo pode ser prorrogado por mais dois
          meses, se necessário, tendo em conta a complexidade e o número
          recebido. O <span className="dattaid">DattaID</span> informará o
          interessado de tais prorrogações no prazo de um mês após o recebimento
          do pedido, indicando as razões do atraso.
        </p>
      </section>

      <section>
        <h2>6. Processamento de dados como empresa responsável</h2>

        <p>
          <span className="dattaid">DattaID</span>, como empresa responsável
          pelos dados, tratará os dados pessoais dos usuários ou signatários
          fornecidos por seus clientes para a prestação dos serviços de acordo
          com o pedido recebido e os manterá apenas pelo o tempo necessário para
          cumprir as obrigações legais e contratuais com seus clientes. No caso
          do serviço de assinatura, os dados processados podem incluir o nome
          completo, número de identificação oficial (CPF), documentos de
          identificação (CPF, Identidade, CNH, etc) assinatura manuscrita,
          endereço de e-mail, número de telefone celular, data de nascimento,
          endereço IP, Selfie (foto para checagem de Biometria Facial em API de
          terceiros), plataforma tecnológica caso esteja integrado e dados de
          geolocalização que permitirão no seu conjunto comprovar a identidade
          do signatário, a hora e o local a partir do qual um documento foi
          assinado.
        </p>

        <p>
          Os dados tratados através da ordem recebida em nenhum caso serão
          utilizados para outra finalidade que não a prestação do serviço de
          assinatura ou cedidos a terceiros, exceto à autoridade administrativa
          ou judicial que os solicite e nos casos em que exista uma obrigação
          legal.
        </p>
      </section>
    </div>
  );
}
