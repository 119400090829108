import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';

import { Language } from '../../types/Language';

import COLORS from '../../utils/colors';
import { i18nToFlag } from '../../utils/i18nToSomething';

const useStyles = makeStyles({
  iconButton: {
    borderRadius: '0.5rem',
    padding: 0,
    margin: '0.875rem 0 1.5rem 0',

    '&:hover': {
      filter: 'brightness(95%)',
      background: 'none',
    },
  },

  menuItem: {
    padding: '0.5rem',

    '&:hover': {
      background: COLORS.background,
      transition: '0.3s',
    },
  },
});

const flagSize = 24;
const flagsItems = [
  { country: 'BR', language: 'pt-BR' },
  { country: 'US', language: 'en-US' },
];

export default function ChangeLanguage() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLSpanElement) | null
  >(null);

  const { i18n } = useTranslation();
  const [chosenFlag, setChosenFlag] = useState(
    i18nToFlag(i18n.language as Language),
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleChangeLanguage = (value: Language) => {
    i18n.changeLanguage(value);

    const flag = i18nToFlag(value);
    setChosenFlag(flag);

    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        component="span"
        className={classes.iconButton}
      >
        <Flag country={chosenFlag} size={flagSize} />
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {flagsItems.map(item => (
          <MenuItem
            key={item.country}
            onClick={() => {
              handleChangeLanguage(item.language as Language);
            }}
            className={classes.menuItem}
          >
            <Flag country={item.country} size={flagSize} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
