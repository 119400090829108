import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import AudioTrackIcon from '@material-ui/icons/Audiotrack';
import ImageIcon from '@material-ui/icons/Image';
import VideoCamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import { useTranslation } from 'react-i18next';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  buttonContainer: {
    fontSize: '0.875rem',
    maxWidth: '12.5%',

    '& button': {
      padding: 0,
      color: COLORS.blue,
      background: 'none',
      border: 0,
      fontSize: '0.875rem',
      lineHeight: '1.75',

      '&:hover': {
        transition: 'all 0.3s',
        textDecoration: 'underline',
      },
    },

    '& span': {
      color: COLORS.gray2,
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.75rem 1.75rem 0 1.75rem',

    '& svg': {
      fontSize: '1.625rem',
      color: COLORS.gray2,
      cursor: 'pointer',
    },
  },

  contentContainer: {
    width: '33vw',
    padding: '2rem 4rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.5rem',
  },

  sectionContainer: {
    display: 'flex',
    columnGap: '1rem',
    alignItems: 'center',

    '& svg': {
      fontSize: '2.5rem',
    },

    '& div': {
      maxWidth: '20rem',
      fontSize: '1rem',
    },
  },
});

export default function ModalFileTypesAvailableInVault() {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        <button type="button" onClick={handleOpen}>
          {t('components.modalFileTypesAvailableInVault.button')}
        </button>

        <span>
          {t('components.modalFileTypesAvailableInVault.description')}
        </span>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle className={classes.header} disableTypography>
          <h1>{t('components.modalFileTypesAvailableInVault.title')}</h1>

          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent className={classes.contentContainer}>
          <section className={classes.sectionContainer}>
            <TextFieldsIcon />

            <div>.txt, .html, .json</div>
          </section>

          <section className={classes.sectionContainer}>
            <AudioTrackIcon />

            <div>.aac, .wav, .ogg, .mp3, .m4a</div>
          </section>

          <section className={classes.sectionContainer}>
            <ImageIcon />

            <div>.bmp, .gif, .jpeg, .jpg, .png, .webp, .svg</div>
          </section>

          <section className={classes.sectionContainer}>
            <VideoCamIcon />

            <div>.mov (quicktime), .ogg e .ogx, .webm, .mp4</div>
          </section>

          <section className={classes.sectionContainer}>
            <DescriptionIcon />

            <div>.pdf</div>
          </section>
        </DialogContent>
      </Dialog>
    </>
  );
}
