import axios from 'axios';
import { cache } from 'swr';

const api = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/api` });

api.interceptors.request.use(config => {
  const localConfig = config;
  const token = localStorage.getItem('@dattaid:token');

  if (token) {
    localConfig.headers['x-access-token'] = `${JSON.parse(token)}`;
  }

  return localConfig;
});

api.interceptors.response.use(
  response => response,

  error => {
    if (
      error.response &&
      error.response.status === 500 &&
      error.response.data.message === 'Failed to authenticate expired token'
    ) {
      localStorage.clear();
      cache.clear();
      localStorage.setItem('@dattaid:session', 'false');
      window.location.reload();
    }

    return Promise.reject(error);
  },
);

export default api;
