import React, { createContext, useState, useEffect } from 'react';
import io, { Socket } from 'socket.io-client';

interface ContextProps {
  socket: Socket;
  socketId: string;
}

interface Props {
  children: React.ReactNode;
}

const SocketContext = createContext<ContextProps>({} as ContextProps);
const socket = io(process.env.REACT_APP_API_URL as string);

const SocketProvider = ({ children }: Props) => {
  const [socketId, setSocketId] = useState('');

  const getSocketId = () => {
    setSocketId(socket.id);
  };

  useEffect(() => {
    socket.on('connect', getSocketId);

    return () => {
      socket.off('connect', getSocketId);
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, socketId }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
