/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
// import SortIcon from '@material-ui/icons/Sort';
import WebViewer from '@pdftron/pdfjs-express';
import { mutate } from 'swr';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';

import PersonSigneeTile from '../../PersonSigneeTile';
import CostCenterSelect from '../../CostCenterSelect';

import useAuthContext from '../../../hooks/useAuthContext';
import useAlert from '../../../hooks/useAlertContext';
import useCostCenters from '../../../hooks/useCostCenters';
import { useAxios } from '../../../hooks/useAxios';

import { Signee } from '../../../types/Signee';
import { Language } from '../../../types/Language';
import { Credit } from '../../../types/Credit';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import { i18nToWebview } from '../../../utils/i18nToSomething';
import generatePassword from '../../../utils/generatePassword';
import linksForSWR from '../../../utils/linksForSWR';

import iconStampWhite from '../../../images/icon-stamp-white.svg';
import iconStampBlack from '../../../images/little-digital.svg';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    padding: '4.5rem 2.75rem 4.5rem 2.25rem',
    background: COLORS.background,
  },

  buttonClose: {
    position: 'absolute',
    top: '1.5rem',
    right: '2.75rem',
    color: COLORS.gray2,
    fontSize: '1.625rem',
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.black,
      transition: '0.3s',
      backgroundColor: 'transparent',
    },
  },

  containerButtonSend: {
    position: 'absolute',
    bottom: '1.5rem',
    right: '2.75rem',
  },

  buttonSend: {
    padding: 0,
    backgroundColor: COLORS.gray2,
    color: COLORS.background,
    borderRadius: '0.5rem',
    textTransform: 'none',
    height: '2.375rem',
    width: '5.5rem',

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',
    },
  },

  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: COLORS.gray2,
    maxWidth: '13.125rem',
    marginTop: '1.5rem',
  },

  addEmailInput: {
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.lightGray1}`,
    borderRadius: '0.5rem',
    height: '3rem',
    width: '20.25rem',
    marginTop: '1.25rem',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 0.375rem 0 1rem',

    '& input': {
      color: COLORS.mediumGray2,
      fontWeight: 500,
      fontSize: '0.875rem',
    },

    '& .Mui-focused': {
      '& input': {
        color: COLORS.gray2,
      },
    },
  },

  containerSwitch: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5rem 0',
    borderBottom: `1px solid ${COLORS.mediumGray1}`,

    '& div': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '1rem',

      '& svg': {
        fontSize: '1.5rem',
        color: COLORS.gray2,
      },

      '& span': {
        color: COLORS.gray2,
        fontWeight: 'bold',
        fontSize: '0.875rem',
        inlineSize: '7rem',
        overflowWrap: 'break-word',

        '&.true': {
          color: COLORS.greenButton,
        },

        '&.false': {
          color: COLORS.lightRed,
        },
      },
    },
  },

  buttonAdd: {
    padding: 0,
    backgroundColor: COLORS.gray2,
    color: COLORS.background,
    borderRadius: '0.5rem',
    textTransform: 'none',
    height: '1.75rem',
    width: '3rem',

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',
    },
  },

  textListEmpty: {
    textAlign: 'center',
    color: COLORS.mediumGray1,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    marginTop: '5.25rem',
  },

  titleListSignees: {
    margin: '1.25rem 0 1.5rem 0',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    color: COLORS.gray2,
  },

  listSignees: {
    maxHeight: '36.5vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '1.5rem',

    '@media (max-height: 65.625rem)': {
      maxHeight: '33vh',
    },

    '@media (max-height: 62.5rem)': {
      maxHeight: '30.125vh',
    },

    '@media (max-height: 59.375rem)': {
      maxHeight: '29.75vh',
    },

    '@media (max-height: 56.25rem)': {
      maxHeight: '25vh',
    },

    '@media (max-height: 53.125rem)': {
      maxHeight: '22vh',
    },

    '@media (max-height: 50rem)': {
      maxHeight: '18vh',
    },
  },

  containerWebview: {
    height: '77.875vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },

  webview: {
    height: '100%',
    width: '100%',
  },

  loading: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const AntSwitch = withStyles({
  root: {
    width: '3.25rem',
    height: '1.5rem',
    padding: 0,
    display: 'flex',
  },

  switchBase: {
    color: COLORS.black,
    borderColor: COLORS.black,
    padding: '0.25rem',

    '&$checked': {
      transform: 'translateX(1.75rem)',
      color: COLORS.background,

      '& + $track': {
        opacity: 1,
        backgroundColor: COLORS.black,
        borderColor: COLORS.black,
      },
    },
  },

  thumb: {
    width: '1rem',
    height: '1rem',
    boxShadow: 'none',
  },

  track: {
    border: `1px solid ${COLORS.black}`,
    borderRadius: '1.25rem',
    opacity: 1,
    backgroundColor: COLORS.background,
  },

  checked: {},
})(Switch);

type AnnotationType = 'initials' | 'signature';

interface Props {
  open: boolean;
  handleClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  file: File | null;
}

export default function WhoWillSign({ open, handleClose, file }: Props) {
  const viewer = useRef(null);
  const [instanceAux, setInstanceAux] = useState<any>(null);
  const [docViewerAux, setDocViewerAux] = useState<any>(null);
  const [annotationManagerAux, setAnnotationManagerAux] = useState<any>(null);
  const [signees, setSignees] = useState<Signee[]>([]);
  const [person, setPerson] = useState({
    email: '',
    order: 1,
    signatureLocations: [],
    initialsLocations: [],
  });

  const [check, setCheck] = useState(false);
  const [faceMatch, setFaceMatch] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isAddingSignature, setIsAddingSignature] = useState(false);
  const [isAddingInitials, setIsAddingInitials] = useState(false);

  const [isOrderingSignees, setIsOrderingSignees] = useState(false);
  const [maxNumberToChooseOrder, setMaxNumberToChooseOrder] = useState(0);

  const [loadingFile, setLoadingFile] = useState(true);

  const { user } = useAuthContext();
  const { costCenters } = useCostCenters();

  const [chosenCostCenter, setChosenCostCenter] = useState(-1);

  const { data: currentCredits }: { data: Credit[] } = useAxios(
    linksForSWR.client.credits(
      chosenCostCenter !== -1 ? costCenters.data[chosenCostCenter]._id : '',
    ),
  );

  const elementsToDisable = [
    'menuButton',
    'toolsHeader',
    'annotationStyleEditButton',
    'linkButton',
  ];
  const featuresToDisable = ['NotesPanel', 'Ribbons'];

  const { showAlert } = useAlert();

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const localHandleClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      setSignees([]);
      setCheck(false);
      setDisabled(true);
      setFaceMatch(false);
      setIsOrderingSignees(false);
      setMaxNumberToChooseOrder(0);
      handleClose(e);
    }, 200);
  };

  const handleMaxNumberToChooseOrder = (
    currentSignees: Signee[],
    valueChosen: number | null,
  ) => {
    if (maxNumberToChooseOrder <= 1) {
      setMaxNumberToChooseOrder(maxNumberToChooseOrder + 1);
      return;
    }

    if (valueChosen && valueChosen === maxNumberToChooseOrder) {
      setMaxNumberToChooseOrder(
        Math.min(signees.length, maxNumberToChooseOrder + 1),
      );
      return;
    }
    const maxNumber = Math.max(...currentSignees.map(element => element.order));

    setMaxNumberToChooseOrder(Math.min(currentSignees.length, maxNumber + 1));
  };

  const handleErrorSendDocumentToSign = (error: any) => {
    if (
      error.message === 'withoutSignees' ||
      error.message === 'withoutSignaturesAndInitialsLocation' ||
      error.message === 'signeesNotOrderedCorrectly'
    ) {
      showAlert(error.message, 'error', null);
      return;
    }

    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === 'insufficient funds'
    ) {
      showAlert('insufficientFunds', 'error', null);
      return;
    }

    showAlert('documentSentError', 'error', null);
  };

  const handleSend = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setIsSending(true);
    try {
      if (!signees.length) {
        throw new Error('withoutSignees');
      }

      const notFoundSignatureAndInitialsLocation = signees.find(
        element =>
          !element.signatureLocations.length &&
          !element.initialsLocations.length,
      );

      if (notFoundSignatureAndInitialsLocation) {
        throw new Error('withoutSignaturesAndInitialsLocation');
      }

      const signeesOrdered = signees.sort((a, b) => a.order - b.order);
      const fakeSigneesOrdered = [{ order: 0 }, ...signeesOrdered];
      for (let i = 0; i < fakeSigneesOrdered.length - 1; i += 1) {
        if (fakeSigneesOrdered[i + 1].order - fakeSigneesOrdered[i].order > 1) {
          throw new Error('signeesNotOrderedCorrectly');
        }
      }

      const xfdf = await annotationManagerAux.exportAnnotations({
        links: false,
      });

      const userSignatures = isOrderingSignees
        ? signeesOrdered.map(element => ({
            email: element.email,
            order: element.order,
            selfie: false,
          }))
        : signees.map(element => ({
            email: element.email,
            selfie: false,
          }));

      const data = new FormData();

      data.append('file', file || '');
      data.append('usersignatures', JSON.stringify(userSignatures));
      data.append('xml', xfdf);
      data.append('facematch', String(faceMatch));
      data.append('isOrderingSignees', String(isOrderingSignees));
      data.append('totalpagepdf', docViewerAux.getPageCount());
      data.append(
        'costcenter_id',
        chosenCostCenter !== -1
          ? costCenters.data[chosenCostCenter]._id
          : 'null',
      );

      const response = await api.post('documents', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      localHandleClose(event);
      mutate(linksForSWR.client.documents.index());
      mutate(linksForSWR.client.home.documents());
      mutate(linksForSWR.client.home.documents(2));
      mutate(linksForSWR.client.home.dashboard());

      showAlert('documentSentSuccessfully', 'success', response.data._id);
    } catch (error) {
      handleErrorSendDocumentToSign(error);
    }

    setIsSending(false);
  };

  const handleClearField = () => {
    setPerson({
      email: '',
      order: 1,
      signatureLocations: [],
      initialsLocations: [],
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setPerson({ ...person, [name]: value });
  };

  // Credits: https://pdfjs.community/t/get-current-viewer-coordinates/569/5
  const getCenter = () => {
    const viewerElement =
      instanceAux.Core.documentViewer.getScrollViewElement();

    const top = viewerElement.scrollTop + viewerElement.offsetTop;
    const bottom = top + viewerElement.offsetHeight;
    const left = viewerElement.scrollLeft + viewerElement.offsetLeft;
    const right = left + viewerElement.offsetWidth;

    const windowCoordinateCenter = {
      x: (left + right) / 2,
      y: (top + bottom) / 2,
    };

    const displayMode = instanceAux.Core.documentViewer
      .getDisplayModeManager()
      .getDisplayMode();

    const pageNumber = instanceAux.Core.documentViewer.getCurrentPage();

    const pageCoordinates = displayMode.windowToPage(
      windowCoordinateCenter,
      pageNumber,
    );

    return pageCoordinates;
  };

  const handleSign = async (email: string) => {
    setIsAddingSignature(true);
    const currentPage = await docViewerAux.getCurrentPage();
    const centerPageCoordinates = getCenter();

    const stampAnnotation = new instanceAux.Core.Annotations.StampAnnotation();
    stampAnnotation.PageNumber = currentPage;

    const id = generatePassword();
    stampAnnotation.Subject = `${email};${id};signature`;
    const name = email.split('@')[0];

    const divContainer = document.createElement('div');

    divContainer.style.cssText = `
      background-color: #222;
      display: flex;
      align-items: center;
      border-radius: 1.5rem;
      width: max-content;
      gap: 0.375rem;
      padding: 1.25rem 1rem;
      font-family: 'Noto Sans', sans-serif;
    `;

    divContainer.innerHTML = `
      <span style="color: white; font-size: 1.375rem;">
          ${name}
      </span>
      <img src="${iconStampWhite}" style="width: 1.675rem;"/>
    `;

    document.body.appendChild(divContainer);

    stampAnnotation.Width = divContainer.clientWidth / 3;
    stampAnnotation.Height = divContainer.clientHeight / 3;

    stampAnnotation.X = centerPageCoordinates.x - stampAnnotation.Width / 2;
    stampAnnotation.Y = centerPageCoordinates.y - stampAnnotation.Height / 2;

    try {
      const canvas = await html2canvas(divContainer, {
        backgroundColor: 'null',
        logging: false,
      });
      const dataURL = canvas.toDataURL();
      stampAnnotation.setImageData(dataURL);
    } catch (err) {
      console.log(err);
    }

    document.body.removeChild(divContainer);

    instanceAux.Core.annotationManager.addAnnotation(stampAnnotation);
    instanceAux.Core.annotationManager.redrawAnnotation(stampAnnotation);

    instanceAux.Core.annotationManager.deselectAllAnnotations();
    instanceAux.Core.annotationManager.selectAnnotation(stampAnnotation);

    const newSignees = [...signees];
    const index = newSignees.findIndex(element => element.email === email);
    newSignees[index].signatureLocations.push({ id, page: currentPage });
    setSignees(newSignees);

    setIsAddingSignature(false);
  };

  const handleRemovePerson = (email: string) => {
    const allAnnotations = annotationManagerAux.getAnnotationsList();
    const annotationsToRemove = allAnnotations.filter((annotation: any) => {
      const emailOnSubject = annotation.Subject.split(';')[0];
      return emailOnSubject === email;
    });
    annotationManagerAux.deleteAnnotations(annotationsToRemove);

    let orderOfRemovedElement = 0;
    let newSignees = signees.filter(element => {
      if (element.email === email) {
        orderOfRemovedElement = element.order;
      }
      return element.email !== email;
    });

    const maxNumber = Math.min(newSignees.length, maxNumberToChooseOrder);

    setMaxNumberToChooseOrder(maxNumber);

    newSignees = newSignees.map(element => {
      if (element.order > orderOfRemovedElement) {
        return {
          ...element,
          order: element.order - 1,
        };
      }

      if (element.order > maxNumber) {
        return {
          ...element,
          order: maxNumber,
        };
      }
      return element;
    });

    setSignees(newSignees);

    if (email === user?.email) {
      setCheck(false);
    }
  };

  const handleRemoveSignature = (email: string, id: string) => {
    const newSignees = [...signees];

    const indexPerson = newSignees.findIndex(
      element => element.email === email,
    );
    if (indexPerson !== -1) {
      newSignees[indexPerson].signatureLocations = newSignees[
        indexPerson
      ].signatureLocations.filter(element => element.id !== id);

      setSignees(newSignees);

      const allAnnotations = annotationManagerAux.getAnnotationsList();
      const annotationsToRemove = allAnnotations.filter(
        (annotation: any) => annotation.Subject === `${email};${id};signature`,
      );
      annotationManagerAux.deleteAnnotations(annotationsToRemove);
    }
  };

  // const getLastYOfInitialsAnnotation = () => {
  //   const currentPage = docViewerAux.getCurrentPage();

  //   const initialsAnnotations = annotationManagerAux
  //     .getAnnotationsList()
  //     .filter((annotation: any) => {
  //       const [, , type] = annotation.Subject.split(';');
  //       return annotation.PageNumber === currentPage && type === 'initials';
  //     });

  //   let lastY = 0;
  //   let height = 0;
  //   initialsAnnotations.forEach((annotation: any) => {
  //     if (annotation.Y > lastY) {
  //       lastY = annotation.Y;
  //       height = annotation.Height;
  //     }
  //   });

  //   return lastY + height;
  // };

  const handleInitials = async (email: string) => {
    setIsAddingInitials(true);

    const currentPage = docViewerAux.getCurrentPage();
    const centerPageCoordinates = getCenter();

    const stampAnnotation = new instanceAux.Core.Annotations.StampAnnotation();
    stampAnnotation.PageNumber = currentPage;

    const id = generatePassword();
    stampAnnotation.Subject = `${email};${id};initials`;
    const name = email.split('@')[0];

    const divContainer = document.createElement('div');

    divContainer.style.cssText = `
      backgroundColor: white;
      border: 0.125rem solid #222;
      display: flex;
      align-items: center;
      border-radius: 1.5rem;
      width: max-content;
      gap: 0.375rem;
      padding: 1rem;
      font-family: 'Noto Sans', sans-serif;
    `;

    divContainer.innerHTML = `
      <span style="color: #222; font-size: 1.375rem;">
        ${name}
      </span>
      <img src="${iconStampBlack}" style="width: 1.675rem;"/>
    `;

    document.body.appendChild(divContainer);

    stampAnnotation.Width = divContainer.clientWidth / 3;
    stampAnnotation.Height = divContainer.clientHeight / 3;

    stampAnnotation.X = centerPageCoordinates.x - stampAnnotation.Width / 2;
    stampAnnotation.Y = centerPageCoordinates.y - stampAnnotation.Height / 2;

    try {
      const canvas = await html2canvas(divContainer, {
        backgroundColor: 'null',
        logging: false,
      });
      const dataURL = canvas.toDataURL();
      stampAnnotation.setImageData(dataURL);
    } catch (err) {
      console.log(err);
    }

    document.body.removeChild(divContainer);

    instanceAux.Core.annotationManager.addAnnotation(stampAnnotation);
    instanceAux.Core.annotationManager.redrawAnnotation(stampAnnotation);

    instanceAux.Core.annotationManager.deselectAllAnnotations();
    instanceAux.Core.annotationManager.selectAnnotation(stampAnnotation);

    const newSignees = [...signees];
    const index = newSignees.findIndex(element => element.email === email);
    newSignees[index].initialsLocations.push({ id, page: currentPage });
    setSignees(newSignees);

    setIsAddingInitials(false);
  };

  const handleRemoveInitials = (email: string, id: string) => {
    const newSignees = [...signees];

    const indexPerson = newSignees.findIndex(
      element => element.email === email,
    );

    if (indexPerson !== -1) {
      newSignees[indexPerson].initialsLocations = newSignees[
        indexPerson
      ].initialsLocations.filter(element => element.id !== id);

      setSignees(newSignees);

      const allAnnotations = annotationManagerAux.getAnnotationsList();
      const annotationsToRemove = allAnnotations.filter(
        (annotation: any) => annotation.Subject === `${email};${id};initials`,
      );
      annotationManagerAux.deleteAnnotations(annotationsToRemove);
    }
  };

  const handleRemoveAnnotationManually = (annotations: any[]) => {
    annotations.forEach(annotation => {
      const subject = annotation.Subject;

      const allAnnotations = annotationManagerAux.getAnnotationsList();
      const found = allAnnotations.find(
        (annotationEl: any) => annotationEl.Subject === subject,
      );

      if (found) {
        return;
      }

      const [email, id, annotationType] = subject.split(';');

      const typeCurrentHandleRemove = {
        signature() {
          handleRemoveSignature(email, id);
        },
        initials() {
          handleRemoveInitials(email, id);
        },
      };

      const currentHandleRemove =
        typeCurrentHandleRemove[annotationType as AnnotationType];

      if (currentHandleRemove) {
        currentHandleRemove();
      }
    });
  };

  const handleAddSignee = async () => {
    if (!person.email) {
      return;
    }

    // Credits: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const regexEmail =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (!regexEmail.test(person.email)) {
      showAlert('invalidEmail', 'error', null);
      return;
    }

    const signeeAlreadyExists =
      signees.findIndex(element => element.email === person.email) !== -1;
    if (signeeAlreadyExists) {
      showAlert('signeeDuplicated', 'error', null);
      return;
    }

    try {
      await api.get(`/vault/user/${person.email}`);
    } catch (error) {
      showAlert('noDattaidInSignDocument', 'error', null);
    }

    const newSignees = [...signees, person];
    setSignees(newSignees);

    if (person.email === user?.email) {
      setCheck(true);
    }

    handleMaxNumberToChooseOrder(newSignees, null);

    handleClearField();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.code === 'Enter') {
      handleAddSignee();
    }
  };

  const handleCheck = () => {
    const currentCheck = check;
    setCheck(!check);

    if (currentCheck) {
      handleRemovePerson(user ? user.email : '');
      return;
    }

    const newSignees = [
      ...signees,
      {
        email: user?.email,
        order: 1,
        signatureLocations: [],
        initialsLocations: [],
      } as Signee,
    ];

    setSignees(newSignees);

    handleMaxNumberToChooseOrder(newSignees, null);
  };

  const handleFaceMatch = () => {
    if (!currentCredits) return;

    const creditsFound = currentCredits.find(
      credit => credit.name === 'facialBiometrics',
    );

    if (!creditsFound) return;

    if (creditsFound.quantity < 1) {
      showAlert('noFaceMatchCreditsError', 'error', null);
      return;
    }

    setFaceMatch(!faceMatch);
  };

  const handleChangeOrder = (email: string, value: number) => {
    const newSignees = [...signees];

    const indexSignee = newSignees.findIndex(
      element => element.email === email,
    );
    if (indexSignee !== -1) {
      newSignees[indexSignee].order = value;
    }

    setSignees(newSignees);
    handleMaxNumberToChooseOrder(newSignees, value);
  };

  const addInitialOnAllPages = (currentSignee: Signee) => {
    const initialsAnnotation = annotationManagerAux
      .getAnnotationsList()
      .find((annotation: any) => {
        const [, id] = annotation.Subject.split(';');
        return id === currentSignee.initialsLocations[0].id;
      });

    if (!initialsAnnotation) return;

    const indexSignee = signees.findIndex(
      signee => signee.email === currentSignee.email,
    );

    if (indexSignee === -1) return;

    const pageCount = instanceAux.Core.documentViewer.getPageCount();

    const pages = Array.from({ length: pageCount }, (value, key) => key + 1);
    const pagesThatHaveInitials = signees[indexSignee].initialsLocations.map(
      location => location.page,
    );

    const pagesToAddInitials = pages.filter(
      page => !pagesThatHaveInitials.includes(page),
    );

    if (!pagesToAddInitials.length) return;

    const [email] = initialsAnnotation.Subject.split(';');
    const newSignees = [...signees];

    pagesToAddInitials.forEach(page => {
      const newId = generatePassword();
      const annotationCopy =
        annotationManagerAux.getAnnotationCopy(initialsAnnotation);

      annotationCopy.PageNumber = page;
      annotationCopy.Subject = `${email};${newId};initials`;

      annotationManagerAux.addAnnotation(annotationCopy);
      annotationManagerAux.redrawAnnotation(annotationCopy);

      newSignees[indexSignee].initialsLocations.push({ id: newId, page });
    });

    setSignees(newSignees);

    showAlert('initialAllPages', 'success', null);
  };

  const handleChangeAnnotationPage = (annotationsModified: any[]) => {
    const newSignees = [...signees];

    annotationsModified.forEach(annotationModified => {
      const [email, id, type] = annotationModified.Subject.split(';');

      const currentSigneeIndex = newSignees.findIndex(
        signee => signee.email === email,
      );

      if (currentSigneeIndex === -1) return;

      const fieldType =
        type === 'initials' ? 'initialsLocations' : 'signatureLocations';

      const annotationIndex = newSignees[currentSigneeIndex][
        fieldType
      ].findIndex(annotation => annotation.id === id);

      if (annotationIndex !== -1) return;

      newSignees[currentSigneeIndex][fieldType].push({
        id,
        page: annotationModified.PageNumber,
      });
    });

    setSignees(newSignees);
  };

  // const handlePositionOfInitialsAnnotation = (annotations: any[]) => {
  //   const currentPage = docViewerAux.getCurrentPage();
  //   const minX =
  //     docViewerAux.getPageWidth(currentPage) *
  //     PERCENTAGE_OF_AVAILABLE_DOCUMENT_SIZE;

  //   annotations.forEach(annotation => {
  //     const [, , type] = annotation.Subject.split(';');

  //     if (annotation.X < minX && type === 'initials') {
  //       annotation.X = minX;
  //       annotationManagerAux.redrawAnnotation(annotation);
  //     }
  //   });
  // };

  const pageCompleteCallback = () => {
    setLoadingFile(false);
  };

  const handleWebview = () => {
    WebViewer(
      {
        path: '/webviewer/lib',
        licenseKey: process.env.REACT_APP_PDF_EXPRESS_JS_KEY_VIEWER,
      },
      viewer.current,
    )
      .then(async (instance: any) => {
        instance.UI.setLanguage(i18nToWebview(i18n.language as Language));
        const { documentViewer, annotationManager } = instance.Core;

        setInstanceAux(instance);
        setDocViewerAux(documentViewer);
        setAnnotationManagerAux(annotationManager);

        instance.UI.loadDocument(file, {
          filename: file?.name,
          extension: 'pdf',
        });
        instance.UI.setFitMode(instance.UI.FitMode.FitWidth);

        const { Feature } = instance.UI;
        instance.UI.disableElements(elementsToDisable);
        instance.UI.disableFeatures(
          featuresToDisable.map(feature => Feature[feature]),
        );

        setDisabled(false);

        documentViewer.addEventListener('pageComplete', pageCompleteCallback);

        annotationManager.setRotationOptions({
          isEnabled: false,
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const annotationChangedCallback = (
    annotations: any,
    action: 'delete' | 'modify',
  ) => {
    if (action === 'delete') {
      handleRemoveAnnotationManually(annotations);
    }

    if (action === 'modify') {
      handleChangeAnnotationPage(annotations);
      // handlePositionOfInitialsAnnotation(annotations);
    }
  };

  useEffect(() => {
    // Credits: https://stackoverflow.com/questions/16149431/make-function-wait-until-element-exists/35211286
    if (open) {
      const observer = new MutationObserver((mutations, me) => {
        if (viewer.current) {
          handleWebview();
          me.disconnect();
        }
      });

      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      if (instanceAux) {
        instanceAux.Core.documentViewer.removeEventListener(
          'pageComplete',
          pageCompleteCallback,
        );
      }
    };
  }, [open]);

  useEffect(() => {
    if (annotationManagerAux) {
      annotationManagerAux.addEventListener(
        'annotationChanged',
        annotationChangedCallback,
      );
    }

    return () => {
      if (annotationManagerAux) {
        annotationManagerAux.removeEventListener(
          'annotationChanged',
          annotationChangedCallback,
        );
      }
    };
  }, [signees]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          localHandleClose(event as React.MouseEvent<HTMLButtonElement>);
        }
      }}
      maxWidth="xl"
      fullWidth
    >
      <DialogContent style={{ padding: '0' }}>
        <div className={classes.content}>
          <IconButton
            className={classes.buttonClose}
            onClick={localHandleClose}
          >
            <CloseIcon />
          </IconButton>

          <div>
            <div style={{ paddingRight: '2rem' }}>
              <CostCenterSelect
                chosenCostCenter={chosenCostCenter}
                setChosenCostCenter={setChosenCostCenter}
              />

              <h1 className={classes.title}>
                {t('components.modalUploadToSign.sign.title')}
              </h1>

              <TextField
                placeholder={t('components.modalUploadToSign.sign.email')}
                name="email"
                value={person.email}
                onChange={handleChange}
                className={classes.addEmailInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Button
                        className={classes.buttonAdd}
                        onClick={handleAddSignee}
                      >
                        {t('components.modalUploadToSign.sign.buttonAdd')}
                      </Button>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onKeyDown={handleKeyDown}
              />

              <div className={classes.containerSwitch}>
                <div>
                  <BorderColorOutlinedIcon />

                  <span>
                    {t('components.modalUploadToSign.listMembers.sign')}
                  </span>

                  <span className={`${check}`}>
                    {check
                      ? t('components.modalUploadToSign.listMembers.yes')
                      : t('components.modalUploadToSign.listMembers.no')}
                  </span>
                </div>

                <AntSwitch
                  disabled={disabled}
                  checked={check}
                  onChange={handleCheck}
                />
              </div>

              <div className={classes.containerSwitch} style={{ margin: 0 }}>
                <div>
                  <FaceOutlinedIcon />

                  <span>
                    {t('components.modalUploadToSign.listMembers.faceMatch')}
                  </span>

                  <span className={`${faceMatch}`}>
                    {faceMatch
                      ? t('components.modalUploadToSign.listMembers.yes')
                      : t('components.modalUploadToSign.listMembers.no')}
                  </span>
                </div>

                <AntSwitch
                  disabled={disabled}
                  checked={faceMatch}
                  onChange={handleFaceMatch}
                />
              </div>

              {/* <div
                className={classes.containerSwitch}
                style={{ border: 'none', margin: 0 }}
              >
                <div>
                  <SortIcon />

                  <span style={{ maxWidth: '10rem' }}>
                    {t('components.modalUploadToSign.listMembers.sort')}
                  </span>

                  <span className={`${isOrderingSignees}`}>
                    {isOrderingSignees
                      ? t('components.modalUploadToSign.listMembers.yes')
                      : t('components.modalUploadToSign.listMembers.no')}
                  </span>
                </div>

                <AntSwitch
                  disabled={disabled}
                  checked={isOrderingSignees}
                  onChange={() => {
                    setIsOrderingSignees(!isOrderingSignees)
                  }}
                />
              </div> */}
            </div>

            {!signees.length ? (
              <p className={classes.textListEmpty}>
                {t('components.modalUploadToSign.listMembers.empty')}
              </p>
            ) : (
              <div>
                <h2 className={classes.titleListSignees}>
                  {t('components.modalUploadToSign.listMembers.title')}
                </h2>

                <List className={classes.listSignees}>
                  {signees.map(s => (
                    <PersonSigneeTile
                      key={s.email}
                      signee={s}
                      handleRemovePerson={handleRemovePerson}
                      handleRemoveSignature={handleRemoveSignature}
                      handleSign={handleSign}
                      handleInitials={handleInitials}
                      handleRemoveInitials={handleRemoveInitials}
                      addInitialOnAllPages={addInitialOnAllPages}
                      canAddInitialOnAllPages={
                        Boolean(s.initialsLocations.length) &&
                        instanceAux.Core.documentViewer.getPageCount() > 1
                      }
                      loadingSignature={loadingFile || isAddingSignature}
                      loadingInitials={loadingFile || isAddingInitials}
                      isSortingSignees={isOrderingSignees}
                      maxNumberToChooseOrder={maxNumberToChooseOrder}
                      handleChangeOrder={handleChangeOrder}
                    />
                  ))}
                </List>
              </div>
            )}
          </div>

          <div className={classes.containerWebview}>
            {loadingFile && (
              <div className={classes.loading}>
                <CircularProgress style={{ color: COLORS.mediumGray5 }} />
              </div>
            )}

            <div className={classes.webview} ref={viewer} />
          </div>

          <div className={classes.containerButtonSend}>
            <Button
              onClick={handleSend}
              className={classes.buttonSend}
              disabled={isSending}
            >
              {isSending ? (
                <CircularProgress size={24} style={{ color: COLORS.white }} />
              ) : (
                t('components.modalUploadToSign.buttonSend')
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
