export default [
  'abacate',
  'abaixo',
  'abalar',
  'abater',
  'abduzir',
  'abelha',
  'aberto',
  'abismo',
  'abotoar',
  'abranger',
  'abreviar',
  'abrigar',
  'abrupto',
  'absinto',
  'absoluto',
  'absurdo',
  'abutre',
  'acabado',
  'acalmar',
  'acampar',
  'acanhar',
  'acaso',
  'aceitar',
  'acelerar',
  'acenar',
  'acervo',
  'acessar',
  'acetona',
  'achatar',
  'acidez',
  'acima',
  'acionado',
  'acirrar',
  'aclamar',
  'aclive',
  'acolhida',
  'acomodar',
  'acoplar',
  'acordar',
  'acumular',
  'acusador',
  'adaptar',
  'adega',
  'adentro',
  'adepto',
  'adequar',
  'aderente',
  'adesivo',
  'adeus',
  'adiante',
  'aditivo',
  'adjetivo',
  'adjunto',
  'admirar',
  'adorar',
  'adquirir',
  'adubo',
  'adverso',
  'advogado',
  'aeronave',
  'afastar',
  'aferir',
  'afetivo',
  'afinador',
  'afivelar',
  'aflito',
  'afluente',
  'afrontar',
  'agachar',
  'agarrar',
  'agasalho',
  'agenciar',
  'agilizar',
  'agiota',
  'agitado',
  'agora',
  'agradar',
  'agreste',
  'agrupar',
  'aguardar',
  'agulha',
  'ajoelhar',
  'ajudar',
  'ajustar',
  'alameda',
  'alarme',
  'alastrar',
  'alavanca',
  'albergue',
  'albino',
  'alcatra',
  'aldeia',
  'alecrim',
  'alegria',
  'alertar',
  'alface',
  'alfinete',
  'algum',
  'alheio',
  'aliar',
  'alicate',
  'alienar',
  'alinhar',
  'aliviar',
  'almofada',
  'alocar',
  'alpiste',
  'alterar',
  'altitude',
  'alucinar',
  'alugar',
  'aluno',
  'alusivo',
  'alvo',
  'amaciar',
  'amador',
  'amarelo',
  'amassar',
  'ambas',
  'ambiente',
  'ameixa',
  'amenizar',
  'amido',
  'amistoso',
  'amizade',
  'amolador',
  'amontoar',
  'amoroso',
  'amostra',
  'amparar',
  'ampliar',
  'ampola',
  'anagrama',
  'analisar',
  'anarquia',
  'anatomia',
  'andaime',
  'anel',
  'anexo',
  'angular',
  'animar',
  'anjo',
  'anomalia',
  'anotado',
  'ansioso',
  'anterior',
  'anuidade',
  'anunciar',
  'anzol',
  'apagador',
  'apalpar',
  'apanhado',
  'apego',
  'apelido',
  'apertada',
  'apesar',
  'apetite',
  'apito',
  'aplauso',
  'aplicada',
  'apoio',
  'apontar',
  'aposta',
  'aprendiz',
  'aprovar',
  'aquecer',
  'arame',
  'aranha',
  'arara',
  'arcada',
  'ardente',
  'areia',
  'arejar',
  'arenito',
  'aresta',
  'argiloso',
  'argola',
  'arma',
  'arquivo',
  'arraial',
  'arrebate',
  'arriscar',
  'arroba',
  'arrumar',
  'arsenal',
  'arterial',
  'artigo',
  'arvoredo',
  'asfaltar',
  'asilado',
  'aspirar',
  'assador',
  'assinar',
  'assoalho',
  'assunto',
  'astral',
  'atacado',
  'atadura',
  'atalho',
  'atarefar',
  'atear',
  'atender',
  'aterro',
  'ateu',
  'atingir',
  'atirador',
  'ativo',
  'atoleiro',
  'atracar',
  'atrevido',
  'atriz',
  'atual',
  'atum',
  'auditor',
  'aumentar',
  'aura',
  'aurora',
  'autismo',
  'autoria',
  'autuar',
  'avaliar',
  'avante',
  'avaria',
  'avental',
  'avesso',
  'aviador',
  'avisar',
  'avulso',
  'axila',
  'azarar',
  'azedo',
  'azeite',
  'azulejo',
  'babar',
  'babosa',
  'bacalhau',
  'bacharel',
  'bacia',
  'bagagem',
  'baiano',
  'bailar',
  'baioneta',
  'bairro',
  'baixista',
  'bajular',
  'baleia',
  'baliza',
  'balsa',
  'banal',
  'bandeira',
  'banho',
  'banir',
  'banquete',
  'barato',
  'barbado',
  'baronesa',
  'barraca',
  'barulho',
  'baseado',
  'bastante',
  'batata',
  'batedor',
  'batida',
  'batom',
  'batucar',
  'baunilha',
  'beber',
  'beijo',
  'beirada',
  'beisebol',
  'beldade',
  'beleza',
  'belga',
  'beliscar',
  'bendito',
  'bengala',
  'benzer',
  'berimbau',
  'berlinda',
  'berro',
  'besouro',
  'bexiga',
  'bezerro',
  'bico',
  'bicudo',
  'bienal',
  'bifocal',
  'bifurcar',
  'bigorna',
  'bilhete',
  'bimestre',
  'bimotor',
  'biologia',
  'biombo',
  'biosfera',
  'bipolar',
  'birrento',
  'biscoito',
  'bisneto',
  'bispo',
  'bissexto',
  'bitola',
  'bizarro',
  'blindado',
  'bloco',
  'bloquear',
  'boato',
  'bobagem',
  'bocado',
  'bocejo',
  'bochecha',
  'boicotar',
  'bolada',
  'boletim',
  'bolha',
  'bolo',
  'bombeiro',
  'bonde',
  'boneco',
  'bonita',
  'borbulha',
  'borda',
  'boreal',
  'borracha',
  'bovino',
  'boxeador',
  'branco',
  'brasa',
  'braveza',
  'breu',
  'briga',
  'brilho',
  'brincar',
  'broa',
  'brochura',
  'bronzear',
  'broto',
  'bruxo',
  'bucha',
  'budismo',
  'bufar',
  'bule',
  'buraco',
  'busca',
  'busto',
  'buzina',
  'cabana',
  'cabelo',
  'cabide',
  'cabo',
  'cabrito',
  'cacau',
  'cacetada',
  'cachorro',
  'cacique',
  'cadastro',
  'cadeado',
  'cafezal',
  'caiaque',
  'caipira',
  'caixote',
  'cajado',
  'caju',
  'calafrio',
  'calcular',
  'caldeira',
  'calibrar',
  'calmante',
  'calota',
  'camada',
  'cambista',
  'camisa',
  'camomila',
  'campanha',
  'camuflar',
  'canavial',
  'cancelar',
  'caneta',
  'canguru',
  'canhoto',
  'canivete',
  'canoa',
  'cansado',
  'cantar',
  'canudo',
  'capacho',
  'capela',
  'capinar',
  'capotar',
  'capricho',
  'captador',
  'capuz',
  'caracol',
  'carbono',
  'cardeal',
  'careca',
  'carimbar',
  'carneiro',
  'carpete',
  'carreira',
  'cartaz',
  'carvalho',
  'casaco',
  'casca',
  'casebre',
  'castelo',
  'casulo',
  'catarata',
  'cativar',
  'caule',
  'causador',
  'cautelar',
  'cavalo',
  'caverna',
  'cebola',
  'cedilha',
  'cegonha',
  'celebrar',
  'celular',
  'cenoura',
  'censo',
  'centeio',
  'cercar',
  'cerrado',
  'certeiro',
  'cerveja',
  'cetim',
  'cevada',
  'chacota',
  'chaleira',
  'chamado',
  'chapada',
  'charme',
  'chatice',
  'chave',
  'chefe',
  'chegada',
  'cheiro',
  'cheque',
  'chicote',
  'chifre',
  'chinelo',
  'chocalho',
  'chover',
  'chumbo',
  'chutar',
  'chuva',
  'cicatriz',
  'ciclone',
  'cidade',
  'cidreira',
  'ciente',
  'cigana',
  'cimento',
  'cinto',
  'cinza',
  'ciranda',
  'circuito',
  'cirurgia',
  'citar',
  'clareza',
  'clero',
  'clicar',
  'clone',
  'clube',
  'coado',
  'coagir',
  'cobaia',
  'cobertor',
  'cobrar',
  'cocada',
  'coelho',
  'coentro',
  'coeso',
  'cogumelo',
  'coibir',
  'coifa',
  'coiote',
  'colar',
  'coleira',
  'colher',
  'colidir',
  'colmeia',
  'colono',
  'coluna',
  'comando',
  'combinar',
  'comentar',
  'comitiva',
  'comover',
  'complexo',
  'comum',
  'concha',
  'condor',
  'conectar',
  'confuso',
  'congelar',
  'conhecer',
  'conjugar',
  'consumir',
  'contrato',
  'convite',
  'cooperar',
  'copeiro',
  'copiador',
  'copo',
  'coquetel',
  'coragem',
  'cordial',
  'corneta',
  'coronha',
  'corporal',
  'correio',
  'cortejo',
  'coruja',
  'corvo',
  'cosseno',
  'costela',
  'cotonete',
  'couro',
  'couve',
  'covil',
  'cozinha',
  'cratera',
  'cravo',
  'creche',
  'credor',
  'creme',
  'crer',
  'crespo',
  'criada',
  'criminal',
  'crioulo',
  'crise',
  'criticar',
  'crosta',
  'crua',
  'cruzeiro',
  'cubano',
  'cueca',
  'cuidado',
  'cujo',
  'culatra',
  'culminar',
  'culpar',
  'cultura',
  'cumprir',
  'cunhado',
  'cupido',
  'curativo',
  'curral',
  'cursar',
  'curto',
  'cuspir',
  'custear',
  'cutelo',
  'damasco',
  'datar',
  'debater',
  'debitar',
  'deboche',
  'debulhar',
  'decalque',
  'decimal',
  'declive',
  'decote',
  'decretar',
  'dedal',
  'dedicado',
  'deduzir',
  'defesa',
  'defumar',
  'degelo',
  'degrau',
  'degustar',
  'deitado',
  'deixar',
  'delator',
  'delegado',
  'delinear',
  'delonga',
  'demanda',
  'demitir',
  'demolido',
  'dentista',
  'depenado',
  'depilar',
  'depois',
  'depressa',
  'depurar',
  'deriva',
  'derramar',
  'desafio',
  'desbotar',
  'descanso',
  'desenho',
  'desfiado',
  'desgaste',
  'desigual',
  'deslize',
  'desmamar',
  'desova',
  'despesa',
  'destaque',
  'desviar',
  'detalhar',
  'detentor',
  'detonar',
  'detrito',
  'deusa',
  'dever',
  'devido',
  'devotado',
  'dezena',
  'diagrama',
  'dialeto',
  'didata',
  'difuso',
  'digitar',
  'dilatado',
  'diluente',
  'diminuir',
  'dinastia',
  'dinheiro',
  'diocese',
  'direto',
  'discreta',
  'disfarce',
  'disparo',
  'disquete',
  'dissipar',
  'distante',
  'ditador',
  'diurno',
  'diverso',
  'divisor',
  'divulgar',
  'dizer',
  'dobrador',
  'dolorido',
  'domador',
  'dominado',
  'donativo',
  'donzela',
  'dormente',
  'dorsal',
  'dosagem',
  'dourado',
  'doutor',
  'drenagem',
  'drible',
  'drogaria',
  'duelar',
  'duende',
  'dueto',
  'duplo',
  'duquesa',
  'durante',
  'duvidoso',
  'eclodir',
  'ecoar',
  'ecologia',
  'edificar',
  'edital',
  'educado',
  'efeito',
  'efetivar',
  'ejetar',
  'elaborar',
  'eleger',
  'eleitor',
  'elenco',
  'elevador',
  'eliminar',
  'elogiar',
  'embargo',
  'embolado',
  'embrulho',
  'embutido',
  'emenda',
  'emergir',
  'emissor',
  'empatia',
  'empenho',
  'empinado',
  'empolgar',
  'emprego',
  'empurrar',
  'emulador',
  'encaixe',
  'encenado',
  'enchente',
  'encontro',
  'endeusar',
  'endossar',
  'enfaixar',
  'enfeite',
  'enfim',
  'engajado',
  'engenho',
  'englobar',
  'engomado',
  'engraxar',
  'enguia',
  'enjoar',
  'enlatar',
  'enquanto',
  'enraizar',
  'enrolado',
  'enrugar',
  'ensaio',
  'enseada',
  'ensino',
  'ensopado',
  'entanto',
  'enteado',
  'entidade',
  'entortar',
  'entrada',
  'entulho',
  'envergar',
  'enviado',
  'envolver',
  'enxame',
  'enxerto',
  'enxofre',
  'enxuto',
  'epiderme',
  'equipar',
  'ereto',
  'erguido',
  'errata',
  'erva',
  'ervilha',
  'esbanjar',
  'esbelto',
  'escama',
  'escola',
  'escrita',
  'escuta',
  'esfinge',
  'esfolar',
  'esfregar',
  'esfumado',
  'esgrima',
  'esmalte',
  'espanto',
  'espelho',
  'espiga',
  'esponja',
  'espreita',
  'espumar',
  'esquerda',
  'estaca',
  'esteira',
  'esticar',
  'estofado',
  'estrela',
  'estudo',
  'esvaziar',
  'etanol',
  'etiqueta',
  'euforia',
  'europeu',
  'evacuar',
  'evaporar',
  'evasivo',
  'eventual',
  'evidente',
  'evoluir',
  'exagero',
  'exalar',
  'examinar',
  'exato',
  'exausto',
  'excesso',
  'excitar',
  'exclamar',
  'executar',
  'exemplo',
  'exibir',
  'exigente',
  'exonerar',
  'expandir',
  'expelir',
  'expirar',
  'explanar',
  'exposto',
  'expresso',
  'expulsar',
  'externo',
  'extinto',
  'extrato',
  'fabricar',
  'fabuloso',
  'faceta',
  'facial',
  'fada',
  'fadiga',
  'faixa',
  'falar',
  'falta',
  'familiar',
  'fandango',
  'fanfarra',
  'fantoche',
  'fardado',
  'farelo',
  'farinha',
  'farofa',
  'farpa',
  'fartura',
  'fatia',
  'fator',
  'favorita',
  'faxina',
  'fazenda',
  'fechado',
  'feijoada',
  'feirante',
  'felino',
  'feminino',
  'fenda',
  'feno',
  'fera',
  'feriado',
  'ferrugem',
  'ferver',
  'festejar',
  'fetal',
  'feudal',
  'fiapo',
  'fibrose',
  'ficar',
  'ficheiro',
  'figurado',
  'fileira',
  'filho',
  'filme',
  'filtrar',
  'firmeza',
  'fisgada',
  'fissura',
  'fita',
  'fivela',
  'fixador',
  'fixo',
  'flacidez',
  'flamingo',
  'flanela',
  'flechada',
  'flora',
  'flutuar',
  'fluxo',
  'focal',
  'focinho',
  'fofocar',
  'fogo',
  'foguete',
  'foice',
  'folgado',
  'folheto',
  'forjar',
  'formiga',
  'forno',
  'forte',
  'fosco',
  'fossa',
  'fragata',
  'fralda',
  'frango',
  'frasco',
  'fraterno',
  'freira',
  'frente',
  'fretar',
  'frieza',
  'friso',
  'fritura',
  'fronha',
  'frustrar',
  'fruteira',
  'fugir',
  'fulano',
  'fuligem',
  'fundar',
  'fungo',
  'funil',
  'furador',
  'furioso',
  'futebol',
  'gabarito',
  'gabinete',
  'gado',
  'gaiato',
  'gaiola',
  'gaivota',
  'galega',
  'galho',
  'galinha',
  'galocha',
  'ganhar',
  'garagem',
  'garfo',
  'gargalo',
  'garimpo',
  'garoupa',
  'garrafa',
  'gasoduto',
  'gasto',
  'gata',
  'gatilho',
  'gaveta',
  'gazela',
  'gelado',
  'geleia',
  'gelo',
  'gemada',
  'gemer',
  'gemido',
  'generoso',
  'gengiva',
  'genial',
  'genoma',
  'genro',
  'geologia',
  'gerador',
  'germinar',
  'gesso',
  'gestor',
  'ginasta',
  'gincana',
  'gingado',
  'girafa',
  'girino',
  'glacial',
  'glicose',
  'global',
  'glorioso',
  'goela',
  'goiaba',
  'golfe',
  'golpear',
  'gordura',
  'gorjeta',
  'gorro',
  'gostoso',
  'goteira',
  'governar',
  'gracejo',
  'gradual',
  'grafite',
  'gralha',
  'grampo',
  'granada',
  'gratuito',
  'graveto',
  'graxa',
  'grego',
  'grelhar',
  'greve',
  'grilo',
  'grisalho',
  'gritaria',
  'grosso',
  'grotesco',
  'grudado',
  'grunhido',
  'gruta',
  'guache',
  'guarani',
  'guaxinim',
  'guerrear',
  'guiar',
  'guincho',
  'guisado',
  'gula',
  'guloso',
  'guru',
  'habitar',
  'harmonia',
  'haste',
  'haver',
  'hectare',
  'herdar',
  'heresia',
  'hesitar',
  'hiato',
  'hibernar',
  'hidratar',
  'hiena',
  'hino',
  'hipismo',
  'hipnose',
  'hipoteca',
  'hoje',
  'holofote',
  'homem',
  'honesto',
  'honrado',
  'hormonal',
  'hospedar',
  'humorado',
  'iate',
  'ideia',
  'idoso',
  'ignorado',
  'igreja',
  'iguana',
  'ileso',
  'ilha',
  'iludido',
  'iluminar',
  'ilustrar',
  'imagem',
  'imediato',
  'imenso',
  'imersivo',
  'iminente',
  'imitador',
  'imortal',
  'impacto',
  'impedir',
  'implante',
  'impor',
  'imprensa',
  'impune',
  'imunizar',
  'inalador',
  'inapto',
  'inativo',
  'incenso',
  'inchar',
  'incidir',
  'incluir',
  'incolor',
  'indeciso',
  'indireto',
  'indutor',
  'ineficaz',
  'inerente',
  'infantil',
  'infestar',
  'infinito',
  'inflamar',
  'informal',
  'infrator',
  'ingerir',
  'inibido',
  'inicial',
  'inimigo',
  'injetar',
  'inocente',
  'inodoro',
  'inovador',
  'inox',
  'inquieto',
  'inscrito',
  'inseto',
  'insistir',
  'inspetor',
  'instalar',
  'insulto',
  'intacto',
  'integral',
  'intimar',
  'intocado',
  'intriga',
  'invasor',
  'inverno',
  'invicto',
  'invocar',
  'iogurte',
  'iraniano',
  'ironizar',
  'irreal',
  'irritado',
  'isca',
  'isento',
  'isolado',
  'isqueiro',
  'italiano',
  'janeiro',
  'jangada',
  'janta',
  'jararaca',
  'jardim',
  'jarro',
  'jasmim',
  'jato',
  'javali',
  'jazida',
  'jejum',
  'joaninha',
  'joelhada',
  'jogador',
  'joia',
  'jornal',
  'jorrar',
  'jovem',
  'juba',
  'judeu',
  'judoca',
  'juiz',
  'julgador',
  'julho',
  'jurado',
  'jurista',
  'juro',
  'justa',
  'labareda',
  'laboral',
  'lacre',
  'lactante',
  'ladrilho',
  'lagarta',
  'lagoa',
  'laje',
  'lamber',
  'lamentar',
  'laminar',
  'lampejo',
  'lanche',
  'lapidar',
  'lapso',
  'laranja',
  'lareira',
  'largura',
  'lasanha',
  'lastro',
  'lateral',
  'latido',
  'lavanda',
  'lavoura',
  'lavrador',
  'laxante',
  'lazer',
  'lealdade',
  'lebre',
  'legado',
  'legendar',
  'legista',
  'leigo',
  'leiloar',
  'leitura',
  'lembrete',
  'leme',
  'lenhador',
  'lentilha',
  'leoa',
  'lesma',
  'leste',
  'letivo',
  'letreiro',
  'levar',
  'leveza',
  'levitar',
  'liberal',
  'libido',
  'liderar',
  'ligar',
  'ligeiro',
  'limitar',
  'limoeiro',
  'limpador',
  'linda',
  'linear',
  'linhagem',
  'liquidez',
  'listagem',
  'lisura',
  'litoral',
  'livro',
  'lixa',
  'lixeira',
  'locador',
  'locutor',
  'lojista',
  'lombo',
  'lona',
  'longe',
  'lontra',
  'lorde',
  'lotado',
  'loteria',
  'loucura',
  'lousa',
  'louvar',
  'luar',
  'lucidez',
  'lucro',
  'luneta',
  'lustre',
  'lutador',
  'luva',
  'macaco',
  'macete',
  'machado',
  'macio',
  'madeira',
  'madrinha',
  'magnata',
  'magreza',
  'maior',
  'mais',
  'malandro',
  'malha',
  'malote',
  'maluco',
  'mamilo',
  'mamoeiro',
  'mamute',
  'manada',
  'mancha',
  'mandato',
  'manequim',
  'manhoso',
  'manivela',
  'manobrar',
  'mansa',
  'manter',
  'manusear',
  'mapeado',
  'maquinar',
  'marcador',
  'maresia',
  'marfim',
  'margem',
  'marinho',
  'marmita',
  'maroto',
  'marquise',
  'marreco',
  'martelo',
  'marujo',
  'mascote',
  'masmorra',
  'massagem',
  'mastigar',
  'matagal',
  'materno',
  'matinal',
  'matutar',
  'maxilar',
  'medalha',
  'medida',
  'medusa',
  'megafone',
  'meiga',
  'melancia',
  'melhor',
  'membro',
  'memorial',
  'menino',
  'menos',
  'mensagem',
  'mental',
  'merecer',
  'mergulho',
  'mesada',
  'mesclar',
  'mesmo',
  'mesquita',
  'mestre',
  'metade',
  'meteoro',
  'metragem',
  'mexer',
  'mexicano',
  'micro',
  'migalha',
  'migrar',
  'milagre',
  'milenar',
  'milhar',
  'mimado',
  'minerar',
  'minhoca',
  'ministro',
  'minoria',
  'miolo',
  'mirante',
  'mirtilo',
  'misturar',
  'mocidade',
  'moderno',
  'modular',
  'moeda',
  'moer',
  'moinho',
  'moita',
  'moldura',
  'moleza',
  'molho',
  'molinete',
  'molusco',
  'montanha',
  'moqueca',
  'morango',
  'morcego',
  'mordomo',
  'morena',
  'mosaico',
  'mosquete',
  'mostarda',
  'motel',
  'motim',
  'moto',
  'motriz',
  'muda',
  'muito',
  'mulata',
  'mulher',
  'multar',
  'mundial',
  'munido',
  'muralha',
  'murcho',
  'muscular',
  'museu',
  'musical',
  'nacional',
  'nadador',
  'naja',
  'namoro',
  'narina',
  'narrado',
  'nascer',
  'nativa',
  'natureza',
  'navalha',
  'navegar',
  'navio',
  'neblina',
  'nebuloso',
  'negativa',
  'negociar',
  'negrito',
  'nervoso',
  'neta',
  'neural',
  'nevasca',
  'nevoeiro',
  'ninar',
  'ninho',
  'nitidez',
  'nivelar',
  'nobreza',
  'noite',
  'noiva',
  'nomear',
  'nominal',
  'nordeste',
  'nortear',
  'notar',
  'noticiar',
  'noturno',
  'novelo',
  'novilho',
  'novo',
  'nublado',
  'nudez',
  'numeral',
  'nupcial',
  'nutrir',
  'nuvem',
  'obcecado',
  'obedecer',
  'objetivo',
  'obrigado',
  'obscuro',
  'obstetra',
  'obter',
  'obturar',
  'ocidente',
  'ocioso',
  'ocorrer',
  'oculista',
  'ocupado',
  'ofegante',
  'ofensiva',
  'oferenda',
  'oficina',
  'ofuscado',
  'ogiva',
  'olaria',
  'oleoso',
  'olhar',
  'oliveira',
  'ombro',
  'omelete',
  'omisso',
  'omitir',
  'ondulado',
  'oneroso',
  'ontem',
  'opcional',
  'operador',
  'oponente',
  'oportuno',
  'oposto',
  'orar',
  'orbitar',
  'ordem',
  'ordinal',
  'orfanato',
  'orgasmo',
  'orgulho',
  'oriental',
  'origem',
  'oriundo',
  'orla',
  'ortodoxo',
  'orvalho',
  'oscilar',
  'ossada',
  'osso',
  'ostentar',
  'otimismo',
  'ousadia',
  'outono',
  'outubro',
  'ouvido',
  'ovelha',
  'ovular',
  'oxidar',
  'oxigenar',
  'pacato',
  'paciente',
  'pacote',
  'pactuar',
  'padaria',
  'padrinho',
  'pagar',
  'pagode',
  'painel',
  'pairar',
  'paisagem',
  'palavra',
  'palestra',
  'palheta',
  'palito',
  'palmada',
  'palpitar',
  'pancada',
  'panela',
  'panfleto',
  'panqueca',
  'pantanal',
  'papagaio',
  'papelada',
  'papiro',
  'parafina',
  'parcial',
  'pardal',
  'parede',
  'partida',
  'pasmo',
  'passado',
  'pastel',
  'patamar',
  'patente',
  'patinar',
  'patrono',
  'paulada',
  'pausar',
  'peculiar',
  'pedalar',
  'pedestre',
  'pediatra',
  'pedra',
  'pegada',
  'peitoral',
  'peixe',
  'pele',
  'pelicano',
  'penca',
  'pendurar',
  'peneira',
  'penhasco',
  'pensador',
  'pente',
  'perceber',
  'perfeito',
  'pergunta',
  'perito',
  'permitir',
  'perna',
  'perplexo',
  'persiana',
  'pertence',
  'peruca',
  'pescado',
  'pesquisa',
  'pessoa',
  'petiscar',
  'piada',
  'picado',
  'piedade',
  'pigmento',
  'pilastra',
  'pilhado',
  'pilotar',
  'pimenta',
  'pincel',
  'pinguim',
  'pinha',
  'pinote',
  'pintar',
  'pioneiro',
  'pipoca',
  'piquete',
  'piranha',
  'pires',
  'pirueta',
  'piscar',
  'pistola',
  'pitanga',
  'pivete',
  'planta',
  'plaqueta',
  'platina',
  'plebeu',
  'plumagem',
  'pluvial',
  'pneu',
  'poda',
  'poeira',
  'poetisa',
  'polegada',
  'policiar',
  'poluente',
  'polvilho',
  'pomar',
  'pomba',
  'ponderar',
  'pontaria',
  'populoso',
  'porta',
  'possuir',
  'postal',
  'pote',
  'poupar',
  'pouso',
  'povoar',
  'praia',
  'prancha',
  'prato',
  'praxe',
  'prece',
  'predador',
  'prefeito',
  'premiar',
  'prensar',
  'preparar',
  'presilha',
  'pretexto',
  'prevenir',
  'prezar',
  'primata',
  'princesa',
  'prisma',
  'privado',
  'processo',
  'produto',
  'profeta',
  'proibido',
  'projeto',
  'prometer',
  'propagar',
  'prosa',
  'protetor',
  'provador',
  'publicar',
  'pudim',
  'pular',
  'pulmonar',
  'pulseira',
  'punhal',
  'punir',
  'pupilo',
  'pureza',
  'puxador',
  'quadra',
  'quantia',
  'quarto',
  'quase',
  'quebrar',
  'queda',
  'queijo',
  'quente',
  'querido',
  'quimono',
  'quina',
  'quiosque',
  'rabanada',
  'rabisco',
  'rachar',
  'racionar',
  'radial',
  'raiar',
  'rainha',
  'raio',
  'raiva',
  'rajada',
  'ralado',
  'ramal',
  'ranger',
  'ranhura',
  'rapadura',
  'rapel',
  'rapidez',
  'raposa',
  'raquete',
  'raridade',
  'rasante',
  'rascunho',
  'rasgar',
  'raspador',
  'rasteira',
  'rasurar',
  'ratazana',
  'ratoeira',
  'realeza',
  'reanimar',
  'reaver',
  'rebaixar',
  'rebelde',
  'rebolar',
  'recado',
  'recente',
  'recheio',
  'recibo',
  'recordar',
  'recrutar',
  'recuar',
  'rede',
  'redimir',
  'redonda',
  'reduzida',
  'reenvio',
  'refinar',
  'refletir',
  'refogar',
  'refresco',
  'refugiar',
  'regalia',
  'regime',
  'regra',
  'reinado',
  'reitor',
  'rejeitar',
  'relativo',
  'remador',
  'remendo',
  'remorso',
  'renovado',
  'reparo',
  'repelir',
  'repleto',
  'repolho',
  'represa',
  'repudiar',
  'requerer',
  'resenha',
  'resfriar',
  'resgatar',
  'residir',
  'resolver',
  'respeito',
  'ressaca',
  'restante',
  'resumir',
  'retalho',
  'reter',
  'retirar',
  'retomada',
  'retratar',
  'revelar',
  'revisor',
  'revolta',
  'riacho',
  'rica',
  'rigidez',
  'rigoroso',
  'rimar',
  'ringue',
  'risada',
  'risco',
  'risonho',
  'robalo',
  'rochedo',
  'rodada',
  'rodeio',
  'rodovia',
  'roedor',
  'roleta',
  'romano',
  'roncar',
  'rosado',
  'roseira',
  'rosto',
  'rota',
  'roteiro',
  'rotina',
  'rotular',
  'rouco',
  'roupa',
  'roxo',
  'rubro',
  'rugido',
  'rugoso',
  'ruivo',
  'rumo',
  'rupestre',
  'russo',
  'sabor',
  'saciar',
  'sacola',
  'sacudir',
  'sadio',
  'safira',
  'saga',
  'sagrada',
  'saibro',
  'salada',
  'saleiro',
  'salgado',
  'saliva',
  'salpicar',
  'salsicha',
  'saltar',
  'salvador',
  'sambar',
  'samurai',
  'sanar',
  'sanfona',
  'sangue',
  'sanidade',
  'sapato',
  'sarda',
  'sargento',
  'sarjeta',
  'saturar',
  'saudade',
  'saxofone',
  'sazonal',
  'secar',
  'secular',
  'seda',
  'sedento',
  'sediado',
  'sedoso',
  'sedutor',
  'segmento',
  'segredo',
  'segundo',
  'seiva',
  'seleto',
  'selvagem',
  'semanal',
  'semente',
  'senador',
  'senhor',
  'sensual',
  'sentado',
  'separado',
  'sereia',
  'seringa',
  'serra',
  'servo',
  'setembro',
  'setor',
  'sigilo',
  'silhueta',
  'silicone',
  'simetria',
  'simpatia',
  'simular',
  'sinal',
  'sincero',
  'singular',
  'sinopse',
  'sintonia',
  'sirene',
  'siri',
  'situado',
  'soberano',
  'sobra',
  'socorro',
  'sogro',
  'soja',
  'solda',
  'soletrar',
  'solteiro',
  'sombrio',
  'sonata',
  'sondar',
  'sonegar',
  'sonhador',
  'sono',
  'soprano',
  'soquete',
  'sorrir',
  'sorteio',
  'sossego',
  'sotaque',
  'soterrar',
  'sovado',
  'sozinho',
  'suavizar',
  'subida',
  'submerso',
  'subsolo',
  'subtrair',
  'sucata',
  'sucesso',
  'suco',
  'sudeste',
  'sufixo',
  'sugador',
  'sugerir',
  'sujeito',
  'sulfato',
  'sumir',
  'suor',
  'superior',
  'suplicar',
  'suposto',
  'suprimir',
  'surdina',
  'surfista',
  'surpresa',
  'surreal',
  'surtir',
  'suspiro',
  'sustento',
  'tabela',
  'tablete',
  'tabuada',
  'tacho',
  'tagarela',
  'talher',
  'talo',
  'talvez',
  'tamanho',
  'tamborim',
  'tampa',
  'tangente',
  'tanto',
  'tapar',
  'tapioca',
  'tardio',
  'tarefa',
  'tarja',
  'tarraxa',
  'tatuagem',
  'taurino',
  'taxativo',
  'taxista',
  'teatral',
  'tecer',
  'tecido',
  'teclado',
  'tedioso',
  'teia',
  'teimar',
  'telefone',
  'telhado',
  'tempero',
  'tenente',
  'tensor',
  'tentar',
  'termal',
  'terno',
  'terreno',
  'tese',
  'tesoura',
  'testado',
  'teto',
  'textura',
  'texugo',
  'tiara',
  'tigela',
  'tijolo',
  'timbrar',
  'timidez',
  'tingido',
  'tinteiro',
  'tiragem',
  'titular',
  'toalha',
  'tocha',
  'tolerar',
  'tolice',
  'tomada',
  'tomilho',
  'tonel',
  'tontura',
  'topete',
  'tora',
  'torcido',
  'torneio',
  'torque',
  'torrada',
  'torto',
  'tostar',
  'touca',
  'toupeira',
  'toxina',
  'trabalho',
  'tracejar',
  'tradutor',
  'trafegar',
  'trajeto',
  'trama',
  'trancar',
  'trapo',
  'traseiro',
  'tratador',
  'travar',
  'treino',
  'tremer',
  'trepidar',
  'trevo',
  'triagem',
  'tribo',
  'triciclo',
  'tridente',
  'trilogia',
  'trindade',
  'triplo',
  'triturar',
  'triunfal',
  'trocar',
  'trombeta',
  'trova',
  'trunfo',
  'truque',
  'tubular',
  'tucano',
  'tudo',
  'tulipa',
  'tupi',
  'turbo',
  'turma',
  'turquesa',
  'tutelar',
  'tutorial',
  'uivar',
  'umbigo',
  'unha',
  'unidade',
  'uniforme',
  'urologia',
  'urso',
  'urtiga',
  'urubu',
  'usado',
  'usina',
  'usufruir',
  'vacina',
  'vadiar',
  'vagaroso',
  'vaidoso',
  'vala',
  'valente',
  'validade',
  'valores',
  'vantagem',
  'vaqueiro',
  'varanda',
  'vareta',
  'varrer',
  'vascular',
  'vasilha',
  'vassoura',
  'vazar',
  'vazio',
  'veado',
  'vedar',
  'vegetar',
  'veicular',
  'veleiro',
  'velhice',
  'veludo',
  'vencedor',
  'vendaval',
  'venerar',
  'ventre',
  'verbal',
  'verdade',
  'vereador',
  'vergonha',
  'vermelho',
  'verniz',
  'versar',
  'vertente',
  'vespa',
  'vestido',
  'vetorial',
  'viaduto',
  'viagem',
  'viajar',
  'viatura',
  'vibrador',
  'videira',
  'vidraria',
  'viela',
  'viga',
  'vigente',
  'vigiar',
  'vigorar',
  'vilarejo',
  'vinco',
  'vinheta',
  'vinil',
  'violeta',
  'virada',
  'virtude',
  'visitar',
  'visto',
  'vitral',
  'viveiro',
  'vizinho',
  'voador',
  'voar',
  'vogal',
  'volante',
  'voleibol',
  'voltagem',
  'volumoso',
  'vontade',
  'vulto',
  'vuvuzela',
  'xadrez',
  'xarope',
  'xeque',
  'xeretar',
  'xerife',
  'xingar',
  'zangado',
  'zarpar',
  'zebu',
  'zelador',
  'zombar',
  'zoologia',
  'zumbido',
];
