/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import HistoryIcon from '@material-ui/icons/History';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ModalCredits from '../Modals/ModalCredits';
import ChangeLanguage from '../ChangeLanguage';

import useAuthContext from '../../hooks/useAuthContext';

import COLORS from '../../utils/colors';
import littleDigitalImg from '../../images/little-digital.svg';

const drawerWidth = '13.75rem';
const drawerRoutes = {
  user: [
    { name: 'home', icon: <HomeOutlinedIcon /> },
    { name: 'documents', icon: <DescriptionOutlinedIcon /> },
    { name: 'vault', icon: <LockOutlinedIcon /> },
    { name: 'folders', icon: <FolderIcon /> },
    { name: 'credits', icon: <MonetizationOnOutlinedIcon /> },
    { name: 'plans', icon: <PollOutlinedIcon /> },
    { name: 'history', icon: <HistoryIcon /> },
  ],
  operator: [
    { name: 'registers', icon: <GroupAddOutlinedIcon /> },
    { name: 'clients', icon: <GroupOutlinedIcon /> },
  ],
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },

  appBar: {
    backgroundColor: COLORS.background,
    color: COLORS.black,
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: `1px ${COLORS.lightGray4} solid`,
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '6.75rem',
    padding: 0,
  },

  greetings: {
    textAlign: 'center',
    fontSize: '1rem',

    '& span': {
      fontWeight: 700,
    },
  },

  logoContainer: {
    minWidth: drawerWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.white,
    height: '100%',
  },

  iconsAppBar: {
    backgroundColor: COLORS.gray1,
    fontSize: '2.75rem',
    borderRadius: '0.75rem',
    padding: '0.625rem',
    fill: COLORS.background,

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',
    },

    '&.selected': {
      backgroundColor: COLORS.black,
    },
  },

  drawer: {
    width: drawerWidth,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '2rem',
  },

  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
    padding: '1rem 0',
  },

  drawerRoutesContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  },

  drawerButtons: {
    height: '2.25rem',
    width: '9.5rem',
    borderRadius: '0.375rem',
    columnGap: '0.375rem',

    '& svg': {
      fill: COLORS.black,
    },

    '& span': {
      color: COLORS.black,
      fontSize: '1rem',
    },

    '&:hover': {
      backgroundColor: COLORS.gray5,
      transition: '0.3s',

      '& svg, & span': {
        color: COLORS.white,
        fill: COLORS.white,
        transition: '0.3s',
      },
    },
  },

  drawerSelected: {
    backgroundColor: COLORS.black,

    '& svg, & span': {
      color: COLORS.white,
      fill: COLORS.white,
    },
  },

  content: {
    flexGrow: 1,
    padding: '2.5rem 3.125rem 1.5rem 2.5rem',
    backgroundColor: COLORS.background,
    overflow: 'auto',
    marginLeft: drawerWidth,
  },

  linksDrawer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',

    '& a': {
      color: COLORS.mediumGray3,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      textDecoration: 'none',
      width: 'max-content',
    },
  },

  versionText: {
    color: COLORS.mediumGray3,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    margin: '1.25rem 0 2rem 0',
  },

  logout: {
    height: 'max-content',
    width: '9.5rem',
    borderRadius: '0.375rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    color: COLORS.redWarning,
    columnGap: '0.5rem',
    fontWeight: 'bold',

    '& svg': {
      fill: COLORS.redWarning,
    },

    '&:hover': {
      color: COLORS.lightRed,
      transition: '0.3s',

      '& svg': {
        fill: COLORS.lightRed,
        transition: '0.3s',
      },
    },
  },
}));

type Route =
  | 'home'
  | 'documents'
  | 'vault'
  | 'folders'
  | 'credits'
  | 'plans'
  | 'history'
  | 'registers'
  | 'clients'
  | 'profile';

interface Props {
  children: React.ReactNode;
  type: 'user' | 'operator';
  routeSelected: Route | '';
}

const Layout: React.FC<Props> = ({ children, type, routeSelected }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { logout, user } = useAuthContext();

  const isUser = type === 'user';

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar elevation={0} position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link
            className={classes.logoContainer}
            to={isUser ? '/home' : '/registers'}
          >
            <img src={littleDigitalImg} alt="logo" height="57" width="40" />
          </Link>

          <Grid
            container
            justifyContent={isUser ? 'space-between' : 'flex-end'}
            alignItems="center"
            style={{ padding: '0 3.125rem 0 2.5rem' }}
          >
            {isUser && <ModalCredits type="appBar" />}

            <div style={{ display: 'flex', columnGap: '1rem' }}>
              {isUser && (
                <Link to="/profile">
                  <AccountCircleOutlinedIcon
                    className={clsx(classes.iconsAppBar, {
                      selected: routeSelected === 'profile',
                    })}
                  />
                </Link>
              )}
            </div>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" classes={{ paper: classes.drawer }}>
        <div className={classes.drawerContainer}>
          <Toolbar className={classes.toolbar} />

          <p className={classes.greetings}>
            {t('components.layout.greetings')},{' '}
            <span>{user?.name.split(' ')[0]}</span>
          </p>

          <List className={classes.drawerRoutesContainer}>
            {drawerRoutes[type].map(drawerRoute => (
              <ListItem
                component={Link}
                key={drawerRoute.name}
                className={clsx(classes.drawerButtons, {
                  [classes.drawerSelected]: routeSelected === drawerRoute.name,
                })}
                to={`/${drawerRoute.name}`}
              >
                {drawerRoute.icon}

                <span>
                  {t(`components.layout.${type}.${drawerRoute.name}`)}
                </span>
              </ListItem>
            ))}
          </List>

          <div>
            <ChangeLanguage />

            {isUser && (
              <>
                <div className={classes.linksDrawer}>
                  <a
                    href="https://www.dattaid.com.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('components.layout.about')} DattaID
                  </a>

                  <Link to="/home">{t('components.layout.help')}</Link>
                </div>

                <Typography className={classes.versionText}>
                  {t('components.layout.version')} 1.0
                </Typography>
              </>
            )}

            <div className={classes.logout} onClick={logout}>
              <ExitToAppIcon />

              <span>{t('components.layout.logout')}</span>
            </div>
          </div>
        </div>
      </Drawer>

      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Layout;
