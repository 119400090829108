import React, { createContext, useState } from 'react';

import ModalAlert from '../components/Modals/ModalAlert';

import { AlertName, AlertType } from '../types/Alert';

interface ContextProps {
  showAlert: (
    alertName: AlertName,
    alertType: AlertType,
    idComponent: string | number | null,
  ) => void;
  hideAlert: (time: number) => void;
}

interface Props {
  children: React.ReactNode;
}

const AlertContext = createContext<ContextProps>({} as ContextProps);

const AlertProvider = ({ children }: Props) => {
  const [name, setName] = useState<AlertName>('documentSentSuccessfully');
  const [type, setType] = useState<AlertType>('success');
  const [isOpen, setIsOpen] = useState(false);
  const [idComponentToView, setIdComponentToView] = useState<
    string | number | null
  >(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const showAlert = (
    alertName: AlertName,
    alertType: AlertType,
    idComponent: string | number | null,
  ) => {
    setName(alertName);
    setType(alertType);
    setIdComponentToView(idComponent);
    setIsOpen(true);
  };

  const hideAlert = (time: number) => {
    setTimeout(handleClose, time);
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      <ModalAlert
        name={name}
        type={type}
        isOpen={isOpen}
        handleClose={handleClose}
        idComponentToView={idComponentToView}
      />
      {children}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider };
