import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import COLORS from '../../../utils/colors';

import { AlertName, AlertType } from '../../../types/Alert';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiSvgIcon-root': {
      fontSize: '2.25rem',
      color: COLORS.mediumGray4,
      cursor: 'pointer',
    },
  },

  iconAlert: {
    fontSize: '3.25rem',
    color: COLORS.white,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 3rem',
  },

  textContent: {
    backgroundColor: COLORS.white,
    fontSize: '1.125rem',
    fontWeight: 'bold',
    color: COLORS.gray2,
    textAlign: 'center',
  },

  divIcon: {
    width: '6.25rem',
    height: '6.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginTop: '2rem',
  },

  buttonViewFile: {
    backgroundColor: COLORS.black,
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.white,
    textTransform: 'none',
    margin: '2.25rem 0',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.pureBlack,
      transition: '0.3s',
    },
  },
});

interface Props {
  name: AlertName;
  type: AlertType;
  isOpen: boolean;
  handleClose: () => void;
  idComponentToView: string | number | null;
}

export default function ModalAlert({
  name,
  type,
  isOpen,
  handleClose,
  idComponentToView,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const alertData = {
    title: t(`components.modalAlert.${name}.title`),
    icon:
      type === 'success' ? (
        <CheckCircleOutlinedIcon className={classes.iconAlert} />
      ) : (
        <ErrorOutlineIcon className={classes.iconAlert} />
      ),
    color: type === 'success' ? COLORS.greenButton : COLORS.lightRed,
    button: {
      text: t(`components.modalAlert.${name}.button`),
      action:
        name === 'documentSentSuccessfully'
          ? () => {
              handleClose();
              history.push(`/documents/${idComponentToView}`);
            }
          : handleClose,
    },
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle className={classes.title} disableTypography>
        <CloseIcon onClick={handleClose} />
      </DialogTitle>

      <DialogContent className={classes.content}>
        <p className={classes.textContent}>{alertData.title}</p>

        <div
          className={classes.divIcon}
          style={{ backgroundColor: alertData.color }}
        >
          {alertData.icon}
        </div>

        <Button
          className={classes.buttonViewFile}
          onClick={alertData.button.action}
        >
          {alertData.button.text}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
