import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import clsx from 'clsx';

import COLORS from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    padding: 0,
    display: 'flex',
    columnGap: '1rem',
  },

  removeEmail: {
    color: COLORS.lightRed,
    marginTop: '0.325rem',
    cursor: 'pointer',

    '&:hover': {
      transition: '0.3s',
      color: COLORS.redWarning,
    },
  },

  email: {
    color: COLORS.gray3,
    fontSize: '1.25rem',
  },

  addNote: {
    width: '7.875rem',
    backgroundColor: 'transparent',
    borderRadius: '0.5rem',
    display: 'flex',
    justifyContent: 'center',

    '& .MuiInput-input': {
      color: COLORS.mediumGray3,
      fontSize: '.75rem',
    },
  },

  editActive: {
    height: '3.75rem',
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.lightGray3}`,
    borderRadius: '0.5rem',
    marginTop: '0.5rem',
    width: '20rem',

    [theme.breakpoints.up('lg')]: {
      width: '25.5rem',
    },

    '& .MuiInput-input': {
      margin: '0.625rem auto auto 1rem',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      color: COLORS.gray1,
      maxWidth: '18.25rem',
    },
  },
}));

interface Person {
  user: string;
  password: string;
  email: string;
  observation: string;
}

interface Props {
  person: Person;
  handleChangeObservation: (newValue: string, email: string) => void;
  handleRemove: (email: string) => void;
  placeholder: string;
}

export default function PersonVaultTile({
  person,
  handleChangeObservation,
  handleRemove,
  placeholder,
}: Props) {
  const classes = useStyles();
  const [editIsActive, setEditIsActive] = useState(false);

  return (
    <div className={classes.container}>
      <RemoveCircleOutlineIcon
        className={classes.removeEmail}
        onClick={() => {
          handleRemove(person.email);
        }}
      />

      <div>
        <Typography className={classes.email}>{person.email}</Typography>

        <TextField
          placeholder={placeholder}
          variant="standard"
          value={person.observation}
          onChange={e => {
            handleChangeObservation(e.target.value, person.email);
          }}
          InputProps={{
            disableUnderline: true,
            inputProps: { maxLength: 80 },
          }}
          className={clsx({
            [classes.editActive]: editIsActive,
            [classes.addNote]: !editIsActive,
          })}
          onClick={() => {
            setEditIsActive(true);
          }}
          multiline={editIsActive}
        />
      </div>
    </div>
  );
}
