/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import FaceIcon from '@material-ui/icons/Face';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import clsx from 'clsx';

import CostCenterSelect from '../../CostCenterSelect';

import useAlert from '../../../hooks/useAlertContext';
import useProducts from '../../../hooks/useProducts';
import useCostCenters from '../../../hooks/useCostCenters';
import { useAxios } from '../../../hooks/useAxios';

import { Credit } from '../../../types/Credit';
import { ProductCategory } from '../../../types/ProductCategory';
import { UserProfile } from '../../../types/UserProfile';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';
import { numberMask, cepMask } from '../../../utils/masks';
import getAddressByCep from '../../../utils/getAddressByCep';

const useStyles = makeStyles({
  textBuyCredits: {
    fontSize: '1.125rem',
    color: COLORS.blue,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
      color: COLORS.blueHover,
    },
  },

  blackCreditsButton: {
    backgroundColor: COLORS.gray1,
    width: '12rem',
    borderRadius: '0.5rem',
    padding: '0.625rem',
    justifyContent: 'center',
    color: COLORS.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    border: 'none',
    textTransform: 'none',

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',
    },
  },

  buyCreditsButtonInAppBar: {
    textTransform: 'none',
    backgroundColor: COLORS.gray2,
    borderRadius: '0.5rem',
    padding: '.5rem 1rem',
    color: COLORS.white,
    fontWeight: 'bold',

    '& svg': {
      color: COLORS.orangeAdvice,
      width: '1.5rem',
      height: '1.5rem',
    },

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: 'all 0.3s',

      '& svg': {
        color: COLORS.orangeHover,
        transition: '0.3s',
      },
    },
  },

  containerTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1.75rem 1.75rem 0 0',

    '& svg': {
      fontSize: '1.625rem',
      color: COLORS.gray2,
      cursor: 'pointer',
    },
  },

  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },

  products: {
    width: '40rem',
    margin: '2rem 3rem 4rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',

    '& .item': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: COLORS.background,
      borderRadius: '.625rem',
      padding: '1rem .5rem',
      columnGap: '.5rem',
    },
  },

  productDescription: {
    width: '100%',

    '& .title': {
      fontSize: '1.125rem',
      fontWeight: 700,
    },

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .value': {
        color: COLORS.gray1,
        fontSize: '1rem',
      },
    },
  },

  currentProducts: {
    width: '100%',
    margin: '1rem 0',

    '& > span': {
      color: COLORS.gray2,
      fontWeight: 700,
    },

    '& section': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '.375rem',

      '& span': {
        fontWeight: 700,
      },
    },
  },

  buttonGroupOperateQuantityProducts: {
    borderRadius: '.75rem',
    border: `1px solid ${COLORS.gray1}`,
    padding: '0 0.25rem',
    width: '7rem',
    height: '2rem',
    display: 'flex',

    '& .MuiButtonBase-root': {
      color: COLORS.orangeAdvice,

      '&.Mui-disabled': {
        color: COLORS.mediumGray4,
      },
    },

    '& input': {
      width: '2.75rem',
      textAlign: 'center',
      height: '80%',
      border: 'none',
      borderRadius: '.75rem',
      backgroundColor: COLORS.white,
    },
  },

  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem',
    fontSize: '1.25rem',

    '& div': {
      width: '70%',
      display: 'flex',
      justifyContent: 'space-between',

      '& :nth-child(2)': {
        color: COLORS.orangeAdvice,
        fontWeight: 700,
      },
    },
  },

  buttonConfirmation: {
    padding: 0,
    height: '3rem',
    width: '29%',
    borderRadius: '0.5rem',
    textTransform: 'none',
    color: COLORS.background,
    background: COLORS.greenButton,
    fontWeight: 'bold',
    fontSize: '0.875rem',

    '&:hover': {
      background: COLORS.greenHover,
      color: COLORS.background,
    },
  },

  addressContainer: {
    width: '40rem',
    margin: '2rem 3rem 4rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem',

    '& .item': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: COLORS.background,
      borderRadius: '.625rem',
      padding: '1rem .5rem',
      columnGap: '.5rem',
    },
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',

    '& label': {
      fontSize: '0.75rem',
      color: COLORS.gray2,

      '&.error': {
        color: COLORS.redWarning,
      },
    },

    '& input': {
      height: '2.75rem',
      border: `1px solid ${COLORS.lightGray3}`,
      borderRadius: '0.5rem',
      padding: '0 1rem',
      outline: 'none',
      backgroundColor: COLORS.white,

      '&:focus': {
        borderColor: COLORS.gray1,
      },

      '&.error': {
        borderColor: COLORS.redWarning,
      },
    },
  },

  errorMessage: {
    color: COLORS.redWarning,
    marginTop: '.5rem',
  },

  buttonBuyInAddress: {
    padding: 0,
    height: '3rem',
    width: '29%',
    borderRadius: '0.5rem',
    textTransform: 'none',
    color: COLORS.background,
    background: COLORS.greenButton,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginTop: '1rem',

    '&:hover': {
      background: COLORS.greenHover,
      color: COLORS.background,
    },
  },
});

interface Props {
  type: 'appBar' | 'button' | 'text' | 'operator';
}

type ProductToBuy = ProductCategory & {
  quantity: number;
};

export default function ModalCredits({ type }: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { products } = useProducts();
  const { costCenters } = useCostCenters();

  const [documents, setDocuments] = useState(0);
  const [facialBiometrics, setFacialBiometrics] = useState(0);
  const [vault, setVault] = useState(0);

  const [chosenCostCenter, setChosenCostCenter] = useState(-1);

  const [zipCode, setZipCode] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const { data: currentCredits }: { data: Credit[] } = useAxios(
    linksForSWR.client.credits(
      chosenCostCenter !== -1 ? costCenters.data[chosenCostCenter]._id : '',
    ),
  );

  const { data: userProfile, mutate: mutateUserProfile } = useAxios(
    linksForSWR.client.profile(),
  ) as {
    data: UserProfile;
    mutate: (
      data?: UserProfile,
      shouldRevalidate?: boolean | undefined,
    ) => void;
  };

  const [productsToBuy, setProductsToBuy] = useState([] as ProductToBuy[]);

  const productIcon = {
    sendingDocuments: (
      <InsertDriveFileOutlinedIcon style={{ fontSize: '3rem' }} />
    ),
    facialBiometrics: <FaceIcon style={{ fontSize: '3rem' }} />,
    vault: <LockOutlinedIcon style={{ fontSize: '3rem' }} />,
  };

  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setIsEditingAddress(false);
      setZipCode('');
      setStreet('');
      setNeighborhood('');
      setNumber('');
      setCity('');
      setState('');
      setHasError(false);
    }, 150);
  };

  const handleButtonQuantityCredits = (value: number, index: number) => {
    const localProductsToBuy = [...productsToBuy];
    localProductsToBuy[index].quantity += value;

    setProductsToBuy(localProductsToBuy);
  };

  const handleInputQuantityCredits = (value: string, index: number) => {
    const localProductsToBuy = [...productsToBuy];
    localProductsToBuy[index].quantity = Number(value);

    setProductsToBuy(localProductsToBuy);
  };

  const transitionToEditAddress = () => {
    setOpen(false);

    setTimeout(() => {
      setIsEditingAddress(true);
      setOpen(true);
    }, 500);
  };

  const handleBuy = async () => {
    const totalQuantityChosen = productsToBuy.reduce(
      (previousValue: number, currentValue: ProductToBuy) =>
        previousValue + currentValue.quantity,
      0,
    );

    if (totalQuantityChosen <= 0) {
      showAlert('noProductsSelectToBuy', 'error', null);
      return;
    }

    if (!isEditingAddress && !userProfile.address.zipNumber) {
      transitionToEditAddress();
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await api.post('/payments', {
        products: productsToBuy
          .filter(product => product.quantity > 0)
          .map(product => ({
            product_id: product._id,
            name: product.name,
            quantity: product.quantity,
          })),
        language: i18n.language,
        costcenter_id:
          chosenCostCenter !== -1
            ? costCenters.data[chosenCostCenter]._id
            : null,
      });

      window.open(response.data.url, '_blank');

      handleClose();

      if (products) {
        setProductsToBuy(
          products
            .sort((a, b) => a.totalValue - b.totalValue)
            .map(product => ({ ...product, quantity: 0 })),
        );
      }
    } catch (error) {
      showAlert('buyCreditsError', 'error', null);
    }

    setIsSubmitting(false);
  };
  const fillAddressByCep = async (cep: string) => {
    const response = await getAddressByCep(cep);

    if (!response) {
      return;
    }

    setStreet(response.street);
    setNeighborhood(response.neighborhood);
    setCity(response.city);
    setState(response.state);
  };

  const handleEditAddress = async () => {
    setHasError(false);
    if (!zipCode || !street || !number || !neighborhood || !city || !state) {
      setHasError(true);

      return;
    }

    setIsSubmitting(true);

    try {
      await api.put('/profile', {
        email: userProfile.email,
        phoneNumber: userProfile.phoneNumber,
        address: {
          zipNumber: zipCode.replace(/\D/g, ''),
          street,
          number,
          neighborhood,
          city,
          state,
        },
      });

      mutateUserProfile(
        {
          ...userProfile,
          address: {
            zipNumber: zipCode,
            street,
            number,
            neighborhood,
            city,
            state,
          },
        },
        false,
      );

      handleBuy();
    } catch (error) {
      console.log(error);
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    try {
      if (!currentCredits) return;

      currentCredits.forEach(element => {
        if (element.name === 'sendingDocuments') setDocuments(element.quantity);

        if (element.name === 'facialBiometrics')
          setFacialBiometrics(element.quantity);

        if (element.name === 'vault') setVault(element.quantity);
      });
    } catch (error) {
      console.log(error);
    }
  }, [currentCredits, chosenCostCenter]);

  useEffect(() => {
    if (products) {
      setProductsToBuy(
        products
          .sort((a, b) => a.totalValue - b.totalValue)
          .map(product => ({ ...product, quantity: 0 })),
      );
    }
  }, [products]);

  return (
    <div>
      {type === 'appBar' ? (
        <Tooltip title={t('components.modalCredits.creditsTitle')}>
          <Button
            className={classes.buyCreditsButtonInAppBar}
            startIcon={<MonetizationOnIcon />}
            onClick={handleOpen}
          >
            {t('components.modalCredits.buttonTitle')}
          </Button>
        </Tooltip>
      ) : type === 'text' ? (
        <Typography className={classes.textBuyCredits} onClick={handleOpen}>
          {t('components.modalCredits.titleText')}
        </Typography>
      ) : type === 'button' ? (
        <Button
          id="addCredits"
          className={classes.blackCreditsButton}
          onClick={handleOpen}
        >
          {t('components.modalCredits.buttonTitle')}
        </Button>
      ) : null}

      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle className={classes.containerTitle} disableTypography>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        {!isEditingAddress ? (
          <DialogContent style={{ padding: 0 }}>
            <div style={{ margin: '0 2.25rem 3rem 2.25rem' }}>
              <h1 className={classes.title}>
                {t('components.modalCredits.creditsTitle')}
              </h1>

              <div className={classes.products}>
                <CostCenterSelect
                  chosenCostCenter={chosenCostCenter}
                  setChosenCostCenter={setChosenCostCenter}
                />

                <div className={classes.currentProducts}>
                  <span>{t('components.modalCredits.productsTitle')}</span>

                  <section>
                    {currentCredits && (
                      <>
                        <p>
                          <span>{documents} un.</span>{' '}
                          {t(
                            'components.modalCredits.products.sendingDocuments',
                          )}
                        </p>

                        <p>
                          <span>{facialBiometrics} un.</span>{' '}
                          {t(
                            'components.modalCredits.products.facialBiometrics',
                          )}
                        </p>

                        <p>
                          <span>{vault} GB</span>{' '}
                          {t('components.modalCredits.products.vault')}
                        </p>
                      </>
                    )}
                  </section>
                </div>

                {productsToBuy.map((product, index) => (
                  <div className="item" key={product._id}>
                    {productIcon[product.name]}

                    <div className={classes.productDescription}>
                      <p className="title">
                        {t(`components.modalCredits.products.${product.name}`)}
                      </p>

                      <div>
                        <span className="value">
                          R$ {product.totalValue} /{' '}
                          {product.name === 'vault' ? 'GB' : 'un.'}
                        </span>

                        <div
                          className={classes.buttonGroupOperateQuantityProducts}
                        >
                          <IconButton
                            size="small"
                            disabled={!product.quantity}
                            onClick={() => {
                              const valueToRemoveOne = -1;
                              handleButtonQuantityCredits(
                                valueToRemoveOne,
                                index,
                              );
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>

                          <MaskedInput
                            mask={numberMask}
                            guide={false}
                            showMask
                            name="number"
                            value={product.quantity}
                            onChange={e => {
                              handleInputQuantityCredits(e.target.value, index);
                            }}
                          />

                          <IconButton
                            size="small"
                            onClick={() => {
                              const valueToAddOne = 1;
                              handleButtonQuantityCredits(valueToAddOne, index);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className={classes.footerContainer}>
                <div>
                  <span>Total</span>

                  <span>
                    R${' '}
                    {productsToBuy
                      .reduce(
                        (previousValue: number, currentValue: ProductToBuy) =>
                          previousValue +
                          currentValue.totalValue * currentValue.quantity,
                        0,
                      )
                      .toFixed(2)}
                  </span>
                </div>

                <Button
                  onClick={handleBuy}
                  className={classes.buttonConfirmation}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : (
                    t('components.modalCredits.buttonBuy')
                  )}
                </Button>
              </div>
            </div>
          </DialogContent>
        ) : (
          <DialogContent style={{ padding: 0 }}>
            <div style={{ margin: '0 2.25rem 3rem 2.25rem' }}>
              <h1 className={classes.title}>
                {t('components.modalCredits.address.title')}
              </h1>

              <div className={classes.addressContainer}>
                <Grid container spacing={3}>
                  <Grid item className={classes.field} xs={12}>
                    <label
                      htmlFor="zipCode"
                      className={clsx({ error: hasError && !zipCode })}
                    >
                      {t('components.modalCredits.address.zipCode')}
                    </label>

                    <MaskedInput
                      mask={cepMask}
                      guide={false}
                      showMask
                      id="zipCode"
                      value={zipCode}
                      onChange={e => {
                        setZipCode(e.target.value);
                        fillAddressByCep(e.target.value);
                      }}
                      className={clsx({ error: hasError && !zipCode })}
                    />
                  </Grid>

                  <Grid item className={classes.field} xs={12}>
                    <label
                      htmlFor="street"
                      className={clsx({ error: hasError && !street })}
                    >
                      {t('components.modalCredits.address.street')}
                    </label>

                    <input
                      id="street"
                      value={street}
                      onChange={e => {
                        setStreet(e.target.value);
                      }}
                      className={clsx({ error: hasError && !street })}
                    />
                  </Grid>

                  <Grid item className={classes.field} xs={12}>
                    <label
                      htmlFor="number"
                      className={clsx({ error: hasError && !number })}
                    >
                      {t('components.modalCredits.address.number')}
                    </label>

                    <input
                      id="number"
                      value={number}
                      onChange={e => {
                        setNumber(e.target.value);
                      }}
                      className={clsx({ error: hasError && !number })}
                    />
                  </Grid>

                  <Grid item className={classes.field} xs={12}>
                    <label
                      htmlFor="neighborhood"
                      className={clsx({ error: hasError && !neighborhood })}
                    >
                      {t('components.modalCredits.address.neighborhood')}
                    </label>

                    <input
                      id="neighborhood"
                      value={neighborhood}
                      onChange={e => {
                        setNeighborhood(e.target.value);
                      }}
                      className={clsx({ error: hasError && !neighborhood })}
                    />
                  </Grid>

                  <Grid item className={classes.field} xs={12}>
                    <label
                      htmlFor="city"
                      className={clsx({ error: hasError && !city })}
                    >
                      {t('components.modalCredits.address.city')}
                    </label>

                    <input
                      id="city"
                      value={city}
                      onChange={e => {
                        setCity(e.target.value);
                      }}
                      className={clsx({ error: hasError && !city })}
                    />
                  </Grid>

                  <Grid item className={classes.field} xs={12}>
                    <label
                      htmlFor="state"
                      className={clsx({ error: hasError && !state })}
                    >
                      {t('components.modalCredits.address.state')}
                    </label>

                    <input
                      id="state"
                      value={state}
                      onChange={e => {
                        setState(e.target.value);
                      }}
                      className={clsx({ error: hasError && !state })}
                    />
                  </Grid>
                </Grid>

                {hasError && (
                  <span className={classes.errorMessage}>
                    {t('components.modalCredits.address.error')}
                  </span>
                )}

                <Button
                  onClick={handleEditAddress}
                  className={classes.buttonBuyInAddress}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : (
                    t('components.modalCredits.buttonBuy')
                  )}
                </Button>
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
