import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import COLORS from '../../utils/colors';

interface StylesProps {
  total: number;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.75rem',
  },

  progress: {
    display: 'grid',
    borderRadius: '0.5rem',
    height: '0.875rem',
    width: '100%',
    padding: 0,
    gridTemplateColumns: (props: StylesProps) => `repeat(${props.total}, 1fr)`,
    gap: (props: StylesProps) => (props.total > 15 ? '' : '0.125rem'),

    '& div': {
      height: '100%',
      width: '100%',
      backgroundColor: COLORS.lightGray2,

      '&.completed': {
        backgroundColor: COLORS.lightGreen,
      },

      '&.rounded': {
        borderRadius: '.5rem',
      },

      '&.leftRounded': {
        borderRadius: '.5rem 0 0 .5rem',
      },

      '&.rightRounded': {
        borderRadius: '0 .5rem .5rem 0',
      },
    },
  },

  text: {
    fontSize: '0.75rem',
    color: COLORS.gray2,
  },
});

interface Props {
  totalProgress: number;
  total: number;
}

export default function CustomProgress({ totalProgress, total }: Props) {
  const classes = useStyles({ total });
  const indexes = Array.from({ length: total }, (v, k) => k);

  const borderRadius = (index: number) => {
    if (total === 1) return 'rounded';
    if (index === 0) return 'leftRounded';
    if (index === total - 1) return 'rightRounded';

    return '';
  };

  return (
    <div className={classes.container}>
      <div className={classes.progress}>
        {indexes.map(index => (
          <div
            key={index}
            className={clsx(borderRadius(index), {
              completed: index < totalProgress,
            })}
          />
        ))}
      </div>

      <span className={classes.text}>
        {totalProgress}/{total}
      </span>
    </div>
  );
}
