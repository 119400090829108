/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import FaceIcon from '@material-ui/icons/Face';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';

import CostCenterSelectOperator from '../../CostCenterSelectOperator';

import useAlert from '../../../hooks/useAlertContext';
import useProducts from '../../../hooks/useProducts';
import useCostCentersOperator from '../../../hooks/useCostCentersOperator';

import { ProductCategory } from '../../../types/ProductCategory';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';
import { numberMask } from '../../../utils/masks';

const useStyles = makeStyles({
  buttonBuyCreditsInOperator: {
    padding: 0,
    textTransform: 'none',
    height: '3rem',
    width: '12.875rem',
    backgroundColor: COLORS.gray2,
    borderRadius: '0.5rem',
    color: COLORS.white,
    fontSize: '1.125rem',
    fontWeight: 'bold',

    '& svg': {
      color: COLORS.orangeAdvice,
      width: '1.5rem',
      height: '1.5rem',
    },

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',

      '& svg': {
        color: COLORS.orangeHover,
        transition: '0.3s',
      },
    },
  },

  containerTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1.75rem 1.75rem 0 0',

    '& svg': {
      fontSize: '1.625rem',
      color: COLORS.gray2,
      cursor: 'pointer',
    },
  },

  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },

  products: {
    width: '40rem',
    margin: '2rem 3rem 1.5rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2rem',

    '& .item': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: COLORS.background,
      borderRadius: '.625rem',
      padding: '1rem .5rem',
      columnGap: '.5rem',
    },
  },

  productDescription: {
    width: '100%',

    '& .title': {
      fontSize: '1.125rem',
      fontWeight: 700,
    },

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .value': {
        color: COLORS.gray1,
        fontSize: '1rem',
      },
    },
  },

  currentProducts: {
    width: '100%',
    margin: '1rem 0',

    '& > span': {
      color: COLORS.gray2,
      fontWeight: 700,
    },

    '& section': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '.375rem',

      '& span': {
        fontWeight: 700,
      },
    },
  },

  buttonGroupOperateQuantityProducts: {
    borderRadius: '.75rem',
    border: `1px solid ${COLORS.gray1}`,
    padding: '0 0.25rem',
    width: '7rem',
    height: '2rem',
    display: 'flex',

    '& .MuiButtonBase-root': {
      color: COLORS.orangeAdvice,

      '&.Mui-disabled': {
        color: COLORS.mediumGray4,
      },
    },

    '& input': {
      width: '2.75rem',
      textAlign: 'center',
      height: '80%',
      border: 'none',
      borderRadius: '.75rem',
      backgroundColor: COLORS.white,
    },
  },

  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem',
    fontSize: '1.25rem',

    '& div': {
      width: '70%',
      display: 'flex',
      justifyContent: 'space-between',

      '& :nth-child(2)': {
        color: COLORS.orangeAdvice,
        fontWeight: 700,
      },
    },
  },

  inputObservation: {
    display: 'flex',
    justifyContent: 'center',
    margin: '.5rem 0',

    '& .MuiInputBase-root': {
      width: '100%',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.lightGray2,
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.mediumGray1,
        transition: '0.3s',
      },

      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.mediumGray1,
        borderWidth: '1px',
      },
    },

    '& label': {
      color: COLORS.mediumGray1,
    },

    '& textarea': {
      color: COLORS.gray2,
    },
  },

  buttonConfirmation: {
    padding: 0,
    height: '3rem',
    width: '29%',
    borderRadius: '0.5rem',
    textTransform: 'none',
    color: COLORS.background,
    background: COLORS.greenButton,
    fontWeight: 'bold',
    fontSize: '0.875rem',

    '&:hover': {
      background: COLORS.greenHover,
      color: COLORS.background,
    },
  },
});

interface Props {
  userId: string;
  name: string;
}

type ProductToBuy = ProductCategory & {
  quantity: number;
};

export default function ModalCreditsOperator({ userId, name }: Props) {
  const [open, setOpen] = useState(false);
  const [observation, setObservation] = useState('');
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { products } = useProducts();
  const { costCenters } = useCostCentersOperator(userId);

  const [chosenCostCenter, setChosenCostCenter] = useState(-1);

  const [productsToBuy, setProductsToBuy] = useState([] as ProductToBuy[]);

  const productIcon = {
    sendingDocuments: (
      <InsertDriveFileOutlinedIcon style={{ fontSize: '3rem' }} />
    ),
    facialBiometrics: <FaceIcon style={{ fontSize: '3rem' }} />,
    vault: <LockOutlinedIcon style={{ fontSize: '3rem' }} />,
  };

  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (products) {
      setProductsToBuy(
        products
          .sort((a, b) => a.totalValue - b.totalValue)
          .map(product => ({ ...product, quantity: 0 })),
      );
    }

    setObservation('');
  };

  const handleButtonQuantityCredits = (value: number, index: number) => {
    const localProductsToBuy = [...productsToBuy];
    localProductsToBuy[index].quantity += value;

    setProductsToBuy(localProductsToBuy);
  };

  const handleInputQuantityCredits = (value: string, index: number) => {
    const localProductsToBuy = [...productsToBuy];
    localProductsToBuy[index].quantity = Number(value);

    setProductsToBuy(localProductsToBuy);
  };

  const handleBuy = async () => {
    if (!observation) {
      showAlert('informObservationInCredits', 'error', null);
      return;
    }

    setIsSubmitting(true);

    try {
      await api.post('/plan', {
        user_id: userId,
        costcenter_id:
          chosenCostCenter !== -1 ? costCenters[chosenCostCenter]._id : null,
        typePayment: 'payment',
        linkPayment: false,
        products: productsToBuy
          .filter(product => product.quantity > 0)
          .map(product => ({
            product_id: product._id,
            name: product.name,
            quantity: product.quantity,
          })),
        language: i18n.language,
        observation,
      });

      handleClose();
    } catch (error) {
      showAlert('buyCreditsError', 'error', null);
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (products) {
      setProductsToBuy(
        products
          .sort((a, b) => a.totalValue - b.totalValue)
          .map(product => ({ ...product, quantity: 0 })),
      );
    }
  }, [products]);

  return (
    <>
      <Button
        className={classes.buttonBuyCreditsInOperator}
        startIcon={<MonetizationOnIcon />}
        onClick={handleOpen}
      >
        {t('components.modalCreditsOperator.buttonTitle')}
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle className={classes.containerTitle} disableTypography>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent style={{ padding: 0 }}>
          <div style={{ margin: '0 2.25rem 3rem 2.25rem' }}>
            <h1 className={classes.title}>
              {t('components.modalCreditsOperator.creditsTitle')}
            </h1>

            <div className={classes.products}>
              <CostCenterSelectOperator
                chosenCostCenter={chosenCostCenter}
                setChosenCostCenter={setChosenCostCenter}
                userId={userId}
                name={name}
              />

              {productsToBuy.map((product, index) => (
                <div className="item" key={product._id}>
                  {productIcon[product.name]}

                  <div className={classes.productDescription}>
                    <p className="title">
                      {t(
                        `components.modalCreditsOperator.products.${product.name}`,
                      )}
                    </p>

                    <div>
                      <span className="value">
                        R$ {product.totalValue} /{' '}
                        {product.name === 'vault' ? 'GB' : 'un.'}
                      </span>

                      <div
                        className={classes.buttonGroupOperateQuantityProducts}
                      >
                        <IconButton
                          size="small"
                          disabled={!product.quantity}
                          onClick={() => {
                            const valueToRemoveOne = -1;
                            handleButtonQuantityCredits(
                              valueToRemoveOne,
                              index,
                            );
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>

                        <MaskedInput
                          mask={numberMask}
                          guide={false}
                          showMask
                          name="number"
                          value={product.quantity}
                          onChange={e => {
                            handleInputQuantityCredits(e.target.value, index);
                          }}
                        />

                        <IconButton
                          size="small"
                          onClick={() => {
                            const valueToAddOne = 1;
                            handleButtonQuantityCredits(valueToAddOne, index);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={classes.footerContainer}>
              <div>
                <span>Total</span>

                <span>
                  R${' '}
                  {productsToBuy
                    .reduce(
                      (previousValue: number, currentValue: ProductToBuy) =>
                        previousValue +
                        currentValue.totalValue * currentValue.quantity,
                      0,
                    )
                    .toFixed(2)}
                </span>
              </div>

              <TextField
                id="observation"
                label={t('components.modalCreditsOperator.observation')}
                variant="outlined"
                className={classes.inputObservation}
                multiline
                minRows={3}
                value={observation}
                onChange={e => {
                  setObservation(e.target.value);
                }}
                fullWidth
              />

              <Button
                onClick={handleBuy}
                className={classes.buttonConfirmation}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  t('components.modalCreditsOperator.buttonBuy')
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
