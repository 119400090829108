/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import useAlert from '../../../hooks/useAlertContext';

import { CostCenter, GroupUser } from '../../../types/CostCenter';

import api from '../../../services/api';

import getTwoFirstLettersByName from '../../../utils/getTwoFirstLettersByName';
import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  avatar: {
    color: COLORS.gray2,
    fontSize: '0.75rem',
    cursor: 'pointer',
    backgroundColor: COLORS.lightGray4,

    '&.accepted': {
      backgroundColor: COLORS.lightGreen,
    },
  },

  contentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '1rem',

    '& section': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '.5rem',
      marginBottom: '1rem',
      fontSize: '1rem',

      '& p': {
        fontWeight: 500,
      },

      '& span': {
        fontWeight: 700,
      },
    },
  },

  buttonNo: {
    backgroundColor: COLORS.black,
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.white,
    textTransform: 'none',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.pureBlack,
      transition: '0.3s',
    },
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 3rem',
    marginTop: '1.5rem',
  },

  buttonYes: {
    backgroundColor: 'none',
    width: '13.5rem',
    minWidth: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.redWarning,
    textTransform: 'none',
    border: `1px ${COLORS.redWarning} solid`,
    margin: '0.75rem 0 2.25rem 0 !important',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.redWarning,
      color: COLORS.white,
      transition: '0.3s',
    },
  },
});

interface Props {
  isOpen: boolean;
  groupUser: GroupUser;
  center: CostCenter;
}

const ModalCostCenterParticipantInfo: React.FC<Props> = ({
  isOpen = false,
  groupUser,
  center,
}) => {
  const [open, setOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showAlert } = useAlert();

  const classes = useStyles();

  const { t } = useTranslation();

  const handleModal = (boolean: boolean) => {
    setOpen(boolean);
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmation(true);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const data = {
      costcenter_id: center._id,
      user_id: groupUser.user?._id,
    };

    try {
      const response = await api.post('/costcenter/user/remove', data);

      setIsSubmitting(false);
      setDeleteConfirmation(false);
      handleModal(false);
      showAlert(
        'costCenterParticipantRemovedSuccessfully',
        'success',
        response.data._id,
      );
    } catch (error) {
      console.log(error);
      showAlert('costCenterParticipantError', 'error', JSON.stringify(error));
      handleModal(false);
      setDeleteConfirmation(false);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const [lastStatus] = groupUser.status.slice(-1);
  const alreadyAccepted = lastStatus.is_active && lastStatus.name === 'active';

  return (
    <>
      <Tooltip
        title={`${groupUser.user?.name} - ${
          alreadyAccepted ? 'confirmado' : 'não confirmado'
        }`}
        key={groupUser.user._id}
      >
        <Avatar
          role="button"
          onClick={() => {
            handleModal(true);
          }}
          aria-label={t('client.costCenters.participantLabel')}
          className={clsx(classes.avatar, { accepted: alreadyAccepted })}
        >
          {getTwoFirstLettersByName(groupUser.user?.name ?? '?')}
        </Avatar>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => {
          handleModal(false);
          setDeleteConfirmation(false);
        }}
      >
        <DialogTitle>
          {deleteConfirmation
            ? `${t(
                'components.modalCostCenterParticipantInfo.confirmDeleteParticipant',
              )} ${groupUser.user?.name}?`
            : `${t('components.modalCostCenterParticipantInfo.title')}`}
        </DialogTitle>

        <DialogContent style={{ minWidth: '27rem' }}>
          {!deleteConfirmation && (
            <div className={classes.contentContainer}>
              <section>
                <p>
                  {`${t(
                    'components.modalCostCenterParticipantInfo.userName',
                  )}: `}
                </p>

                <span>{groupUser.user?.name}</span>
              </section>

              <section>
                <p>
                  {`${t(
                    'components.modalCostCenterParticipantInfo.userEmail',
                  )}: `}
                </p>

                <span>{groupUser.user?.email}</span>
              </section>
            </div>
          )}
        </DialogContent>

        <DialogActions className={classes.buttonsContainer}>
          <Button
            className={classes.buttonNo}
            onClick={() => {
              handleModal(false);
              setDeleteConfirmation(false);
            }}
          >
            {t('components.modalCostCenterParticipantInfo.returnButton')}
          </Button>

          <Button
            className={classes.buttonYes}
            onClick={
              deleteConfirmation ? handleSubmit : handleDeleteConfirmation
            }
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={16} />
            ) : deleteConfirmation ? (
              t('components.modalCostCenterParticipantInfo.confirmButton')
            ) : (
              t('components.modalCostCenterParticipantInfo.actionButton')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCostCenterParticipantInfo;
