/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import { useTranslation } from 'react-i18next';

import Layout from '../../../components/Layout';

import useCostCenters from '../../../hooks/useCostCenters';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  title: {
    fontSize: '3rem',
    fontWeight: 700,
    marginBottom: '2.25rem',
    color: COLORS.pureBlack,
  },

  folderItem: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${COLORS.gray1}`,
    borderRadius: '.5rem',
    columnGap: '.5rem',
    color: COLORS.black,
    textDecoration: 'none',
    padding: '1rem',
    maxWidth: '18.875rem',

    '&:hover': {
      backgroundColor: COLORS.lightGray3,
      transition: '0.3s',
      cursor: 'pointer',
    },

    '& p': {
      fontSize: '1.125rem',
      fontWeight: 700,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },

  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '71vh',
    rowGap: '1.5rem',
    fontSize: '2.25rem',
    color: COLORS.gray2,
    fontWeight: 500,
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64.5vh',
  },
});

export default function Folders() {
  const classes = useStyles();

  const { t } = useTranslation();
  const { costCenters, error } = useCostCenters();

  return (
    <Layout type="user" routeSelected="folders">
      <Typography variant="h1" className={classes.title}>
        {t('client.folders.title')}
      </Typography>

      {costCenters ? (
        <Grid container style={{ gap: 8 }}>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            className={classes.folderItem}
            component={Link}
            to="/folders/personal"
          >
            <FolderIcon />

            <p>{t('client.folders.index.personalTitle')}</p>
          </Grid>

          {costCenters.data.map(costCenter => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              key={costCenter._id}
              className={classes.folderItem}
              component={Link}
              to={`/folders/${costCenter._id}`}
            >
              <FolderIcon />

              <p>{costCenter.name}</p>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div className={classes.errorContainer}>
          {error ? t('client.folders.error') : <CircularProgress />}
        </div>
      )}
    </Layout>
  );
}
