import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ControlledPublicRoute from './public.routes';
import PrivateRoute from './private.routes';

import Login from '../pages/Login';
import Validator from '../pages/Validator';
import ViewValidator from '../pages/Validator/view';
import NotFound from '../pages/NotFound';
import PublicDocumentReview from '../pages/PublicDocumentReview';
import Privacy from '../pages/Privacy';

import PendingRegisters from '../pages/Operator/PendingRegisters';
import EditPendingRegisters from '../pages/Operator/PendingRegisters/edit';
import Clients from '../pages/Operator/Clients';
import EditClient from '../pages/Operator/Clients/edit';

import Home from '../pages/Client/Home';
import Documents from '../pages/Client/Documents';
import DocumentView from '../pages/Client/Documents/view';
import Credits from '../pages/Client/Credits';
import History from '../pages/Client/History';
import Profile from '../pages/Client/Profile';
import Pricing from '../pages/Client/Pricing';
import CostCenters from '../pages/Client/CostCenters';
import Vault from '../pages/Client/Vault';
import CreateVault from '../pages/Client/Vault/create';
import ViewVault from '../pages/Client/Vault/view';
import Folders from '../pages/Client/Folders';
import FoldersView from '../pages/Client/Folders/view';

export default function Routes() {
  return (
    <Switch>
      <ControlledPublicRoute exact path="/" component={Login} />
      <Route exact path="/validator" component={Validator} />
      <Route exact path="/validator/result" component={ViewValidator} />
      <Route
        exact
        path="/documents/public-review/:id"
        component={PublicDocumentReview}
      />
      <Route exact path="/privacy" component={Privacy} />

      <PrivateRoute
        exact
        path="/registers"
        component={PendingRegisters}
        type="moderator"
      />
      <PrivateRoute
        exact
        path="/registers/:id"
        component={EditPendingRegisters}
        type="moderator"
      />
      <PrivateRoute
        exact
        path="/clients"
        component={Clients}
        type="moderator"
      />
      <PrivateRoute
        exact
        path="/clients/:id"
        component={EditClient}
        type="moderator"
      />

      <PrivateRoute exact path="/home" component={Home} type="user" />
      <PrivateRoute exact path="/documents" component={Documents} type="user" />
      <PrivateRoute
        exact
        path="/documents/:id"
        component={DocumentView}
        type="user"
      />
      <PrivateRoute exact path="/credits" component={Credits} type="user" />
      <PrivateRoute exact path="/plans" component={Pricing} type="user" />
      <PrivateRoute exact path="/history" component={History} type="user" />
      <PrivateRoute exact path="/profile" component={Profile} type="user" />
      <PrivateRoute
        exact
        path="/cost_centers"
        component={CostCenters}
        type="user"
      />
      <PrivateRoute exact path="/vault" component={Vault} type="user" />
      <PrivateRoute
        exact
        path="/vault/create"
        component={CreateVault}
        type="user"
      />
      <PrivateRoute exact path="/vault/:id" component={ViewVault} type="user" />
      <PrivateRoute exact path="/folders" component={Folders} type="user" />
      <PrivateRoute
        exact
        path="/folders/:id"
        component={FoldersView}
        type="user"
      />

      <Route component={NotFound} />
    </Switch>
  );
}
