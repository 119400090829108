import { useAxios } from './useAxios';

import { Subscription } from '../types/Subscription';

import linksForSWR from '../utils/linksForSWR';

interface Response {
  subscription?: Subscription;
  error: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  mutateSubscription: (
    data?: Subscription,
    shouldRevalidate?: boolean,
  ) => Promise<Subscription>;
}

export default function useSubscription(costCenter: string | null): Response {
  const {
    data: subscription,
    error,
    mutate: mutateSubscription,
  } = useAxios(linksForSWR.client.subscriptions(costCenter || ''));

  return { subscription, error, mutateSubscription };
}
