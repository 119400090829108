/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import BeenHereIcon from '@material-ui/icons/Beenhere';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Layout from '../../../components/Layout';
import CostCenterSelect from '../../../components/CostCenterSelect';
import ModalEditAddressInPricing from '../../../components/Modals/ModalEditAddressInPricing';

import { useAxios } from '../../../hooks/useAxios';
import useAlert from '../../../hooks/useAlertContext';
import useCostCenters from '../../../hooks/useCostCenters';
import useSubscription from '../../../hooks/useSubscription';

import { Plan, PlansResponse } from '../../../types/Plan';
import { UserProfile } from '../../../types/UserProfile';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';

import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '71vh',
  },

  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
  },

  divider: {
    width: '100%',
    height: '0.125rem',
    backgroundColor: COLORS.lightGray2,
    margin: '2rem 0',
  },

  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: '2.25rem',
    color: COLORS.pureBlack,
  },

  sectionValue: {
    fontSize: '1rem',
    color: COLORS.gray2,
    fontWeight: 800,
  },

  creditsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  sectionInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '.5rem',
    padding: '0 .5rem',
  },

  subtitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },

  sectionSubtitle: {
    fontSize: '1rem',
    color: COLORS.gray2,
    fontWeight: 500,
  },

  pricingContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2rem',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginBottom: 'auto',
    },
  },

  cardAndButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6rem',
    width: '100%',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },

  userPlanButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1.5rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  pricingPaper: {
    width: '16.75rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    height: '19rem',
    borderRadius: '0.5rem',
  },

  bestCostTitle: {
    width: '100%',
    backgroundColor: COLORS.blue,
    height: '3.375rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '.5rem .5rem 0 0',

    '& span': {
      color: COLORS.white,
      fontWeight: 'bold',
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
  },

  pricingHeader: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5rem 0 0 0',
    rowGap: '0.75rem',

    '& div': {
      height: '0.25rem',
      width: '5.625rem',
      backgroundColor: COLORS.gray5,

      '&.currentPlan': {
        backgroundColor: COLORS.blue,
      },
    },

    '& h2': {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: '400',
      color: COLORS.pureBlack,

      '&.currentPlan': {
        color: COLORS.blue,
      },
    },
  },

  creditsBlackButton: {
    backgroundColor: COLORS.gray1,
    width: '12rem',
    height: '3.25rem',
    borderRadius: '0.5rem',
    padding: '0.625rem',
    justifyContent: 'center',
    color: COLORS.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    border: 'none',
    textTransform: 'none',

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',
    },

    '& .Mui-disabled': {
      color: COLORS.mediumGray2,
      backgroundColor: COLORS.lightGray2,
    },
  },

  pricingValue: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.25rem',
    margin: '0.5rem 0 0 0',

    '& h2': {
      fontWeight: '400',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      color: COLORS.pureBlack,
    },

    '& span': {
      color: COLORS.pureBlack,
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
  },

  pricingItem: {
    margin: '1.125rem 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',

    '& div': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem',

      '& svg': {
        fontSize: '0.875rem',
      },

      '& span': {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        color: COLORS.pureBlack,
        maxWidth: '8rem',
      },
    },

    '& .flexStart': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },

  buttonChoose: {
    height: '3.5rem',
    width: '8.5rem',
    margin: '1.5rem 0 0 0',
    color: COLORS.white,
    backgroundColor: COLORS.black,

    '&:hover': {
      color: COLORS.white,
      backgroundColor: COLORS.black,
      transition: '0.3s',
    },

    '&.Mui-disabled': {
      color: COLORS.white,
      backgroundColor: COLORS.gray1,
    },
  },

  buttonChooseBestCost: {
    backgroundColor: COLORS.blue,

    '&:hover': {
      color: COLORS.white,
      backgroundColor: COLORS.blueHover,
      transition: '0.3s',
    },

    '&.Mui-disabled': {
      color: COLORS.white,
      backgroundColor: COLORS.blueHover,
    },
  },

  currentPlan: {
    marginTop: '1.5rem',
    height: '3.5rem',
    width: '100%',
    columnGap: '.5rem',
    fontWeight: 600,
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.black,

    '&.isCurrent': {
      color: COLORS.blue,
    },
  },

  dialogTitleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  closeDialogButton: {
    width: '3rem',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.5rem',

    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
      color: COLORS.mediumGray4,
      cursor: 'pointer',
    },
  },

  iconContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2rem 0',
  },

  errorContainer: {
    width: '6.25rem',
    height: '6.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },

  modalButtonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
  },

  returnButton: {
    backgroundColor: COLORS.black,
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.white,
    textTransform: 'none',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.pureBlack,
      transition: '0.3s',
    },
  },

  confirmButton: {
    backgroundColor: 'none',
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.redWarning,
    textTransform: 'none',
    border: `1px ${COLORS.redWarning} solid`,
    margin: '0.75rem 0 2.25rem 0',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.redWarning,
      color: COLORS.white,
      transition: '0.3s',
    },
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: '0.5rem',

    '&:hover': {
      border: `0.125rem solid ${COLORS.blue}`,
      backgroundColor: 'rgba(25, 177, 210, .1)',
      opacity: 0.8,
      transition: '0.2s',
    },
  },

  selectedPlan: {
    border: `0.125rem solid ${COLORS.blue}`,
    cursor: 'default',
  },
}));

interface ModalProps {
  openConfirmationModal: boolean;
  handleConfirmationModal: (boolean: boolean) => void;
  modalType: 'change' | 'cancel';
  newChosenPlan: Plan;
  currentPlanName: string | undefined;
  modalConfirmButtonLoading: boolean;
  handleCancelPlan: () => void;
}

function ConfirmationModal({
  openConfirmationModal,
  handleConfirmationModal,
  modalType,
  newChosenPlan,
  currentPlanName,
  modalConfirmButtonLoading,
  handleCancelPlan,
}: ModalProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={openConfirmationModal}
      onClose={() => {
        handleConfirmationModal(false);
      }}
    >
      <div className={classes.dialogTitleContainer}>
        <DialogTitle>
          {modalType === 'change'
            ? t('client.pricing.changeModal.title')
            : t('client.pricing.cancelModal.title')}
        </DialogTitle>

        <div className={classes.closeDialogButton}>
          <CloseIcon
            onClick={() => {
              handleConfirmationModal(false);
            }}
          />
        </div>
      </div>

      <DialogContent>
        <p style={{ textAlign: 'justify' }}>
          {newChosenPlan.name === 'CustomPlan' ? (
            <>
              <p style={{ marginBottom: '1rem' }}>
                {t('client.pricing.modal.customPlanContact')}
              </p>

              <p>{t('client.pricing.modal.customPlanWarning')}</p>
            </>
          ) : modalType === 'change' ? (
            <>
              {t('client.pricing.modal.content.certain')}
              <span style={{ fontWeight: 'bold', color: COLORS.redWarning }}>
                {` ${t('client.pricing.changeModal.action')} `}
              </span>
              {` ${t('client.pricing.modal.content.object')} `}
              <span
                style={{ fontWeight: 'bold' }}
              >{` ${currentPlanName} `}</span>
              {` ${t('client.pricing.changeModal.to')} `}
              <span
                style={{ fontWeight: 'bold' }}
              >{` ${newChosenPlan.name}`}</span>
              ?
            </>
          ) : (
            <>
              {t('client.pricing.modal.content.certain')}
              <span style={{ fontWeight: 'bold', color: COLORS.redWarning }}>
                {` ${t('client.pricing.cancelModal.action')} `}
              </span>
              {` ${t('client.pricing.modal.content.object')} `}
              <span
                style={{ fontWeight: 'bold' }}
              >{` ${currentPlanName}`}</span>
              ?
            </>
          )}
        </p>

        {newChosenPlan.name !== 'CustomPlan' && (
          <>
            <div className={classes.iconContainer}>
              <div
                className={classes.errorContainer}
                style={{ backgroundColor: COLORS.lightRed }}
              >
                <ErrorOutlineIcon
                  style={{ fontSize: '3.25rem', color: COLORS.white }}
                />
              </div>
            </div>

            <p style={{ textAlign: 'justify' }}>
              {t('client.pricing.modal.warning')}
            </p>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <div className={classes.modalButtonsContainer}>
          <Button
            className={classes.returnButton}
            onClick={() => {
              handleConfirmationModal(false);
            }}
          >
            {t('client.pricing.modal.returnButton')}
          </Button>

          <Button
            className={classes.confirmButton}
            onClick={() => {
              if (newChosenPlan.name === 'CustomPlan') {
                window.open(
                  'https://dattaid.com.br/atendimento.html',
                  '_blank',
                );
                return;
              }
              handleCancelPlan();
            }}
          >
            {newChosenPlan.name === 'CustomPlan' ? (
              t('client.pricing.modal.customPlanButton')
            ) : modalConfirmButtonLoading ? (
              <CircularProgress size={18} />
            ) : modalType === 'change' ? (
              `${t('client.pricing.modal.confirmButton')}, ${t(
                'client.pricing.changeModal.action',
              )}`
            ) : (
              `${t('client.pricing.modal.confirmButton')}, ${t(
                'client.pricing.cancelModal.action',
              )}`
            )}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default function Pricing() {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { data: plans } = useAxios(linksForSWR.plans()) as {
    data: PlansResponse;
  };

  const { costCenters } = useCostCenters();

  const [isMonthly] = useState(true);
  const [newChosenPlan, setNewChosenPlan] = useState({} as Plan);
  const [changePlan, setChangePlan] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPlanName, setCurrentPlanName] = useState('');
  const [modalType, setModalType] = useState<'change' | 'cancel'>('change');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [modalConfirmButtonLoading, setModalConfirmButtonLoading] =
    useState(false);

  const [isOpenModalEditAddress, setIsOpenModalEditAddress] = useState(false);
  const [chosenCostCenter, setChosenCostCenter] = useState(-1);

  const { subscription } = useSubscription(
    chosenCostCenter !== -1 ? costCenters.data[chosenCostCenter]._id : null,
  );

  const { data: userProfile } = useAxios(linksForSWR.client.profile()) as {
    data: UserProfile;
    mutate: (
      data?: UserProfile,
      shouldRevalidate?: boolean | undefined,
    ) => void;
  };

  const handleConfirmationModal = (boolean: boolean) => {
    setOpenConfirmationModal(boolean);
  };

  const handleSubscribePlan = async (plan: Plan) => {
    setModalConfirmButtonLoading(true);
    setIsSubmitting(true);

    if (!isOpenModalEditAddress && !userProfile.address.zipNumber) {
      setIsOpenModalEditAddress(true);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await api.post('/subscriptions', {
        plan_id: plan._id,
        plan_name: plan.name,
        interval: isMonthly ? 'month' : 'year',
        language: i18n.language,
        costcenter_id:
          chosenCostCenter !== -1
            ? costCenters.data[chosenCostCenter]._id
            : null,
      });

      handleConfirmationModal(false);
      setIsSubmitting(false);
      setModalConfirmButtonLoading(false);

      window.open(response.data.url, '_blank');
    } catch (error) {
      console.log(error);
      setModalConfirmButtonLoading(false);
      setIsSubmitting(false);
      showAlert('commonError', 'error', JSON.stringify(error));
    }
  };

  const handleCancelPlan = async () => {
    setModalConfirmButtonLoading(true);

    try {
      const response = await api.delete(`/subscriptions/${subscription?._id}`);

      setModalConfirmButtonLoading(false);
      if (!changePlan) {
        showAlert('canceledPlanSuccessfully', 'success', response.data._id);
      }
      if (changePlan) {
        handleSubscribePlan(newChosenPlan);
      }
    } catch (error) {
      console.log(error);
      setModalConfirmButtonLoading(false);
      showAlert('commonError', 'error', JSON.stringify(error));
    }
  };

  const handleChoosePlan = (plan: Plan) => {
    setNewChosenPlan(plan);
    if (changePlan) {
      if (plan.name !== currentPlanName) {
        setModalType('change');
        handleConfirmationModal(true);
      }
    }
  };

  return (
    <Layout type="user" routeSelected="plans">
      <Typography variant="h1" className={classes.title}>
        {t('client.pricing.title')}
      </Typography>

      <div className={classes.contentContainer}>
        <div className={classes.creditsContainer}>
          <div className={classes.sectionInnerContainer}>
            {subscription?.plans.name ? (
              <>
                <Typography className={classes.sectionSubtitle}>
                  {t('client.pricing.activePlan')}:
                </Typography>

                <Typography className={classes.sectionValue}>
                  {subscription?.plans.name}
                </Typography>
              </>
            ) : (
              <div className={classes.subtitleContainer}>
                <Typography className={classes.sectionSubtitle}>
                  {t('client.pricing.noActivePlan')}
                </Typography>

                <Typography className={classes.sectionSubtitle}>
                  {t('client.pricing.choosePlan')}
                </Typography>
              </div>
            )}
          </div>

          <div style={{ width: '50%' }}>
            <CostCenterSelect
              chosenCostCenter={chosenCostCenter}
              setChosenCostCenter={setChosenCostCenter}
            />
          </div>
        </div>

        <div className={classes.divider} />

        {changePlan && (
          <div style={{ width: '100%', padding: '0 .5rem' }}>
            <Typography className={classes.sectionSubtitle}>
              {t('client.pricing.choosePlan')}
            </Typography>
          </div>
        )}

        {(plans && subscription === null) || changePlan ? (
          <>
            <div className={classes.pricingContainer}>
              {plans.data
                .sort((a, b) => a.totalValue - b.totalValue)
                .map((plan, index) => {
                  const isCurrentPlan = plan._id === subscription?.plans._id;
                  const isCustomPlan = plan.name === 'CustomPlan';

                  return (
                    <div
                      role="button"
                      aria-label={t('client.pricing.buttonChoose')}
                      key={plan._id}
                      tabIndex={index}
                      onKeyDown={() => {
                        handleChoosePlan(plan);
                      }}
                      onClick={() => {
                        handleChoosePlan(plan);
                      }}
                    >
                      <Paper
                        className={clsx(
                          classes.pricingPaper,
                          classes.cardContainer,
                          {
                            [classes.selectedPlan]:
                              newChosenPlan._id === plan._id,
                          },
                        )}
                        elevation={2}
                      >
                        <div className={classes.pricingHeader}>
                          <div
                            className={clsx({ currentPlan: isCurrentPlan })}
                          />

                          <h2 className={clsx({ currentPlan: isCurrentPlan })}>
                            {t(`client.pricing.${plan.name}.title`)}
                          </h2>
                        </div>

                        <div className={classes.pricingValue}>
                          <h2>
                            R${' '}
                            {isCustomPlan
                              ? '$$$'
                              : plan.value.toFixed(2).replace('.', ',')}
                          </h2>

                          {!isCustomPlan && (
                            <span>
                              {t('client.pricing.value.per')}{' '}
                              {isMonthly
                                ? t('client.pricing.value.formMonth')
                                : t('client.pricing.value.formYear')}{' '}
                            </span>
                          )}
                        </div>

                        <div
                          className={classes.pricingItem}
                          style={{ cursor: 'pointer' }}
                        >
                          {isCustomPlan && (
                            <div>
                              <CheckOutlinedIcon />

                              <span>
                                {t(`client.pricing.CustomPlan.description`)}
                              </span>
                            </div>
                          )}

                          {plan.products.map(item => (
                            <div key={item.product._id}>
                              <CheckOutlinedIcon />

                              <span>
                                {item.quantity}{' '}
                                {t(
                                  `client.pricing.products.${item.product.name}`,
                                )}
                              </span>
                            </div>
                          ))}
                        </div>

                        {plan._id === subscription?.plans._id ? (
                          <div
                            className={clsx(classes.currentPlan, {
                              isCurrent: isCurrentPlan,
                            })}
                          >
                            <BeenHereIcon style={{ color: COLORS.blue }} />

                            <span style={{ color: COLORS.blue }}>
                              {t('client.pricing.currentPlan')}
                            </span>
                          </div>
                        ) : (
                          <Button
                            disabled={newChosenPlan.name === currentPlanName}
                            onClick={() => {
                              handleChoosePlan(plan);
                            }}
                            className={clsx(classes.buttonChoose, {
                              [classes.buttonChooseBestCost]: isCurrentPlan,
                            })}
                          >
                            {t('client.pricing.buttonChoose')}
                          </Button>
                        )}
                      </Paper>
                    </div>
                  );
                })}
            </div>

            {!changePlan ? (
              <div className={classes.userPlanButtonsContainer}>
                <Button
                  className={classes.creditsBlackButton}
                  onClick={() => {
                    if (changePlan) {
                      setChangePlan(false);
                    }
                    history.push('/credits');
                  }}
                >
                  {t('client.pricing.cancelButton')}
                </Button>

                <Button
                  className={classes.creditsBlackButton}
                  onClick={() => {
                    if (newChosenPlan.name === 'CustomPlan') {
                      window.open(
                        'https://dattaid.com.br/atendimento.html',
                        '_blank',
                      );
                      return;
                    }
                    handleSubscribePlan(newChosenPlan);
                  }}
                  disabled={
                    isSubmitting ||
                    newChosenPlan.name === currentPlanName ||
                    Object.keys(newChosenPlan).length === 0
                  }
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={18}
                      style={{ color: COLORS.white }}
                    />
                  ) : (
                    t('client.pricing.continueButton')
                  )}
                </Button>
              </div>
            ) : (
              <div className={classes.userPlanButtonsContainer}>
                <Button
                  id="cancelPlan"
                  className={classes.creditsBlackButton}
                  onClick={() => {
                    if (changePlan) {
                      setChangePlan(false);
                    }
                  }}
                >
                  {t('client.pricing.cancelButton')}
                </Button>
              </div>
            )}
          </>
        ) : plans === undefined || subscription === undefined ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.pricingContainer}>
            <div className={classes.cardAndButtonsContainer}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.bestCostTitle}>
                  <span>{t('client.pricing.activePlan')}</span>
                </div>

                <Paper className={classes.pricingPaper} elevation={2}>
                  <>
                    <div className={classes.pricingHeader}>
                      <div className={classes.bestCostTitle} />

                      <h2 style={{ color: COLORS.blue }}>
                        {subscription?.plans.name}
                      </h2>
                    </div>

                    <div className={classes.pricingValue}>
                      <h2>
                        R${' '}
                        {subscription.totalValue.toFixed(2).replace('.', ',')}
                      </h2>

                      <span>
                        {t('client.pricing.value.per')}{' '}
                        {isMonthly
                          ? t('client.pricing.value.formMonth')
                          : t('client.pricing.value.formYear')}{' '}
                      </span>
                    </div>

                    <div className={classes.pricingItem}>
                      {subscription.products.map(product => (
                        <div key={product._id}>
                          <CheckOutlinedIcon />

                          <span>
                            {product.quantity}{' '}
                            {t(`client.pricing.products.${product.name}`)}
                          </span>
                        </div>
                      ))}
                    </div>

                    <div className={`${classes.currentPlan} true`}>
                      <BeenHereIcon />

                      <span>{t('client.pricing.currentPlan')}</span>
                    </div>
                  </>
                </Paper>
              </div>

              <div className={classes.userPlanButtonsContainer}>
                <Button
                  id="changePlan"
                  className={classes.creditsBlackButton}
                  onClick={() => {
                    setCurrentPlanName(subscription?.plans.name || '');
                    setChangePlan(true);
                  }}
                >
                  {t('client.pricing.changePlanButton')}
                </Button>

                <Button
                  id="cancelPlan"
                  className={classes.creditsBlackButton}
                  onClick={() => {
                    setModalType('cancel');
                    setCurrentPlanName(subscription?.plans.name || '');
                    handleConfirmationModal(true);
                  }}
                >
                  {t('client.pricing.cancelPlanButton')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ConfirmationModal
        openConfirmationModal={openConfirmationModal}
        handleConfirmationModal={handleConfirmationModal}
        modalType={modalType}
        newChosenPlan={newChosenPlan}
        currentPlanName={currentPlanName}
        modalConfirmButtonLoading={modalConfirmButtonLoading}
        handleCancelPlan={handleCancelPlan}
      />

      <ModalEditAddressInPricing
        isOpen={isOpenModalEditAddress}
        setIsOpen={setIsOpenModalEditAddress}
        handleSubscribePlan={handleSubscribePlan}
        chosenPlan={newChosenPlan}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
      />
    </Layout>
  );
}
