import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import useAuthContext from '../hooks/useAuthContext';

import { Role } from '../types/Role';

interface Props {
  component: React.FC;
  exact: boolean;
  path: string;
  type: Role;
}

const PrivateRoute = ({ component: Component, exact, path, type }: Props) => {
  const { user } = useAuthContext();

  const roleIsValidated = () => {
    if (!user) return false;
    if (type === 'user') return user.role === 'user';

    return user.role === 'moderator' || user.role === 'admin';
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={() => (roleIsValidated() ? <Component /> : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
