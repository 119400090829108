const repeat = (element: RegExp | string, times: number) =>
  Array.from({ length: times }, () => element);

export const cpfMask = () => [
  ...repeat(/\d/, 3),
  '.',
  ...repeat(/\d/, 3),
  '.',
  ...repeat(/\d/, 3),
  '-',
  ...repeat(/\d/, 2),
];

// Credits: https://github.com/text-mask/text-mask/issues/174
export const phoneMask = (userInput: string) => {
  const numbers = userInput.match(/\d/g) || [];

  return [
    '(',
    ...repeat(/[1-9]/, 2),
    ')',
    ' ',
    ...repeat(/\d/, numbers.length > 10 ? 5 : 4),
    '-',
    ...repeat(/\d/, 4),
  ];
};

export const cepMask = () => {
  return [...repeat(/\d/, 2), '.', ...repeat(/\d/, 3), '-', ...repeat(/\d/, 3)];
};

export const numberMask = (userInput: string) => {
  const numbers = userInput.match(/\d/g) || [];

  return repeat(/\d/, numbers.length);
};
