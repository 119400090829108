/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import CryptoJS from 'crypto-js';
import fileTypeChecker from 'file-type-checker';

function convertWordArrayToUint8Array(wordArray: CryptoJS.lib.WordArray) {
  const arrayOfWords = wordArray.words ?? [];
  const length = wordArray.sigBytes ?? arrayOfWords.length * 4;
  const uInt8Array = new Uint8Array(length);
  let index = 0;
  let word;
  for (let i = 0; i < length; i += 1) {
    word = arrayOfWords[i];
    uInt8Array[index++] = word >> 24;
    uInt8Array[index++] = (word >> 16) & 0xff;
    uInt8Array[index++] = (word >> 8) & 0xff;
    uInt8Array[index++] = word & 0xff;
  }

  return uInt8Array;
}

const decryptFile = (encryptedFile: string, secret: string) => {
  const encryptedFileBinary = Buffer.from(encryptedFile, 'base64').toString(
    'binary',
  );
  const decrypted = CryptoJS.AES.decrypt(encryptedFileBinary, secret);

  const typedArray = convertWordArrayToUint8Array(decrypted);

  const fileType = fileTypeChecker.detectFile(typedArray);

  const fileBlob = new Blob([typedArray], {
    type: fileType?.mimeType,
  });

  return fileBlob;
};

export default decryptFile;
