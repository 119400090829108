import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import useAlert from '../../../hooks/useAlertContext';
import useCostCenters from '../../../hooks/useCostCenters';

import { CostCenter } from '../../../types/CostCenter';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  deleteButton: {
    border: `0.125rem solid ${COLORS.lightRed}`,
    padding: 0,
    width: '2.25rem',
    minWidth: '2.25rem',
    height: '2.25rem',
    borderRadius: '0.5rem',
    marginRight: '1rem',

    '& svg': {
      fontSize: '1.5rem',
      color: COLORS.lightRed,
    },

    '&:hover': {
      transition: '0.3s',
      backgroundColor: COLORS.lightRed,

      '& svg': {
        fill: COLORS.white,
        transition: 'all 0.3s',
      },
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiSvgIcon-root': {
      fontSize: '2.25rem',
      color: COLORS.mediumGray4,
      cursor: 'pointer',

      '&:hover': {
        color: COLORS.mediumGray5,
        transition: '0.3s',
      },
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 3rem',
    minWidth: '28rem',
  },

  textContent: {
    backgroundColor: COLORS.white,
    fontSize: '1.125rem',
    color: COLORS.black,
    maxWidth: '29.25rem',

    '& .blackBold': {
      color: COLORS.black,
      fontWeight: 'bold',
    },
    '& .redBold': {
      color: COLORS.redWarning,
      fontWeight: 'bold',
    },
  },

  iconContainer: {
    width: '3.875rem',
    height: '3.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.redWarning,
    borderRadius: '50%',
    margin: '1.75rem 0',

    '& .MuiSvgIcon-root': {
      fontSize: '2.375rem',
      color: COLORS.white,
    },
  },

  buttonNo: {
    backgroundColor: COLORS.black,
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.white,
    textTransform: 'none',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.pureBlack,
      transition: '0.3s',
    },
  },

  buttonYes: {
    backgroundColor: 'none',
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.redWarning,
    textTransform: 'none',
    border: `1px ${COLORS.redWarning} solid`,
    margin: '0.75rem 0 2.25rem 0',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.redWarning,
      color: COLORS.white,
      transition: '0.3s',
    },
  },
});

interface Props {
  center: CostCenter;
}

export default function ModalDeleteCostCenter({ center }: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { showAlert } = useAlert();

  const { t } = useTranslation();

  const { costCenters, mutateCostCenters } = useCostCenters();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      await api.delete(`/costcenter/${center._id}`);

      setIsDeleting(false);

      mutateCostCenters({
        data: costCenters.data.filter(item => item._id !== center._id),
        xTotalCount: costCenters.xTotalCount - 1,
      });
      showAlert('costCenterDeletedSuccessfully', 'success', center._id);
      handleClose();
    } catch (err) {
      setIsDeleting(false);
      console.log(err);
      showAlert('costCenterError', 'error', center._id);
      handleClose();
    }
  };

  return (
    <>
      <Tooltip title={t('components.modalDeleteCostCenter.title')}>
        <Button
          aria-label={t('components.modalDeleteCostCenter.title')}
          className={classes.deleteButton}
          id={center._id}
          onClick={handleOpen}
        >
          <DeleteOutlineIcon />
        </Button>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography className={classes.title}>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent className={classes.content}>
          <p className={classes.textContent}>
            {t('components.modalDeleteCostCenter.content.title')}{' '}
            <span className="redBold">
              {t('components.modalDeleteCostCenter.content.delete')}
            </span>{' '}
            {t('components.modalDeleteCostCenter.content.deleteObject')}
            <span className="blackBold">{` ${center.name}`}</span>?
          </p>

          <div className={classes.iconContainer}>
            <DeleteOutlinedIcon />
          </div>

          <Button className={classes.buttonNo} onClick={handleClose}>
            {t('components.modalDeleteCostCenter.no')}
          </Button>

          <Button
            className={classes.buttonYes}
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <CircularProgress
                size={16}
                style={{ color: COLORS.redWarning }}
              />
            ) : (
              t('components.modalDeleteCostCenter.yes')
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
