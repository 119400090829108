import { useAxios } from './useAxios';

import { CostCenter } from '../types/CostCenter';

import linksForSWR from '../utils/linksForSWR';

interface CostCenterData {
  data: CostCenter[];
  xTotalCount: number;
}

interface Response {
  costCenters: CostCenterData;
  error: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  mutateCostCenters: (
    data?: CostCenterData,
    shouldRevalidate?: boolean,
  ) => Promise<CostCenterData>;
}

export default function useCostCenters(): Response {
  const {
    data: costCenters,
    error,
    mutate: mutateCostCenters,
  } = useAxios(linksForSWR.client.costCenters(null, null));

  return { costCenters, error, mutateCostCenters };
}
