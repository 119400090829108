import ptBR from './default.pt_br.json';
import en from './default.en.json';

import loginPtBR from '../../pages/Login/locales/pt_br.json';
import validatorPtBR from '../../pages/Validator/locales/pt_br.json';
import clientProfilePtBR from '../../pages/Client/Profile/locales/pt_br.json';
import clientHomePtBR from '../../pages/Client/Home/locales/pt_br.json';
import clientDocumentsPtBR from '../../pages/Client/Documents/locales/pt_br.json';
import clientHistoryPtBR from '../../pages/Client/History/locales/pt_br.json';
import clientCreditsPtBR from '../../pages/Client/Credits/locales/pt_br.json';
import clientTransactionsPtBR from '../../pages/Client/Transactions/locales/pt_br.json';
import clientPricingPtBR from '../../pages/Client/Pricing/locales/pt_br.json';
import clientCostCentersPtBR from '../../pages/Client/CostCenters/locales/pt_br.json';
import clientVaultPtBR from '../../pages/Client/Vault/locales/pt_br.json';
import clientFoldersPtBR from '../../pages/Client/Folders/locales/pt_br.json';
import operatorPendingRegistersPtBR from '../../pages/Operator/PendingRegisters/locales/pt_br.json';
import operatorClientsPtBR from '../../pages/Operator/Clients/locales/pt_br.json';
import componentLayoutPtBR from '../../components/Layout/locales/pt_br.json';
import componentModalUploadToSignPtBR from '../../components/Modals/ModalUploadToSign/locales/pt_br.json';
import componentModalDeletePtBR from '../../components/Modals/ModalDelete/locales/pt_br.json';
import componentModalDeleteCostCenterPtBR from '../../components/Modals/ModalDeleteCostCenter/locales/pt_br.json';
import componentModalCreateCostCenterPtBR from '../../components/Modals/ModalCreateCostCenter/locales/pt_br.json';
import componentModalInviteCostCenterParticipantPtBR from '../../components/Modals/ModalInviteCostCenterParticipant/locales/pt_br.json';
import componentModalCostCenterParticipantInfoPtBR from '../../components/Modals/ModalCostCenterParticipantInfo/locales/pt_br.json';
import componentModalEditCostCenterPtBR from '../../components/Modals/ModalEditCostCenter/locales/pt_br.json';
import componentModalSignDocumentPtBR from '../../components/Modals/ModalSignDocument/locales/pt_br.json';
import componentModalCreditsPtBR from '../../components/Modals/ModalCredits/locales/pt_br.json';
import componentModalCreditsOperatorPtBR from '../../components/Modals/ModalCreditsOperator/locales/pt_br.json';
import componentModalApproveDocumentPtBR from '../../components/Modals/ModalApproveDocument/locales/pt_br.json';
import componentCustomPaginationPtBR from '../../components/CustomPagination/locales/pt_br.json';
import componentPersonSigneeTilePtBR from '../../components/PersonSigneeTile/locales/pt_br.json';
import componentModalOpenDocumentVaultPtBR from '../../components/Modals/ModalOpenDocumentVault/locales/pt_br.json';
import componentModalExitCreateVaultPagePtBR from '../../components/Modals/ModalExitCreateVaultPage/locales/pt_br.json';
import componentModalAlertPtBR from '../../components/Modals/ModalAlert/locales/pt_br.json';
import componentModalRevokeUserPtBR from '../../components/Modals/ModalRevokeUser/locales/pt_br.json';
import componentCostCenterSelectPtBR from '../../components/CostCenterSelect/locales/pt_br.json';
import componentCostCenterSelectOperatorPtBR from '../../components/CostCenterSelectOperator/locales/pt_br.json';
import componentModalChooseCostCenterPtBR from '../../components/Modals/ModalChooseCostCenter/locales/pt_br.json';
import componentModalSubscriptionToTheClientPtBR from '../../components/Modals/ModalSubscriptionToTheClient/locales/pt_br.json';
import componentModalEditAddressInPricingPtBR from '../../components/Modals/ModalEditAddressInPricing/locales/pt_br.json';
import componentModalFileTypesAvailableInVaultPtBR from '../../components/Modals/ModalFileTypesAvailableInVault/locales/pt_br.json';

import loginEn from '../../pages/Login/locales/en.json';
import validatorEn from '../../pages/Validator/locales/en.json';
import clientProfileEn from '../../pages/Client/Profile/locales/en.json';
import clientHomeEn from '../../pages/Client/Home/locales/en.json';
import clientDocumentsEn from '../../pages/Client/Documents/locales/en.json';
import clientHistoryEn from '../../pages/Client/History/locales/en.json';
import clientCreditsEn from '../../pages/Client/Credits/locales/en.json';
import clientTransactionsEn from '../../pages/Client/Transactions/locales/en.json';
import clientPricingEn from '../../pages/Client/Pricing/locales/en.json';
import clientCostCentersEn from '../../pages/Client/CostCenters/locales/en.json';
import clientVaultEn from '../../pages/Client/Vault/locales/en.json';
import clientFoldersEn from '../../pages/Client/Folders/locales/en.json';
import operatorPendingRegistersEn from '../../pages/Operator/PendingRegisters/locales/en.json';
import operatorClientsEn from '../../pages/Operator/Clients/locales/en.json';
import componentLayoutEN from '../../components/Layout/locales/en.json';
import componentModalUploadToSignEn from '../../components/Modals/ModalUploadToSign/locales/en.json';
import componentModalDeleteEn from '../../components/Modals/ModalDelete/locales/en.json';
import componentModalDeleteCostCenterEn from '../../components/Modals/ModalDeleteCostCenter/locales/en.json';
import componentModalCreateCostCenterEn from '../../components/Modals/ModalCreateCostCenter/locales/en.json';
import componentModalInviteCostCenterParticipantEn from '../../components/Modals/ModalInviteCostCenterParticipant/locales/en.json';
import componentModalCostCenterParticipantInfoEn from '../../components/Modals/ModalCostCenterParticipantInfo/locales/en.json';
import componentModalEditCostCenterEn from '../../components/Modals/ModalEditCostCenter/locales/en.json';
import componentModalSignDocumentEn from '../../components/Modals/ModalSignDocument/locales/en.json';
import componentModalCreditsEn from '../../components/Modals/ModalCredits/locales/en.json';
import componentModalCreditsOperatorEn from '../../components/Modals/ModalCreditsOperator/locales/en.json';
import componentModalApproveDocumentEn from '../../components/Modals/ModalApproveDocument/locales/en.json';
import componentCustomPaginationEn from '../../components/CustomPagination/locales/en.json';
import componentPersonSigneeTileEn from '../../components/PersonSigneeTile/locales/en.json';
import componentModalOpenDocumentVaultEn from '../../components/Modals/ModalOpenDocumentVault/locales/en.json';
import componentModalExitCreateVaultPageEn from '../../components/Modals/ModalExitCreateVaultPage/locales/en.json';
import componentModalAlertEn from '../../components/Modals/ModalAlert/locales/en.json';
import componentModalRevokeUserEn from '../../components/Modals/ModalRevokeUser/locales/en.json';
import componentCostCenterSelectEn from '../../components/CostCenterSelect/locales/en.json';
import componentCostCenterSelectOperatorEn from '../../components/CostCenterSelectOperator/locales/en.json';
import componentModalChooseCostCenterEn from '../../components/Modals/ModalChooseCostCenter/locales/en.json';
import componentModalSubscriptionToTheClientEn from '../../components/Modals/ModalSubscriptionToTheClient/locales/en.json';
import componentModalEditAddressInPricingEn from '../../components/Modals/ModalEditAddressInPricing/locales/en.json';
import componentModalFileTypesAvailableInVaultEn from '../../components/Modals/ModalFileTypesAvailableInVault/locales/en.json';

ptBR.login = loginPtBR;
ptBR.validator = validatorPtBR;
ptBR.client.profile = clientProfilePtBR;
ptBR.client.home = clientHomePtBR;
ptBR.client.documents = clientDocumentsPtBR;
ptBR.client.history = clientHistoryPtBR;
ptBR.client.credits = clientCreditsPtBR;
ptBR.client.transactions = clientTransactionsPtBR;
ptBR.client.pricing = clientPricingPtBR;
ptBR.client.costCenters = clientCostCentersPtBR;
ptBR.client.vault = clientVaultPtBR;
ptBR.client.folders = clientFoldersPtBR;
ptBR.operator.registers = operatorPendingRegistersPtBR;
ptBR.operator.clients = operatorClientsPtBR;
ptBR.components.layout = componentLayoutPtBR;
ptBR.components.modalUploadToSign = componentModalUploadToSignPtBR;
ptBR.components.modalDelete = componentModalDeletePtBR;
ptBR.components.modalDeleteCostCenter = componentModalDeleteCostCenterPtBR;
ptBR.components.modalCreateCostCenter = componentModalCreateCostCenterPtBR;
ptBR.components.modalInviteCostCenterParticipant =
  componentModalInviteCostCenterParticipantPtBR;
ptBR.components.modalCostCenterParticipantInfo =
  componentModalCostCenterParticipantInfoPtBR;
ptBR.components.modalEditCostCenter = componentModalEditCostCenterPtBR;
ptBR.components.modalSignDocument = componentModalSignDocumentPtBR;
ptBR.components.modalCredits = componentModalCreditsPtBR;
ptBR.components.modalCreditsOperator = componentModalCreditsOperatorPtBR;
ptBR.components.modalApproveDocument = componentModalApproveDocumentPtBR;
ptBR.components.customPagination = componentCustomPaginationPtBR;
ptBR.components.personSigneeTile = componentPersonSigneeTilePtBR;
ptBR.components.modalOpenDocumentVault = componentModalOpenDocumentVaultPtBR;
ptBR.components.modalExitCreateVaultPage =
  componentModalExitCreateVaultPagePtBR;
ptBR.components.modalAlert = componentModalAlertPtBR;
ptBR.components.modalRevokeUser = componentModalRevokeUserPtBR;
ptBR.components.costCenterSelect = componentCostCenterSelectPtBR;
ptBR.components.costCenterSelectOperator =
  componentCostCenterSelectOperatorPtBR;
ptBR.components.modalChooseCostCenter = componentModalChooseCostCenterPtBR;
ptBR.components.modalSubscriptionToTheClient =
  componentModalSubscriptionToTheClientPtBR;
ptBR.components.modalEditAddressInPricing =
  componentModalEditAddressInPricingPtBR;

ptBR.components.modalFileTypesAvailableInVault =
  componentModalFileTypesAvailableInVaultPtBR;

en.login = loginEn;
en.validator = validatorEn;
en.client.profile = clientProfileEn;
en.client.home = clientHomeEn;
en.client.documents = clientDocumentsEn;
en.client.history = clientHistoryEn;
en.client.credits = clientCreditsEn;
en.client.transactions = clientTransactionsEn;
en.client.pricing = clientPricingEn;
en.client.costCenters = clientCostCentersEn;
en.client.vault = clientVaultEn;
en.client.folders = clientFoldersEn;
en.operator.registers = operatorPendingRegistersEn;
en.operator.clients = operatorClientsEn;
en.components.layout = componentLayoutEN;
en.components.modalUploadToSign = componentModalUploadToSignEn;
en.components.modalDelete = componentModalDeleteEn;
en.components.modalDeleteCostCenter = componentModalDeleteCostCenterEn;
en.components.modalCreateCostCenter = componentModalCreateCostCenterEn;
en.components.modalInviteCostCenterParticipant =
  componentModalInviteCostCenterParticipantEn;
en.components.modalCostCenterParticipantInfo =
  componentModalCostCenterParticipantInfoEn;
en.components.modalEditCostCenter = componentModalEditCostCenterEn;
en.components.modalSignDocument = componentModalSignDocumentEn;
en.components.modalCredits = componentModalCreditsEn;
en.components.modalCreditsOperator = componentModalCreditsOperatorEn;
en.components.modalApproveDocument = componentModalApproveDocumentEn;
en.components.customPagination = componentCustomPaginationEn;
en.components.personSigneeTile = componentPersonSigneeTileEn;
en.components.modalOpenDocumentVault = componentModalOpenDocumentVaultEn;
en.components.modalExitCreateVaultPage = componentModalExitCreateVaultPageEn;
en.components.modalAlert = componentModalAlertEn;
en.components.modalRevokeUser = componentModalRevokeUserEn;
en.components.costCenterSelect = componentCostCenterSelectEn;
en.components.costCenterSelectOperator = componentCostCenterSelectOperatorEn;
en.components.modalChooseCostCenter = componentModalChooseCostCenterEn;
en.components.modalSubscriptionToTheClient =
  componentModalSubscriptionToTheClientEn;
en.components.modalEditAddressInPricing = componentModalEditAddressInPricingEn;
en.components.modalFileTypesAvailableInVault =
  componentModalFileTypesAvailableInVaultEn;

export { ptBR, en };
