import React from 'react';
import { useTranslation } from 'react-i18next';

import COLORS from '../../utils/colors';

const styles = {
  height: '100vh',
  backgroundColor: COLORS.gray1,
  color: COLORS.background,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
} as React.CSSProperties;

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <div style={styles}>
      <h1>404</h1>

      <h1>{t('notFound')}</h1>
    </div>
  );
}
