import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';

import COLORS from '../../../utils/colors';

import api from '../../../services/api';
import useAlert from '../../../hooks/useAlertContext';

const useStyles = makeStyles({
  buttonRevokeUser: {
    padding: 0,
    textTransform: 'none',
    height: '3rem',
    width: '10.25rem',
    border: `1px solid ${COLORS.orangeAdvice}`,
    borderRadius: '0.5rem',
    color: COLORS.orangeAdvice,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',

    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },

    '&:hover': {
      backgroundColor: COLORS.orangeAdvice,
      color: COLORS.white,
      transition: '0.3s',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiSvgIcon-root': {
      fontSize: '2.25rem',
      color: COLORS.mediumGray4,
      cursor: 'pointer',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 3rem',
  },

  reason: {
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    padding: '0.625rem 0 0 1.5rem',
    border: `1px solid ${COLORS.lightGray3}`,
    height: '5.5rem',
    margin: '2.25rem 0 3rem 0',

    '& textarea': {
      color: COLORS.gray1,
      fontSize: '0.75rem',
      padding: 0,
      maxWidth: '25.75rem',
    },

    '&:hover': {
      borderColor: COLORS.black,
    },
  },

  textContent: {
    backgroundColor: COLORS.white,
    fontSize: '1.125rem',
    color: COLORS.black,
    maxWidth: '29.25rem',

    '& .revoke': {
      color: COLORS.orangeAdvice,
      fontWeight: 'bold',
    },

    '& .bold': {
      fontWeight: 'bold',
    },
  },

  selectStatus: {
    width: '50%',
  },

  buttonConfirm: {
    width: '9.25rem',
    height: '3rem',
    margin: '3rem 0 3rem 0',
    fontSize: '0.875rem',
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: COLORS.orangeAdvice,
    color: COLORS.white,
    borderRadius: '0.5rem',

    '&:hover': {
      backgroundColor: COLORS.orangeHover,
      color: COLORS.white,
      transition: '0.3s',
    },

    '&.Mui-disabled': {
      color: COLORS.white,
      backgroundColor: COLORS.lightGray2,
    },
  },
});

interface Props {
  user: {
    id: string;
    name: string;
  };
}

export default function ModalRevokeUser({ user }: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [status, setStatus] = useState('');

  const [isRevoking, setIsRevoking] = useState(false);

  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReason('');
    setStatus('');
  };

  const handleRevoke = async () => {
    setIsRevoking(true);

    const minReasonLength = 5;
    if (reason.length < minReasonLength || !status) return;

    try {
      await api.post(`/operator/user/revoke/${user.id}`, {
        reason,
        status,
      });
      handleClose();
      showAlert('revokeUserSuccessfully', 'success', null);
    } catch (error) {
      console.log(error);
      handleClose();
      showAlert('revocationError', 'error', null);
    }

    setIsRevoking(false);
  };

  return (
    <>
      <Button
        startIcon={<AutoRenewIcon />}
        onClick={handleOpen}
        className={classes.buttonRevokeUser}
      >
        {t('components.modalRevokeUser.buttonRevokeUser')}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.title} disableTypography>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent className={classes.content}>
          <p className={classes.textContent}>
            {t('components.modalRevokeUser.text1')}{' '}
            <span className="revoke">
              {t('components.modalRevokeUser.textElement')}
            </span>{' '}
            {t('components.modalRevokeUser.text2')}{' '}
            <span className="bold">{user.name}?</span>
          </p>

          <TextField
            fullWidth
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            multiline
            minRows="4"
            value={reason}
            onChange={e => {
              setReason(e.target.value);
            }}
            className={classes.reason}
          />

          <TextField
            value={status}
            onChange={event => {
              setStatus(event.target.value);
            }}
            className={classes.selectStatus}
            variant="outlined"
            label="Status"
            select
          >
            <MenuItem value="" style={{ height: '2.25rem' }} />
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="suspended">Suspended</MenuItem>
          </TextField>

          <Button
            className={classes.buttonConfirm}
            onClick={handleRevoke}
            disabled={reason.length < 5 || !status || isRevoking}
          >
            {isRevoking ? (
              <CircularProgress
                size={32}
                style={{ color: COLORS.mediumGray5 }}
              />
            ) : (
              t('components.modalRevokeUser.buttonConfirm')
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
