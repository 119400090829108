import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import useAuthContext from '../../hooks/useAuthContext';
import useCostCenters from '../../hooks/useCostCenters';

import COLORS from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  selectLabel: {
    fontWeight: 'bold',
    color: COLORS.gray2,
  },

  costCenterSelect: {
    '& .MuiInputBase-input': {
      borderRadius: '.5rem',
      position: 'relative',
      border: `1px solid ${COLORS.gray2}`,
      color: COLORS.gray2,
      backgroundColor: COLORS.white,
      fontSize: 16,
      padding: '0.625rem 1.625rem 0.625rem 0.75rem',
      marginTop: '.4rem',
      transition: theme.transitions.create(['border-color', 'box-shadow']),

      '&.Mui-disabled': {
        backgroundColor: COLORS.background,
        color: COLORS.mediumGray3,
        borderColor: COLORS.mediumGray3,
      },
    },

    '&.MuiInput-formControl': {
      marginTop: 0,
    },

    '& .MuiSelect-select': {
      display: 'flex',

      '& span': {
        display: 'none',
      },
    },

    '&:focus': {
      borderRadius: '.5rem',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },

  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: '1rem',

    '& p, & span': {
      width: '100%',
    },

    '& span': {
      color: COLORS.mediumGray1,
    },

    '&:hover': {
      backgroundColor: COLORS.lightBlue,
      transition: '0.3s',

      '& span': {
        color: COLORS.gray1,
      },
    },
  },
}));

const CostCenterMenuItem = withStyles(() =>
  createStyles({
    root: {
      '&$selected': {
        backgroundColor: COLORS.lightBlue2,

        '&:hover': {
          backgroundColor: COLORS.lightBlueHover,
        },
      },

      '&:hover': {
        backgroundColor: COLORS.lightBlue,
      },
    },

    selected: {
      backgroundColor: COLORS.lightBlue,
    },
  }),
)(MenuItem);

interface Props {
  chosenCostCenter: number;
  setChosenCostCenter: React.Dispatch<React.SetStateAction<number>>;
}

export default function CostCenterSelect({
  chosenCostCenter,
  setChosenCostCenter,
}: Props) {
  const classes = useStyles();
  const { costCenters } = useCostCenters();
  const { user } = useAuthContext();
  const { t } = useTranslation();

  return (
    <FormControl disabled={costCenters && !costCenters.data.length} fullWidth>
      <label
        id="select-label"
        htmlFor="costCenter-select"
        className={classes.selectLabel}
      >
        {t('components.costCenterSelect.labelContent')}
      </label>

      <Select
        className={classes.costCenterSelect}
        id="costCenter-select"
        labelId="select-label"
        value={chosenCostCenter}
        onChange={e => {
          setChosenCostCenter(Number(e.target.value));
        }}
        disableUnderline
      >
        <CostCenterMenuItem value={-1} className={classes.menuItem}>
          <p>{user?.name}</p>

          <span>{t('components.costCenterSelect.personalAccount')}</span>
        </CostCenterMenuItem>

        {costCenters &&
          costCenters.data.map((costCenter, index) => (
            <CostCenterMenuItem
              key={costCenter._id}
              value={index}
              className={classes.menuItem}
            >
              <p>{costCenter.name}</p>

              <span>{t('components.costCenterSelect.costCenter')}</span>
            </CostCenterMenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
