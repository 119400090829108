import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import useAuthContext from '../hooks/useAuthContext';
import useAlertContext from '../hooks/useAlertContext';
import useQuery from '../hooks/useQuery';

interface Props {
  component: React.FC;
  exact: boolean;
  path: string;
}

const LoginRoute = ({ component: Component, exact, path }: Props) => {
  const { user } = useAuthContext();
  const query = useQuery();
  const { showAlert } = useAlertContext();

  useEffect(() => {
    const addedToCostCenter = Boolean(query.get('addedToCostCenter'));

    if(addedToCostCenter) {
      showAlert('addedToCostCenter', 'success', null);
    }
  }, []);

  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        if (!user) return <Component />;
        if (user.role === 'user') return <Redirect to="/home" />;

        if (user.role === 'moderator' || user.role === 'admin')
          return <Redirect to="/registers" />;

        return <Component />;
      }}
    />
  );
};

export default LoginRoute;
