import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { useTranslation } from 'react-i18next';

import useAlert from '../../../hooks/useAlertContext';

import { CostCenter } from '../../../types/CostCenter';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  editButton: {
    textTransform: 'none',
    backgroundColor: COLORS.lightGray2,
    color: COLORS.mediumGray3,
    width: 'auto',
    height: '2.25rem',
    borderRadius: '0.5rem',

    '&:hover': {
      backgroundColor: COLORS.lightGray4,
      color: COLORS.mediumGray5,
      transition: '0.3s',
    },

    [theme.breakpoints.up('md')]: {
      width: '6rem',
    },
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '0.5rem',
    width: '100%',
  },

  inputField: {
    width: '100%',
    padding: '1rem',
  },
}));

interface Props {
  isOpen: boolean;
  center: CostCenter;
}

const ModalEditCostCenter: React.FC<Props> = ({
  isOpen = false,
  center,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showAlert } = useAlert();

  const [costCenterName, setCostCenterName] = useState(center.name || '');

  const classes = useStyles();

  const { t } = useTranslation();

  const matchesMediaQueryUpMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  );

  const handleModal = (value: boolean) => {
    setOpen(value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const data = {
      name: costCenterName,
    };

    try {
      const response = await api.put(`/costcenter/${center._id}`, data);

      showAlert('costCenterEditedSuccessfully', 'success', response.data._id);
    } catch (error) {
      console.log(error);
      showAlert('costCenterError', 'error', center._id);
    }

    handleModal(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      {matchesMediaQueryUpMD ? (
        <Button
          className={classes.editButton}
          onClick={() => {
            handleModal(true);
          }}
          endIcon={<ReceiptOutlinedIcon />}
        >
          {t('components.modalEditCostCenter.button')}
        </Button>
      ) : (
        <IconButton
          className={classes.editButton}
          onClick={() => {
            handleModal(true);
          }}
        >
          <ReceiptOutlinedIcon />
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={() => {
          handleModal(false);
        }}
      >
        <DialogTitle>{t('components.modalEditCostCenter.title')}</DialogTitle>

        <DialogContent style={{ minWidth: '27rem' }}>
          <form className={classes.formContainer}>
            <label htmlFor="costCenterName">
              {t('components.modalEditCostCenter.label')}
            </label>

            <input
              id="editCostCenterName"
              type="text"
              className={classes.inputField}
              value={costCenterName}
              onChange={e => {
                setCostCenterName(e.target.value);
              }}
              disabled={isSubmitting}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              handleModal(false);
            }}
            disabled={isSubmitting}
          >
            {t('components.modalEditCostCenter.returnButton')}
          </Button>

          <Button onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? (
              <CircularProgress size={16} />
            ) : (
              t('components.modalEditCostCenter.confirmButton')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalEditCostCenter;
