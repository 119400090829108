import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CloseIcon from '@material-ui/icons/Close';
import { QRCodeSVG } from 'qrcode.react';
import { mutate } from 'swr';
import { useTranslation } from 'react-i18next';

import useAlert from '../../../hooks/useAlertContext';
import useSocketContext from '../../../hooks/useSocketContext';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';

import iconFaceMatch from '../../../images/undraw_taking_selfie.svg';
import iconSigning from '../../../images/undraw_terms.svg';
import ModalChooseCostCenter from '../ModalChooseCostCenter';

const useStyles = makeStyles({
  buttonSignNowDocument: {
    height: '2.25rem',
    width: '6.75rem',
    textTransform: 'none',
    borderRadius: '0.5rem',
    padding: 0,
    backgroundColor: COLORS.orangeAdvice,
    color: COLORS.white,
    fontSize: '0.875rem',
    fontWeight: 'bold',

    '& .MuiSvgIcon-root': {
      fill: COLORS.white,
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      backgroundColor: COLORS.orangeHover,
      transition: '0.3s',
    },

    '&.Mui-disabled': {
      backgroundColor: COLORS.mediumGray1,
      color: COLORS.white,
    },
  },

  titleContainer: {
    display: 'flex',
    paddingBottom: '0',

    '& .title': {
      fontWeight: 'bold',
      color: COLORS.gray2,
      fontSize: '1.125rem',
      paddingLeft: '1rem',
      maxWidth: '22.25rem',
      textAlign: 'center',
    },

    '& .MuiSvgIcon-root': {
      fontSize: '2.25rem',
      color: COLORS.mediumGray3,
      cursor: 'pointer',
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '2rem',
    margin: '1.875rem 0 2.25rem 0',

    '& img': {
      maxWidth: '58%',
    },
  },
});

type Message =
  | 'sign_start'
  | 'sign_failure'
  | 'sign_success'
  | 'facematch_start'
  | 'facematch_failure';

interface Props {
  hash: string;
  documentId: string;
  disabled: boolean;
  isLoading: boolean;
  faceMatch: boolean;
  isOwner: boolean;
}

export default function ModalSignDocument({
  hash,
  documentId,
  disabled,
  isLoading,
  faceMatch,
  isOwner,
}: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openModalChooseCostCenter, setOpenModalChooseCostCenter] =
    useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isSigningWithFaceMatch, setIsSigningWithFaceMatch] = useState(false);

  const { t } = useTranslation();
  const { showAlert, hideAlert } = useAlert();
  const { socket, socketId } = useSocketContext();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModalChooseCostCenter(false);
  };

  const handleAlertOnSocket = {
    sign_start() {
      setTimeout(() => {
        setIsSigningWithFaceMatch(false);
        setIsSigning(true);
        handleOpen();
      }, 200);
    },

    sign_success() {
      hideAlert(1.5 * 1000);
      setTimeout(() => {
        if (!isOwner) {
          setOpenModalChooseCostCenter(true);
        }
      }, 2 * 1000);
    },

    sign_failure() {
      showAlert('signingFailed', 'error', null);
      setTimeout(() => {
        setIsSigning(false);
        setIsSigningWithFaceMatch(false);
      }, 200);
    },

    facematch_start() {
      setIsSigning(false);
      setIsSigningWithFaceMatch(true);
      setTimeout(handleOpen, 200);
    },

    facematch_failure() {
      showAlert('faceMatchFailed', 'error', null);
      setTimeout(() => {
        setIsSigning(false);
        setIsSigningWithFaceMatch(false);
      }, 200);
    },
  };

  const documentSignature = () => {
    mutate(linksForSWR.client.documents.view(documentId));

    setTimeout(() => {
      handleClose();
      setIsSigning(false);
      showAlert('signingSuccess', 'success', null);
    }, 1 * 1000);
  };

  const sign = (message: Message) => {
    handleClose();
    handleAlertOnSocket[message]();
  };

  useEffect(() => {
    if (open) {
      socket.on('documentSignature', documentSignature);
      socket.on('sign', sign);
    }

    return () => {
      socket.off('documentSignature', documentSignature);
      socket.off('sign', sign);
    };
  }, [documentId, open]);

  return (
    <>
      <Button
        className={classes.buttonSignNowDocument}
        endIcon={!isLoading ? <BorderColorIcon /> : null}
        onClick={handleOpen}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} style={{ color: COLORS.white }} />
        ) : (
          t('components.modalSignDocument.button')
        )}
      </Button>

      <Dialog
        open={open}
        onClose={
          isSigning || isSigningWithFaceMatch
            ? () => {
                //
              }
            : handleClose
        }
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          className={classes.titleContainer}
          style={{
            justifyContent: isSigningWithFaceMatch ? 'center' : 'space-between',
          }}
          disableTypography
        >
          <Typography className="title">
            {!isSigning && !isSigningWithFaceMatch
              ? t('components.modalSignDocument.content')
              : t(
                  `components.modalSignDocument.${
                    isSigning ? 'signing' : 'faceMatch'
                  }`,
                )}
          </Typography>

          {!isSigning && !isSigningWithFaceMatch && (
            <CloseIcon onClick={handleClose} />
          )}
        </DialogTitle>

        <DialogContent>
          {!isSigning && !isSigningWithFaceMatch ? (
            <div className={classes.container}>
              <QRCodeSVG
                value={`{"socket":"${socketId}","method":"SIGN","needs":["name","email","token","dattaid"],"who":"DattaID","sends":{"documentId":"${documentId}","documentHash":"${hash}","facematch":${faceMatch}}}`}
                size={256}
              />
            </div>
          ) : (
            <div className={classes.container}>
              <img
                src={isSigning ? iconSigning : iconFaceMatch}
                alt={isSigning ? 'Icon Signing' : 'Icon Face Match'}
              />
              <CircularProgress />
            </div>
          )}
        </DialogContent>
      </Dialog>

      <ModalChooseCostCenter
        isOpen={openModalChooseCostCenter}
        documentId={documentId}
      />
    </>
  );
}
