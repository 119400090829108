/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTranslation } from 'react-i18next';
import { DebounceInput } from 'react-debounce-input';

import Layout from '../../../components/Layout';
import CustomPagination from '../../../components/CustomPagination';
import CustomProgress from '../../../components/CustomProgress';
import ModalDelete from '../../../components/Modals/ModalDelete';

import { useAxios } from '../../../hooks/useAxios';

import { Client } from '../../../types/Client';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';

const totalPersonalDocuments = 3;

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: COLORS.pureBlack,
  },

  search: {
    width: '100%',
    marginTop: '1rem',
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    paddingLeft: '1rem',
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${COLORS.lightGray3}`,
    outline: `${COLORS.lightGray1}`,

    '&:hover': {
      transition: '0.1s',
      outline: `0.25rem solid ${COLORS.lightGray1}`,
    },

    [theme.breakpoints.up('md')]: {
      width: '33.33%',
      marginTop: 0,
    },

    '& input': {
      fontSize: '1.125rem',
      marginBottom: '0.125rem',
      lineHeight: '1.5rem',
      color: COLORS.gray3,
    },

    '& svg': {
      fill: COLORS.mediumGray1,
      fontSize: '1.5rem',
    },

    '& .Mui-focused': {
      '& svg': {
        fill: COLORS.gray2,
      },
    },
  },

  closeIcon: {
    fontSize: '1.625rem',
    marginRight: '0.625rem',
    color: COLORS.mediumGray5,
    cursor: 'pointer',
  },

  paperTable: {
    width: '100%',
    borderRadius: '0.5rem',
  },

  tableContainer: {
    padding: '1rem',
  },

  tableHead: {
    border: 'none',
    padding: 0,
    height: '2rem',
    color: COLORS.gray2,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    width: '25%',
    fontWeight: 500,
  },

  tableNameCell: {
    height: '4.25rem',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    width: '25%',
    padding: 0,
    paddingLeft: '0.125rem',

    '&:hover': {
      backgroundColor: COLORS.lightOrange,
      transition: '0.3s',
    },

    '& a': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      textDecoration: 'none',
      color: COLORS.black,
    },
  },

  tableCell: {
    padding: 0,
    height: '4.25rem',
    color: COLORS.black,
    fontSize: '0.875rem',
    width: '25%',
    paddingLeft: '0.125rem',
  },

  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    columnGap: '1.875rem',
  },

  buttonReview: {
    height: '2.25rem',
    width: '5.75rem',
    textTransform: 'none',
    borderRadius: '0.5rem',
    padding: 0,
    backgroundColor: COLORS.orangeAdvice,
    color: COLORS.white,
    fontSize: '0.875rem',
    fontWeight: 600,

    '& svg': {
      fill: COLORS.white,
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      backgroundColor: COLORS.orangeHover,
      transition: '0.3s',
    },
  },

  buttonView: {
    height: '2.25rem',
    width: '4.5rem',
    textTransform: 'none',
    borderRadius: '0.5rem',
    backgroundColor: COLORS.lightGray2,
    color: COLORS.mediumGray5,
    fontSize: '0.875rem',
    fontWeight: 600,

    '& svg': {
      fill: COLORS.mediumGray5,
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      color: COLORS.gray2,
      backgroundColor: COLORS.lightGray4,
      transition: '0.3s',

      '& svg': {
        fill: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  errorTableRow: {
    '& .MuiTableCell-root': {
      border: 'none',
      width: '25%',
    },
  },
}));

interface RegisterData {
  data: Client[];
  xTotalCount: number;
}

export default function PendingRegisters() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const rowsPerPage = 8;

  const classes = useStyles();

  const { t } = useTranslation();

  const { data: registers, error }: { data: RegisterData; error: any } =
    useAxios(linksForSWR.operator.registers.index(page, rowsPerPage, search));

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleSearch = (value: string) => {
    setPage(1);
    setSearch(value);
  };

  return (
    <Layout type="operator" routeSelected="registers">
      <Grid
        container
        item
        alignItems="flex-end"
        justifyContent="space-between"
        style={{ marginBottom: '2.25rem' }}
      >
        <h1 className={classes.title}>{t('operator.registers.index.title')}</h1>

        <DebounceInput
          minLength={0}
          debounceTimeout={300}
          element={TextField}
          size="medium"
          placeholder={t('operator.registers.index.search')}
          fullWidth
          variant="standard"
          value={search}
          onChange={e => {
            handleSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: search ? (
              <InputAdornment
                position="end"
                onClick={() => {
                  setSearch('');
                }}
              >
                <CloseIcon className={classes.closeIcon} />
              </InputAdornment>
            ) : null,
            disableUnderline: true,
          }}
          className={classes.search}
        />
      </Grid>

      <Paper className={classes.paperTable} elevation={0}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {(
                  t('operator.registers.index.columns', {
                    returnObjects: true,
                  }) as string[]
                ).map(element => (
                  <TableCell key={element} className={classes.tableHead}>
                    {element}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {registers ? (
                registers.data.map(register => {
                  let sentDocuments = 0;
                  register.documents.forEach(document => {
                    const lastStatus = document.status.slice(-1)[0];
                    if (
                      lastStatus.name !== 'waitingDocuments' &&
                      lastStatus.is_active
                    ) {
                      sentDocuments += 1;
                    }
                  });

                  return (
                    <TableRow key={register._id}>
                      <TableCell className={classes.tableNameCell}>
                        <Link to={`/registers/${register._id}`}>
                          {register.name}
                        </Link>
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <span>{register.cpf}</span>
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <CustomProgress
                          totalProgress={sentDocuments}
                          total={totalPersonalDocuments}
                        />
                      </TableCell>

                      <TableCell align="center" className={classes.tableCell}>
                        <div className={classes.buttonsContainer}>
                          {sentDocuments === 4 ? (
                            <Button
                              className={classes.buttonReview}
                              endIcon={<VisibilityIcon />}
                              component={Link}
                              to={`/registers/${register._id}`}
                            >
                              {t('operator.registers.index.buttonReview')}
                            </Button>
                          ) : (
                            <Button
                              className={classes.buttonView}
                              endIcon={<VisibilityIcon />}
                              component={Link}
                              to={`/registers/${register._id}`}
                            >
                              {t('operator.registers.index.buttonView')}
                            </Button>
                          )}

                          <ModalDelete
                            what="client"
                            type="index"
                            component={{
                              id: register._id || '',
                              name: register.name,
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow className={classes.errorTableRow}>
                  <TableCell />

                  <TableCell align="right">
                    {error ? (
                      t('operator.registers.error')
                    ) : (
                      <CircularProgress />
                    )}
                  </TableCell>

                  <TableCell />

                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <CustomPagination
          page={page}
          handleChangePage={handleChangePage}
          total={registers?.xTotalCount}
          rowsPerPage={rowsPerPage}
          contentToLeft={null}
        />
      </Paper>
    </Layout>
  );
}
