/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FaceIcon from '@material-ui/icons/Face';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import ModalCredits from '../../../components/Modals/ModalCredits';
import Layout from '../../../components/Layout';
import CostCenterSelect from '../../../components/CostCenterSelect';

import { useAxios } from '../../../hooks/useAxios';
import useCostCenters from '../../../hooks/useCostCenters';

import { CostCenter } from '../../../types/CostCenter';
import { Credit } from '../../../types/Credit';

import COLORS from '../../../utils/colors';
import linksForSWR from '../../../utils/linksForSWR';
import { Subscription } from '../../../types/Subscription';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    rowGap: '1rem',

    [theme.breakpoints.up('md')]: {
      rowGap: 0,
      flexDirection: 'row',
    },
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      columnGap: '1.625rem',
    },
  },

  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: '2.25rem',
    color: COLORS.pureBlack,
  },

  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.25rem',
    textAlign: 'justify',

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '.3rem',
    },
  },

  description: {
    color: COLORS.gray2,
    fontSize: '0.875rem',
    maxWidth: '16.5rem',
  },

  learnMore: {
    color: COLORS.mediumGray3,
    fontSize: '0.75rem',
  },

  learnMoreLink: {
    color: COLORS.blue,
    fontSize: '0.75rem',
    textDecoration: 'none',
    textTransform: 'none',
    padding: '0',

    '&:hover': {
      color: COLORS.blueHover,
      backgroundColor: 'transparent',
    },
  },

  costCenterSelectContainer: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '33%',
    },
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minHeight: '71vh',
    marginTop: '1rem',
  },

  divider: {
    width: '100%',
    height: '0.125rem',
    backgroundColor: COLORS.lightGray2,
    margin: '2rem 0',
  },

  sectionTitle: {
    fontSize: '1.725rem',
    color: COLORS.gray2,
    fontWeight: 600,
  },

  sectionSubtitle: {
    fontSize: '1rem',
    color: COLORS.gray2,
    fontWeight: 500,
  },

  sectionValue: {
    fontSize: '1rem',
    color: COLORS.gray2,
    fontWeight: 800,
  },

  positiveAvailableDocuments: {
    fontSize: '1rem',
    color: COLORS.greenButton,
    fontWeight: 800,
  },

  negativeAvailableDocuments: {
    fontSize: '1rem',
    color: COLORS.redWarning,
    fontWeight: 800,
  },

  sectionValueDescription: {
    fontSize: '1rem',
    color: COLORS.mediumGray1,
    fontWeight: 500,
  },

  creditsSectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'column',
    rowGap: '1rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      rowGap: 0,
    },
  },

  creditsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  sectionInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '.5rem',
    padding: '0 .5rem',
    margin: '.2rem 0',
  },

  sectionContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1rem',
    flexDirection: 'column-reverse',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  costCentersItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
  },

  costCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '.5rem',
    width: '50%',
    padding: '.5rem',
    borderLeft: `0.25rem solid ${COLORS.lightGray4}`,
  },

  planItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    gap: '1rem',
  },

  sectionLink: {
    backgroundColor: COLORS.gray1,
    width: '12rem',
    height: '3rem',
    borderRadius: '0.5rem',
    padding: '0.625rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: '1rem 0',
    textDecoration: 'none',

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',
    },

    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
  },

  popoverContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '.3rem',
    padding: '1rem',
  },

  infoButton: {
    width: '1.1rem',
    backgroundColor: 'transparent',
    color: COLORS.blue,
    cursor: 'pointer',
  },

  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Credits() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [documentIcon, setDocumentIcon] = React.useState<SVGSVGElement | null>(
    null,
  );
  const [biometricsIcon, setBiometricsIcon] =
    React.useState<SVGSVGElement | null>(null);
  const [vaultIcon, setVaultIcon] = React.useState<SVGSVGElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<SVGSVGElement>,
    el: 'document' | 'biometrics' | 'vault',
  ) => {
    switch (el) {
      case 'document':
        setDocumentIcon(event.currentTarget);
        break;

      case 'biometrics':
        setBiometricsIcon(event.currentTarget);
        break;

      case 'vault':
        setVaultIcon(event.currentTarget);
        break;

      default:
        setDocumentIcon(event.currentTarget);
    }
  };

  const handleClose = () => {
    setDocumentIcon(null);
    setBiometricsIcon(null);
    setVaultIcon(null);
  };

  const openDocument = Boolean(documentIcon);
  const openBiometrics = Boolean(biometricsIcon);
  const openVault = Boolean(vaultIcon);
  const popoverId =
    openDocument || openBiometrics || openVault ? 'simple-popover' : undefined;

  const [startTime, setStartTime] = useState<string | number | Date | null>(
    null,
  );
  const [endTime, setEndTime] = useState<string | number | Date | null>(null);

  const { data: subscriptions, error } = useAxios(
    linksForSWR.client.subscriptions(),
  ) as {
    data: Subscription;
    error: any;
  };

  const { costCenters, error: costCentersError } = useCostCenters();

  const [chosenCostCenter, setChosenCostCenter] = useState(-1);

  const { data: currentCredits }: { data: Credit[] } = useAxios(
    linksForSWR.client.credits(
      chosenCostCenter !== -1 ? costCenters.data[chosenCostCenter]._id : '',
    ),
  );

  const [documents, setDocuments] = useState(0);
  const [facialBiometrics, setFacialBiometrics] = useState(0);
  const [vault, setVault] = useState(0);

  useEffect(() => {
    try {
      if (!currentCredits) return;

      currentCredits.forEach(element => {
        if (element.name === 'sendingDocuments') setDocuments(element.quantity);

        if (element.name === 'facialBiometrics')
          setFacialBiometrics(element.quantity);

        if (element.name === 'vault') setVault(element.quantity);
      });
    } catch (err) {
      console.log(err);
    }
  }, [currentCredits, chosenCostCenter]);

  useEffect(() => {
    if (subscriptions) {
      const valueToConvertToMilliseconds = 1000;

      const startDate = new Date(
        subscriptions.subscriptions.stripe.current_period_start *
          valueToConvertToMilliseconds,
      );
      const endDate = new Date(
        subscriptions.subscriptions.stripe.current_period_end *
          valueToConvertToMilliseconds,
      );
      setStartTime(startDate);
      setEndTime(endDate);
    }
  }, [subscriptions]);

  return (
    <Layout type="user" routeSelected="credits">
      <Grid container className={classes.headerContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h1" className={classes.title}>
            {t('client.credits.title')}
          </Typography>

          <div className={classes.descriptionContainer}>
            <Typography className={classes.description}>
              {t('client.credits.description')}
            </Typography>

            <div>
              <Button
                variant="text"
                onClick={() => {
                  window.open(
                    'https://dattaid.com.br/atendimento.html',
                    '_blank',
                  );
                }}
                className={classes.learnMoreLink}
              >
                {t('client.credits.learnMore.link')}
              </Button>

              <Typography className={classes.learnMore}>
                {t('client.credits.learnMore.content')}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.costCenterSelectContainer}>
          <CostCenterSelect
            chosenCostCenter={chosenCostCenter}
            setChosenCostCenter={setChosenCostCenter}
          />
        </div>
      </Grid>

      <div className={classes.contentContainer}>
        <div className={classes.creditsSectionContainer}>
          <div className={classes.creditsContainer}>
            <div className={classes.sectionInnerContainer}>
              <Tooltip title={t('client.credits.infoLabel')}>
                <InfoOutlinedIcon
                  id={t('client.credits.credits.documents.title')}
                  role="button"
                  aria-label={t('client.credits.infoLabel')}
                  className={classes.infoButton}
                  aria-describedby={popoverId}
                  onClick={e => {
                    handleClick(e, 'document');
                  }}
                />
              </Tooltip>

              <Popover
                id={
                  openDocument
                    ? t('client.credits.credits.documents.title')
                    : undefined
                }
                open={openDocument}
                anchorEl={documentIcon}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <div className={classes.popoverContainer}>
                  <InsertDriveFileOutlinedIcon style={{ fontSize: '1.2rem' }} />

                  <Typography style={{ fontSize: '.9rem' }}>
                    {t('client.credits.credits.documents.description')}
                  </Typography>
                </div>
              </Popover>

              <Typography className={classes.sectionSubtitle}>
                {`${t('client.credits.credits.documents.title')}: `}
              </Typography>

              <Typography className={classes.sectionValue}>
                {currentCredits ? documents : <CircularProgress size={14} />}
              </Typography>
            </div>

            <div className={classes.sectionInnerContainer}>
              <Tooltip title={t('client.credits.infoLabel')}>
                <InfoOutlinedIcon
                  id={t('client.credits.credits.biometrics.title')}
                  role="button"
                  aria-label={t('client.credits.infoLabel')}
                  className={classes.infoButton}
                  aria-describedby={popoverId}
                  onClick={e => {
                    handleClick(e, 'biometrics');
                  }}
                />
              </Tooltip>

              <Popover
                id={
                  openBiometrics
                    ? t('client.credits.credits.biometrics.title')
                    : undefined
                }
                open={openBiometrics}
                anchorEl={biometricsIcon}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <div className={classes.popoverContainer}>
                  <FaceIcon style={{ fontSize: '1.2rem' }} />

                  <Typography style={{ fontSize: '.9rem' }}>
                    {t('client.credits.credits.biometrics.description')}
                  </Typography>
                </div>
              </Popover>

              <Typography className={classes.sectionSubtitle}>
                {`${t('client.credits.credits.biometrics.title')}: `}
              </Typography>

              <Typography className={classes.sectionValue}>
                {currentCredits ? (
                  facialBiometrics
                ) : (
                  <CircularProgress size={14} />
                )}
              </Typography>
            </div>

            <div className={classes.sectionInnerContainer}>
              <Tooltip title={t('client.credits.infoLabel')}>
                <InfoOutlinedIcon
                  id={t('client.credits.credits.vault.title')}
                  role="button"
                  aria-label={t('client.credits.infoLabel')}
                  className={classes.infoButton}
                  aria-describedby={popoverId}
                  onClick={e => {
                    handleClick(e, 'vault');
                  }}
                />
              </Tooltip>

              <Popover
                id={
                  openVault
                    ? t('client.credits.credits.vault.title')
                    : undefined
                }
                open={openVault}
                anchorEl={vaultIcon}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <div className={classes.popoverContainer}>
                  <LockOutlinedIcon style={{ fontSize: '1.2rem' }} />

                  <Typography style={{ fontSize: '.9rem' }}>
                    {t('client.credits.credits.vault.description')}
                  </Typography>
                </div>
              </Popover>

              <Typography className={classes.sectionSubtitle}>
                {`${t('client.credits.credits.vault.title')}: `}
              </Typography>

              <Typography className={classes.sectionValue}>
                {currentCredits ? vault : <CircularProgress size={14} />}
              </Typography>
            </div>
          </div>

          <ModalCredits type="button" />
        </div>

        <div className={classes.divider} />

        <Typography className={classes.sectionTitle}>
          {t('client.credits.costCenters.title')}
        </Typography>

        <div className={classes.sectionContainer}>
          <div style={{ padding: '0 .5rem' }}>
            {costCenters && costCenters.data.length > 0 ? (
              <Typography
                className={classes.sectionSubtitle}
                style={{ marginTop: '1rem' }}
              >
                {t('client.credits.costCenters.text')}
              </Typography>
            ) : costCenters === undefined ? (
              <div className={classes.errorContainer}>
                <Typography
                  className={classes.sectionSubtitle}
                  style={{ marginTop: '2rem' }}
                >
                  {costCentersError ? (
                    t('client.credits.error')
                  ) : (
                    <CircularProgress />
                  )}
                </Typography>
              </div>
            ) : (
              t('client.credits.costCenters.empty')
            )}
          </div>

          {costCenters !== undefined && (
            <Link className={classes.sectionLink} to="/cost_centers">
              {costCenters && costCenters.data.length > 0
                ? t('client.credits.costCenters.manageCostCenterButton')
                : t('client.credits.costCenters.noCostCenterButton')}
            </Link>
          )}
        </div>

        <div className={classes.costCentersItemsContainer}>
          {costCenters &&
            costCenters.data.slice(0, 3).map((costCenter: CostCenter) => (
              <div className={classes.costCenter} key={costCenter._id}>
                <Typography className={classes.sectionValue}>
                  {costCenter.name}
                </Typography>

                <Typography className={classes.sectionValueDescription}>
                  {`${t(
                    'client.credits.costCenters.costCenterCreatedAt',
                  )} ${moment(costCenter.createdAt)
                    .locale(t('client.credits.locale'))
                    .format('lll')}`}
                </Typography>
              </div>
            ))}

          {costCenters && costCenters.data.length > 1 && (
            <Typography
              className={classes.sectionSubtitle}
              style={{ marginTop: '1rem', padding: '0 .5rem' }}
            >
              {t('client.credits.costCenters.warning')}
            </Typography>
          )}
        </div>

        <div className={classes.divider} />

        <Typography className={classes.sectionTitle}>
          {t('client.credits.plans.title')}
        </Typography>

        <div className={classes.sectionContainer}>
          {subscriptions ? (
            <div className={classes.planItemsContainer}>
              <div className={classes.sectionInnerContainer}>
                <Typography className={classes.sectionSubtitle}>
                  {t('client.credits.plans.active')}
                </Typography>

                <Typography className={classes.sectionValue}>
                  {subscriptions.subscriptions.name}
                </Typography>
              </div>

              <div className={classes.sectionInnerContainer}>
                <Typography className={classes.sectionSubtitle}>
                  {t('client.credits.plans.price')}
                </Typography>

                <Typography className={classes.sectionValue}>
                  {t('client.credits.plans.currency') +
                    subscriptions.plans.value}
                </Typography>
              </div>

              <div className={classes.sectionInnerContainer}>
                <Typography className={classes.sectionSubtitle}>
                  {t('client.credits.plans.subscribed')}
                </Typography>

                <Typography className={classes.sectionValue}>
                  {moment(startTime)
                    .locale(t('client.credits.locale'))
                    .format('lll') +
                    t('client.credits.plans.validity') +
                    moment(endTime)
                      .locale(t('client.credits.locale'))
                      .format('lll')}
                </Typography>
              </div>
            </div>
          ) : subscriptions === undefined ? (
            <div className={classes.errorContainer}>
              {error ? t('client.transactions.error') : <CircularProgress />}
            </div>
          ) : (
            <div className={classes.planItemsContainer}>
              <div style={{ padding: '0 .5rem' }}>
                <Typography className={classes.sectionSubtitle}>
                  {t('client.credits.plans.noActivePlan')}
                </Typography>
              </div>
            </div>
          )}
          {subscriptions !== undefined && (
            <Link className={classes.sectionLink} to="/plans">
              {subscriptions && subscriptions.subscriptions.name
                ? t('client.credits.plans.hasPlanButton')
                : t('client.credits.plans.noPlanButton')}
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
}
