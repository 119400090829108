/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useTranslation } from 'react-i18next';

import useAlert from '../../../hooks/useAlertContext';

import { CostCenter } from '../../../types/CostCenter';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  buttonInviteParticipant: {
    width: '2.25rem',
    minWidth: '2.25rem',
    height: '2.25rem',
    borderRadius: '0.5rem',
    border: `0.125rem solid ${COLORS.greenButton}`,
    marginRight: '1rem',
    fontSize: '1.125rem',
    textTransform: 'none',
    fontWeight: 600,
    color: COLORS.greenButton,
    backgroundColor: COLORS.white,
    transition: 'all .3s ease',

    '& svg': {
      fontSize: '1.25rem',
    },

    '&:hover': {
      color: COLORS.white,
      backgroundColor: COLORS.greenHover,
      border: `0.125rem solid ${COLORS.greenHover}`,
    },
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '0.5rem',
    width: '100%',
  },

  inputField: {
    width: '100%',
    padding: '1rem',
  },

  buttonNo: {
    backgroundColor: COLORS.black,
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.white,
    textTransform: 'none',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.pureBlack,
      transition: '0.3s',
    },
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 3rem',
    marginTop: '1.5rem',
  },

  buttonYes: {
    backgroundColor: 'none',
    width: '13.5rem',
    minWidth: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.redWarning,
    textTransform: 'none',
    border: `1px ${COLORS.redWarning} solid`,
    margin: '0.75rem 0 2.25rem 0 !important',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.redWarning,
      color: COLORS.white,
      transition: '0.3s',
    },
  },
});

interface Props {
  isOpen: boolean;
  center: CostCenter;
}

const ModalInviteCostCenterParticipant: React.FC<Props> = ({
  isOpen = false,
  center,
}) => {
  const [open, setOpen] = useState(false);
  const [inviteConfirmation, setInviteConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showAlert } = useAlert();

  const [participantEmail, setParticipantEmail] = useState('');

  const classes = useStyles();

  const { t } = useTranslation();

  const handleModal = (value: boolean) => {
    setOpen(value);
  };

  const handleInviteConfirmation = () => {
    setInviteConfirmation(true);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const data = {
      costcenter_id: center._id,
      email: participantEmail,
    };

    try {
      const response = await api.post('/costcenter/user/invite', data);

      setIsSubmitting(false);
      setInviteConfirmation(false);
      handleModal(false);
      showAlert(
        'costCenterParticipantAddedSuccessfully',
        'success',
        response.data._id,
      );
    } catch (error) {
      console.log(error);
      showAlert('costCenterParticipantError', 'error', JSON.stringify(error));
      handleModal(false);
      setInviteConfirmation(false);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <Tooltip title={t('components.modalInviteCostCenterParticipant.title')}>
        <Button
          onClick={() => {
            handleModal(true);
          }}
          className={classes.buttonInviteParticipant}
        >
          <PersonAddIcon />
        </Button>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => {
          handleModal(false);
          setInviteConfirmation(false);
        }}
      >
        <DialogTitle>{`${t('components.modalInviteCostCenterParticipant.title')}
        ${t('components.modalInviteCostCenterParticipant.to')} ${
          center.name
        }`}</DialogTitle>

        <DialogContent style={{ minWidth: '27rem' }}>
          <h3 style={{ fontWeight: '500', marginBottom: '1rem' }}>
            {inviteConfirmation
              ? `${t(
                  'components.modalInviteCostCenterParticipant.confirmInvite',
                )} ${participantEmail} ?`
              : t('components.modalInviteCostCenterParticipant.subtitle')}
          </h3>

          {inviteConfirmation && (
            <p style={{ marginBottom: '1rem' }}>
              <strong>
                {t('components.modalInviteCostCenterParticipant.warning')}
              </strong>
            </p>
          )}

          {!inviteConfirmation && (
            <form className={classes.formContainer}>
              <label htmlFor="participantEmail">
                {t('components.modalInviteCostCenterParticipant.label')}
              </label>

              <input
                id="participantEmail"
                type="email"
                className={classes.inputField}
                value={participantEmail}
                onChange={e => {
                  setParticipantEmail(e.target.value);
                }}
                disabled={isSubmitting}
              />
            </form>
          )}
        </DialogContent>

        <DialogActions className={classes.buttonsContainer}>
          <Button
            className={classes.buttonNo}
            onClick={() => {
              handleModal(false);
              setInviteConfirmation(false);
            }}
          >
            {t('components.modalInviteCostCenterParticipant.returnButton')}
          </Button>

          <Button
            className={classes.buttonYes}
            onClick={
              inviteConfirmation ? handleSubmit : handleInviteConfirmation
            }
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={16} />
            ) : inviteConfirmation ? (
              t('components.modalInviteCostCenterParticipant.confirmButton')
            ) : (
              t('components.modalInviteCostCenterParticipant.actionButton')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalInviteCostCenterParticipant;
