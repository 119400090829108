/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import COLORS from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3rem',
    padding: '0 1.125rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  pagination: {
    '& .MuiPaginationItem-page': {
      color: COLORS.lightGray4,
      fontWeight: 'bold',
      fontSize: '0.675rem',

      [theme.breakpoints.up('md')]: {
        fontSize: '0.875rem',
      },
    },

    '& .MuiPaginationItem-page:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: COLORS.mediumGray1,
      fontWeight: 'bold',
      fontSize: '0.675rem',

      [theme.breakpoints.up('md')]: {
        fontSize: '0.875rem',
      },
    },

    '& .MuiPaginationItem-page.Mui-selected': {
      color: COLORS.gray5,
      fontWeight: 'bold',
      padding: 0,
      backgroundColor: COLORS.lightGray4,
      fontSize: '0.675rem',

      [theme.breakpoints.up('md')]: {
        fontSize: '0.875rem',
      },
    },

    '& .MuiPaginationItem-ellipsis': {
      color: COLORS.lightGray4,
      fontWeight: 'bold',
      fontSize: '0.675rem',

      [theme.breakpoints.up('md')]: {
        fontSize: '0.875rem',
      },
    },
  },

  buttonPaginationActions: {
    padding: '0.25rem 0.5rem',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',

    '& span, & svg': {
      fontSize: '0.625rem',
      color: COLORS.mediumGray5,
      fontWeight: 'bold',
    },

    '& svg': {
      margin: '0 0.25rem',
    },

    '&:hover': {
      backgroundColor: COLORS.white,

      '& span, & svg': {
        color: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  buttonPaginationActionsDisabled: {
    padding: '0.25rem 0.5rem',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',

    '& span, & svg': {
      fontSize: '0.625rem',
      color: COLORS.lightGray2,
      fontWeight: 'bold',
    },

    '& svg': {
      margin: '0 0.25rem',
    },

    '&:hover': {
      backgroundColor: COLORS.white,

      '& span, & svg': {
        color: COLORS.gray2,
        transition: '0.3s',
      },
    },
  },

  totalResults: {
    fontSize: '0.75rem',
    color: COLORS.lightGray4,
    fontWeight: 'bold',
  },
}));

interface Props {
  page: number;
  handleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
  total: number;
  rowsPerPage: number;
  contentToLeft: JSX.Element | null;
}

export default function CustomPagination({
  page,
  handleChangePage,
  total,
  rowsPerPage,
  contentToLeft,
}: Props) {
  const classes = useStyles();

  const { t } = useTranslation();

  const matchesMediaQueryUpMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  );

  return (
    <div className={classes.container}>
      {contentToLeft ?? <div />}

      <Pagination
        page={page}
        onChange={handleChangePage}
        count={Math.ceil(total / rowsPerPage)}
        shape="rounded"
        siblingCount={matchesMediaQueryUpMD ? 1 : 0}
        size="small"
        boundaryCount={matchesMediaQueryUpMD ? 1 : 0}
        className={classes.pagination}
        renderItem={item => {
          if (item.type === 'previous') {
            return (
              <Button
                disabled={item.disabled}
                onClick={item.onClick}
                size={item.size}
                variant={item.variant}
                color="primary"
                disableElevation
                className={clsx({
                  [classes.buttonPaginationActionsDisabled]: item.disabled,
                  [classes.buttonPaginationActions]: !item.disabled,
                })}
              >
                <ArrowBackIosIcon />
                <span>{t('components.customPagination.prev')}</span>
              </Button>
            );
          }

          if (item.type === 'next') {
            return (
              <Button
                disabled={item.disabled}
                onClick={item.onClick}
                size={item.size}
                variant={item.variant}
                color="primary"
                disableElevation
                className={clsx({
                  [classes.buttonPaginationActionsDisabled]: item.disabled,
                  [classes.buttonPaginationActions]: !item.disabled,
                })}
              >
                <span>{t('components.customPagination.next')}</span>
                <ArrowForwardIosIcon />
              </Button>
            );
          }

          return <PaginationItem {...item} color="primary" />;
        }}
      />

      <Typography className={classes.totalResults}>
        {t('components.customPagination.results')} 1 -{' '}
        {Math.min(rowsPerPage, total)} {t('components.customPagination.of')}{' '}
        {total}
      </Typography>
    </div>
  );
}
