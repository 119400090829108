/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  containerSelectDocument: {
    background: COLORS.background,
    height: '13.125rem',
    borderRadius: '0.5rem',
    position: 'relative',

    '& div': {
      height: '7.25rem',
      width: '33rem',
      backgroundColor: COLORS.white,
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      margin: '0',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '0.5rem',
      columnGap: '0.75rem',

      '& span': {
        fontWeight: 'bold',
        fontSize: '0.875rem',
        color: COLORS.black,

        '& span': {
          color: COLORS.blue,
          cursor: 'pointer',

          '&:hover': {
            color: COLORS.blueHover,
          },
        },
      },

      '& svg': {
        fontSize: '1.875rem',
        color: COLORS.mediumGray5,
      },
    },
  },

  buttonClose: {
    position: 'absolute',
    top: '0.625rem',
    right: '0.625rem',
    color: COLORS.gray2,
    fontSize: '1.625rem',
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.black,
      transition: '0.3s',
      backgroundColor: 'transparent',
    },
  },

  buttonNext: {
    padding: 0,
    backgroundColor: COLORS.gray2,
    color: COLORS.background,
    borderRadius: '0.5rem',
    textTransform: 'none',
    height: '2.375rem',
    width: '5.5rem',
    position: 'absolute',
    bottom: '0.75rem',
    right: '0.75rem',

    '&:hover': {
      backgroundColor: COLORS.black,
      transition: '0.3s',
    },
  },
});

interface Props {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  open: boolean;
  handleClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleNext: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function Upload({
  file,
  setFile,
  open,
  handleClose,
  handleNext,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf',
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        fileRejections[0].errors.forEach(err => {
          console.log(err.message);
        });
        return;
      }

      setFile(acceptedFiles[0]);
    },
  });

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event as React.MouseEvent<HTMLButtonElement>);
        }
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent style={{ padding: '0' }}>
        <div style={{ padding: '1.25rem' }}>
          <div {...getRootProps()} className={classes.containerSelectDocument}>
            <IconButton className={classes.buttonClose} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            {!file ? (
              <div>
                <span>
                  {t('components.modalUploadToSign.textUpload.initial')}{' '}
                  <span>
                    {t('components.modalUploadToSign.textUpload.link')}
                  </span>{' '}
                  {t('components.modalUploadToSign.textUpload.final')}
                </span>
              </div>
            ) : (
              <>
                <div>
                  <PictureAsPdfIcon />

                  <span>{file.name}</span>
                </div>

                <Button onClick={handleNext} className={classes.buttonNext}>
                  {t('components.modalUploadToSign.buttonNext')}
                </Button>
              </>
            )}

            <input hidden {...getInputProps()} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
