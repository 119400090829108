import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { useTranslation } from 'react-i18next';

import useAlert from '../../../hooks/useAlertContext';

import api from '../../../services/api';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  buttonCreateDocument: {
    height: '3rem',
    width: '13.25rem',
    textTransform: 'none',
    fontSize: '1.125rem',
    fontWeight: 600,
    color: COLORS.white,
    backgroundColor: COLORS.greenButton,
    borderRadius: '0.5rem',
    border: COLORS.greenButton,

    '& svg': {
      fontSize: '1.25rem',
    },

    '&:hover': {
      backgroundColor: COLORS.greenHover,
    },
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '0.5rem',
    width: '100%',
  },

  inputField: {
    width: '100%',
    padding: '1rem',
  },
});

interface Props {
  isOpen: boolean;
}

const ModalCreateCostCenter: React.FC<Props> = ({ isOpen = false }) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showAlert } = useAlert();

  const [costCenterName, setCostCenterName] = useState('');

  const classes = useStyles();

  const { t } = useTranslation();

  const handleModal = (boolean: boolean) => {
    setOpen(boolean);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const data = { name: costCenterName };

    try {
      const response = await api.post('/costcenter', data);

      setIsSubmitting(false);
      handleModal(false);

      showAlert('costCenterCreatedSuccessfully', 'success', response.data._id);
      setCostCenterName('');
    } catch (error) {
      console.log(error);
      showAlert('costCenterError', 'error', JSON.stringify(error));
      handleModal(false);
      setCostCenterName('');
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <Button
        startIcon={<ControlPointIcon />}
        onClick={() => {
          handleModal(true);
        }}
        className={classes.buttonCreateDocument}
      >
        {t('components.modalCreateCostCenter.createButton')}
      </Button>

      <Dialog
        open={open}
        onClose={() => {
          handleModal(false);
        }}
      >
        <DialogTitle>{t('components.modalCreateCostCenter.title')}</DialogTitle>

        <DialogContent style={{ minWidth: '27rem' }}>
          <form className={classes.formContainer}>
            <label htmlFor="costCenterName">
              {t('components.modalCreateCostCenter.label')}
            </label>

            <input
              id="costCenterName"
              type="text"
              className={classes.inputField}
              value={costCenterName}
              onChange={e => {
                setCostCenterName(e.target.value);
              }}
              disabled={isSubmitting}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              handleModal(false);
            }}
            disabled={isSubmitting}
          >
            {t('components.modalCreateCostCenter.returnButton')}
          </Button>

          <Button
            onClick={handleSubmit}
            disabled={isSubmitting}
            color="primary"
          >
            {isSubmitting ? (
              <CircularProgress size={16} />
            ) : (
              t('components.modalCreateCostCenter.confirmButton')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCreateCostCenter;
