import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';

import useAlert from '../../../hooks/useAlertContext';
import useSocketContext from '../../../hooks/useSocketContext';

import COLORS from '../../../utils/colors';
import blockchainBundle from '../../../utils/blockchainBundle';

const useStyles = makeStyles({
  tableCellDocument: {
    padding: 0,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.lightOrange,
    },
  },

  divTableCellDocument: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      fontSize: '1.75rem',
      margin: '0 2rem',
    },

    '& div': {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  textTableCellDocument: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '29.5rem',
  },

  buttonOpenDocument: {
    backgroundColor: COLORS.orangeAdvice,
    color: COLORS.white,
    borderRadius: '0.5rem',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '0.5rem 0.75rem',
    fontSize: '0.875rem',

    '&:hover': {
      backgroundColor: COLORS.orangeHover,
    },
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0',

    '& .title': {
      fontWeight: 'bold',
      color: COLORS.gray2,
      fontSize: '1.125rem',
      paddingLeft: '1rem',
      maxWidth: '22.25rem',
    },

    '& .MuiSvgIcon-root': {
      fontSize: '2.25rem',
      color: COLORS.mediumGray3,
      cursor: 'pointer',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '0',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },

  observation: {
    backgroundColor: COLORS.white,
    borderRadius: '0.5rem',
    padding: '0.625rem 0 0 1.5rem',
    border: `1px solid ${COLORS.lightGray3}`,
    height: '3.875rem',
    margin: '2.25rem 0 3.25rem 0',

    '& textarea': {
      color: COLORS.gray1,
      fontSize: '0.75rem',
      padding: 0,
      maxWidth: '25.75rem',
    },
  },

  buttonConfirmOpenDocument: {
    backgroundColor: COLORS.black,
    color: COLORS.white,
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'none',
    height: '3rem',
    width: '13.5rem',
    borderRadius: '0.5rem',

    '&:hover': {
      backgroundColor: COLORS.pureBlack,
      transition: '0.3s',
    },
  },

  buttonDeclineOpenDocument: {
    color: COLORS.mediumGray3,
    border: `1px ${COLORS.mediumGray1} solid`,
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'none',
    height: '3rem',
    width: '13.5rem',
    borderRadius: '0.5rem',
    marginTop: '0.75rem',
    marginBottom: '2.375rem',

    '&:hover': {
      backgroundColor: COLORS.gray5,
      color: COLORS.white,
      transition: '0.3s',
    },
  },

  textDocument: {
    marginTop: '1rem',
    color: COLORS.gray2,
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
});

interface Vault {
  id: string;
  title: string;
  password: string;
  createdAt: string;
  stillWillOpen: boolean;
  observation: string;
}

interface Props {
  type: 'documentName' | 'openDocument';
  documentData: Vault;
}

export default function ModalOpenDocumentVault({ type, documentData }: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [isModalObservation, setIsModalObservation] = useState(
    !!documentData.observation,
  );

  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { socket, socketId } = useSocketContext();

  const publicKeyWeb = JSON.parse(
    localStorage.getItem('@dattaid:publicKeyWeb') || 'null',
  );
  const privateKeyWeb = JSON.parse(
    localStorage.getItem('@dattaid:privateKeyWeb') || 'null',
  );
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setIsModalObservation(!!documentData.observation);
    }, 200);
  };

  const handleConfirmOpenDocument = () => {
    setOpen(false);
    setTimeout(() => {
      setIsModalObservation(false);
      setOpen(true);
    }, 500);
  };

  useEffect(() => {
    if (open) {
      socket.on('open vault', async ({ password }) => {
        try {
          const passwordFile = await blockchainBundle.decrypt(
            password,
            privateKeyWeb,
          );

          history.push(`/vault/${documentData.id}`, {
            passwordFile: JSON.parse(passwordFile.message.message).message
              .message,
          });
        } catch (error) {
          handleClose();
          showAlert('vaultErrorWhenOpen', 'error', null);

          console.log(error);
        }
      });
    }

    return () => {
      socket.off('open vault');
    };
  }, [open]);

  return (
    <>
      {type === 'documentName' ? (
        <TableCell
          align="left"
          onClick={handleOpen}
          className={classes.tableCellDocument}
        >
          <div className={classes.divTableCellDocument}>
            <DescriptionIcon />

            <div>
              <Typography
                className={classes.textTableCellDocument}
                style={
                  documentData.stillWillOpen
                    ? { color: COLORS.orangeAdvice }
                    : {}
                }
              >
                {documentData.title}
              </Typography>

              <Typography style={{ fontSize: '0.75rem' }}>
                {documentData.createdAt}
              </Typography>
            </div>
          </div>
        </TableCell>
      ) : (
        <TableCell align="center" onClick={handleOpen}>
          <Button
            endIcon={<VisibilityOutlinedIcon />}
            className={classes.buttonOpenDocument}
          >
            {t('components.modalOpenDocumentVault.buttonOpen')}
          </Button>
        </TableCell>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle className={classes.titleContainer} disableTypography>
          <Typography className="title">
            {isModalObservation
              ? t('components.modalOpenDocumentVault.contentObservation.title')
              : t('components.modalOpenDocumentVault.content.title')}
          </Typography>

          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent className={classes.content}>
          {isModalObservation ? (
            <>
              <TextField
                fullWidth
                variant="standard"
                defaultValue={documentData.observation}
                InputProps={{ disableUnderline: true }}
                multiline
                minRows={4}
                disabled
                className={classes.observation}
              />

              <Button
                className={classes.buttonConfirmOpenDocument}
                onClick={handleConfirmOpenDocument}
              >
                {t(
                  'components.modalOpenDocumentVault.contentObservation.buttonOpenNow',
                )}
              </Button>

              <Button
                onClick={handleClose}
                className={classes.buttonDeclineOpenDocument}
              >
                {t(
                  'components.modalOpenDocumentVault.contentObservation.buttonOpenAnotherTime',
                )}
              </Button>
            </>
          ) : (
            <>
              <Typography className={classes.textDocument}>
                {t('components.modalOpenDocumentVault.content.remember')}
              </Typography>

              <QRCodeSVG
                value={`{"socket":"${socketId}","method":"APPROVE","needs":["dattaid"],"who":"DattaID","sends":{"password":"${documentData.password}","publicKey":"${publicKeyWeb}"}}`}
                size={256}
                style={{ margin: '1.875rem 0 2.25rem 0' }}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
