import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import COLORS from '../../../utils/colors';

const useStyles = makeStyles({
  buttonBack: {
    color: COLORS.black,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    marginTop: '1.5rem',
    textTransform: 'none',
    padding: 0,
    height: '3rem',
    width: '7.625rem',
    borderRadius: '0.5rem',

    '&:hover': {
      backgroundColor: COLORS.gray2,
      color: COLORS.white,

      '& svg': {
        fill: COLORS.white,
        transition: 'all 0.3s',
      },
      transition: 'all 0.3s',
    },
  },

  title: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiSvgIcon-root': {
      fontSize: '2.25rem',
      color: COLORS.mediumGray4,
      cursor: 'pointer',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 3rem',
  },

  textContent: {
    backgroundColor: COLORS.white,
    fontSize: '1.125rem',
    color: COLORS.gray2,
    maxWidth: '13.75rem',

    '& .delete': {
      color: COLORS.redWarning,
      fontWeight: 'bold',
    },
  },

  buttonNo: {
    backgroundColor: COLORS.black,
    width: '13.5rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.white,
    textTransform: 'none',
    marginTop: '2.25rem',
    fontWeight: 'bold',
    padding: 0,

    '&:hover': {
      backgroundColor: COLORS.pureBlack,
      transition: '0.3s',
    },
  },

  buttonYes: {
    backgroundColor: COLORS.white,
    width: '13.75rem',
    height: '3rem',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: COLORS.black,
    textTransform: 'none',
    border: `1px ${COLORS.black} solid`,
    padding: 0,
    margin: '0.75rem 0 2.25rem 0',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: COLORS.pureBlack,
      color: COLORS.white,
      transition: '0.3s',
    },
  },
});

interface Props {
  setIsShowingExitPrompt: (value: boolean) => void;
}

export default function ModalExitCreateVaultPage({
  setIsShowingExitPrompt,
}: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsShowingExitPrompt(true);
  };

  const handleYes = () => {
    history.push('/vault');
  };

  useEffect(() => {
    if (open) {
      setIsShowingExitPrompt(false);
    }
  }, [open]);

  return (
    <>
      <Button
        startIcon={
          <ArrowBackIcon style={{ color: COLORS.black, fontSize: '1.5rem' }} />
        }
        className={classes.buttonBack}
        onClick={handleOpen}
      >
        {t('components.modalExitCreateVaultPage.buttonBack')}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.title} disableTypography>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>

        <DialogContent className={classes.content}>
          <p className={classes.textContent}>
            {t('components.modalExitCreateVaultPage.content.part1')}{' '}
            <span className="delete">
              {t('components.modalExitCreateVaultPage.content.erase')}
            </span>{' '}
            {t('components.modalExitCreateVaultPage.content.part2')}
          </p>

          <Button className={classes.buttonNo} onClick={handleClose}>
            {t('components.modalExitCreateVaultPage.no')}
          </Button>

          <Button className={classes.buttonYes} onClick={handleYes}>
            {t('components.modalExitCreateVaultPage.yes')}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
