import EthCrypto from 'eth-crypto';
import Mnemonic from 'bitcore-mnemonic';
import privateKeyToPublicKey from 'ethereum-private-key-to-public-key';
import publicKeyToAddress from 'ethereum-public-key-to-address';

import portugueseWords from './words/portuguese';

function generateDattaId() {
  const code = new Mnemonic(portugueseWords);
  const hdPrivateKey = code.toHDPrivateKey();

  const mnemonic = code.toString();
  const privateKey = hdPrivateKey.deriveChild(1).privateKey.toString();
  const publicKey = privateKeyToPublicKey(privateKey).toString('hex');
  const dattaIdAddress = publicKeyToAddress(publicKey);

  return { mnemonic, privateKey, publicKey, dattaIdAddress };
}

async function decrypt(encryptedString: string, privateKey: string) {
  const encrypted = EthCrypto.cipher.parse(encryptedString);

  const decrypted = await EthCrypto.decryptWithPrivateKey(
    privateKey,
    encrypted,
  );

  const payload = JSON.parse(decrypted);

  const senderAddress = EthCrypto.recover(
    payload.signature,
    EthCrypto.hash.keccak256(payload.message),
  );

  const signer = EthCrypto.recoverPublicKey(
    payload.signature,
    EthCrypto.hash.keccak256(payload.message),
  );

  return { message: payload, senderAddress, signer };
}

async function encrypt(message: string, privateKey: string, publicKey: string) {
  const signature = EthCrypto.sign(
    privateKey,
    EthCrypto.hash.keccak256(message),
  );

  const payload = { message, signature };

  const encrypted = await EthCrypto.encryptWithPublicKey(
    publicKey,
    JSON.stringify(payload),
  );

  return EthCrypto.cipher.stringify(encrypted);
}

export default { generateDattaId, encrypt, decrypt };
