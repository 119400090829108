// Credits: https://medium.com/@pablodarde/internacionalizando-uma-aplica%C3%A7%C3%A3o-front-end-8efa3f48fd22
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { ptBR, en } from './locales';

const options = {
  interpolation: { escapeValue: false },

  debug: !!+(process.env.REACT_APP_I18N_MODE_DEBUG as string),

  resources: {
    pt: { common: ptBR },
    en: { common: en },
  },

  fallbackLng: 'en',
  ns: ['common'],
  defaultNS: 'common',
};

i18n.use(LanguageDetector).use(initReactI18next).init(options);

if (i18n.language.startsWith('en')) i18n.changeLanguage('en-US');
if (i18n.language.startsWith('pt')) i18n.changeLanguage('pt-BR');
